import SchoolIcon from '@mui/icons-material/School';
import { Avatar, Box, Divider, Stack, Step, StepLabel, Stepper, ThemeProvider, Typography, createTheme, styled } from '@mui/material';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React from 'react';
import { entelectBlue } from 'src/utilities/customColors';

export interface StudyTimelineProps {
  certificateName: string;
  startDate: string;
  completedDate?: string;
}

const StyledStep = styled(Step)(({ theme, ...props }) => ({
  '& .MuiStepIcon-root': {
    color: '#22A368',
  },
  '& .MuiStepConnector-root': {
    '&.Mui-active': {
      '& .MuiStepConnector-line': {
        borderColor: '#22A368',
      },
    },
    '&.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: '#22A368',
      },
    },
    '& .MuiStepConnector-line': {
      borderColor: props.completed ? '#22A368' : '#eaeaf0',
      borderWidth: 1,
      borderStyle: isEmpty(props.completed) ? 'solid' : 'dotted',
    },
  },
}));

export const StudyTimeline = (props: StudyTimelineProps) => {
  const stepperWidth = 800;

  const calculateLearningTime = (startDate: string, endDate?: string) => {
    const typedStartDate = new Date(startDate).getTime();
    const typedEndDate = (endDate ? new Date(endDate) : new Date()).getTime();
    const timeDifference = typedEndDate - typedStartDate;
    const currentLearningTimeInDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return currentLearningTimeInDays;
  };

  let currentLearningTimeInDays = 0;
  if (!isEmpty(props.completedDate)) {
    currentLearningTimeInDays = calculateLearningTime(props.startDate, props.completedDate!);
  } else {
    currentLearningTimeInDays = calculateLearningTime(props.startDate, '');
  }

  const CircleSchoolIcon = () => {
    const color = props.completedDate ? '#22A368' : '#5E5299';

    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar sx={{ backgroundColor: color, width: 32, height: 32 }}>
          <SchoolIcon sx={{ color: '#FFF' }} />
        </Avatar>
      </Stack>
    );
  };

  const theme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.MuiStepIcon-root': {
              color: '#22A368',
            },
          },
        },
      },
    },
  });

  return (
    <>
      <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography sx={{ color: '#444', fontWeight: 'bold', fontSize: '21px' }}>{props.certificateName}</Typography>

        <Box>
          <Stack sx={{ py: '18px' }}>
            <Divider />
          </Stack>

          <ThemeProvider theme={theme}>
            <Stepper alternativeLabel activeStep={props.completedDate ? 3 : 1} sx={{ width: stepperWidth }}>
              <StyledStep completed={isEmpty(props.completedDate)}>
                <StepLabel>
                  <Typography sx={{ color: '#444', fontWeight: 'normal', fontSize: '14px' }}>Start Date</Typography>
                </StepLabel>
                <Box display="flex" justifyContent="center" sx={{ py: '20px' }}>
                  <Typography sx={{ color: '#444', fontWeight: 'normal', fontSize: '17px' }}>
                    {format(new Date(props.startDate), 'dd MMMM yyyy')}
                  </Typography>
                </Box>
              </StyledStep>

              <StyledStep sx={{ color: entelectBlue }}>
                <StepLabel StepIconComponent={CircleSchoolIcon}>
                  <Typography sx={{ color: props.completedDate ? '#22A368' : '#5E5299', fontWeight: 'normal', fontSize: '16px' }}>
                    {props.completedDate ? 'Total Learning Time' : 'Current Learning Time'}
                  </Typography>
                </StepLabel>

                <Box display="flex" justifyContent="center" sx={{ py: '4px' }}>
                  <Typography sx={{ color: props.completedDate ? '#22A368' : '#5E5299', fontWeight: 'normal', fontSize: '28px' }} variant="h4">
                    {currentLearningTimeInDays} Days
                  </Typography>
                </Box>
              </StyledStep>

              <StyledStep completed={!isEmpty(props.completedDate)}>
                <StepLabel>
                  <Typography sx={{ color: '#444', fontWeight: 'normal', fontSize: '14px' }}>Completion Date</Typography>
                </StepLabel>
                <Box display="flex" justifyContent="center" sx={{ py: '20px' }}>
                  <Typography sx={{ color: '#444', fontWeight: 'normal' }}>
                    {props.completedDate ? format(new Date(props.completedDate), 'dd MMMM yyyy') : 'TBC'}
                  </Typography>
                </Box>
              </StyledStep>
            </Stepper>
          </ThemeProvider>
        </Box>
      </Stack>
    </>
  );
};
