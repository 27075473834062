import React from "react";

const EyeIcon = (props:JSX.IntrinsicElements['svg']) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
    <circle cx="9.5" cy="9.5" r="9.5" fill="#0089CF" />
    <g clipPath="url(#clip0_5063_24968)">
      <path
        d="M9.49984 5.4375C6.7915 5.4375 4.47859 7.12208 3.5415 9.5C4.47859 11.8779 6.7915 13.5625 9.49984 13.5625C12.2082 13.5625 14.5211 11.8779 15.4582 9.5C14.5211 7.12208 12.2082 5.4375 9.49984 5.4375ZM9.49984 12.2083C8.00484 12.2083 6.7915 10.995 6.7915 9.5C6.7915 8.005 8.00484 6.79167 9.49984 6.79167C10.9948 6.79167 12.2082 8.005 12.2082 9.5C12.2082 10.995 10.9948 12.2083 9.49984 12.2083ZM9.49984 7.875C8.60067 7.875 7.87484 8.60083 7.87484 9.5C7.87484 10.3992 8.60067 11.125 9.49984 11.125C10.399 11.125 11.1248 10.3992 11.1248 9.5C11.1248 8.60083 10.399 7.875 9.49984 7.875Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5063_24968">
        <rect width="13" height="13" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
export default EyeIcon;
