import { useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import EyeIcon from '../shared/EyeIcon';

interface EyeBadgeProps {
  show?: boolean;
}

const EyeBadge: React.FC<EyeBadgeProps> = ({ children, show }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!show) return <>{children}</>;
  return (
    <div style={{ position: 'relative' }}>
      {children}
      <span
        style={{
          position: 'absolute',
          bottom: isMobile ? 12 : 0,
          right: isMobile ? 22 : 0,
          pointerEvents: 'none',
        }}>
        <EyeIcon color="inherit" fontSize={0.1} />
      </span>
    </div>
  );
};
export default EyeBadge;
