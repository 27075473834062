import { AxiosResponse } from 'axios';
import {
  CertificationFilterRequestModel,
  DashboardEmployee,
  EmployeeDetails,
  EmployeeDetailsResponse,
  IncommingDashboardMetrics,
  LearningPathFilterRequestModel,
  ReducedEmployee,
  RelatedEmployee,
} from 'src/Models/EmployeeModels';

import { BaseService } from './BaseService';

export type DashboardMetrics = {
  quickFilterCounts: QuickFilterCounts;
};

export type QuickFilterCounts = {
  flaggedCount: number;
  negativeSentimentCount: number;
  notOnAPathOrCertCount: number;
  noFeedbackCount: number;
};

export class EmployeeService extends BaseService {
  constructor() {
    super('Employee');
  }

  async find(search: string): Promise<AxiosResponse> {
    return await this.httpService.get(`/search?search=${search}`);
  }

  async getActiveEmployees(): Promise<any> {
    return (await this.httpService.get(`/employees/active`)).data;
  }

  async getArchivedEmployeesForSquad(id: string): Promise<any> {
    return (await this.httpService.get(`/employees/archived/${id}`)).data;
  }

  async getActiveEmployeesForSquad(id: string): Promise<any> {
    return (await this.httpService.get(`/employees/active/${id}`)).data;
  }

  async getActiveDashboardEmployeesForSquad(id: string): Promise<DashboardEmployee[]> {
    return (await this.httpService.get(`/employees/dashboard/active/${id}`)).data;
  }

  async getAllEmployeesForSquad(id: string): Promise<ReducedEmployee[]> {
    return (await this.httpService.get(`/employees/${id}`)).data;
  }

  async getProfilePicture(email: string, size: number): Promise<any> {
    return (await this.httpService.get(`/GetProfilePhoto/${email}?Size=${size}`)).data;
  }

  async getAllProfilePictures(squadId: number, size: number): Promise<any> {
    return (await this.httpService.get(`/profilePictures/${squadId}/${size}`)).data;
  }

  async getMobileNumber(email: string): Promise<any> {
    return (await this.httpService.get(`/GetMobileNumber/${email}`)).data;
  }

  async getEmployeesForSquadById(id: string, squadId: string): Promise<any> {
    return (await this.httpService.get(`/${id}/${squadId}`)).data;
  }

  async getEmployeeByEmployeeSquadId(employeeSquadId: string, squadId: string): Promise<any> {
    return (await this.httpService.get(`/employeeSquad/${employeeSquadId}/${squadId}`)).data;
  }

  async getRelatedEmployeesByEmployeeId(squadId: string, employeeId: number): Promise<RelatedEmployee[]> {
    return (await this.httpService.get(`/getRelatedEmployeesByEmployeeId/${squadId}/${employeeId}`)).data;
  }

  async deleteEmployeeBySquad(id: string, squadId: string): Promise<any> {
    return (await this.httpService.delete(`/${id}/${squadId}`)).data;
  }

  async getSquadsByEmployeeId(employeeId: number, squadId: number): Promise<EmployeeDetails> {
    const response = (await this.httpService.get(`/getEmployeeDetailsByEmployeeId/${employeeId}/${squadId}`)).data as EmployeeDetailsResponse;
    function mapEmployeeData(employee: EmployeeDetailsResponse): EmployeeDetails {
      return {
        employeeId: employee.employeeId,
        currentEmployeeSquads: employee.currentEmployeeSquads,
        currentEmployeeGroups: employee.currentEmployeeGroups,
        projectHistory: employee.projectHistory.map((project: any) => ({
          squadName: project.squad.squadName,
          shortName: project.squad.shortName,
          startDate: new Date(project.startDate),
          endDate: project.endDate ? new Date(project.endDate) : undefined,
        })),
      };
    }

    const projectDetails = mapEmployeeData(response);
    return projectDetails;
  }

  async getActiveDashboardEmployees(
    id: number,
    showAllEmployees: boolean,
    learningPathFilterRequest?: LearningPathFilterRequestModel,
    certificationFilterRequest?: CertificationFilterRequestModel,
  ): Promise<DashboardEmployee[]> {
    const params = new URLSearchParams();

    if (learningPathFilterRequest?.selectedPathName) {
      params.append('PathName', learningPathFilterRequest.selectedPathName.toString());
    }

    if (learningPathFilterRequest?.selectedPathStatus) {
      params.append('PathStatus', learningPathFilterRequest.selectedPathStatus.toString());
    }

    if (learningPathFilterRequest?.selectedPhaseName) {
      params.append('PhaseName', learningPathFilterRequest.selectedPhaseName.toString());
    }

    if (learningPathFilterRequest?.selectedPhaseStatus) {
      params.append('PhaseStatus', learningPathFilterRequest.selectedPhaseStatus.toString());
    }

    if (certificationFilterRequest?.certificationId) {
      params.append('certificationId', certificationFilterRequest.certificationId.toString());
    }

    if (certificationFilterRequest?.companyId) {
      params.append('companyId', certificationFilterRequest.companyId.toString());
    }

    if (certificationFilterRequest?.certificationStatus) {
      params.append('certificationStatus', certificationFilterRequest.certificationStatus.toString());
    }

    const queryString = params.toString();
    const url = queryString
      ? `/employees/dashboard/active/${id}/${showAllEmployees}?${queryString}`
      : `/employees/dashboard/active/${id}/${showAllEmployees}`;

    const response = await this.httpService.get(url);
    return response.data;
  }

  async getQuickFilterEmployees(
    filterBy: string,
    squadId: string,
    showAllEmployees: boolean,
    startDate: Date | null,
    endDate: Date | null,
  ): Promise<DashboardEmployee[]> {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', new Date(startDate).toISOString());
    }

    if (endDate) {
      params.append('endDate', new Date(endDate).toISOString());
    }
    const queryString = params.toString();

    const url = queryString
      ? `/dashboard/quickfilter/${filterBy}/${squadId}/${showAllEmployees}?${queryString}`
      : `/dashboard/quickfilter/${filterBy}/${squadId}/${showAllEmployees}`;

    return (await this.httpService.get(url)).data;
  }

  async GetEmployeesNoFeedbackByCategory(
    categoryId: number,
    squadId: string,
    startDate: Date | null,
    endDate: Date | null,
  ): Promise<DashboardEmployee[]> {
    return (
      await this.httpService.get(
        `/dashboard/quickfilter/feedbackCategory/${categoryId}/${squadId}?startDate=${startDate ? new Date(startDate).toISOString() : ''}&endDate=${
          endDate ? new Date(endDate).toISOString() : ''
        }`,
      )
    ).data;
  }

  async GetIncommingDashboardEmployeeQuickFilter(squadId: string, filterType: string): Promise<DashboardEmployee[]> {
    return (await this.httpService.get(`/employees/incomming/${squadId}/${filterType}`)).data;
  }

  async GetIncommingEmployeeMetrics(squadId: string): Promise<IncommingDashboardMetrics> {
    return (await this.httpService.get(`/employees/incomming/metrics/${squadId}`)).data;
  }

  async GetAllIncommingEmployees(squadId: number): Promise<DashboardEmployee[]> {
    return (await this.httpService.get(`/employees/incomming/${squadId}`)).data;
  }

  async GetIncommingEmployeesByStatusName(squadId: number, statusId: number): Promise<DashboardEmployee[]> {
    return (await this.httpService.get(`/employees/incomming/status/${statusId}/${squadId}`)).data;
  }

  async GetActiveEmployeesBySelectedTagId(
    squadId: number,
    tagId: number,
    showAllEmployees: boolean,
    startDate: Date | null,
    endDate: Date | null,
  ): Promise<DashboardEmployee[]> {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', new Date(startDate).toISOString());
    }

    if (endDate) {
      params.append('endDate', new Date(endDate).toISOString());
    }

    const queryString = params.toString();
    const url = queryString
      ? `/dashboard/tagGraph/${squadId}/${tagId}/${showAllEmployees}?${queryString}`
      : `/dashboard/tagGraph/${squadId}/${tagId}/${showAllEmployees}`;
    return (await this.httpService.get(url)).data;
  }

  async getDashboardMetrics(id: string, showAllEmployees: boolean, startDate: Date | null, endDate: Date | null): Promise<DashboardMetrics> {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', new Date(startDate).toISOString());
    }

    if (endDate) {
      params.append('endDate', new Date(endDate).toISOString());
    }
    const queryString = params.toString();

    const url = queryString ? `/employees/metrics/${id}/${showAllEmployees}?${queryString}` : `/employees/metrics/${id}/${showAllEmployees}`;

    return (await this.httpService.get(url)).data;
  }
}
