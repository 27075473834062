import { Card, CardHeader } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProfilePicture from 'src/components/employee/ProfilePicture';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  squadGroupCard: {
    borderRadius: '0!important',
    '&:hover': {
      background: '#13397010',
      cursor: 'pointer',
    },
    '& .MuiCardHeader-title': {
      fontSize: '18px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      color: '#000000',
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '14px',
      fontFamily: 'Roboto',
      marginTop: '0px',
    },
  },
}));
interface Props {
  squadGroup: any;
  selectedSquadGroup?: any;
  selectSquadGroup: (squadGroup: any) => void;
}

const GroupCard = (props: Props) => {
  const { squadGroup, selectedSquadGroup, selectSquadGroup } = props;

  const { classes } = useStyles();
  const [background, setBackground] = useState('#FFF');
  const [borderLeft, setBorderLeft] = useState('none');

  useEffect(() => {
    if (squadGroup?.groupId === selectedSquadGroup?.groupId) {
      setBackground('rgba(19, 57, 112, 0.1)');
      setBorderLeft('7px solid #133970');
    } else {
      setBackground('#FFFFFF');
      setBorderLeft('none');
    }
  }, [squadGroup, selectedSquadGroup]);

  return (
    <Card
      data-testid="group-card"
      variant="outlined"
      className={classes.squadGroupCard}
      sx={{
        backgroundColor: { background },
        borderLeft: { borderLeft },
      }}
      onClick={() => selectSquadGroup(squadGroup)}>
      <CardHeader
        avatar={<ProfilePicture alt={''} emailAddress={squadGroup.groupOwner.squadLeader.user.emailAddress} />}
        title={squadGroup.groupName}
        subheader={`Group Owner: ${squadGroup.groupOwner.squadLeader.user.fullName}`}
      />
    </Card>
  );
};

export default GroupCard;
