import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Box, Button, Stack, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useToggle } from 'src/hooks/useToggle';
import NoSquadGroupsImage from '../../images/NoSquadGroupsImage';
import CreateSquadGroupModal from '../squadGroup/CreateSquadGroupModal';
import useIsMounted from '../../hooks/useIsMounted'

const EmptySquadGroup = ({ handleSuccess }: { handleSuccess: () => void }) => {
  const [openCreateGroupModal, toggleCreateGroupModal] = useToggle();
  const isMounted = useIsMounted();
  return (
    <>
      <Stack direction="column" alignItems="center" gap={2} data-testid="empty-squad-group" sx={{ width: '100%' }}>
        <Fragment>
          <Box width={'100%'}>
            <NoSquadGroupsImage />
          </Box>
          <Stack direction="column" alignItems="center" gap={2} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Oh no!
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: 'center' }}>
              It looks like you have not created any groups yet.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Create your first group today!
            </Typography>
            <Button variant="contained" onClick={() => toggleCreateGroupModal()} color="secondary" startIcon={<AddBoxOutlinedIcon />}>
              Create Group
            </Button>
          </Stack>
        </Fragment>
      </Stack>
      {isMounted() && (
        <CreateSquadGroupModal
          handleSuccess={handleSuccess}
          openCreateGroupModal={openCreateGroupModal}
          handleCloseCreateGroupModal={toggleCreateGroupModal}
          selectedGroup={null}
        />
      )}
    </>
  );
};

export default EmptySquadGroup;
