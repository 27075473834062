import React, { createContext } from 'react';

interface LoggedInUserContextState {
  loggedInUser: string;
  setLoggedInUser: React.Dispatch<React.SetStateAction<string>>;
  loggedInUserFullName: string;
  setLoggedInUserFullName: React.Dispatch<React.SetStateAction<string>>;
}

const initialState: LoggedInUserContextState = {
  loggedInUser: '',
  setLoggedInUser: () => {},
  loggedInUserFullName: '',
  setLoggedInUserFullName: () => {},
};

export const LoggedInUserContext = createContext(initialState);

export const LoggedInUserProvider = ({ children }: { children: React.ReactNode }) => {
  const [loggedInUser, setLoggedInUser] = React.useState('');
  const [loggedInUserFullName, setLoggedInUserFullName] = React.useState('');

  return (
    <LoggedInUserContext.Provider value={{ loggedInUser, setLoggedInUser, loggedInUserFullName, setLoggedInUserFullName }}>
      {children}
    </LoggedInUserContext.Provider>
  );
};
