import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Stack, Grow, ListItem, Box } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import FullDateWithDivider from '../FullDateWithDivider';
import FeedbackCard from './feedback/history/FeedbackCard';
import useIsMobile from 'src/hooks/useIsMobile';
import { FeedbackGroup, FeedbackService } from 'src/services/FeedbackService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { useCategoryContext } from './context/CategoryContext';
import FeedbackCardLoader from './feedback/history/FeedbackCardLoader';

interface CombinedComponentProps {
  open: boolean;
  onClose: () => void;
  refreshData?: () => void;
  tagId: number;
  employeeId: number;
  squadId: string;
}

const EmployeeTagModal: React.FC<CombinedComponentProps> = ({ open, onClose, tagId, employeeId, squadId }) => {
  const { squad } = useContext(SquadsContext);
  const { categoryList } = useCategoryContext();
  const [searchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<FeedbackGroup[] | any>(null);
  const isMobile = useIsMobile();
  const [isLoading, setisloading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const feedbackService = new FeedbackService();

      try {
        const response = await feedbackService.getFeedbackByTagAndEmployeeId(tagId, employeeId, squadId);
        setSearchResults(response.data);
        setisloading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [squad.squadId, searchTerm]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
        <DialogTitle sx={{ backgroundColor: '#E0E0E0', display: 'flex', justifyContent: 'center', color: 'black' }}>
          <Box>Feedback</Box>
        </DialogTitle>
        <DialogContent>
          <div>
            <Stack spacing={9}>
              {isLoading && (
                <ListItem sx={{ mt: '20px' }}>
                  <FeedbackCardLoader />
                </ListItem>
              )}
              {searchResults?.map((feedbackItem: { createdDate: any; feedback: any[] }, index: any) => (
                <Stack spacing={3} key={`${feedbackItem.createdDate}-${index}-${employeeId}`} sx={{ marginY: { xs: '4px' } }}>
                  {!isMobile && feedbackItem?.feedback?.length > 0 && <FullDateWithDivider sticky date={feedbackItem.createdDate} />}
                  <TransitionGroup>
                    {feedbackItem.feedback?.map((item) => (
                      <Grow key={item.feedbackId}>
                        <ListItem key={item.feedbackId} sx={{ width: '100%', padding: 0, mt: 1 }}>
                          <FeedbackCard feedbackItem={item} categoryList={categoryList} displayEmployeeDetail={false} />
                        </ListItem>
                      </Grow>
                    ))}
                  </TransitionGroup>
                </Stack>
              ))}
            </Stack>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmployeeTagModal;
