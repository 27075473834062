import React, { createContext, useContext, useState } from 'react';
type TabVisibilityContextType = {
  showTabContext: boolean;
  toggleTabVisibility: (forceValue?: boolean) => void;
};
const TabVisibilityContext = createContext<TabVisibilityContextType>({
  showTabContext: false,
  toggleTabVisibility: () => {},
});
export const useTabVisibility = () => useContext(TabVisibilityContext);
export const TabVisibilityProvider: React.FC = ({ children }) => {
  const [showTabContext, setShowTabContext] = useState(true);
  const toggleTabVisibility = (forceValue?: boolean) => {
    if (typeof forceValue === 'boolean') {
      setShowTabContext(forceValue);
    } else {
      setShowTabContext((prevState) => !prevState);
    }
  };

  return <TabVisibilityContext.Provider value={{ showTabContext, toggleTabVisibility }}>{children}</TabVisibilityContext.Provider>;
};
