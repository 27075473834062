import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
 
import { FeedbackGroup } from 'src/services/FeedbackService';
import { useCategoryContext } from '../../../context/CategoryContext';
import { isEmpty } from 'lodash';
 
const defaultCategory = {
  id: 0,
  color: '#5300EB',
  backgroundColor: '#5300EB33',
  name: 'All',
};
 
const flaggedCategory: any = {
  categoryId: -1,
  color: '#666',
  backgroundColor: '#DDD',
  name: 'Flagged',
};
 
const overviewSelectedCategory: any = {
  id: -2,
  color: '#8479B1',
  backgroundColor: '#8479B130',
  name: 'Selected',
};
 
interface Category {
  categoryId: number;
  hexColor: string;
  backgroundHexColor: string;
  name: string;
}
 
interface FilterComponentProps {
  selectedCategory: number;
  feedbackItems: FeedbackGroup[];
  handleSelect: (category: number) => void;
  isSelectedFeedbackItem?: boolean;
  hasFlaggedFeedback?: boolean;
}
 
const FilterComponent = ({ selectedCategory, feedbackItems, handleSelect, isSelectedFeedbackItem, hasFlaggedFeedback }: FilterComponentProps) => {
  const { categoryList } = useCategoryContext();
 
  const handleCategorySelect = (category: number) => {
     handleSelect(category);
  };
 
  if (isEmpty(feedbackItems) && selectedCategory === 0) return null;
 
  return (
     <>
       <Grid pt={2}>
         <Grid item xs={12}>
           <Autocomplete
           
             value={categoryList.find((category) => category.categoryId === selectedCategory) || defaultCategory}
             onChange={(_, newValue: Category | null) => handleCategorySelect(newValue ? newValue.categoryId : defaultCategory.id)}
             options={[
               ...(hasFlaggedFeedback ? [...categoryList, flaggedCategory] : categoryList),
               selectedCategory === -2 ? overviewSelectedCategory : defaultCategory,
             ].filter((option) => option.name !== 'All')}
             getOptionLabel={(option: Category) => option.name}
             renderInput={(params) => <TextField {...params} label="Filter: " sx={{ fontSize: 16 }} />}
             renderOption={(props, option: Category) => (
               <li {...props}>
                 {option.name}
               </li>
             )}
           />
         </Grid>
       </Grid>
     </>
  );
 };
 
 
export default FilterComponent;
 