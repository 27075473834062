import React from 'react';
import { SplashScreenImage } from './SplashScreenImage';

export const SplashScreen = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh',
      }}>
      <img src={require('./EntelectLogo.png')} alt="Entelect Logo" style={{ width: '493px', height: '166px' }} />
      <SplashScreenImage />
    </div>
  );
};
