import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Autocomplete, TextField, CircularProgress, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { Category } from 'src/services/FeedbackType';

import { useFilterContext } from './context/FilterContext';

export interface QuickFilterFeedbackCategoryModalProps {
  open: boolean;
  onClose: () => void;
  feedbackCategories: any;
  applyFilter: (category: Category | null, startDate: Date | null, endDate: Date | null) => void;
}

const QuickFilterFeedbackCategoryModal: React.FC<QuickFilterFeedbackCategoryModalProps> = ({ open, onClose, feedbackCategories, applyFilter }) => {
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(new Date());
  const { endDate, startDate } = useFilterContext();

  const handleModalClose = () => {
    onClose();
  };

  useEffect(() => {
    setIsDisabled(!selectedCategory);
  }, [selectedCategory]);

  const onSelectedCategoryChange = (value: Category | null) => {
    setSelectedCategory(value);
  };

  const handleFilterEmployeeList = () => {
    applyFilter(selectedCategory, selectedStartDate, selectedEndDate);
    handleModalClose();
  };

  const handleSetEndDate = () => {
    if (endDate) setSelectedEndDate(endDate);
    else setSelectedEndDate(null);
  };

  const handleSetStartDate = () => {
    if (startDate) setSelectedStartDate(startDate);
    else setSelectedStartDate(null);
  };

  useEffect(() => {
    handleSetEndDate();
    handleSetStartDate();
  }, [endDate, startDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Select category and dates</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ marginTop: '10px', justifyContent: 'center' }}>
            <Grid item xs={12}>
              {feedbackCategories ? (
                <Autocomplete
                  fullWidth
                  options={feedbackCategories}
                  getOptionLabel={(category: Category) => category.name}
                  multiple={false}
                  filterSelectedOptions
                  onChange={(event, value) => onSelectedCategoryChange(value)}
                  renderInput={(params) => <TextField {...params} size="medium" label="Select feedback category" />}
                />
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFilterEmployeeList} color="primary" disabled={isDisabled}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default QuickFilterFeedbackCategoryModal;
