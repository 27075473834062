import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { EmployeeLearningPathDTO, EmployeeLearningPhaseDTO } from 'src/Models/LearninpathModels';
import { calculateTimeDifferenceInDaysOrWeeks } from 'src/utilities/DateUtilities';

import { useEditor } from '../Editor/EditorContext';
import { CERTIFICATION_TAG_COLOUR, LEARNING_PATH_TAG_TYPE } from '../mentions/MentionsShared';
import EditLearningPathModal from './Modals/EditLearningPathModal';
import './Modals/ModalStyles.css';
import { useLearningPathContext } from './context/LearningPathContext';

interface EmployeeLearningPathTagsProps {
  employeeLearningPaths: EmployeeLearningPathDTO[] | undefined;
}

const EmployeeLearningPathTagComponent: React.FC<EmployeeLearningPathTagsProps> = ({ employeeLearningPaths }) => {
  const { appendTextToEditorContentWithMentions } = useEditor();
  const [displayedPaths] = useState(employeeLearningPaths);
  const [showLearningPathModal, setShowLearningPathModal] = useState(false);
  const [selectedPath, setSelectedPath] = useState<EmployeeLearningPathDTO>();

  const {
    setSelectedLearningPathPhase,
    setSelectedLearningPathName,
    setSelectedLearningPath,
    setToggleChecklist,
    toggleChecklist,
    selectedLearningPathName,
    clearLearningPathSelectionContext,
  } = useLearningPathContext();

  const handleShowModal = (employeeLearningPath: EmployeeLearningPathDTO) => {
    if (employeeLearningPath) {
      setSelectedPath(employeeLearningPath);
      setShowLearningPathModal(true);
    }
  };

  const handleCloseEditModal = () => {
    setShowLearningPathModal(false);
  };

  const handleOnClick = (employeeLearningPathPhase: EmployeeLearningPhaseDTO | undefined, learningPath: EmployeeLearningPathDTO) => {
    if (selectedLearningPathName === '') {
      setSelectedLearningPathPhase(employeeLearningPathPhase);
      setSelectedLearningPathName(learningPath.name!);
      setSelectedLearningPath(learningPath);
      setToggleChecklist(!toggleChecklist);
    } else {
      clearLearningPathSelectionContext();
    }
    if (!toggleChecklist) {
      appendTextToEditorContentWithMentions(
        `${learningPath.name} - ${employeeLearningPathPhase?.name!}`,
        employeeLearningPathPhase?.id!,
        LEARNING_PATH_TAG_TYPE,
        CERTIFICATION_TAG_COLOUR,
      );
    } else {
      setSelectedLearningPathName('');
    }
  };

  return (
    <Container maxWidth="lg" disableGutters id="employee-learning-path-tag-component">
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        {displayedPaths &&
          displayedPaths.map((learningPath) => {
            const activePhase = (learningPath.phases || []).find((phase) => phase.isActive) || undefined;
            const pausedPhase = (learningPath.phases || []).some((phase) => phase.status === 'Paused');
            const selected = learningPath.name === selectedLearningPathName && toggleChecklist;
            const isLearningPathPhaseOverdue = activePhase?.endDate && new Date(activePhase.endDate) < new Date();
            const daysOverdueCaption =
              activePhase?.endDate &&
              calculateTimeDifferenceInDaysOrWeeks(`${new Date().toISOString()}`, `${new Date(activePhase?.endDate).toISOString()}`);

            return (
              <Grid item mr={1} key={learningPath.id}>
                <Grid container direction="column" justifyContent="center">
                  <Grid item justifyContent="center">
                    <Box display="flex" alignItems="center">
                      <SettingsIcon
                        sx={{ opacity: '56%', transition: 'opacity 0.3s' }}
                        onClick={() => handleShowModal(learningPath)}
                        style={{ cursor: 'pointer' }}
                        className="rotateOnHover"
                      />
                      <Button
                        sx={{
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                          color: '#00695C',
                          fontWeight: 'bold',
                          borderWidth: '2px',
                          borderColor: '#00695C',
                          backgroundColor: selected ? '#00695C' : 'none',
                          '&:hover': {
                            backgroundColor: selected ? '#00695C' : 'none',
                            borderColor: '#00695C',
                            borderWidth: '2px',
                          },
                        }}
                        disableRipple
                        variant="outlined"
                        size="small"
                        key={activePhase?.id}
                        onClick={() => handleOnClick(activePhase, learningPath)}>
                        <Typography
                          sx={{
                            fontFamily: 'Roboto',
                            fontSize: '13px',
                            fontWeight: '500',
                            fontStyle: 'normal',
                            color: selected ? '#FFF' : '#00695C',
                          }}>
                          {learningPath.name + '-' + learningPath.phases?.filter((phase) => phase.isActive).map((phase) => phase.name)[0]}
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }} mt="2px" mb="16px">
                    {isLearningPathPhaseOverdue && !pausedPhase && (
                      <Typography sx={{ color: '#EF6C00', lineHeight: '12px' }} variant="caption">
                        {daysOverdueCaption}
                      </Typography>
                    )}
                    {pausedPhase && (
                      <Typography sx={{ color: '#EF6C00', lineHeight: '12px' }} variant="caption">
                        Paused
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>

      {selectedPath && <EditLearningPathModal learningPath={selectedPath} open={showLearningPathModal} onClose={handleCloseEditModal} />}
    </Container>
  );
};

export default EmployeeLearningPathTagComponent;
