import { Tab, styled } from "@mui/material";
import { DEFAULT_TAB_HEIGHT } from "src/utilities/Constants";

const StyledTab = styled(Tab)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    height: DEFAULT_TAB_HEIGHT,
    minHeight: DEFAULT_TAB_HEIGHT,
}));

export default StyledTab;