import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { EditFeedbackMentions } from 'src/components/mentions/EditFeedbackMentions';
import { useAsync } from 'src/hooks/useAsync';
import { Category, Feedback } from 'src/services/FeedbackType';
import { SquadLeaderContext } from 'src/utilities/SquadLeaderContext';

import { FeedbackFollowUp, FeedbackPayload, FeedbackService } from '../../../../../services/FeedbackService';
import mapJobTitleToCategory from '../../../../../utilities/MapJobTitleToCategory';
import FlagTooltip from '../../../../shared/FlagTooltip';
import { useEntityContext } from '../../../context/EntityContext';
import { useFeedbackContext } from '../../../context/FeedbackContext';
import FeedbackFollowUpForm from '../../form/FeedbackFollowUpForm';
import SentimentSelector from '../../form/components/SentimentSelector';

interface EditFeedbackCardProps {
  feedbackItem: Feedback;
  categoryList: Category[];
  handleClose: () => void;
  refreshData: () => void;
  isMobile?: boolean;
}

const feedbackService = new FeedbackService();

const EditFeedbackCard = ({ feedbackItem, categoryList, handleClose, refreshData, isMobile }: EditFeedbackCardProps) => {
  const [description, setDescription] = useState<string>(feedbackItem?.description);
  const [categoryId, setCategoryId] = useState<number>(feedbackItem.category.categoryId);
  const [sentimentId, setSentimentId] = useState<number>(feedbackItem.sentiment.sentimentId);
  const { mutateFeedbackItems, displayedFeedbackItems } = useFeedbackContext();
  const { activeSquadLeader } = React.useContext(SquadLeaderContext);
  const [doSubmitEdit, setDoSubmitEdit] = useState(false);
  const [originalFollowUp, setOriginalFollowUp] = useState(false);
  const [feedbackFollowUp, setFeedbackFollowUp] = useState<FeedbackFollowUp>();

  const submitEdit = async () =>
    feedbackService
      .edit({
        ...feedbackItem,
        description: description.trim(),
        categoryId: categoryId,
        sentimentId: sentimentId,
        employeeId: employee?.employeeId ?? 0,
        createdBy: activeSquadLeader?.user?.emailAddress,
        assignedRole: mapJobTitleToCategory(employee ? employee?.jobTitle : ''),
        followUp: followUp,
        feedbackFollowUp: feedbackFollowUp ? feedbackFollowUp : null,
      })
      .then(refreshData);

  const { execute } = useAsync(submitEdit, false, 'Failed to edit feedback');

  useEffect(() => {
    if (doSubmitEdit) {
      execute();
    }
  }, [doSubmitEdit]);

  const handleSave = async (data: FeedbackPayload) => {
    if (!data.description || !data.categoryId || !data.sentimentId) return;

    setDescription(data.description);
    setCategoryId(data.categoryId);
    setSentimentId(data.sentimentId);
    if (data.feedbackFollowUp && data.feedbackFollowUp !== undefined) {
      setFeedbackFollowUp(data.feedbackFollowUp);
    }

    const newFeedbackItems = displayedFeedbackItems.map((item) => ({
      ...item,
      feedback: item.feedback.map((f) =>
        f.feedbackId === feedbackItem.feedbackId
          ? {
              ...f,
              description: data.description.trim(),
              categoryId: data.categoryId,
              sentimentId: data.sentimentId ? data.sentimentId : 0,
            }
          : f,
      ),
    }));

    mutateFeedbackItems(newFeedbackItems);

    setDoSubmitEdit(true);
    handleClose();
  };

  const { employee } = useEntityContext();

  const methods = useForm<FeedbackPayload>({
    defaultValues: {
      sentimentId: feedbackItem.sentimentId,
      categoryId: feedbackItem.categoryId,
      employeeId: employee?.employeeId,
      description: feedbackItem.description,
    },
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = methods;
  useEffect(() => {
    if (feedbackItem) {
      setValue('categoryId', feedbackItem.category.categoryId);
      setValue('sentimentId', feedbackItem.sentiment.sentimentId);
      setValue('description', feedbackItem.description);
      setValue('followUp', feedbackItem.followUp);
      setOriginalFollowUp(feedbackItem.followUp);
    }
  }, [feedbackItem]);

  const followUp = watch('followUp');

  const [resetEditorState, setResetEditorState] = useState(false);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            backgroundColor: '#E1E1E1',
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
            px: '6px',
            height: 34,
            width: !isMobile ? '700px' : '100%',
          }}>
          {!originalFollowUp && (
            <FlagTooltip followUp={followUp} handleConfirm={() => setValue('followUp', !followUp)}>
              <Stack direction="row" alignItems="center" sx={{ cursor: 'pointer' }}>
                <Typography sx={{ mr: 1 }} variant="subtitle2">
                  {!followUp ? 'Flag' : 'Unflag'} this
                </Typography>
                {followUp ? <FlagIcon fontSize="medium" /> : <FlagOutlinedIcon fontSize="medium" />}
              </Stack>
            </FlagTooltip>
          )}
        </Stack>

        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{
            required: 'Feedback notes is required.',
          }}
          render={({ field }) => (
            <div style={{ paddingBottom: '12px' }}>
              <EditFeedbackMentions
                {...field}
                onEditorStateChange={() => {}}
                resetEditorState={resetEditorState}
                setResetEditorState={setResetEditorState}
                textToAdd={feedbackItem.description}
                feedbackId={feedbackItem.feedbackId}
              />
            </div>
          )}
        />
        {errors.description && <FormHelperText error>{errors.description.message}</FormHelperText>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl required size="small" fullWidth>
              <InputLabel id="select-label">Feedback Category</InputLabel>
              <Controller
                name="categoryId"
                control={control}
                rules={{ required: 'Feedback category is required.', pattern: { value: /[1-9]\d*$/, message: 'Feedback category is required.' } }}
                render={({ field }) => (
                  <Select
                    labelId="select-label"
                    id="feedback-select"
                    label="Feedback Category"
                    {...field}
                    error={!!errors.categoryId}
                    value={field.value}>
                    {categoryList.map((category) => (
                      <MenuItem key={category.categoryId} value={category.categoryId}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.categoryId && <FormHelperText error>{errors.categoryId.message}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <SentimentSelector />
            <FeedbackFollowUpForm />
            <Button variant="outlined" color="error" sx={{ height: '36px', ml: 2 }} onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" color={'secondary'} variant="contained" sx={{ height: '36px', ml: 2, width: '96px' }}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default EditFeedbackCard;
