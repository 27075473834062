import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import { useEntityContext } from 'src/components/feedbackComponent/context/EntityContext';

const DynamicTitleComponent: React.FC = () => {
  const { employee } = useEntityContext();
  const location = useLocation();
  const params = useParams();
  const hasEmployeeIdParam = !!params['id'] && params && params.tab === 'individuals';

  useEffect(() => {
    const title = employee && hasEmployeeIdParam ? employee.fullName : 'Dreadnought';
    document.title = title;
  }, [employee, hasEmployeeIdParam, location.pathname, params]);

  return (
    <Helmet>
      <title>{employee && hasEmployeeIdParam ? employee.fullName : 'Dreadnought'}</title>
    </Helmet>
  );
};

export default DynamicTitleComponent;
