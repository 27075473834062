import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { EmployeeProjectTimelineData } from 'src/Models/EmployeeModels';
import useIsMobile from 'src/hooks/useIsMobile';

interface EmployeeProjectTimelineProps {
  employeeProjectTimelineData: EmployeeProjectTimelineData[];
}
function EmployeeProjectTimeline({ employeeProjectTimelineData }: EmployeeProjectTimelineProps) {
  const isMobile = useIsMobile();
  if (isEmpty(employeeProjectTimelineData)) return null;
  employeeProjectTimelineData?.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
  const rootSx = isMobile ? {} : { height: '360px', mb: '8px', overflowY: 'auto', mt: 0 };

  return (
    <Box sx={rootSx}>
      <Timeline position="right" sx={{ p: 0 }}>
        {employeeProjectTimelineData.map((data, index) => (
          <div key={`timeline-node-${index}`}>
            <TimelineItem>
              <TimelineOppositeContent display={'none'} />
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    backgroundColor: '#863EA8',
                  }}>
                  <InsertInvitationIcon />
                </TimelineDot>
                {index !== employeeProjectTimelineData.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Grid container maxWidth="420px" alignItems="baseline" spacing={1}>
                  <Grid item>
                    <Typography variant="h6" component="span" color={'#5E5E5E'}>
                      {data.squadName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color={'#5E5E5E99'} sx={{ width: '268px' }}>
                      -<wbr /> ({format(data.startDate, 'dd MMMM yyyy')} - {data.endDate ? format(data.endDate, 'dd MMMM yyyy') : 'Current'})
                    </Typography>
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          </div>
        ))}
      </Timeline>
    </Box>
  );
}

export default EmployeeProjectTimeline;
