import { AxiosRequestConfig } from 'axios';

import { BaseService } from './BaseService';

export interface BulkUpdateEmployeePreviewResult {
  employeesToAdd: BulkUpdateEmployeeItem[];
  employeesToRemove: BulkUpdateEmployeeItem[];
}

export interface BulkUpdateEmployeeItem {
  fullName: string;
  emailAddress: string;
  entelectJobTitleId?: number;
  jobTitleDescription: string;
  availableDate: Date;
  employeeCode?: string;
  entelectEmployeeId?: number;
  employeeId: number;
  employeeStatus: string;
  skills: string[];
  statusDate: Date;
  allocatedTeam: string;
  currentAM: string;
}

export interface ListBulkUploadHistory {
  uploadedBy: string;
  uploadDate: Date;
}

export interface BulkUploadHistoryDashboard {
  daysSinceLastUpload: number;
  uploadData: ListBulkUploadHistory;
}

export class BulkImportEmployeeService extends BaseService {
  constructor() {
    super('BulkImportEmployee');
  }

  async bulkUploadDeathStarEmployeesPreview(payload: FormData, squadId: string | number, config?: AxiosRequestConfig<any>) {
    return (await this.httpService.post<BulkUpdateEmployeePreviewResult>(`/employee-excel-bulk-upload-preview/${squadId}`, payload, config)).data;
  }

  async bulkUploadDeathStarEmployees(body: any, squadId: string | number) {
    return (await this.httpService.post<unknown>(`/employee-excel-bulk-upload/${squadId}`, body)).data;
  }

  async bulkImportDeathStarEmployeesPreview() {
    return (await this.httpService.get(`/deathstar-bulk-import-preview`)).data;
  }

  async bulkImportDeathStarEmployees(body: any) {
    return (await this.httpService.post<unknown>(`/deathstar-bulk-import`, body)).data;
  }

  async createUploadHistory(squadId: string) {
    return (await this.httpService.post(`/employee-excel-bulk-upload/history/${squadId}`, {})).data;
  }

  async getMostRecentUpload(): Promise<BulkUploadHistoryDashboard> {
    return (await this.httpService.get(`/bulkUpload/Recent`)).data;
  }
}
