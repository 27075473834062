import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import ChartHeading from 'src/components/dashboardShared/ChartHeading';

import { useFilterContext } from '../context/FilterContext';
import ActiveSquadOverviewChart from './ActiveSquadOverviewChart';

const ActiveSquadBarChart: React.FC = () => {
  const { squadGroupGraphData, updateSquadData, squadChartLoading } = useFilterContext();

  useEffect(() => {
    updateSquadData();
  }, []);

  return (
    <>
      <ChartHeading>{'Squad overview'}</ChartHeading>
      {!squadChartLoading ? (
        <Box display="flex" flexDirection="column" width="100%">
          <ActiveSquadOverviewChart squadChartData={squadGroupGraphData} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ActiveSquadBarChart;
