// Add newest updates at the top
export const content = [
    {
        Id: 0,
        name: 'Tagging [Tutorial]',
        description: 'Instructions on tagging and the improved overview search',
        file: 'Dread0 Tagging Manual.pdf',
        date: '5 Oct 2023'
    },
    {
        Id: 1,
        name: 'User Manual [Tutorial]',
        description: 'Comprehensive overview of all of Dreadnoughts most important features',
        file: 'Dread0 User Manual.pdf',
        date: '5 Aug 2023'
    },
    {
        Id: 2,
        name: 'Sprint 50 [Release]',
        description: 'Sprint 50 feature release',
        file: 'sprint50.pdf',
        date: '13 Feb 2024'
    },
];