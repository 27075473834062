import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEntityContext } from 'src/components/feedbackComponent/context/EntityContext';
import Mention from 'src/components/mentions/Mention';
import { Feedback } from 'src/services/FeedbackType';

import FeedbackReason from './FeedbackReason';

interface FeedbackDescriptionProps {
  feedbackItem: Feedback;
  searchTerms?: string[];
  employeeId?: number;
}

const FeedbackDescription = ({ feedbackItem, searchTerms, employeeId }: FeedbackDescriptionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { employee } = useEntityContext();
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  const emboldenString = function (stringToBold: string, searchTerm: string[]) {
    var output = stringToBold;
    searchTerm.forEach((term) => {
      var regex = new RegExp('(' + term + ')', 'ig');

      output = output.replace(regex, '<span style="color:#7B61FF">$1</span>');
    });
    return output;
  };

  return searchTerms ? (
    <Typography variant="body1" sx={{ pt: 1, px: 2 }} dangerouslySetInnerHTML={{ __html: emboldenString(feedbackItem.description, searchTerms) }}>
      <FeedbackReason name={feedbackItem.unflaggedFeedback?.capturedBy} reason={feedbackItem.unflaggedFeedback?.unflagReason} />
    </Typography>
  ) : (
    <Typography key={`FeedbackDescription-${feedbackItem.feedbackId}`} variant="body2" sx={{ paddingY: 1, px: 2, whiteSpace: 'pre-wrap' }}>
      {isExpanded ? (
        employee ? (
          <Mention employeeId={employee?.employeeId} text={feedbackItem.description} />
        ) : (
          <Mention employeeId={employeeId} text={feedbackItem.description} />
        )
      ) : employee ? (
        <Mention employeeId={employee?.employeeId} text={truncateString(feedbackItem.description, 400)} />
      ) : (
        <Mention employeeId={employeeId} text={truncateString(feedbackItem.description, 400)} />
      )}
      {feedbackItem.description.length > 400 && <Button onClick={handleToggleExpand}>{isExpanded ? 'Collapse' : 'Expand'}</Button>}
      <FeedbackReason name={feedbackItem.unflaggedFeedback?.capturedBy} reason={feedbackItem.unflaggedFeedback?.unflagReason} />
    </Typography>
  );
};

export default FeedbackDescription;
