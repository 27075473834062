import React, { createContext, useContext, useState } from 'react';
import { FeedBackFollowUpService } from 'src/services/FeedbackFollowUpService';
import useSWR from 'swr';

import { SquadsContext } from './SquadsContext';

interface TaskContextProps {
  setNumberOfTasks: (newNumberOfTasks: number) => void;
  numberOfTasks: number;
  getOutstandingTasks: (silent?: boolean) => Promise<any>;
}

const TaskContext = createContext<TaskContextProps>({
  setNumberOfTasks: () => {},
  numberOfTasks: 0,
  getOutstandingTasks: async () => {},
});

interface TaskProviderProps {
  children: React.ReactNode;
}
const feedbackFollowUpService = new FeedBackFollowUpService();

export const TaskProvider: React.FC<TaskProviderProps> = ({ children }) => {
  const [, setNumberOfTasks] = useState<number>(0);
  const { squad } = React.useContext(SquadsContext);

  const getOutstandTasksCount = async () => await feedbackFollowUpService.getTotalTasksOutstanding(+squad.squadId);
  const { data: outstandTasksCount, mutate: getOutstandingTasks } = useSWR('getOutstandTasksCount', getOutstandTasksCount, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });

  return (
    <TaskContext.Provider
      value={{
        setNumberOfTasks,
        numberOfTasks: outstandTasksCount?.totalOustandingTasks ?? 0,
        getOutstandingTasks: () => getOutstandingTasks(),
      }}>
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskContext = () => {
  return useContext(TaskContext);
};
