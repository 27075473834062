import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import ChartHeading from 'src/components/dashboardShared/ChartHeading';

import { useClientManagementFilterContext } from '../context/ClientManagementFilterContext';
import SquadOverviewChart from './SquadOverviewChart';

const SquadBarChart: React.FC = () => {
  const { squadGroupGraphData, updateSquadData, squadGraphLoading } = useClientManagementFilterContext();

  useEffect(() => {
    updateSquadData();
  }, []);

  return (
    <>
      <ChartHeading>{'Squad overview'}</ChartHeading>
      {!squadGraphLoading ? (
        <Box display="flex" flexDirection="column" width="100%">
          <SquadOverviewChart squadChartData={squadGroupGraphData} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default SquadBarChart;
