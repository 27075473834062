import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';

interface CopyButtonProps {
  emailAddress: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ emailAddress }) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(emailAddress);
    handleTooltipOpen();

    // Close the tooltip after 2 seconds
    setTimeout(handleTooltipClose, 2000);
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipClose}
      open={tooltipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title="Copied to clipboard!">
      <IconButton aria-label="copy email" onClick={copyToClipboard} sx={{ padding: '5px' }}>
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
