import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useContext, useEffect, useState } from 'react';
import { useEntityContext } from 'src/components/feedbackComponent/context/EntityContext';
import { LearningPhaseStatus } from 'src/utilities/Constants';
import { SquadsContext } from 'src/utilities/SquadsContext';

import SnackBarComponent from '../../shared/SnackBarComponent';
import { LearningPathFallBackService } from 'src/services/LearningPathFallBackService';
import { TagService } from 'src/services/TagService';
import { EmployeeLearningPathDTO, LearningPathDTO, LearningPhaseDTO, StartEmployeeLearningPathDTO } from 'src/Models/LearninpathModels';
import { TagDTO } from 'src/Models/TagModels';

interface StartLearningPathModalProps {
  open: boolean;
  onClose: () => void;
  employeeId: number;
}

const StartLearningPathModal: React.FC<StartLearningPathModalProps> = ({ open, onClose, employeeId }) => {
  const [allLearningPaths, setAllLearningPaths] = useState<LearningPathDTO[]>([]);
  const [allTags, setAllTags] = useState<TagDTO[]>([]);
  const [selectedLearningPath, setSelectedLearningPath] = useState<LearningPathDTO>();
  const [selectedTags, setSelectedTags] = useState<TagDTO[]>([]);
  const [learningPhases, setLearningPhases] = useState<LearningPhaseDTO[]>();
  const [learningPathSelected, setLearningPathSelected] = useState(false);
  const [selectedLearningPhase, setSelectedLearningPhase] = useState<LearningPhaseDTO>();
  const [selectedStatus, setSelectedStatus] = useState<LearningPhaseStatus>('InProgress' as unknown as LearningPhaseStatus);
  const [timeFrame, setTimeFrame] = useState<number>();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const squadId = useContext(SquadsContext).squad.squadId;
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>(' ');
  const [showDateError, setShowDateError] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>();
  const [filteredPaths, setFilteredpaths] = useState<LearningPathDTO[]>([]);
  const [startDateValue, setStartDateValue] = useState<Date>();
  const [endDateValue, setEndDateValue] = useState<Date>();
  const { setIsUpdated, isUpdated } = useEntityContext();
  const [learningpathData, setLearningPathData] = useState<LearningPathDTO[]>();
  const [employeeLearningPathData, setEmployeeLearningPathData] = useState<EmployeeLearningPathDTO[]>();
  const [tags, setTags] = useState<TagDTO[]>()

  const learningpathService = new LearningPathFallBackService();
  const tagService = new TagService();

  const getDataAsync = async () => {
    const learningpathResponse = await learningpathService.getLearningPathConfigs()
    const employeeLearningpathResponse = await learningpathService.getEmployeeLearningPaths(employeeId)
    const tagResponse = await tagService.getAllTags()

    setLearningPathData(learningpathResponse);
    setTags(tagResponse)
    setEmployeeLearningPathData(employeeLearningpathResponse)
  }

  useEffect(() => {
    getDataAsync();
  }, [])

  useEffect(() => {
    if (employeeLearningPathData && learningpathData && employeeLearningPathData) {
      const employeeLearningPathNames = employeeLearningPathData?.map((learningpath) => learningpath.name);
      setFilteredpaths(learningpathData.filter((path) => !employeeLearningPathNames.includes(path.name)));
    }
  }, [employeeLearningPathData, learningpathData, employeeLearningPathData]);

  const handleLearningPathChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    const selectedPath = allLearningPaths.find((path) => path.name === value);
    setSelectedLearningPath(selectedPath);

    if (selectedPath?.phases) {
      setLearningPhases(selectedPath?.phases);
      setLearningPathSelected(true);
    } else {
      setLearningPathSelected(false);
    }
  };

  const handleStartDateChange = (date: Date) => {
    setStartDateValue(date);

    if (selectedLearningPhase) {
      const expectedDays = selectedLearningPhase.expectedDurationDays || 14;

      const endDate = new Date(date);

      endDate.setDate(endDate.getDate() + expectedDays);
      setEndDateValue(endDate);
    }
  };
  useEffect(() => {
    if (!startDateValue || !endDateValue)
      setSubmitDisabled(true)

    if (!startDateValue) {
      setEndDateValue(undefined)
    }

  }, [startDateValue, endDateValue])

  const handleEndDateChange = (date: Date) => {
    setEndDateValue(date);
  };

  useEffect(() => {
    if (learningpathData && tags) {
      setAllLearningPaths(learningpathData!);
      setAllTags(tags ?? []);
    }
  }, [tags, learningpathData]);

  const handlePhaseChange = async (event: React.ChangeEvent<{}>, value: LearningPhaseDTO | null) => {
    if (value) setSelectedLearningPhase(value);
    if (value?.expectedDurationDays) {
      const timeInDays = value.expectedDurationDays / 7;
      setTimeFrame(Math.ceil(timeInDays));
    }
    handleStartDateChange(new Date())
    if (value) {
      const expectedDays = value.expectedDurationDays || 14;
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + expectedDays);
      setEndDateValue(endDate);
    }
  };

  const handleSetStatus = (event: React.ChangeEvent<{}> | null, value: LearningPhaseStatus | null) => {
    setSelectedStatus(value ? value : LearningPhaseStatus['InProgress']);
  };

  const handleTagsChange = (event: React.ChangeEvent<{}>, value: TagDTO[] | null) => {
    setSelectedTags(value ?? []);
  };

  useEffect(() => {
    if (learningPathSelected && selectedLearningPhase && selectedStatus && endDateValue && startDateValue) setSubmitDisabled(false);
  }, [learningPathSelected, selectedLearningPhase, selectedStatus, endDateValue]);

  const handleStartNewLearningPath = async () => {
    if (showDateError) {
      setSnackBarMessage('Dates are invalid!');
      setSeverity('error');
      setShowSnackBar(true);

      setTimeout(() => {
        setShowSnackBar(false);
      }, 3000);
      return;
    }

    const initialTagIds: number[] = selectedTags.map((tag) => tag.tagId).filter((tagId) => typeof tagId === 'number') as number[];

    const payload: StartEmployeeLearningPathDTO = {
      employeeId: employeeId,
      endDate: endDateValue?.toISOString(),
      initialTagIds: initialTagIds,
      learningPathId: selectedLearningPath?.id,
      name: selectedLearningPath?.name,
      phaseId: selectedLearningPhase?.id,
      squadId: parseInt(squadId),
      startDate: startDateValue?.toISOString(),
      status: selectedStatus.toString(),
    };

    try {
      await learningpathService.startEmployeeLearningPath(payload);

      setSnackBarMessage('Learning Path Started!');
      setSeverity('success');
      setShowSnackBar(true);
      setIsUpdated(!isUpdated);

      setTimeout(() => {
        setShowSnackBar(false);
        onClose();
      }, 3000);
    } catch {
      setSnackBarMessage('Failed Starting New Learning Path!');
      setSeverity('error');
      setShowSnackBar(true);
      setTimeout(() => {
        setShowSnackBar(false);
        onClose();
      }, 3000);
    }
  };

  useEffect(() => {
    if (startDateValue && endDateValue) {
      if (startDateValue > endDateValue) {
        setShowDateError(true);
      } else {
        setShowDateError(false);
      }
    }
  }, [startDateValue, endDateValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        {!learningpathData || !tags ? (
          <CircularProgress />
        ) : (
          <Paper
            variant="elevation"
            elevation={24}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              width: '900px',
            }}>
            <DialogTitle
              color="black"
              sx={{
                display: 'flex',
                padding: '16px 24px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
              }}>
              Start new learning path
            </DialogTitle>
            <DialogContent
              sx={{
                display: 'flex',
                padding: '0px 24px 20px 24px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
              }}>
              <Typography fontSize="13px" color="black" fontWeight="bold">
                Path Details
              </Typography>

              <Autocomplete
                style={{ paddingTop: '12px', width: '852px' }}
                onChange={handleLearningPathChange}
                renderInput={(params) => <TextField {...params} label="Learning Path" placeholder="" />}
                options={filteredPaths.length ? filteredPaths.map((path) => path.name) : allLearningPaths.map((path) => path.name)}
              />

              <Autocomplete
                style={{ paddingTop: '12px', width: '852px' }}
                onChange={handleTagsChange}
                renderInput={(params) => <TextField {...params} label="Linked Tags to Path" placeholder="Select" />}
                options={allTags}
                getOptionLabel={(option) => option.tagName ?? ' '}
                multiple
                filterSelectedOptions
                isOptionEqualToValue={(option, value) => option.tagId === value.tagId}
              />

              <Typography color="black" fontWeight="bold" paddingTop="10px" paddingBottom="10px">
                Phase Details
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  width: '100%',
                  gap: '12px',
                  alignSelf: 'stretch',
                }}>
                <Autocomplete
                  disabled={!learningPathSelected}
                  onChange={handlePhaseChange}
                  renderInput={(params) => <TextField {...params} label="Current Phase" placeholder="" fullWidth />}
                  options={(learningPhases || []).map((phase) => ({ ...phase }))}
                  getOptionLabel={(option) => option.name ?? ' '}
                  sx={{ flex: '1 0 0', width: '50%' }}
                />

                <Autocomplete
                  disabled={!learningPathSelected}
                  onChange={handleSetStatus}
                  renderInput={(params) => <TextField {...params} label="Phase Status" placeholder="" fullWidth />}
                  options={Object.values(LearningPhaseStatus).filter((value) => typeof value === 'string')}
                  getOptionLabel={(option) => option.toString() ?? ' '}
                  sx={{ flex: '1 0 0', width: '50%' }}
                  defaultValue={selectedStatus}
                />
              </Box>

              <Box sx={{ paddingTop: '12px', display: 'flex', width: '100%', alignItems: 'flex-start', gap: '12px', alignSelf: 'stretch' }}>
                <DatePicker
                  label="Phase Start Date: "
                  value={startDateValue ? startDateValue : null}
                  views={['month', 'year', 'day']}
                  onChange={handleStartDateChange}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  disabled={!learningPathSelected}
                />

                <DatePicker
                  label="Phase End Date: "
                  value={endDateValue ? endDateValue : null}
                  views={['month', 'year', 'day']}
                  onChange={handleEndDateChange}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  disabled={!learningPathSelected}
                />
              </Box>
              {selectedLearningPhase &&
                <>
                  <Divider
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: '12px' }}>
                      <Typography sx={{ color: '#646464', fontSize: '12px' }}>Suggested time frame</Typography>
                      <Typography sx={{ color: 'black', fontWeight: '500', fontSize: '14px' }}>1 - {timeFrame} weeks</Typography>
                    </div>
                  </Divider>
                </>
              }
            </DialogContent>
            <DialogActions
              sx={{
                display: 'flex',
                padding: '8px',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
              }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button disabled={submitDisabled} onClick={handleStartNewLearningPath}>
                Submit{' '}
              </Button>
            </DialogActions>
          </Paper>
        )}
      </Dialog>
      <SnackBarComponent
        openSnackbarState={showSnackBar}
        setOpenSnackbarState={setShowSnackBar}
        severity={severity}
        message={snackBarMessage}></SnackBarComponent>
    </LocalizationProvider>
  );
};

export default StartLearningPathModal;
