import { makeStyles } from 'tss-react/mui';
import { Alert, Button, createTheme, ThemeProvider, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { entelectBlue } from 'src/utilities/customColors';

const useStyles = makeStyles()((theme: any) => ({
  tooltip: {
    maxWidth: 400,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  buttonGroup: {
    width: '100%',
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    '&>button': { width: 123 },
  },
}));
const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F6F6F6',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 290,
    border: '1px solid #dadde9',
  },
}));

const FlagTooltip: React.FC<Partial<TooltipProps> & { followUp?: boolean; handleConfirm: () => void }> = ({
  children,
  followUp,
  handleConfirm,
  ...tooltipProps
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => !followUp && setOpen(true);
  const handleClose = () => setOpen(false);
  const handleContinue = () => {
    handleClose();
    handleConfirm();
  };
  const { classes } = useStyles();
  return (
    <ThemeProvider theme={createTheme({ palette: { primary: { main: entelectBlue } } })}>
      <StyledTooltip
        {...tooltipProps}
        disableHoverListener
        title={
          <div className={classes.tooltip}>
            <div className={classes.info}>
              <Alert severity="info"> The profile will require follow up actions.</Alert>

              <div className={classes.buttonGroup}>
                <Button variant="outlined" color="primary" size="large" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" size="large" onClick={handleContinue}>
                  Continue
                </Button>
              </div>
            </div>
          </div>
        }
        arrow
        open={open}
        onClose={handleClose}>
        <div onClick={followUp ? handleConfirm : handleOpen}>{children}</div>
      </StyledTooltip>
    </ThemeProvider>
  );
};

export default FlagTooltip;
