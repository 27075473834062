import { Chip, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import NameInitials from "./NameInitials"

const SquadLeaderInitials = (props: any) => {
  const [squadLeaders, setSquadLeaders] = useState<any[]>([])
  const [extra, setExtra] = useState(0)
  const [extraLabel, setExtraLabel] = useState('')

  useEffect(() => {
    const limit = 4
    const squadLeaders = (props.squadLeaders as any[]).filter(x => !x.isDeleted)
    
    if (squadLeaders.length > limit) {
      setExtra(squadLeaders.length - limit)
      setExtraLabel(`+ ${squadLeaders.length - limit}`)
      setSquadLeaders(squadLeaders.slice(0, limit))
    } else {
      setExtra(0)
      setExtraLabel(``)
      setSquadLeaders(squadLeaders)
    }
  }, [props])

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
      {squadLeaders.map((leader: any, key: any) => (
        !leader.isDeleted && ( <NameInitials name={leader.user.fullName} key={key}/> )
      ))}
      {(extra > 0) ? <div className="col-md-6"><Chip label={extraLabel} sx={{p: 0}}/></div> : null }
    </Stack>
  )
}

export default SquadLeaderInitials