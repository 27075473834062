import { Box, CircularProgress } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CertificationService, DashboardCertificationCompanyDTO } from 'src/services/CertificationService';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';

import ChartHeading from '../../dashboardShared/ChartHeading';
import { useDashboardContext } from '../context/DashboardContext';
import { useFilterContext } from '../context/FilterContext';
import CertificationOverviewChart from './CertificationOverviewChart';

const certificationService = new CertificationService();
const employeeService = new EmployeeService();

const CertificationBarChart: React.FC = () => {
  const [certificates, setCertificates] = useState<DashboardCertificationCompanyDTO[]>();

  const { certificationFilterRequest, startDate, endDate } = useFilterContext();
  const { squad } = React.useContext(SquadsContext);
  const { setEmployees, setIsLoading } = useDashboardContext();
  const { showAllEmployees } = useFilterContext();
  const [chartLoading, setChartLoading] = useState(false);

  const getCompanyCertificatesData = async () => {
    setChartLoading(true);
    const response = await certificationService.getCertificationCompanyDashboard(startDate, endDate, showAllEmployees);
    setCertificates(response);
    setChartLoading(false);
  };

  const getFiltredEmployees = async () => {
    if (!isEmpty(certificationFilterRequest)) {
      setIsLoading(true);
      const response = await employeeService.getActiveDashboardEmployees(+squad.squadId, showAllEmployees, undefined, certificationFilterRequest);
      setEmployees(response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompanyCertificatesData();
  }, [startDate, endDate, showAllEmployees]);

  useEffect(() => {
    getFiltredEmployees();
  }, [certificationFilterRequest]);

  return (
    <>
      <ChartHeading>{'Certification overview'}</ChartHeading>
      {certificates && !chartLoading ? (
        <Box display="flex" flexDirection="column" width="100%">
          <CertificationOverviewChart certificationData={certificates} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default CertificationBarChart;
