import { ReducedEmployee } from "src/Models/EmployeeModels";


/**
 * Filters and sorts an array of employees based on the given criteria:
 * Employees who are not deleted and have followUp set to true, sorted alphabetically by fullName.
 * Employees who are not deleted and do not have followUp set to true, sorted alphabetically by fullName.
 * Employees who are deleted and have followUp set to true, sorted alphabetically by fullName.
 * Employees who are deleted and do not have followUp set to true, sorted alphabetically by fullName.
 * @param employees - An array of Employee objects.
 * @returns An array of Employee objects sorted and filtered according to the criteria.
 */
export const sortEmployees = (employees: ReducedEmployee[]): ReducedEmployee[] => {
  const notDeletedWithFollowUp = employees.filter((employee) => !employee.isDeleted && employee.followUp).sort((a, b) => fullNameCompare(a, b));
  const notDeletedWithoutFollowUp = employees.filter((employee) => !employee.isDeleted && !employee.followUp).sort((a, b) => fullNameCompare(a, b));
  const deletedWithFollowUp = employees.filter((employee) => employee.isDeleted && employee.followUp).sort((a, b) => fullNameCompare(a, b));
  const deletedWithoutFollowUp = employees.filter((employee) => employee.isDeleted && !employee.followUp).sort((a, b) => fullNameCompare(a, b));
  return [...notDeletedWithFollowUp, ...notDeletedWithoutFollowUp, ...deletedWithFollowUp, ...deletedWithoutFollowUp];
};

const fullNameCompare = (a: ReducedEmployee, b: ReducedEmployee): number => {
  return (a?.fullName || '').localeCompare(b?.fullName || '');
};
