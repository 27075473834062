import { Checkbox, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import { EmployeeLearningChecklistDTO } from "src/Models/LearninpathModels";
import { getFullDate } from "src/utilities/DateUtilities";

interface ChecklistProps {
    variant?: 'feedback' | 'overview',
    items: EmployeeLearningChecklistDTO[],
    onChange: (itemId: number) => void;
}

const Checklist: React.FC<ChecklistProps> = ({ variant = 'feedback', items, onChange }) => {
    return (
        <List
            sx={{
                flexGrow: 1,
                overflowY: 'scroll',
                overflowX: 'hidden',
                scrollbarWidth: 'thin',

                borderRadius: '5px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
            }}>
            {items.map((item) => {
                const checkedToday = item.checkedDate! === 'Today';
                return (
                    <ListItem key={item.id} sx={{ py: 0 }}>
                        <Checkbox
                            edge="start"
                            checked={item.isChecked}
                            onChange={() => onChange(item.id!)}
                            disabled={item.isChecked && !checkedToday}
                            disableRipple
                        />
                        <ListItemText
                            primary={item.name &&
                                <Typography variant="body1" sx={{ fontSize: '14px', color: '#000000DE' }}>
                                    {item.name}
                                </Typography>
                            }
                            secondary={
                                item.isChecked
                                    ? checkedToday
                                        ? 'Today'
                                        : getFullDate(new Date(item.checkedDate!).toLocaleString('en-ZA'))
                                    : ''
                            }
                        />
                    </ListItem>
                );
            })}
        </List>
    );
}

export default Checklist;