import React, { useContext, useEffect } from 'react';
import { useSnackbar } from 'src/components/shared/SnackbarContext';
import { FeedbackKeywordsService } from 'src/services/FeedbackKeywordsService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import useSWR from 'swr';
import { useEntityContext } from '../../context/EntityContext';
import { useFeedbackContext } from '../../context/FeedbackContext';
import FeedbackWordCloud from './FeedbackWordCloud';
const feedbackKeywordsService = new FeedbackKeywordsService();

const WordCloud = () => {
  const [filteredWord, setFilteredWord] = React.useState('');
  const { setDisplayedFeedbackItems, feedbackItems } = useFeedbackContext();
  const finalData: { text: any; value: number }[] = [];

  const { employee } = useEntityContext();
  const { squad } = useContext(SquadsContext);
  const fetchData = async () => await feedbackKeywordsService.getByFeedbackId(employee?.employeeId, squad?.squadId);
  const { data: wordCloud, error } = useSWR(employee && squad ? `WordCloud ${employee.employeeId} ${squad.squadId}` : undefined, fetchData);

  useEffect(() => {
    wordCloud?.forEach((word: any) => {
      let reviewWords = word.description.split(' ');
      reviewWords.forEach((singleWord: any) => {
        let wordExists = finalData.find((entry) => entry.text === singleWord);
        if (typeof wordExists == 'undefined') {
          if (singleWord.length > 0) {
            finalData.push({ text: singleWord, value: 25 });
          }
        } else {
          wordExists.value += 50;
        }
      });
    });
  }, [wordCloud]);

  useEffect(() => {
    filteredWord === ''
      ? setDisplayedFeedbackItems(feedbackItems)
      : setDisplayedFeedbackItems(
          feedbackItems.map((feedbackItem) => ({
            ...feedbackItem,
            feedback: feedbackItem.feedback.filter((entry) => new RegExp(`\\b${filteredWord}\\b`, 'gi').test(entry.description)),
          })),
        );
  }, [filteredWord]);

  const { openSnackbar } = useSnackbar();
  useEffect(() => {
    error && openSnackbar('error', 'Error loading word cloud');
  }, [error]);

  return (
    <>
      <FeedbackWordCloud selectedWord={filteredWord} wordCloud={wordCloud ?? []} setSelectedWord={setFilteredWord} />
    </>
  );
};

export default WordCloud;
