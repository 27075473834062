import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertColor,
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { formatISO } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { EmployeeLearningPathDTO, UpdateEmployeeLearningPhaseDTO, EmployeeLearningPhaseDTO, LearningPathDTO, LearningPhaseDTO, NewEmployeeLearningPhaseDTO, UpdateEmployeeLearningPathDTO } from 'src/Models/LearninpathModels';
import { Tag, TagDTO } from 'src/Models/TagModels';
import { useEntityContext } from 'src/components/feedbackComponent/context/EntityContext';
import { LearningPathFallBackService } from 'src/services/LearningPathFallBackService';
import { TagService } from 'src/services/TagService';
import { LearningPhaseStatus, LearningPhaseStatusKey } from 'src/utilities/Constants';
import { SquadsContext } from 'src/utilities/SquadsContext';

interface PatchEmployeePathProps {
    learningPath: EmployeeLearningPathDTO | null;
    open: boolean;
    onClose: () => void;
}

const EditLearningPathModal: React.FC<PatchEmployeePathProps> = ({ learningPath, open, onClose }) => {
    const [payloadHistoricPhases, setPayloadHistoricPhases] = useState<UpdateEmployeeLearningPhaseDTO[]>();
    const learningPathService = new LearningPathFallBackService();
    const tagService = new TagService();
    const squadId = useContext(SquadsContext).squad.squadId;

    const [completedPhases, setCompletedPhases] = useState<EmployeeLearningPhaseDTO[] | []>([]);
    const [hasCompletedPhases, setHasCompletedPhases] = useState(false);
    const [activePhase, setActivePhase] = useState<EmployeeLearningPhaseDTO>();
    const [currentStartDate, setStartDateValue] = useState<Date>();
    const [currentEndDate, setCurrentEndDate] = useState<Date>();
    const [showStartNewPhase, setShowStartNewPhase] = useState(false);
    const [currentLearningConfig, setCurrentLearningConfig] = useState<LearningPathDTO>();
    const [nextPhaseDefault, setNextPhaseDefault] = useState<LearningPhaseDTO>();
    const [newPhaseStartDate, setNewPhaseStartDate] = useState<Date>(new Date());
    const [newPhaseEndDate, setNewPhaseEndDate] = useState<Date>(new Date());
    const [newPhaseObject, setNewPhaseObject] = useState<NewEmployeeLearningPhaseDTO>();
    const [isLastPhase, setIsLastPhase] = useState(false);
    const [currentTags, setCurrentTags] = useState<TagDTO[] | []>();
    const [patchTags, setPatchTags] = useState<TagDTO[]>();
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState<string>('');
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>();
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [disableUpdate, setDisableUpdate] = useState(true);
    const { isUpdated, setIsUpdated } = useEntityContext()
    const [currentPhaseConfig, setCurrentPhaseConfig] = useState<LearningPhaseDTO>()
    const [timeFrame, setTimeFrame] = useState<number>();
    const [description, setDescription] = useState('')
    const [pathConfig, setPathConfig] = useState<any>()
    const [allTags, setAllTags] = useState<Tag[]>()

    const learningPhaseStatusOptions = Object.keys(LearningPhaseStatus)
        .filter((key) => isNaN(parseInt(key)))
        .map((key) => ({
            value: LearningPhaseStatus[key as LearningPhaseStatusKey],
            label: key,
        }));

    const defaultStatus = learningPhaseStatusOptions.find((option) => option.label === 'InProgress');

    const getPathConfig = async () => {
        const data = await learningPathService.getLearningPathConfigs();
        const tagResponse = await tagService.getAllTags();
        setAllTags(tagResponse)
        setPathConfig(data)
    }

    useEffect(() => {
        getPathConfig()
    }, [])

    const currentPhaseStatus = learningPhaseStatusOptions.find(
        (option) => option.label.toString() === learningPath?.phases?.find((phase) => phase.isActive)?.status?.toString(),
    );

    const [currentStatus, setCurrentStatus] = useState<any>(currentPhaseStatus);

    const [newPhaseStatus, setNewPhaseStatus] = useState<any>(defaultStatus);

    const handleClearAlert = () => {
        setAlertMessage('');
        setAlertSeverity(undefined);
        setAlertTitle('');
        setShowAlert(false);
    };

    useEffect(() => {
        if (pathConfig && learningPath && learningPath?.name) {
            const currentConfig = pathConfig.filter((path: any) => path.name === learningPath.name)[0];
            setCurrentLearningConfig(currentConfig);
            if (activePhase) {
                setCurrentPhaseConfig(currentConfig?.phases?.filter((pahse: any) => pahse.name === activePhase.name)[0])
            }
        }
        setPayloadHistoricPhases(completedPhases);
        setCurrentTags(learningPath?.tags ?? []);
    }, [pathConfig, learningPath, completedPhases, activePhase]);

    useEffect(() => {
        if (showStartNewPhase && nextPhaseDefault) {
            const formatNewPhaseStartDate = formatISO(newPhaseStartDate ?? new Date(), { representation: 'complete' });
            const formattedNewPhaseEndDate = formatISO(newPhaseEndDate ?? new Date(), { representation: 'complete' });

            const newPhase: NewEmployeeLearningPhaseDTO = {
                startDate: formatNewPhaseStartDate,
                endDate: formattedNewPhaseEndDate,
                learningPhaseId: nextPhaseDefault.id,
            };
            setNewPhaseObject(newPhase);
        }
    }, [showStartNewPhase, nextPhaseDefault]);

    const handleStartNewPhase = () => {
        const activeConfigPhaseOrder = currentLearningConfig?.phases?.find((phase) => phase.name === activePhase?.name)?.order;

        if (activeConfigPhaseOrder) {
            const nextPhase = currentLearningConfig?.phases?.find((phase) => phase.order === activeConfigPhaseOrder + 1);

            if (nextPhase) {
                setNextPhaseDefault(nextPhase);
                setNewPhaseStartDate(new Date());
                const expectedDays = nextPhase.expectedDurationDays || 14;
                const endDate = new Date();
                endDate.setDate(endDate.getDate() + expectedDays);
                setNewPhaseEndDate(endDate);
                setDescription(description + 'Employee started a new phase. ')
            }
        }
    };

    const handleUpdateClicked = async () => {
        const formattedStartDate = formatISO(currentStartDate ?? new Date(), { representation: 'complete' });
        const formattedEndDate = formatISO(currentEndDate ?? new Date(), { representation: 'complete' });

        const tagIds: number[] | undefined = patchTags ? patchTags.map((tag) => tag.tagId).filter((id): id is number => id !== undefined) : undefined;

        const updateEntity: UpdateEmployeeLearningPathDTO = {
            id: learningPath?.id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            tagIds: tagIds,
            existingPhases: payloadHistoricPhases,
            newPhase: newPhaseObject,
        };

        await learningPathService.updateEmployeePathExtended(squadId, updateEntity);
        setIsUpdated(!isUpdated)

        onClose();
    };

    const handleGenericStartDateChange = (id: number, newStartDate: Date) => {
        setDisableUpdate(false);

        setCompletedPhases((prevPhases) => {
            return prevPhases.map((phase) => {
                if (phase.id === id) {
                    return { ...phase, startDate: formatISO(newStartDate ?? new Date(), { representation: 'complete' }) };
                }
                return phase;
            });
        });
    };

    const handleGenericEndDateChange = (id: number, newEndDate: Date) => {
        setDisableUpdate(false);

        setCompletedPhases((prevPhases) => {
            return prevPhases.map((phase) => {
                if (phase.id === id) {
                    return { ...phase, endDate: formatISO(newEndDate ?? new Date(), { representation: 'complete' }) };
                }
                return phase;
            });
        });
    };

    const handleNewPhaseStartDateChange = (date: Date) => {
        setNewPhaseStartDate(date);
        if (nextPhaseDefault) {
            const endDays = nextPhaseDefault.expectedDurationDays || 14;
            const endDate = new Date(date);
            endDate.setDate(endDate.getDate() + endDays);
            setNewPhaseEndDate(endDate);
        }
    };

    const handleNewPhaseEndDateChange = (date: Date) => {
        setNewPhaseEndDate(date);
    };

    const handleTagsChange = (event: React.ChangeEvent<{}>, value: TagDTO[] | null) => {
        setCurrentTags(value ?? []);
        setPatchTags(value ?? []);
        setDescription(description + `Added new tags to path. `)
    };

    const handleChangeNewPhaseStatus = (event: React.ChangeEvent<{}>, value: string | null) => {
        setNewPhaseStatus(value);
    };

    useEffect(() => {
        const completedPhases = learningPath?.phases;
        setCompletedPhases(completedPhases ?? []);
        setPatchTags(learningPath?.tags ?? []);

        if (completedPhases) setHasCompletedPhases(true);
        else setHasCompletedPhases(false);

        setActivePhase(learningPath?.phases?.find((phase) => phase.isActive === true));

        setStartDateValue(learningPath?.startDate ? new Date(learningPath.startDate) : undefined);
        setCurrentEndDate(learningPath?.endDate ? new Date(learningPath.endDate) : undefined);

        if (currentPhaseConfig && currentPhaseConfig.expectedDurationDays) {
            const timeInDays = currentPhaseConfig.expectedDurationDays / 7;
            const weeks = (Math.ceil(timeInDays));
            setTimeFrame(weeks)
        }
    }, [learningPath]);

    const handleUpdateCurrentStatus = (event: React.ChangeEvent<{}>, value: any) => {
        handleClearAlert();
        setDisableUpdate(false);

        setDescription(description + `Updated learning phase status. `)

        setCurrentStatus(value);
        if (value.value === LearningPhaseStatus.Completed && activePhase?.name !== 'Sergeant') {
            setShowStartNewPhase(true);
            setIsLastPhase(false);

            handleStartNewPhase();
        } else {
            setShowStartNewPhase(false);
            setIsLastPhase(true);
        }
        if (value.value === LearningPhaseStatus.Abandoned) {
            setAlertSeverity('warning');
            setAlertTitle('You are about to abandon a learning path');
            setAlertMessage('This learning path will no longer be active against this employees name. This action is not reversible.');
            setShowAlert(true);
        }

        if (value.value === LearningPhaseStatus.Completed && activePhase?.name === 'Sergeant') {
            setAlertSeverity('success');
            setAlertTitle('Learning path complete');
            setAlertMessage('You are about to mark this path as complete. It will no longer be an active learning path.');
            setShowAlert(true);
            setDescription(description + 'Learning path completed. ')
        }

        setCompletedPhases((prevPhases) => {
            return prevPhases.map((phase) => {
                if (phase.id === activePhase?.id) {
                    return { ...phase, status: value.label };
                }
                return phase;
            });
        });
    };

    if (!learningPath || (!learningPath.name && !patchTags)) {
        return <CircularProgress />;
    }

    const handleChangeNextPhase = (event: React.ChangeEvent<{}>, value: LearningPhaseDTO) => {
        setNextPhaseDefault(value);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Dialog open={open} maxWidth={'md'} onClose={onClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <DialogTitle sx={{ color: 'black', display: 'flex', fontSize: '20px' }}>Update learning path</DialogTitle>
                <DialogContent
                    sx={{ gap: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '800px', overflowX: 'hidden' }}>
                    <Typography fontSize={'13px'} color={'black'} sx={{ fontWeight: '500' }}>
                        Path details
                    </Typography>

                    <div>
                        <Typography color="#646464" fontSize="12px">
                            Learning path
                        </Typography>
                        <Typography fontWeight={500} color="black" fontSize="14px">
                            {learningPath.name}
                        </Typography>
                    </div>
                    <Autocomplete
                        style={{ width: '100%' }}
                        onChange={handleTagsChange}
                        renderInput={(params) => <TextField {...params} label="Associated tags" />}
                        value={currentTags}
                        options={allTags ?? []}
                        getOptionLabel={(option) => option.tagName ?? ' '}
                        multiple
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value) => option.tagId === value.tagId}
                    />

                    <Typography sx={{ fontSize: '13px', lineHeight: '18px', color: '#000', fontWeight: '500' }}>Phase details</Typography>
                    <div style={{ width: '100%' }}>
                        <Accordion sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignSelf: 'stretch' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                                {hasCompletedPhases ? 'Previous phase dates' : 'No Previous Phase Dates'}
                            </AccordionSummary>
                            <AccordionDetails sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'stretch' }}>
                                {hasCompletedPhases && completedPhases && (
                                    <List style={{ width: '100%' }}>
                                        {completedPhases.map((phase) => (
                                            <ListItem key={phase.id} sx={{ gap: '17px', width: 'fill', height: 'hug' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50px', height: 'hug' }}>
                                                    <Typography color="#646464" fontSize="12px" fontWeight={400} lineHeight="166%">
                                                        Phase
                                                    </Typography>
                                                    <Typography lineHeight="157%" fontWeight={500} fontSize="14px" color="#000000DE">
                                                        {phase.name}
                                                    </Typography>
                                                </div>

                                                <Box sx={{ paddingTop: '12px', display: 'flex', width: '90%', alignItems: 'flex-start', gap: '12px', alignSelf: 'stretch' }}>
                                                    <DatePicker
                                                        label="Phase start date"
                                                        value={phase.startDate ? new Date(phase.startDate) : null}
                                                        onChange={(date) => handleGenericStartDateChange(phase.id ?? 0, date!)}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        maxDate={phase.endDate ? new Date(phase.endDate) : null}
                                                    />

                                                    <DatePicker
                                                        label="Phase end date"
                                                        value={phase.endDate ? new Date(phase.endDate) : null}
                                                        onChange={(date) => handleGenericEndDateChange(phase.id ?? 0, date!)}
                                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                                        minDate={phase.startDate ? new Date(phase.startDate) : null}
                                                    />
                                                </Box>
                                            </ListItem>
                                        ))}
                                    </List>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: '12px' }}>
                        <div style={{ width: '50%', paddingRight: '12px' }}>
                            <Typography sx={{ fontSize: '13px', lineHeight: '18px', color: '#646464', fontWeight: '500' }}>Current Phase</Typography>
                            <Typography sx={{ fontSize: '14px', lineHeight: '157%', fontWeight: '500', color: '#000' }}>{activePhase?.name}</Typography>
                        </div>
                        <Autocomplete
                            onChange={(event, value) => handleUpdateCurrentStatus(event, value ?? ' ')}
                            renderInput={(params) => <TextField {...params} label="Phase Status" />}
                            value={currentStatus}
                            options={learningPhaseStatusOptions.filter((option) => option.label !== currentStatus.label)}
                            getOptionLabel={(option) => option.label ?? ' '}
                            sx={{ width: '50%' }}
                        />
                    </div>
                    <div>
                        <Typography sx={{ fontSize: '12px', lineHeight: '166%', letterSpacing: '0.4px', color: '#646464' }}>Suggested time frame</Typography>
                        <Typography sx={{ fontSize: '14px', lineHeight: '157%', fontWeight: '500', color: '#000000DE', letterSpacingL: '0.1px' }}>1 - {timeFrame} weeks</Typography>
                    </div>

                    {showStartNewPhase && !isLastPhase && (
                        <>
                            <Typography fontSize="13px" fontWeight={500} color="#000">
                                Next phase details
                            </Typography>

                            <div style={{ display: 'flex', gap: '12px', width: '100%', flexDirection: 'row' }}>
                                <div style={{ flex: 1, width: '50%' }}>
                                    <Autocomplete
                                        onChange={(event, value) => handleChangeNextPhase(event, value!)}
                                        renderInput={(params) => <TextField {...params} label="Next phase" />}
                                        value={nextPhaseDefault}
                                        options={(currentLearningConfig && currentLearningConfig!.phases ? currentLearningConfig!.phases : []).filter((phase: any) => {
                                            return phase.name !== (activePhase as any)?.name && !completedPhases.some((prevPhase: any) => prevPhase.name === phase.name);
                                        })}
                                        getOptionLabel={(option: any) => option.name ?? ' '}
                                        filterSelectedOptions
                                    />
                                </div>
                                <div style={{ flex: 1, width: '50%' }}>
                                    <Autocomplete
                                        onChange={(event, value) => handleChangeNewPhaseStatus(event, value ?? '')}
                                        renderInput={(params) => <TextField {...params} label="Phase Status" />}
                                        value={newPhaseStatus}
                                        options={learningPhaseStatusOptions.filter((option) => option.label !== newPhaseStatus.label)}
                                        getOptionLabel={(option) => option.label ?? ' '}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', gap: '12px', width: '100%', flexDirection: 'row' }}>
                                <div style={{ flex: 1, width: '50%' }}>
                                    <DatePicker
                                        label="Next phase start date"
                                        value={newPhaseStartDate}
                                        onChange={(date) => handleNewPhaseStartDateChange(date ?? new Date())}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        maxDate={newPhaseEndDate}
                                    />
                                </div>
                                <div style={{ flex: 1, width: '50%' }}>
                                    <DatePicker
                                        label="Next phase end date"
                                        value={newPhaseEndDate}
                                        onChange={(date) => handleNewPhaseEndDateChange(date ?? new Date())}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        minDate={newPhaseStartDate}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {showAlert && (
                        <Alert
                            sx={{ width: '760px', border: '1px', display: 'flex', alignItems: 'flex-start' }}
                            severity={alertSeverity}
                            hidden={showAlert}
                            title={alertTitle}>
                            {alertMessage}
                        </Alert>
                    )}

                    <DialogActions sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Button size="medium" color="primary" onClick={() => onClose()}>
                            CANCEL
                        </Button>

                        <Button disabled={disableUpdate} size="medium" color="primary" onClick={handleUpdateClicked}>
                            UPDATE
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </LocalizationProvider>
    );
};

export default EditLearningPathModal;
