import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { UnflaggedIcon } from 'src/components/shared/UnflaggedIcon';
import { entelectBlue } from 'src/utilities/customColors';

const FeedbackReason = ({ name, reason }: { name: string; reason: string }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  if (!reason) return null;
  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
      }}>
      <AccordionSummary
        id="accordion"
        sx={{ height: '23px', padding: 4, m: 0, '&.MuiAccordionSummary-root': { width: '134px', minHeight: 23, p: 0 } }}
        expandIcon={<ExpandMore sx={{ width: '16px', color: entelectBlue }} />}>
        <span
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: entelectBlue,
          }}>
          Reason for Unflag
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction={'row'}>
          <UnflaggedIcon style={{ marginRight: 12 }} />
          <div>
            <Typography variant="body2" component="div" sx={{ color: 'black', fontWeight: 500, fontSize: 12 }}>
              {name}
            </Typography>
            <Typography variant="body2" component="div" sx={{ color: '#7A8598 ', pt: '4px' }}>
              {reason}
            </Typography>
          </div>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default FeedbackReason;
