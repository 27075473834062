import { Box } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Legend, Tooltip, ChartOptions } from 'chart.js';
import React, { useContext, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { IncommingSquadGroupGraphData, SquadGroupService } from 'src/services/SquadGroupService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { entelectBlue } from 'src/utilities/customColors';

import { useDashboardContext } from '../context/DashboardContext';
import { useFilterContext } from '../context/FilterContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

interface SquadOverviewChartBarChartProps {
  squadChartData: IncommingSquadGroupGraphData[];
}

const ActiveSquadOverviewChart: React.FC<SquadOverviewChartBarChartProps> = ({ squadChartData }: SquadOverviewChartBarChartProps) => {
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | null>(null);
  const { getAllEmployees, setEmployees, setIsLoading } = useDashboardContext();
  const { showAllEmployees } = useFilterContext();

  const { squad } = useContext(SquadsContext);
  const squadGroupService = new SquadGroupService();

  const squadNames = squadChartData.map((x) => x.squadName);
  const employeeCount = squadChartData.map((x) => x.countEmployees);

  const chartData = {
    labels: squadNames,
    datasets: [
      {
        backgroundColor: squadNames.map((_, index) => (index === selectedBarIndex ? 'lightblue' : entelectBlue)),
        borderColor: entelectBlue,
        data: employeeCount,
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;

        if (selectedBarIndex === index) {
          setSelectedBarIndex(null);
          getAllEmployees();
        } else {
          const label = chartData.labels![index];
          setSelectedBarIndex(index);
          filterEmployees(label);
        }
      }
    },
  };

  const filterEmployees = async (label: string) => {
    setIsLoading(true);
    const newEmployeeList = await squadGroupService.GetActiveEmployeesBySquadGroup(squad.squadId, label, showAllEmployees);
    setEmployees(newEmployeeList);
    setIsLoading(false);
  };

  return (
    <Box id="active-squad-chart">
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default ActiveSquadOverviewChart;
