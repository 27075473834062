import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Tag, TagDashboardGraphModel, TagGraphRequestModel } from 'src/Models/TagModels';
import { TagService } from 'src/services/TagService';

import ChartHeading from '../../dashboardShared/ChartHeading';
import { useFilterContext } from '../context/FilterContext';
import TagChart from './TagChart';
import TagChartControls from './TagChartControls';

const TopFiveTags: React.FC = () => {
  const [topTags, setTopTags] = useState<TagDashboardGraphModel[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagRequest, setTagRequest] = useState<TagGraphRequestModel>({});
  const [tagLoading, setTagLoading] = useState(false);

  const { startDate, endDate, showAllEmployees } = useFilterContext();

  const tagService = new TagService();

  const getTagData = async () => {
    setTagLoading(true);
    const topTagData = await tagService.getTopTagsGraphData(tagRequest, showAllEmployees);
    const tagNames = await tagService.getAllTags();
    setTopTags(topTagData);
    setTags(tagNames);
    setTagLoading(false);
  };

  useEffect(() => {
    getTagData();
  }, [tagRequest, showAllEmployees]);

  useEffect(() => {
    setTagRequest((prevRequest) => ({
      ...prevRequest,
      dateFrom: startDate || undefined,
      dateTo: endDate || undefined,
    }));
  }, [startDate, endDate]);

  const handleTagControlChanged = (selectedTag: Tag | null) => {
    setTagRequest((prevRequest) => ({
      ...prevRequest,
      tagId: selectedTag ? selectedTag.tagId : undefined,
    }));
  };

  return (
    <>
      <ChartHeading>Top 5 tags</ChartHeading>
      {topTags && tags.length > 0 && !tagLoading ? (
        <Box display="flex" flexDirection="column" width="100%">
          <TagChart tagData={topTags} />
          <TagChartControls tags={tags} onTagChange={handleTagControlChanged} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default TopFiveTags;
