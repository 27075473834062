import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Hotkeys from 'src/components/feedbackComponent/hotkeys/Hotkeys';
const ctrlUpHotkey = require('../../../hotkeys/images/ctrlup.png');
const ctrlDownHotkey = require('../../../hotkeys/images/ctrldown.png');

interface SentimentSelectorProps {
  onSentimentChange?: (sentimentId: number) => void;
}

const SentimentSelector = (props: SentimentSelectorProps) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={'sentimentId'}
        control={control}
        render={({ field: { onChange, value } }) => (
          <ToggleButtonGroup
            value={value}
            exclusive
            aria-label="sentiment"
            onChange={
              (_, newValue) => {
                onChange(newValue);
                props.onSentimentChange?.(newValue)
              }
            }
            sx={{ height: '40px' }}>
            <ToggleButton value={1} aria-label="red" sx={{ border: 'none',  width: '40px' }}>
              <Hotkeys text="Negative Sentiment" imageSrc={ctrlDownHotkey}>
                <SentimentVeryDissatisfiedIcon
                  sx={{
                    color: '#D65745FF',
                    fontSize: { xs: '1.4rem', md: '2rem' },
                  }}
                />
              </Hotkeys>
            </ToggleButton>
            <ToggleButton value={3} aria-label="green" sx={{ border: 'none',  width: '40px' }}>
              <Hotkeys text="Positive Sentiment" imageSrc={ctrlUpHotkey}>
                <SentimentSatisfiedAltIcon
                  sx={{
                    color: '#55AB68D9',
                    fontSize: { xs: '1.4rem', md: '2rem' },
                  }}
                />
              </Hotkeys>
            </ToggleButton>
          </ToggleButtonGroup >
        )}
      />
    </>
  );
};

export default SentimentSelector;
