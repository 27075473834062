import { BaseService } from './BaseService';

export interface FollowUpTaskDTO {
  totalOustandingTasks: number;
}

export class FeedBackFollowUpService extends BaseService {
  constructor() {
    super('FeedbackFollowUp');
  }

  async getTotalTasksOutstanding(squadId: number): Promise<FollowUpTaskDTO> {
    return (await this.httpService.get(`/taskCount/${squadId}`)).data;
  }
}
