import React from 'react';

export const UnflaggedIcon = (props:any) => (
  <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8335 19.832L16.2335 16.232H3.1835C2.8385 16.232 2.52725 16.0933 2.24975 15.8158C1.97225 15.5383 1.8335 15.227 1.8335 14.882V3.18203C1.8335 2.83703 1.97225 2.52578 2.24975 2.24828C2.52725 1.97078 2.8385 1.83203 3.1835 1.83203H18.4835C18.8435 1.83203 19.1585 1.97078 19.4285 2.24828C19.6985 2.52578 19.8335 2.83703 19.8335 3.18203V19.832ZM3.1835 3.18203V14.882H16.796L18.4835 16.5695V3.18203H3.1835Z"
      fill="black"></path>
    <path
      d="M11.0882 5.43242L11.3003 6.63242H14.0792V10.2324H12.2973L12.0852 9.03242H8.2457V5.43242H11.0882ZM11.9579 4.23242H7.18506V14.4324H8.2457V10.2324H11.2155L11.4276 11.4324H15.1398V5.43242H12.1701L11.9579 4.23242Z"
      fill="black"></path>
  </svg>
);
