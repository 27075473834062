import { isUndefined } from 'lodash';
import React, { createContext, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { env } from 'src/env';
import useSessionStorage from 'src/hooks/useSessionStorage';
import { LoggedInUserContext } from 'src/utilities/LoggedInUserContext';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { ActiveFeatureToggles, featureTogglesConfig } from './featureTogglesConfig';

interface FeatureToggleContextProps extends ActiveFeatureToggles {
  setFeatureToggles: React.Dispatch<React.SetStateAction<ActiveFeatureToggles>>;
  toggleFeatureToggle: (toggleName: string, enabled?: boolean) => void;
}

const FeatureToggleContext = createContext<FeatureToggleContextProps | undefined>(undefined);

interface FeatureToggleProviderProps {
  children: React.ReactNode;
}

export const FeatureToggleProvider: React.FC<FeatureToggleProviderProps> = ({ children }) => {
  const environment = env.REACT_DEPLOYMENT_ENVIRONMENT;
  const { loggedInUserFullName } = useContext(LoggedInUserContext);
  const { squad } = useContext(SquadsContext);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const removeQuery = (key: string) => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.delete(key);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const initialToggles = {
    ...featureTogglesConfig.tenants?.[squad.shortName],
    ...featureTogglesConfig.environment?.[environment],
    ...featureTogglesConfig.users?.[loggedInUserFullName],
  } as ActiveFeatureToggles;

  const [featureToggles, setFeatureToggles] = useSessionStorage('featureToggles', initialToggles);

  const toggleFeatureToggle = (toggleName: string, isEnabled: boolean | undefined = undefined) => {
    setFeatureToggles((prevToggles) => ({
      ...featureToggles,
      [toggleName]: isUndefined(isEnabled) ? !prevToggles[toggleName] : isEnabled,
    }));
  };

  useEffect(() => {
    queryParams.forEach((value, key) => {
      if (key in initialToggles) {
        removeQuery(key);
        toggleFeatureToggle(key, value === 'true');
      }
    });
  }, [queryParams]);

  useEffect(() => {
    setFeatureToggles(initialToggles);
  }, [squad]);

  return (
    <FeatureToggleContext.Provider value={{ ...featureToggles, setFeatureToggles, toggleFeatureToggle }}>{children}</FeatureToggleContext.Provider>
  );
};

export const useFeatureToggle = () => {
  const context = useContext(FeatureToggleContext);

  if (!context) {
    throw new Error('useFeatureToggle must be used within a FeatureToggleProvider');
  }

  return context;
};
