import { Box, Divider } from '@mui/material';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
import { getDate, getDay, getMonth } from '../utilities/DateUtilities';

const useStyles = makeStyles()((theme) => ({
  muiDividerRoot: {
    marginBottom: 10,
    marginTop: 15,
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
  },
}));

const FullDateWithDivider = (props: any) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const dayIndex = getDay(props.date);
  const monthIndex = parseInt(getMonth(props.date)) - 1;
  const day = getDate(props.date);
  const date = new Date(props.date);
  const year = date.getFullYear();

  const { classes } = useStyles();
  return (
    <Box className={props.sticky ? classes.sticky : undefined}>
      <Divider className={classes.muiDividerRoot}>{`${days[dayIndex]},  ${months[monthIndex]}   ${day}, ${year}`}</Divider>
    </Box>
  );
};

export default FullDateWithDivider;
