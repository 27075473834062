import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemButton, Typography, Stack, Tooltip } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import React, { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SquadService } from 'src/services/SquadService';
import { SquadLeader, SquadLeaderContext } from 'src/utilities/SquadLeaderContext';
import { SquadsContext } from 'src/utilities/SquadsContext';

import useIsMounted from '../../hooks/useIsMounted';
import * as customColorsClass from '../../utilities/customColors';

const VariantDrawer = (props: any) => {
  const { squad, squads, setSquad, setSquads } = useContext(SquadsContext);
  const { squadLeader, setActiveSquadLeader } = useContext(SquadLeaderContext);
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const openedMixin = (theme: Theme): CSSObject => ({
    width: 360,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: 360,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const squadService = new SquadService();

  const onSquadChangeEvent = async (squad: any) => {
    isMounted() && setSquad(squad);
    localStorage.setItem('selected_squad', JSON.stringify(squad));
    const activeLeader = squadLeader.find((leader: SquadLeader) => leader.squadId === squad.squadId);
    isMounted() && typeof activeLeader !== 'undefined' && activeLeader !== undefined && setActiveSquadLeader(activeLeader);
    navigate('/');
  };

  const checkStoredSquad = useCallback(() => {
    const storedSquad = localStorage.getItem('selected_squad');
    if (storedSquad) {
      setSquad(JSON.parse(storedSquad));
    }
  }, []);

  useEffect(() => {
    checkStoredSquad();
  }, [checkStoredSquad]);

  const loadSquads = useCallback(async () => {
    const response = await squadService.getSquadsForCurrentUser();
    setSquads(response);
  }, []);

  useEffect(() => {
    loadSquads();
  }, []);

  return (
    <Box sx={{ display: 'flex', width: 0 }}>
      <Drawer
        variant="permanent"
        open={props.open}
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        PaperProps={{ sx: { backgroundColor: customColorsClass.blue50 } }}>
        <Box sx={{ mt: !props.open ? '60px' : '0px' }}>
          {!props.open && (
            <>
              {props.handleDrawerMotion && (
                <Tooltip arrow={true} title={'Dashboard'} placement="bottom" sx={{ borderRadius: '10px' }}>
                  <DashboardOutlinedIcon
                    sx={{
                      backgroundColor: customColorsClass.entelectBlue,
                      p: '7%',
                      borderRadius: '8px',
                      color: 'white',
                      height: '32px',
                      width: '32px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      ml: '17%',
                      mt: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={props.handleDrawerMotion}
                  />
                </Tooltip>
              )}
              <List sx={{ backgroundColor: customColorsClass.blue50 }}>
                {squads?.map((userSquad: any) => (
                  <ListItem key={userSquad.squadId} disablePadding sx={{ display: 'block', justifyContent: 'center' }}>
                    <Divider sx={{ mx: '14%', opacity: 0.5, backgroundColor: customColorsClass.blue200 }} />
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: 2.5,
                      }}>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 'auto',
                          justifyContent: 'center',
                          display: 'block',
                          color: customColorsClass.blue200,
                        }}
                        onClick={() => onSquadChangeEvent(userSquad)}>
                        {
                          <Stack direction={'column'} alignItems={'center'}>
                            {userSquad.squadId === squad.squadId ? <FolderSpecialOutlinedIcon color="primary" /> : <FolderOutlinedIcon />}
                            <Typography variant="subtitle2" color={userSquad.squadId === squad.squadId ? 'primary' : ''}>
                              {userSquad.shortName}
                            </Typography>
                          </Stack>
                        }
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {props.children}
        </Box>
      </Drawer>
    </Box>
  );
};

export default VariantDrawer;
