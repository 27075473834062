import useIsMounted from '../../../../../hooks/useIsMounted';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Button, Stack } from '@mui/material';
import * as React from 'react';
import RequireAuthorization from 'src/components/authentication/RequireAuthorization';
import { useEntityContext } from 'src/components/feedbackComponent/context/EntityContext';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';

const employeeService = new EmployeeService();

const ReactivateEmployee = () => {
  const isMounted = useIsMounted();
  const { employee, getEmployees, setEmployee } = useEntityContext();
  const squad = React.useContext(SquadsContext).squad;

  const activateEmployee = async () => {
    const reactivateEmployee = employee;
    if (!reactivateEmployee) return;
    reactivateEmployee.isDeleted = false;
    reactivateEmployee.isReactivate = true;
    reactivateEmployee.squadId = squad.squadId;

    const response = await employeeService.edit(reactivateEmployee);
    if (isMounted() && response) {
      getEmployees();
      setEmployee(reactivateEmployee);
    }
  };
  if (!employee?.isDeleted) return null;
  return (
    <RequireAuthorization userAuthorization="team">
      <Stack
        alignItems="center"
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-evenly"
        sx={{ background: '#13397020', height: '70px' }}>
        <Button variant="contained" onClick={activateEmployee} sx={{ background: '#002850', my: '14px' }} endIcon={<PersonAddAltIcon />}>
          RE-ACTIVATE EMPLOYEE
        </Button>
      </Stack>
    </RequireAuthorization>
  );
};

export default ReactivateEmployee;
