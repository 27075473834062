import React, { createContext, useContext, useState } from 'react';

export const useCategoryContext = () => {
  return useContext(FeedbackTagLibraryContext);
};

interface FeedbackTagLibraryContextProps {
  tagName: string;
  setTagName: React.Dispatch<React.SetStateAction<string>>;
}

const FeedbackTagLibraryContext = createContext<FeedbackTagLibraryContextProps>({
  tagName: '',
  setTagName: () => {},
});

interface FeedbackTagLibraryProviderProps {
  children: React.ReactNode;
}

export const FeedbackTagLibraryProvider: React.FC<FeedbackTagLibraryProviderProps> = ({ children }) => {
  const [tagName, setTagName] = useState<string>('');

  return (
    <FeedbackTagLibraryContext.Provider
      value={{
        tagName,
        setTagName,
      }}>
      {children}
    </FeedbackTagLibraryContext.Provider>
  );
};

export const useFeedbackTagLibraryContext = () => {
  return useContext(FeedbackTagLibraryContext);
};
