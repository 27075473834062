import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FlagIcon from '@mui/icons-material/Flag';
import { Box, Chip, IconButton, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FeedbackGroup } from 'src/services/FeedbackService';

import ProfilePicture from '../../../employee/ProfilePicture';
import { useTabVisibility } from '../../FeedTabContext';
import { useEntityContext } from '../../context/EntityContext';
import { useFeedbackContext } from '../../context/FeedbackContext';
import CopyButton from './components/CopyButton';

const getLocation = (employee: any) => {
  if (employee.location) {
    return <Box>{employee.location}</Box>;
  }
  return null;
};

const getSkills = (employee: any) => {
  if (employee.skills) {
    return (
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1, maxWidth: '50%' }}>
        {employee.skills.map((skill: any, i: any) => (
          <Chip key={i} label={skill} />
        ))}
      </Stack>
    );
  }
  return null;
};

// TODO: This is a temporary fix to remove deleted feedback items from the list of feedback items to check for followup items. This should be removed once the components have been refactored.
function removeDeletedAndCheckFollowup(items: FeedbackGroup[]): boolean {
  const remainingItems = [];

  for (const item of items) {
    const feedback = item.feedback.filter((f: any) => !f.isDeleted);
    remainingItems.push({ createdDate: item.createdDate, feedback });
  }

  for (const item of remainingItems) {
    const feedback = item.feedback;
    for (const f of feedback) {
      if (f.followUp) {
        return true;
      }
    }
  }

  return false;
}

const FeedbackHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [searchParams] = useSearchParams();
  const detailsExpanded = searchParams.get('detailsExpanded') === 'true';
  const { employee } = useEntityContext();
  const { feedbackItems } = useFeedbackContext();
  const isFlagged = removeDeletedAndCheckFollowup(feedbackItems ?? []);
  const { toggleTabVisibility } = useTabVisibility();
  const onBackClicked = () => {
    toggleTabVisibility();
  };

  if (!employee) {
    return null;
  }
  return (
    <Stack
      id="employee-header-selected-employee"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      height="40px"
      px="14px"
      pt="14px"
      pb="8px">
      <Stack direction="row" spacing={2} alignItems="center">
        {!isMobile && (
          <ProfilePicture
            showEyeBadge
            detailsExpanded={detailsExpanded}
            variant="circular"
            employee={employee}
            alt={employee?.fullName}
            emailAddress={employee?.emailAddress}
          />
        )}
        {isMobile && (
          <>
            <IconButton onClick={onBackClicked}>
              <ArrowBackIosIcon sx={{ color: '#FFF' }} />
            </IconButton>
            <ProfilePicture
              detailsExpanded={detailsExpanded}
              sx={{
                width: '60px',
                padding: 0,
                margin: '5px 0px',
                mr: '4px',
              }}
              variant="circular"
              employee={employee}
              alt={employee?.fullName}
              emailAddress={employee?.emailAddress}
              showEyeBadge
            />
          </>
        )}
        <Stack direction="column" sx={{ pr: '20px' }}>
          <Typography
            variant="h4"
            color={'inherit'}
            sx={{
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
            }}>
            {employee.fullName}
          </Typography>
          <Typography variant="body2" color={'inherit'}>
            {employee.jobTitle ?? ''}
          </Typography>
        </Stack>

        <Stack>
          {getLocation(employee)}
          {getSkills(employee)}
        </Stack>
      </Stack>

      <Stack direction="column">
        <Stack direction="row" spacing={1} alignItems="center" sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Link href={`mailto:${employee.emailAddress}`} underline="always">
            {employee.emailAddress}
          </Link>

          <CopyButton emailAddress={employee.emailAddress} />
          {isFlagged && <FlagIcon color="error" />}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FeedbackHeader;
