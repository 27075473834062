import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';

const SearchBar = (props: TextFieldProps & { setSearchTerm: (value: string) => void; searchTerm?: string }) => {
  const { setSearchTerm, searchTerm, ...textFieldProps } = props;
  const [value, setValue] = React.useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    setSearchTerm(newValue);
  };

  const handleClear = () => {
    setValue('');
    setSearchTerm('');
  };

  return (
    <TextField
      sx={{ width: 1 }}
      variant="outlined"
      value={searchTerm ?? value}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <CloseIcon sx={{ color: '#0000008F', cursor: 'pointer' }} onClick={handleClear} />
          </InputAdornment>
        ) : null,
      }}
      {...textFieldProps}
    />
  );
};

export default SearchBar;
