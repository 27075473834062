import AddFilled from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Divider, Grow, ListItem, Stack, Switch, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import FullDateWithDivider from 'src/components/FullDateWithDivider';
import EmployeeLearningPathTagComponent from 'src/components/LearningPaths/EmployeeLearningPathTagComponent';
import StartLearningPathModal from 'src/components/LearningPaths/Modals/StartLearningPathModal';
import useIsMobile from 'src/hooks/useIsMobile';
import { FeedbackGroup, FeedbackService } from 'src/services/FeedbackService';
import ErrorBoundary from 'src/shared/ErrorBoundary';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { entelectBlue } from 'src/utilities/customColors';

import { useCategoryContext } from '../../context/CategoryContext';
import { useEntityContext } from '../../context/EntityContext';
import { useFeedbackContext } from '../../context/FeedbackContext';
import BackToTopButton from './BackToTopButton';
import FeedbackCard from './FeedbackCard';
import LoaderPage from './LoaderPage';
import FilterComponent from './components/FilterComponent';
import { EmployeeLearningPathDTO } from 'src/Models/LearninpathModels';

interface FeedbackHistoryProps {
  refreshData?: () => void;
  FeedbackForm: any;
  employeeLearningPaths: EmployeeLearningPathDTO[] | undefined;
}

const FeedbackHistory: React.FC<FeedbackHistoryProps> = ({ refreshData, FeedbackForm, employeeLearningPaths }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const backToTopRef = useRef<HTMLDivElement>(null);

  const { employee } = useEntityContext();
  const feedbackService = new FeedbackService();
  const squadId = useContext(SquadsContext).squad.squadId;

  const {
    displayedFeedbackItems,
    setDisplayedFeedbackItems,
    feedbackItems,
    getFeedbackItems,
    feedbackStatus,
    loadMoreItems,
    onCategoryUpdate,
    isLoadingData,
    isInitialPageLoad,
    setIsInitialPageLoad,
    loadSpecificFeedbackItem,
    hasFlaggedFeedback,
    isLoadingPage,
    hasMoreFeedback,
  } = useFeedbackContext();
  const { categoryList } = useCategoryContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [searchParams] = useSearchParams();
  const scrollFeedbackId = searchParams.get('feedbackId');
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [isSelectedFeedbackItem, setIsSelectedFeedbackItem] = useState(false);
  const [showOnlyGroupFeedback, setShowOnlyGroupFeedback] = useState(false);
  const [showOnlyFlaggedFeedback, setShowOnlyFlaggedFeedback] = useState(false);
  const [showStartLearningPathModal, setShowStartLearningPathModal] = useState(false);
  const [filteredFeedback, setFilteredFeedback] = useState<FeedbackGroup[]>([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);

  const handleStartLearningPathModal = () => {
    setShowStartLearningPathModal(true);
  };

  const filterFeedback = async () => {
    if (showOnlyFlaggedFeedback || showOnlyGroupFeedback || selectedCategory) {
      const response =
        (await feedbackService.getFeedbackByEmployeeIdWithFilter(
          employee.employeeId,
          squadId,
          getCategoryNameById(selectedCategory)?.toString() || '-',
          showOnlyGroupFeedback,
          showOnlyFlaggedFeedback,
        )) ?? [];
      setFilteredFeedback(response);
    }
  };

  useEffect(() => {
    const boxElement = backToTopRef.current;
    if (boxElement) boxElement.style.visibility = 'hidden';
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current && !isEmpty(feedbackItems)) {
      const storedScrollPosition = sessionStorage.getItem(`scrollPosition-${employee?.employeeId}`);

      if (storedScrollPosition && (isLoadingPage || isLoadingData)) {
        scrollContainerRef.current.scrollTop = Number(storedScrollPosition);
      }
    }
  }, [feedbackItems]);

  useEffect(() => {
    if (showOnlyFlaggedFeedback || showOnlyGroupFeedback || selectedCategory !== 0) {
      setHasFilter(true);
      filterFeedback();
    } else {
      setHasFilter(false);
    }
  }, [showOnlyGroupFeedback, showOnlyFlaggedFeedback, selectedCategory]);

  useEffect(() => {
    if (scrollFeedbackId || isSelectedFeedbackItem) return;
    if (employee) getFeedbackItems();
  }, [employee]);

  useEffect(() => {
    if (!scrollFeedbackId || isSelectedFeedbackItem) return;

    setIsSelectedFeedbackItem(true);
    loadSpecificFeedbackItem(scrollFeedbackId);
  }, [feedbackItems, scrollFeedbackId, searchParams, navigate]);

  useEffect(() => {
    setDisplayedFeedbackItems(feedbackItems);
  }, [feedbackItems]);

  const handleLoadMoreItems = async () => {
    if (selectedCategory <= 0 && !showOnlyFlaggedFeedback && !showOnlyGroupFeedback) {
      setNextPageLoading(true)
      await loadMoreItems();
      setNextPageLoading(false)
    }

  }

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current && employee && !isEmpty(feedbackItems)) {
      sessionStorage.setItem(`scrollPosition-${employee.employeeId}`, String(scrollContainerRef.current.scrollTop));

      const container = scrollContainerRef.current;
      const scrollPosition = container.scrollTop;

      const boxElement = backToTopRef.current;
      if (boxElement) {
        if (scrollPosition >= 300) {
          boxElement.style.visibility = 'visible';
        } else {
          boxElement.style.visibility = 'hidden';
        }
      }
    }
  }, []);

  const getCategoryNameById = (categoryId: number): string | null => {
    if (!categoryList) return null;
    if (categoryId == 0) return '-';
    const matches = categoryList.filter((x: { categoryId: number }) => x.categoryId == selectedCategory);
    const categoryName = matches.length > 0 ? matches[0].name : null;
    return categoryName;
  };

  if (isInitialPageLoad && feedbackStatus === 'success') {
    setIsInitialPageLoad(false);
  }

  if (isInitialPageLoad || !categoryList) return <LoaderPage />;

  const onCategorySelect = (item: number) => {
    setSelectedCategory(item);
    const name = getCategoryNameById(selectedCategory);
    if (name === null || item <= 0) {
      onCategoryUpdate(item);
    }
  };

  const handleSwitchGroupChange = () => {
    setShowOnlyGroupFeedback(!showOnlyGroupFeedback);
  };

  const handleSwitchFlagChange = () => {
    setShowOnlyFlaggedFeedback(!showOnlyFlaggedFeedback);
  };

  const handleClick = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };

  const closeStartLearningPathModal = () => {
    setShowStartLearningPathModal(false);
  };

  const InterActiveComponents = () => (
    <>
      <FeedbackForm />
      <Divider sx={{ mt: 2 }} />
      <FilterComponent
        selectedCategory={selectedCategory}
        feedbackItems={feedbackItems}
        isSelectedFeedbackItem={isSelectedFeedbackItem}
        handleSelect={onCategorySelect}
        hasFlaggedFeedback={hasFlaggedFeedback}
      />
      <Divider />
      <div style={{ paddingTop: '8px', paddingLeft: '8px' }}>
        <span style={{ color: entelectBlue }}>Show Only Group Feedback</span>
        <Switch checked={showOnlyGroupFeedback} onChange={handleSwitchGroupChange} />

        <span style={{ color: entelectBlue }}>Show Flagged and Unflagged Feedback</span>
        <Switch checked={showOnlyFlaggedFeedback} onChange={handleSwitchFlagChange} />
      </div>
    </>
  );

  return (
    <>
      {isLoadingData ? (
        <LoaderPage hideFilterPart={true} />
      ) : (
        <Box
          id="feedback-container"
          key={`feedback-history-${employee?.employeeId}`}
          ref={scrollContainerRef}
          display={'flex'}
          flexDirection={'column'}
          flex={1}
          p={1.5}
          onScroll={handleScroll}
          sx={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
            <Box>
              {employeeLearningPaths && employeeLearningPaths.length > 0 && (
                <Typography sx={{ fontSize: '15px', color: '#646464' }}>Current learnings</Typography>
              )}
              {employeeLearningPaths && employeeLearningPaths.length > 0 && (
                <EmployeeLearningPathTagComponent employeeLearningPaths={employeeLearningPaths} />
              )}
            </Box>

            <Button
              color="secondary"
              startIcon={<AddFilled sx={{ ml: '2px' }} />}
              onClick={handleStartLearningPathModal}
              sx={{ p: '4px 5px', alignItems: 'center', minWidth: '144px' }}>
              LEARNING PATH
            </Button>
          </Box>

          <InterActiveComponents />

          {hasFilter
            ? filteredFeedback.map((feedbackItem, index) => (
              <Stack key={`${feedbackItem.createdDate}-${index}-${employee?.employeeId}`}>
                {isMobile && feedbackItem?.feedback?.length > 0 && <FullDateWithDivider sticky date={feedbackItem.createdDate} />}
                <TransitionGroup>
                  {feedbackItem.feedback?.map((item) => (
                    <Grow key={item.feedbackId}>
                      <ListItem key={item.feedbackId} sx={{ gap: '8px', padding: '4px', borderRadius: 'var(--borderRadius, 4px)' }}>
                        <ErrorBoundary id={`FeedbackCard-${item.feedbackId}`} showNavBack={false}>
                          <FeedbackCard feedbackItem={item} categoryList={categoryList} displayEmployeeDetail={false} refreshData={refreshData} />
                        </ErrorBoundary>
                      </ListItem>
                    </Grow>
                  ))}
                </TransitionGroup>
              </Stack>
            ))
            : <Stack key={employee.employeeId} direction="column" spacing={2}>
              <TransitionGroup>
                {displayedFeedbackItems
                  ?.flatMap(group => group.feedback)
                  .map((item, index) => (
                    <Grow key={item.feedbackId}>
                      <ListItem sx={{ gap: '8px', padding: '4px', borderRadius: 'var(--borderRadius, 4px)' }}>
                        <ErrorBoundary id={`FeedbackCard-${item.feedbackId}`} showNavBack={false}>
                          <FeedbackCard feedbackItem={item} categoryList={categoryList} displayEmployeeDetail={false} refreshData={refreshData} />
                        </ErrorBoundary>
                      </ListItem>
                    </Grow>
                  ))}
              </TransitionGroup>
              {hasMoreFeedback && (
                <Box display="flex" justifyContent="center" width="100%">
                  {!nextPageLoading ? (<Button onClick={handleLoadMoreItems}>Load More</Button>) : <CircularProgress />}
                </Box>)}
            </Stack>
          }
        </Box>
      )}
      <BackToTopButton ref={backToTopRef} handleClick={handleClick} />

      {showStartLearningPathModal && (
        <StartLearningPathModal open={showStartLearningPathModal} onClose={closeStartLearningPathModal} employeeId={employee.employeeId} />
      )}
    </>
  );
};

export default FeedbackHistory;
