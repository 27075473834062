import { Badge, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SquadLeader } from 'src/utilities/SquadLeaderContext';
import { SquadsContext } from 'src/utilities/SquadsContext';
import '../AppBar.css';
import ProfilePicture from '../employee/ProfilePicture';
import CustomBadge from '../shared/CustomBadge';
import { content } from '../whats-new/whats-new-content';


interface ResponsiveAppBarHeaderProfileProps {
    loggedInUserFullName: string;
    handleOpenUserMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
    squadLeader: SquadLeader[];
    visited: Set<string>;
    anchorElUser: HTMLElement | null;
    setAnchorElUser: (h: HTMLElement | null) => void;
    navigateToManageSettings: () => void;
    signOutClickHandler: () => void;
    activeSquadLeader: SquadLeader
}

const ResponsiveAppBarHeaderProfile: React.FC<ResponsiveAppBarHeaderProfileProps> = ({
    loggedInUserFullName,
    handleOpenUserMenu,
    squadLeader,
    visited,
    anchorElUser,
    setAnchorElUser,
    navigateToManageSettings,
    signOutClickHandler,
    activeSquadLeader,
}) => {
    const navigate = useNavigate();
    const unreadItems: number = content.length - visited.size;
    const { squad } = React.useContext(SquadsContext);

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const navigateToWhatsNew = () => {
        setAnchorElUser(null);
        navigate('/whats-new');
    };

    return (
        <Box id='header-profile' display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'18px'}>
            <Box className="logged-in-user" sx={{ flexDirection: 'column', alignItems: 'flex-end', display: { xs: 'none', lg: 'flex' } }}>
                <Typography variant="body1" sx={{ m: 0, color: 'white', display: { md: 'contents', xs: 'none' } }}>
                    {loggedInUserFullName}
                </Typography>
                <Typography fontSize={'12px'} sx={{ color: 'white' }}>
                    {squad.squadName}
                </Typography>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', lg: 'block' } }} key={`profile-${squad.squadId}`}>
                <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <ProfilePicture alt={loggedInUserFullName} emailAddress={squadLeader[0]?.user?.emailAddress ?? ''} />
                        <Badge
                            invisible={unreadItems === 0}
                            badgeContent={'!'}
                            color="error"
                            sx={{
                                position: 'relative',
                                top: -8,
                            }}
                        />
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="profile-menu"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}>
                    <MenuItem onClick={navigateToWhatsNew}>
                        <CustomBadge badgeContent={unreadItems}>
                            <Typography>Whats New?</Typography>
                        </CustomBadge>
                    </MenuItem>

                    {activeSquadLeader && (
                        <MenuItem onClick={navigateToManageSettings}>
                            <Typography>Settings</Typography>
                        </MenuItem>
                    )}

                    <MenuItem onClick={signOutClickHandler}>
                        <Typography>Sign Out</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    );
}

export default ResponsiveAppBarHeaderProfile;