import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material"
import React, { useState } from "react"
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#476B97',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F6F6F6',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SquadLeadersTable = (props: any) => {
  const [openRemoveSquadLeader, setOpenRemoveSquadLeader] = useState(false);
  const [selectedSquadLeader, setSelectedSquadLeader] = useState<any>(null);

  const getPermissionsDescription = (permissions: string): string => {
    switch (permissions?.toString()) {
      case '0':
        return 'User';
      case '1':
        return 'Admin';
      case '2':
        return 'Super User';
      case '3':
        return 'Tenant Manager'
      default:
        return 'Unknown';
    }
  }

  const handleCloseRemoveSquadLeader = () => {
    setOpenRemoveSquadLeader(false);
    setSelectedSquadLeader(null)
  };

  const handleConfirmClicked = () => {
    props.handleRemoveSquadLeader(selectedSquadLeader)
    handleCloseRemoveSquadLeader()
  }

  const handleDeleteClicked = (squadLeader: any) => {
    setSelectedSquadLeader(squadLeader);
    setOpenRemoveSquadLeader(true);
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
        <Table sx={{ minWidth: '100%' }} aria-label='customized table' size='small'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Squad Leader</StyledTableCell>
              <StyledTableCell>Permissions</StyledTableCell>
              <StyledTableCell align='right'></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.squadLeaders.map((squadLeader: any) => (
              <StyledTableRow key={squadLeader.emailAddress}>
                <StyledTableCell>{squadLeader.fullName}</StyledTableCell>
                <StyledTableCell>{getPermissionsDescription(squadLeader.permissions)}</StyledTableCell>
                <StyledTableCell align='right' sx={{p: 0}}>
                  <IconButton onClick={() => { props.handleEditSquadLeaderPermissions(squadLeader) }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClicked(squadLeader)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openRemoveSquadLeader && 
        <Dialog
          open={openRemoveSquadLeader}
          onClose={handleCloseRemoveSquadLeader}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Remove Squad Leader?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action will remove {selectedSquadLeader?.fullName} from the squad.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRemoveSquadLeader} autoFocus>Cancel</Button>
            <Button onClick={handleConfirmClicked}>
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

export default SquadLeadersTable
