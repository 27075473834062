import { EmployeeService } from 'src/services/EmployeeService';
import useSWR from 'swr';

export const useGetProfilePicture = (emailAddress: string, size = 64) => {
  const employeeService = new EmployeeService();

  const fetchProfilePicture = async () => employeeService.getProfilePicture(emailAddress, size);
  const {
    data: value,
    error,
    isLoading,
  } = useSWR(emailAddress ? `fetchProfilePicture ${emailAddress} ${size}` : undefined, fetchProfilePicture, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const status = error ? 'error' : isLoading ? 'loading' : 'success';
  const profilePictureImgSrc = status === 'success' && value ? `data:image/png;base64, ${value}` : '';

  return { profilePictureImgSrc, status };
};
