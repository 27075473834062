import { createContext } from 'react';

export class User {
  userId: string;
  emailAddress: string;
  isActive: boolean;
  isTenantManager: boolean;
  fullName: string;
}

interface UserContextState {
  user: User,
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

const initialState: UserContextState = {
  user: new User(),
  setUser: () => { }
}

export const UserContext = createContext(initialState);
