import axios, { AxiosRequestConfig } from 'axios';

export default class HttpService {
  urlPrefix: string;

  constructor(url: string) {
    this.urlPrefix = url;
  }

  async get(url: string, config?: AxiosRequestConfig<any>) {
    return await axios.get(`${this.urlPrefix}${url}`, config);
  }

  async post<T>(url: string, body: any, config?: AxiosRequestConfig<any>) {
    return axios.post<T>(`${this.urlPrefix}${url}`, body, config);
  }

  async put(url: string, body: any) {
    return await axios.put(`${this.urlPrefix}${url}`, body);
  }

  async patch(url: string, body: any) {
    return await axios.patch(`${this.urlPrefix}${url}`, body);
  }

  async delete(url: string) {
    return await axios.delete(`${this.urlPrefix}${url}`);
  }
}
