import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React from 'react';
import { Feedback } from "src/services/FeedbackType";
import { getTime } from "src/utilities/DateUtilities";
import FeedbackCategoryDisplay from "./FeedbackCategoryDisplay";

interface FeedbackCardHeaderProps {
    feedbackItem: Feedback;
    isEditable: boolean;
    isMobile: boolean;
}

const FeedbackCardHeader: React.FC<FeedbackCardHeaderProps> = ({
    feedbackItem,
    isEditable,
    isMobile
}) => {
    const userName: string = extractName(feedbackItem.createdByEmail);
    const utcDate = new Date(feedbackItem.createdDate + 'Z');
    const localDate = utcDate.toLocaleString('en-ZA');
    const time = getTime(localDate);

    const theme = useTheme();
    const smallDevicesBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

    function extractName(email: string) {
        const firstPart = email.split('@')[0];
        const formattedName = firstPart
            .split('.')
            .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
            .join(' ');

        return formattedName;
    }

    return (
        <Box id='feedback-card-header' ml={2} sx={{ display: 'flex' }}>
            {isMobile && (
                <Box display={'flex'} alignItems={'center'} mt={0.5}>
                    <Typography variant="caption" fontWeight={600}>
                        {userName}
                    </Typography>
                    <Typography variant="caption" fontWeight={600}>
                        {time}
                    </Typography>
                </Box>
            )}

            {!smallDevicesBreakpoint && (
                <Stack direction="column" flex='1' mt={0.5}>
                    <Box display={'flex'} alignItems={'center'} >
                        <Typography variant='caption' fontSize={'12'} lineHeight={'20px'} sx={{ color: '#00000099' }}>{userName}</Typography>
                        <Typography variant='caption' fontWeight={700} sx={{ color: '#00000099' }}>{feedbackItem.squadGroup && <>{`(${feedbackItem.squadGroup.groupName})`}</>}</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} >
                        <Typography variant='caption' fontSize={'10px'} sx={{ color: '#00000099' }}>
                            {format(new Date(feedbackItem.createdDate), 'dd MMMM yyyy hh:mm a')}
                        </Typography>
                    </Box>
                </Stack>
            )}

            {!isEditable && feedbackItem.feedbackFollowUpId && <EventAvailableIcon sx={{ color: '#55AB68D9' }} fontSize="large" />}
            {!isEditable && (
                <FeedbackCategoryDisplay
                    name={feedbackItem.category.name}
                    backgroundColour={feedbackItem.category.backgroundHexColor}
                    hexColor={feedbackItem.category.hexColor}
                    isFlagged={feedbackItem.followUp}
                    hasReason={!!feedbackItem.unflaggedFeedback?.unflagReason}
                    isHiddenFromOverview={feedbackItem.hideFromOverview}
                />
            )}
        </Box>
    );
}

export default FeedbackCardHeader;