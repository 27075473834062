import { Chip, ChipProps } from "@mui/material";
import React from "react";
import { Tag } from "src/Models/TagModels";

interface TagProps extends ChipProps {
    tag: Tag
}

const TagBubble: React.FC<TagProps> = ({
    tag,
    key,
    clickable,
    sx,
    onClick
}) => {
    return (
        <Chip
            size="small"
            key={key ?? tag.tagId}
            label={tag.tagName}
            clickable={clickable}
            sx={{
                color: 'white',
                backgroundColor: tag.tagColor ?? 'teal',
                ...sx,
            }}
            onClick={onClick}
        />
    );
}

export default TagBubble; 