import { Box, Divider, Grid, List, ListItem, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import Duplicate from 'src/components/shared/Duplicate';
import useIsMobile from 'src/hooks/useIsMobile';
import { makeStyles } from 'tss-react/mui';
import LoaderFeedbackCard from './FeedbackCardLoader';

interface LoaderPageProps {
  hideFilterPart?: boolean,
}

const useStyles = makeStyles()(() => ({
  muiDividerRoot: {
    marginBottom: 10,
    marginTop: 15,
  },
}));
const LoaderPage = (props: LoaderPageProps) => {
  const isMobile = useIsMobile();
  const { classes } = useStyles();
  return (
    <>
      <Divider sx={{ mt: 2 }} />
      {props.hideFilterPart ? (
        <div></div>
      ) : (
        <Grid container spacing={0} direction="row" justifyContent="flex-start" sx={{ margin: 0, mt: 2 }}>
          <Grid item xs={3}>
            <Typography align="left" variant="subtitle1">
              Filter by:{' '}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <List sx={{ display: 'flex', flexDirection: 'row', padding: 0, justifyContent: 'flex-start', flexWrap: 'wrap' }}>
              <Skeleton sx={{ borderRadius: '20px', height: '46px', mr: 1 }}>
                <Box sx={{ color: 'white', backgroundColor: 'lightgray', maxWidth: 100, m: 0.5, borderRadius: '20px', p: 1 }}>
                  <div>test</div>
                </Box>
              </Skeleton>
              <Skeleton sx={{ borderRadius: '20px', height: '46px' }}>
                <Box sx={{ color: 'white', backgroundColor: 'lightgray', maxWidth: 100, m: 0.5, borderRadius: '20px', p: 1 }}>
                  <div>test</div>
                </Box>
              </Skeleton>
            </List>
          </Grid>
        </Grid>
      )}
      <Stack spacing={3} sx={{ marginY: { xs: '4px' } }}>
        {!isMobile && (
          props.hideFilterPart ? (
            <div></div>
          ) : (
            <div>
              <Divider className={classes.muiDividerRoot}>
                <Skeleton>
                  <span>Placeholder</span>
                </Skeleton>
              </Divider>
            </div>
          )
        )}
        <Duplicate count={3}>
          <ListItem sx={{ width: '100%', padding: 0 }}>
            <LoaderFeedbackCard />
          </ListItem>
        </Duplicate>
      </Stack>
    </>
  );
};

export default LoaderPage;
