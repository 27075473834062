import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Paper, Stack, Tab, styled } from '@mui/material';
import React, { Suspense, useContext } from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import { SquadGroup } from 'src/services/SquadGroupService';
import ErrorBoundary from 'src/shared/ErrorBoundary';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { entelectBlue } from 'src/utilities/customColors';
import { makeStyles } from 'tss-react/mui';

import { useEntityContext } from './context/EntityContext';
import EmployeeCard from './entityList/EmployeeCard';
import EntityList from './entityList/EntityList';
import GroupCard from './entityList/GroupCard';
import Feedback from './feedback/Feedback';
import { ReducedEmployee } from 'src/Models/EmployeeModels';

interface MobileFeedbackPageProps {
  classes: any;
  showTabContext: boolean;
  tabGridWidth: string;
  selectedTabIndex: string;
  employees: ReducedEmployee[] | null;
  employeeStatus: string;
  selectedEmployee: any;
  groups: SquadGroup[] | null;
  groupStatus: string;
  selectedGroup: any;
  handleEntitySelection: (entity: any) => void;
  getEmployees: (refresh?: boolean) => void;
  onTabSelectionChange: (event: React.SyntheticEvent, newValue: string) => void;
  showEntityList: boolean;
  setGroup: React.Dispatch<React.SetStateAction<SquadGroup | null>>;
  setEmployee: React.Dispatch<React.SetStateAction<ReducedEmployee | null>>;
  feedbackGridWidth: string;
  feedbackGridOpacity: number;
  employeeSearchQueryParam: string;
}

const StyledTabList = styled(TabList)({
  borderBottom: '1px solid #8E8E8E',
  padding: 0,
  margin: 0,
  marginBottom: '24px',
  marginLeft: '24px',
  height: '30px',
  width: '360px',
  '& .MuiTabs-indicator': {
    backgroundColor: entelectBlue,
  },
});

const useStyles = makeStyles()(() => ({
  customContainer: {},
  customContainerMobile: {
    padding: 0,
  },
}));

const MobileFeedbackPage: React.FC<MobileFeedbackPageProps> = ({
  classes,
  showTabContext,
  tabGridWidth,
  selectedTabIndex,
  employees,
  employeeStatus,
  selectedEmployee,
  groups,
  groupStatus,
  selectedGroup,
  handleEntitySelection,
  getEmployees,
  onTabSelectionChange,
  showEntityList,
  setGroup,
  setEmployee,
  feedbackGridOpacity,
  feedbackGridWidth,
  employeeSearchQueryParam,
}) => {
  const isMobile = useIsMobile();
  const { classes: styleClass } = useStyles();
  const { squad } = useContext(SquadsContext);
  const { getGroups } = useEntityContext();

  return (
    <Box sx={{ minWidth: '1200px' }} className={isMobile ? styleClass.customContainerMobile : styleClass.customContainer}>
      <Grid container spacing={isMobile ? 0 : 2}>
        {showTabContext && (
          <Grid item sx={{ maxWidth: tabGridWidth }}>
            <Paper sx={{ width: '100%', display: showEntityList ? 'block' : 'inline-block' }}>
              <TabContext value={selectedTabIndex}>
                <TabContext value={selectedTabIndex}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <StyledTabList onChange={onTabSelectionChange} indicatorColor="primary" style={{ height: 30, color: '#5B5B5B' }}>
                      <Tab
                        className={classes.tabs}
                        label="Individuals"
                        value="individuals"
                        sx={{ width: '50%' }}
                        iconPosition="start"
                        icon={<PersonIcon />}
                      />
                      <Tab className={classes.tabs} label="Groups" value="groups" sx={{ width: '50%' }} iconPosition="start" icon={<GroupIcon />} />
                    </StyledTabList>
                    <Box display={'flex'} alignItems={'center'} pr={'24px'}>
                      <div id="rotate-action-button" />
                    </Box>
                  </Stack>
                  <TabPanel value={'individuals'} data-testid={'tab-0'} sx={{ p: 0 }}>
                    <EntityList
                      employeeSearchQueryParam={employeeSearchQueryParam}
                      entities={employees ?? []}
                      filterBy="fullName"
                      isLoading={employeeStatus === 'pending'}
                      renderEntity={(employee) => (
                        <ErrorBoundary id={`EmployeeCardMobile-${employee.employeeId}`} showNavBack={false}>
                          <EmployeeCard
                            employee={employee}
                            isSelected={employee.employeeId === selectedEmployee?.employeeId}
                            key={employee.employeeId}
                          />
                        </ErrorBoundary>
                      )}
                      entityType="Employees"
                      selectEntity={(entity) => {
                        handleEntitySelection(entity);
                      }}
                      loadEntities={() => getEmployees(true)}
                    />
                  </TabPanel>
                  <TabPanel value={'groups'} sx={{ p: 0 }}>
                    <EntityList
                      entities={groups ?? []}
                      filterBy="groupName"
                      isLoading={groupStatus === 'pending'}
                      renderEntity={(group) => (
                        <GroupCard selectSquadGroup={() => setGroup(group)} squadGroup={group} selectedSquadGroup={selectedGroup} />
                      )}
                      entityType="Groups"
                      selectEntity={(entity) => {
                        setEmployee(null);
                        setGroup(entity);
                      }}
                      loadEntities={() => getGroups()}
                    />
                  </TabPanel>
                </TabContext>
              </TabContext>
            </Paper>
          </Grid>
        )}
        <Grid item sx={{ width: feedbackGridWidth, opacity: feedbackGridOpacity }}>
          <Suspense fallback={<div></div>}>{squad.squadId && <Feedback employee={selectedEmployee} group={selectedGroup} />}</Suspense>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MobileFeedbackPage;
