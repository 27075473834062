import { useCallback, useState } from 'react';

type ToggleReturn = [state: boolean, toggle: (newState?: boolean) => void];

export const useToggle = (initialState = false): ToggleReturn => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback((newState?: boolean) => {
    setState((state) => (typeof newState === 'boolean' ? newState : !state));
  }, []);

  return [state, toggle];
};
