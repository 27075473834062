import TabPanel from '@mui/lab/TabPanel';
import { Grid } from '@mui/material';
import React from 'react';

import TableArea from '../activeEmployeesDashboard/TableArea';
import ChartsArea from '../activeEmployeesDashboard/chartsArea/ChartsArea';
import ClientManagementTableArea from '../clientManagementDashboard/ClientManagementTableArea';
import ClientManagementChartsArea from '../clientManagementDashboard/chartsArea/ClientManagementChartsArea';

const DashboardContent: React.FC = () => {
  return (
    <>
      <TabPanel value="active-employees">
        <Grid container>
          <Grid item xs={12}>
            <ChartsArea />
          </Grid>
          <Grid item xs={12}>
            <TableArea />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="client-management">
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <ClientManagementChartsArea />
          </Grid>
          <Grid item xs={12}>
            <ClientManagementTableArea />
          </Grid>
        </Grid>
      </TabPanel>
    </>
  );
};

export default DashboardContent;
