import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import * as React from 'react';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import './App.css';
import './GoogleFonts.css';
import LoadingComponent from './components/LoadingComponent';
import DashboardWrapper from './components/activeEmployeesDashboard/Dashboard';
import ResponsiveAppBar from './components/appBar/ResponsiveAppBar';
import EmployeeProfilePageWrapper from './components/employee/employee-profile/EmployeeProfilePage';
import FeedbackPageWrapper from './components/feedbackComponent/FeedbackPage';
import { EntityProvider } from './components/feedbackComponent/context/EntityContext';
import ManageSquads from './components/squads/ManageSquads';
import './custom.css';
import useGlobalErrorHandler from './hooks/useGlobalErrorHandler';
import DynamicTitleComponent from './shared/DynamicTitleComponent';
import ErrorBoundary from './shared/ErrorBoundary';

const ManageTasks = lazy(() => import('./components/manageTasks/ManageTasksContainer'));
const ManageUsers = lazy(() => import('./components/user/ManageUsers'));
const AddUser = lazy(() => import('./components/user/AddUser'));
const EditUser = lazy(() => import('./components/user/EditUser'));
const ManageCategories = lazy(() => import('./components/categories/ManageCategories'));
const AddCategory = lazy(() => import('./components/categories/AddCategory'));
const EditCategory = lazy(() => import('./components/categories/EditCategory'));
const UnauthorizedView = lazy(() => import('./views/UnauthorizedView'));
const ManageJobTitles = lazy(() => import('./components/title/ManageJobTitles'));
const EditJobTitle = lazy(() => import('./components/title/EditJobTitle'));
const AddJobTitle = lazy(() => import('./components/title/AddJobTitle'));
const EmployeeBulkUpload = lazy(() => import('./views/EmployeeBulkUpload'));
const EmployeeBulkImport = lazy(() => import('./views/EmployeeBulkImport'));
const EmployeeStatusBulkImport = lazy(() => import('./views/EmployeeStatusBulkImport'));
const RequireAuthorization = lazy(() => import('./components/authentication/RequireAuthorization'));
const AddEmployee = lazy(() => import('./components/employee/AddEmployee'));
const ManageSquadGroup = lazy(() => import('./components/squadGroup/ManageSquadGroup'));
const ManageSettings = lazy(() => import('./components/settings/ManageSettings'));
const RotateGroups = lazy(() => import('./components/rotateGroups/RotateGroups'));
const ManageSquadEmployees = lazy(() => import('./components/manageSquadEmployees/ManageSquadEmployees'));
const ManageCertificates = lazy(() => import('./components/manageCertificates/ManageCertificates'));
const ManageTagsModule = lazy(() => import('./components/manageTags/ManageTagsModule'));
const WhatsNew = lazy(() => import('./components/whats-new/WhatsNew'));
const Overview = lazy(() => import('./components/overview/Overview'));
const FeedbackBulkUpload = lazy(() => import('./components/feedbackImport/BulkFeedbackUpload'));

const App = (): JSX.Element => {
  const initialVisited = new Set(JSON.parse(localStorage.getItem('visited') || '[]'));
  const [visited, setVisited] = React.useState(initialVisited);
  useGlobalErrorHandler();

  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment) {
    const { worker } = require('./mocks/browser');
    worker.start();
  }

  return (
    <>
      <DynamicTitleComponent />
      <AuthenticatedTemplate>
        <ResponsiveAppBar visited={visited as Set<string>} />
        <div style={{ position: 'fixed', zIndex: 0 }} id={'route-leaving-guard'} />
        <Suspense fallback={<LoadingComponent />}>
          <Routes>
            <Route
              path="/bulk-upload-feedback"
              element={
                <ErrorBoundary id={'FeedbackBulkUpload'}>
                  <FeedbackBulkUpload />
                </ErrorBoundary>
              }
            />
            <Route
              path="/"
              element={
                <ErrorBoundary id={'NewDashboard'}>
                  <DashboardWrapper />
                </ErrorBoundary>
              }
            />
            <Route
              path="/employee-profile/:entelectId"
              element={
                <ErrorBoundary id={'EmployeeProfilePageWrapper'}>
                  <EmployeeProfilePageWrapper />
                </ErrorBoundary>
              }
            />
            <Route
              path="/capture-feedback"
              element={
                <ErrorBoundary id={'FeedbackPageWrapper'}>
                  <FeedbackPageWrapper />
                </ErrorBoundary>
              }>
              <Route
                path=":tab"
                element={
                  <ErrorBoundary id={'FeedbackPageWrapper'}>
                    <FeedbackPageWrapper />
                  </ErrorBoundary>
                }>
                <Route
                  path=":id"
                  element={
                    <ErrorBoundary id={'FeedbackPageWrapper'}>
                      <FeedbackPageWrapper />
                    </ErrorBoundary>
                  }
                />
                <Route path=":id/:showFlagged" />
              </Route>
            </Route>

            <Route
              path="/overview"
              element={
                <RequireAuthorization userAuthorization={'overview'}>
                  <ErrorBoundary id={'Overview'}>
                    <EntityProvider>
                      <Overview />
                    </EntityProvider>
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/manage-tasks"
              element={
                <ErrorBoundary id={'ManageTasks'}>
                  <ManageTasks />
                </ErrorBoundary>
              }
            />
            <Route
              path="/rotate-groups"
              element={
                <ErrorBoundary id={'RotateGroups'}>
                  <RotateGroups />
                </ErrorBoundary>
              }
            />
            <Route
              path="/rotate-groups/:tab"
              element={
                <ErrorBoundary id={'RotateGroups'}>
                  <RotateGroups />
                </ErrorBoundary>
              }
            />
            <Route
              path="/manage-squad-employees"
              element={
                <RequireAuthorization userAuthorization={'user'}>
                  <ErrorBoundary id={'ManageSquadEmployees'}>
                    <ManageSquadEmployees />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/manage-squad-employees/:tab"
              element={
                <ErrorBoundary id={'ManageSquadEmployees'}>
                  <ManageSquadEmployees />
                </ErrorBoundary>
              }
            />
            <Route
              path="/add-employee"
              element={
                <RequireAuthorization userAuthorization={'team'}>
                  <ErrorBoundary id={'AddEmployee'}>
                    <AddEmployee />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/bulk-upload-employee"
              element={
                <RequireAuthorization userAuthorization={'employee-bulk-upload'}>
                  <ErrorBoundary id={'EmployeeBulkUpload'}>
                    <EmployeeBulkUpload />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/bulk-import-employee"
              element={
                <ErrorBoundary id={'EmployeeBulkImport'}>
                  <EmployeeBulkImport />
                </ErrorBoundary>
              }
            />
            <Route path="/bulk-import-statuses" element={<EmployeeStatusBulkImport />} />
            <Route
              path="/manage-categories"
              element={
                <RequireAuthorization userAuthorization={'categories'}>
                  <ErrorBoundary id={'ManageCategories'}>
                    <ManageCategories />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/add-category"
              element={
                <RequireAuthorization userAuthorization={'categories'}>
                  <ErrorBoundary id={'AddCategory'}>
                    <AddCategory />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/edit-category"
              element={
                <RequireAuthorization userAuthorization={'categories'}>
                  <ErrorBoundary id={'EditCategory'}>
                    <EditCategory />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/manage-users"
              element={
                <RequireAuthorization userAuthorization={'user'}>
                  <ErrorBoundary id={'ManageUsers'}>
                    <ManageUsers />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/manage-certificates"
              element={
                <RequireAuthorization userAuthorization={'team'}>
                  <ErrorBoundary id={'ManageCertificates'}>
                    <ManageCertificates />
                  </ErrorBoundary>
                </RequireAuthorization>
              }>
              <Route
                path=":tab"
                element={
                  <RequireAuthorization userAuthorization={'user'}>
                    <ErrorBoundary id={'ManageCertificates'}>
                      <ManageCertificates />
                    </ErrorBoundary>
                  </RequireAuthorization>
                }
              />
            </Route>

            <Route
              path="/manage-tags"
              element={
                <RequireAuthorization userAuthorization={'team'}>
                  <ErrorBoundary id={'ManageTagsModule'}>
                    <EntityProvider>
                      <ManageTagsModule />
                    </EntityProvider>
                  </ErrorBoundary>
                </RequireAuthorization>
              }>
              <Route
                path=":tab"
                element={
                  <RequireAuthorization userAuthorization={'user'}>
                    <ErrorBoundary id={'ManageTagsModule'}>
                      <EntityProvider>
                        <ManageTagsModule />
                      </EntityProvider>
                    </ErrorBoundary>
                  </RequireAuthorization>
                }
              />
            </Route>

            <Route
              path="/whats-new"
              element={
                <RequireAuthorization userAuthorization={'team'}>
                  <ErrorBoundary id={'WhatsNew'}>
                    <WhatsNew visited={visited} setVisited={setVisited} />
                  </ErrorBoundary>
                </RequireAuthorization>
              }></Route>

            <Route
              path="/add-users"
              element={
                <RequireAuthorization userAuthorization={'user'}>
                  <ErrorBoundary id={'AddUser'}>
                    <AddUser />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/edit-users"
              element={
                <RequireAuthorization userAuthorization={'user'}>
                  <ErrorBoundary id={'EditUser'}>
                    <EditUser />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/unauthorized"
              element={
                <ErrorBoundary id={'UnauthorizedView'}>
                  <UnauthorizedView />
                </ErrorBoundary>
              }
            />
            <Route
              path="/manage-job-titles"
              element={
                <RequireAuthorization userAuthorization={'title'}>
                  <ErrorBoundary id={'ManageJobTitles'}>
                    <ManageJobTitles />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/edit-job-title"
              element={
                <RequireAuthorization userAuthorization={'title'}>
                  <ErrorBoundary id={'EditJobTitle'}>
                    <EditJobTitle />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/add-job-title"
              element={
                <RequireAuthorization userAuthorization={'title'}>
                  <ErrorBoundary id={'AddJobTitle'}>
                    <AddJobTitle />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/manage-squads"
              element={
                <RequireAuthorization userAuthorization={'squads'}>
                  <ErrorBoundary id={'ManageSquads'}>
                    <ManageSquads />
                  </ErrorBoundary>
                </RequireAuthorization>
              }
            />
            <Route
              path="/manage-groups"
              element={
                <ErrorBoundary id={'ManageSquadGroup'}>
                  <ManageSquadGroup />
                </ErrorBoundary>
              }
            />
            <Route
              path="/manage-settings"
              element={
                <ErrorBoundary id={'ManageSettings'}>
                  <ManageSettings />
                </ErrorBoundary>
              }
            />
          </Routes>
        </Suspense>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>You must be authenticated using your Entelect credentials in order to use the Dreadnought system.</p>
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
