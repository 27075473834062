import { ArrowRight } from '@mui/icons-material';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { EmployeeStatusService, StatusHistoryDTO } from 'src/services/EmployeeStatusService';
import { SquadLeaderContext } from 'src/utilities/SquadLeaderContext';

interface IEmployeeProfileHeaderProps {
  fullName: string;
  jobTitle: string;
  isActive: boolean;
  employeeId: number;
}

const EmployeeProfileHeader = (props: IEmployeeProfileHeaderProps) => {
  const { fullName, jobTitle, isActive, employeeId } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [statusDelta, setStatusDelta] = React.useState<StatusHistoryDTO[]>([]);

  const { squadId } = React.useContext(SquadLeaderContext).activeSquadLeader;

  const statusService = new EmployeeStatusService();

  const getStatusDelta = React.useCallback(async () => {
    if (employeeId && squadId) {
      try {
        const response = await statusService.getEmmployeeStatusDelta(+squadId, employeeId);
        setStatusDelta(response);
      } catch (error) {
        console.error('Error fetching status history:', error);
      }
    }
  }, [squadId, employeeId]);

  React.useEffect(() => {
    getStatusDelta();
  }, [getStatusDelta]);

  return (
    <Stack direction="row">
      <Stack alignItems={isMobile ? 'center' : 'start'}>
        <Typography variant="h5" component="div" align="center">
          <b>{fullName}</b>
          {!isActive && (
            <Typography variant="h6" component={'span'} color={'#5E5E5E'}>
              (Deactivated)
            </Typography>
          )}
        </Typography>
        <Typography variant="h6" color="#5D5D5D" component="div" align="center" width="100%">
          {jobTitle}
        </Typography>
      </Stack>
      {statusDelta.length > 0 && (
        <Stack direction="row" sx={{ alignItems: 'center', alignSelf: 'center', pl: '120px', textDecoration: 'underline' }}>
          <Stack>
            <Typography color="black" fontSize="18px">
              {statusDelta[0].employeeStatus}
            </Typography>
            <Typography fontSize="small">{`${new Date(statusDelta[0].dateRecorded).toLocaleDateString('en-CA')}`}</Typography>
          </Stack>

          <ArrowRight sx={{ pr: '4px', pl: '4px' }} />
          <Stack>
            <Typography color="black" fontSize="18px">
              {statusDelta[1].employeeStatus}
            </Typography>
            <Typography fontSize="small">{`${new Date(statusDelta[1].dateRecorded).toLocaleDateString('en-CA')}`}</Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default EmployeeProfileHeader;
