import { Box, Card, CardContent, Grid, ToggleButton, Typography } from '@mui/material';
import React from 'react';

interface QuickFilterCardProps {
  title: string;
  id: string;
  value: string;
  count: number | undefined;
  icon: any;
  isSelected?: boolean;
  onSelect?: (event: React.MouseEvent<HTMLElement>, newFilter: string) => void;
}

const QuickFilterCard: React.FC<QuickFilterCardProps> = ({ title, id, value, count, icon, isSelected, onSelect }) => {
  let elevation: number = 2;
  let disabled: boolean = false;
  if (count === undefined) {
    elevation = 0;
    disabled = true;
  }

  let peopleSubtitle: string = '';
  if (count === undefined) {
    peopleSubtitle = '-';
  } else if (count > 1) {
    peopleSubtitle = `${count} people`;
  } else if (count === 1) {
    peopleSubtitle = `${count} person`;
  }

  return (
    <Card sx={{ height: '46px' }} elevation={elevation} id={id}>
      <CardContent sx={{ p: 0, height: '100%' }}>
        <ToggleButton
          value={value}
          selected={isSelected}
          disabled={disabled}
          fullWidth
          onChange={onSelect}
          sx={{ height: '100%', paddingX: '10px', paddingY: '2px' }}>
          <Grid container display="flex" alignItems="center" gap="5px" wrap="nowrap">
            <Grid item>
              <Box width="40px" height="40px" display="flex" justifyContent="center" alignItems="center">
                {icon}
              </Box>
            </Grid>
            <Grid item>
              <Grid container display="flex" flexDirection="column" justifyContent="center" wrap="nowrap">
                <Grid item display="flex" justifyContent="flex-start">
                  <Typography variant="caption" textTransform="uppercase" noWrap>
                    {title}
                  </Typography>
                </Grid>
                {id !== 'no-feedback-by-category' && (
                  <Grid item display="flex" justifyContent="flex-start">
                    <Typography variant="subtitle2" textTransform="initial" noWrap sx={{ color: '#000000de' }}>
                      {peopleSubtitle}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ToggleButton>
      </CardContent>
    </Card>
  );
};

export default QuickFilterCard;
