import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { BoxProps } from '@mui/system';
import { intersectionBy } from 'lodash';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AsyncStatus } from 'src/hooks/useAsync';
import { Employee } from 'src/services/FeedbackType';
import { SquadGroup, SquadLeader } from 'src/services/SquadGroupService';
import { grey600 } from 'src/utilities/customColors';
import { SquadsContext } from 'src/utilities/SquadsContext';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #476B97',
  boxShadow: 24,
  p: 2,
};

interface Props {
  selectedGroup?: any;
  data: { employees: Employee[]; employeesStatus: AsyncStatus; squadLeaders: SquadLeader[]; squadLeadersStatus: AsyncStatus; groups: SquadGroup[] };
  onCancel: () => void;
  onSubmit: (data: any) => void;
}
interface FormValues {
  groupName: string;
  groupOwner?: SquadLeader;
  groupLeaders: SquadLeader[];
  groupMembers: Employee[];
}

const ModalForm: React.FC<Props> = (props) => {
  const { selectedGroup, data, onCancel, onSubmit } = props;
  const { squad } = useContext(SquadsContext);
  const editMode = selectedGroup !== null;

  const { employees, employeesStatus, squadLeaders, squadLeadersStatus } = data;

  const {
    control,
    watch,
    handleSubmit,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    values: {
      groupLeaders:
        (selectedGroup?.groupLeaders.filter(
          (leader: any) => leader.squadLeaderId !== selectedGroup?.groupOwner.squadLeaderId,
        ) as unknown as SquadLeader[]) || [],
      groupMembers: intersectionBy(employees, selectedGroup?.groupMembers ?? [], 'employeeSquadId').filter((e) => e.employeeId !== 0) || [],
      groupName: selectedGroup?.groupName || '',
      groupOwner: selectedGroup?.groupOwner.squadLeader || undefined,
    },
  });

  const groupOwner = watch('groupOwner');
  const groupLeaders = watch('groupLeaders');


  const getUniqueSquadOwners = (): SquadLeader[] => {
    const combinedArray = [...squadLeaders ?? [], ...groupLeaders ?? []];

    const uniqueEntries = combinedArray.reduce((accumulator, currentEntry) => {
      const { squadLeaderId, ...rest } = currentEntry;

      if (!accumulator[squadLeaderId]) {
        accumulator[squadLeaderId] = { squadLeaderId, ...rest };
      }

      return accumulator;
    }, {});

    return Object.values(uniqueEntries);
  }

  return (
    <Modal open={true}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          {editMode ? 'Update Squad' : 'Add Squad'}
        </Typography>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel shrink id="single-chip-label" htmlFor="single-chip-label" sx={{ backgroundColor: 'white', pr: '4px' }}>
                  Linked to which squad
                </InputLabel>
                <Select
                  readOnly
                  label="Linked to which squad"
                  notched
                  labelId="single-chip-label"
                  id="single-chip"
                  value={squad.squadId}
                  inputProps={{ IconComponent: () => null }}
                  input={<OutlinedInput notched id="select-single-chip" label="Chip" />}>
                  <MenuItem value={squad.squadId}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      <Chip key={squad.squadId} label={squad.shortName} />
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <FormControl sx={{ width: '100%' }}>
                <Stack direction="row" spacing={1}>
                  <Controller
                    name="groupName"
                    control={control}
                    rules={{
                      required: 'Squad name is required',
                      minLength: { value: 3, message: 'Squad name must be at least 3 characters' },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        sx={{ width: '100%' }}
                        onBlur={() => trigger('groupName')}
                        label="Squad Name"
                        error={errors.groupName !== undefined}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="groupOwner"
                    control={control}
                    rules={{ required: 'Group owner is required' }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        sx={{ width: '100%' }}
                        options={getUniqueSquadOwners()}
                        isOptionEqualToValue={(option, value) => option.squadLeaderId === value.squadLeaderId}
                        loading={squadLeadersStatus === 'pending'}
                        filterSelectedOptions
                        getOptionLabel={(option) => option?.user?.fullName ?? ''}
                        disabled={editMode}
                        onChange={(e: any, value: any) => field.onChange(value)}
                        value={field.value}
                        renderInput={(params: any) => <TextField {...params} label="Select Squad Owner" disabled={editMode} />}
                      />
                    )}
                  />
                </Stack>
              </FormControl>
            </Grid>
            <Grid container spacing={1} mt={'4px'}>
              <Grid item xs={12}>
                <Controller
                  name="groupLeaders"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: '100%' }}
                      multiple
                      isOptionEqualToValue={(option, value) => option.squadLeaderId === value.squadLeaderId}
                      options={squadLeaders?.filter((leader) => leader.squadLeaderId !== groupOwner?.squadLeaderId) ?? []}
                      loading={squadLeadersStatus === 'pending'}
                      filterSelectedOptions
                      getOptionLabel={(option) => squadLeaders?.find((leader) => leader.squadLeaderId === option.squadLeaderId)?.user?.fullName ?? ''}
                      onChange={(e: any, value: any) => field.onChange(value)}
                      value={field.value}
                      renderInput={(params: any) => <TextField {...params} label="Select Squad Leaders" />}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="groupMembers"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: '100%' }}
                      multiple
                      isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
                      options={employees ?? []}
                      loading={employeesStatus === 'pending'}
                      filterSelectedOptions
                      getOptionLabel={(option) => option.fullName ?? ''}
                      renderOption={(props, option) => (
                        <Box {...(props as BoxProps)} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                          <Typography>{option.fullName}</Typography> -
                          <Typography variant="body2" sx={{ color: grey600 }}>
                            {option.emailAddress}
                          </Typography>
                        </Box>
                      )}
                      onChange={(e: any, value: any) => field.onChange(value)}
                      value={field.value}
                      renderInput={(params: any) => <TextField {...params} label="Select Squad Member" />}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
              <Button variant="contained" color="secondary" disabled={!isDirty || !isValid} type="submit">
                {editMode ? 'UPDATE SQUAD' : 'ADD SQUAD'}
              </Button>
            </Stack>
          </form>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalForm;
