import { useTabContext } from '@mui/lab/TabContext';
import { List as MuiList, Paper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { KeyboardEvent, MouseEvent, RefObject, useContext, useEffect, useState } from 'react';
import { FixedSizeList as VirtualizedList } from 'react-window';
import Duplicate from 'src/components/shared/Duplicate';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { makeStyles } from 'tss-react/mui';

import SearchBar from '../../SearchBar';
import EmptySquadGroup from '../../shared/EmptySquad';
import EntityLoading from './EmployeeLoading';

const useStyles = makeStyles()(() => ({
  paper: {
    boxShadow: 'none',
    borderRadius: '0px',
  },
  list: {
    padding: 0,
  },
  listHeader: {
    backgroundColor: '#062951',
    color: '#FFF',
    padding: '8px 16px',
  },
}));

interface EntityListProps {
  entities: any[];
  renderEntity: (entity: any, scrollContainerRef?: RefObject<HTMLDivElement>) => JSX.Element;
  filterBy: string;
  entityType: string;
  isLoading: boolean;
  selectEntity: (entity: any | null) => void;
  loadEntities: () => void;
  employeeSearchQueryParam?: string;
}

const EntityList: React.FC<EntityListProps> = ({
  entities,
  renderEntity,
  filterBy,
  entityType,
  isLoading,
  selectEntity,
  loadEntities,
  employeeSearchQueryParam,
}) => {
  const [entityList, setEntityList] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState(employeeSearchQueryParam ?? '');
  const [listHeight, setListHeight] = useState(0);
  const tabContext = useTabContext();

  const { squad } = useContext(SquadsContext);

  useEffect(() => {
    const updateListHeight = () => {
      setListHeight(window.innerHeight - 310);
    };

    console.log(tabContext?.value);

    updateListHeight();
    window.addEventListener('resize', updateListHeight);

    return () => window.removeEventListener('resize', updateListHeight);
  }, []);

  useEffect(() => {
    const today = new Date();
    if (tabContext?.value === 'individuals') {
      if (!searchTerm || searchTerm === '') {
        setEntityList(
          entities
            .map((entity: any) => ({
              ...entity,
              futureDatePriority: entity.availableDate && new Date(entity.availableDate) > today ? 1 : 0,
              isEmployeeDeleted: entity.isDeleted && entity.isDeleted ? 3 : 2,
            }))
            .sort((a: any, b: any) => {
              if (a.isEmployeeDeleted !== b.isEmployeeDeleted) {
                return a.isEmployeeDeleted - b.isEmployeeDeleted;
              }
              return a.futureDatePriority - b.futureDatePriority;
            }),
        );
      } else {
        setEntityList(
          entities
            .filter((entity: any) => entity[filterBy].toLowerCase().includes(searchTerm.toLowerCase()))
            .map((entity: any) => ({
              ...entity,
              priority: entity[filterBy].toLowerCase().split(' ').includes(searchTerm.toLowerCase()) ? 1 : 0,
              futureDatePriority: entity.availableDate && new Date(entity.availableDate) > today ? 1 : 0,
            }))
            .sort((a: any, b: any) => {
              if (a.priority !== b.priority) return b.priority - a.priority;
              return a.futureDatePriority - b.futureDatePriority;
            }),
        );
      }
    } else {
      setEntityList(!searchTerm ? entities : entities.filter((entity: any) => entity[filterBy].toLowerCase().includes(searchTerm.toLowerCase())));
    }
  }, [entities, searchTerm, tabContext?.value]);

  const renderRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const entity = entityList[index];

    const handleEntitySelect = (e?: MouseEvent<HTMLDivElement>) => {
      if (e?.button === 0 || e?.button === undefined) {
        selectEntity(entity);
      }
    };
    const handleKeypress = (e: KeyboardEvent<HTMLDivElement>) => {
      if (e?.key === 'Enter') {
        handleEntitySelect();
      }
    };

    const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
      if (e.button === 0) {
        e.preventDefault();
      }
    };

    return (
      <div tabIndex={0} onClick={handleEntitySelect} onKeyUp={handleKeypress} style={style} key={index} onContextMenu={handleEntitySelect}>
        <a
          href={`/capture-feedback/indivduals/${entity.employeeId ?? undefined}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
          onClick={handleLinkClick}>
          {renderEntity(entity)}
        </a>
      </div>
    );
  };

  const { classes } = useStyles();

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} sx={{ my: 2, px: 1.25 }}>
        <SearchBar
          id="entity-search"
          tabIndex={0}
          placeholder={`Search ${entityType.toLowerCase()}`}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          autoFocus
          onFocus={(e) => e.target.select()}
        />
      </Box>
      <Box className={classes.listHeader} mt={2}>
        <Typography variant="body1" color={'inherit'}>
          {entityType} on {squad.shortName}
        </Typography>
      </Box>

      {isLoading ? (
        <Paper className={classes.paper}>
          <MuiList sx={{ padding: 0 }}>
            <Duplicate count={5}>
              <EntityLoading />
            </Duplicate>
          </MuiList>
        </Paper>
      ) : (
        !(entityList == null || entityList.length === 0) && (
          <Paper id="entity-list" className={classes.paper}>
            <VirtualizedList
              className={classes.list}
              height={listHeight}
              itemCount={entityList.length}
              itemSize={entityType.toLowerCase() === 'employees' ? 50 : 80}
              width="100%"
              style={{
                scrollbarWidth: 'thin',
              }}>
              {renderRow}
            </VirtualizedList>
            {entityList.length === 0 && <p>No results found.</p>}
          </Paper>
        )
      )}
      {(entityList == null || entityList.length === 0) && entityType === 'Groups' && <EmptySquadGroup handleSuccess={loadEntities} />}
    </>
  );
};

export default EntityList;
