import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";

export const CustomNavLink = styled(NavLink)(() => ({
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    letterSpacing: '0.02857em',
    padding: '1.1rem 1.1rem',

    [`&.active`]: {
        textDecoration: 'underline',
        textDecorationColor: '#8ABB4F',
        textDecorationThickness: '3px',
    }
}));