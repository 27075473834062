import React from 'react';
import { CertificationService } from 'src/services/CertificationService';
import useSWR from 'swr';

import { useEntityContext } from '../feedbackComponent/context/EntityContext';
import ProcessMentions from './ProcessMentions';
import { LearningPathFallBackService } from 'src/services/LearningPathFallBackService';

interface MentionProps {
  employeeId?: number;
  text: string;
  clickable?: boolean;
}
const certificationService = new CertificationService();

const Mention = (props: MentionProps) => {
  const { employeeId, text, clickable = true } = props;
  const learningpathService = new LearningPathFallBackService()

  const fetchEmployeeCertificates = async () => certificationService.getCertificatesByEmployeeId(employeeId!);
  const fetchLearningPaths = async () => await learningpathService.getEmployeeLearningPaths(employeeId!);

  const { data: employeeCertificate } = useSWR(employeeId ? ['mentions', employeeId] : undefined, fetchEmployeeCertificates);
  const { data: employeeLearningPaths } = useSWR(employeeId ? ['getApiLearningPaths', employeeId] : undefined, fetchLearningPaths);
  const { tags } = useEntityContext();

  const processedTags: any = [];

  tags?.forEach((item) => {
    if (item.tags && item.tags.length > 0) {
      const uniqueTagNames = new Set();

      item.tags.forEach((tag) => {
        if (!uniqueTagNames.has(tag.tagName)) {
          uniqueTagNames.add(tag.tagName);

          const newObject = {
            categoryColor: item.categoryColor,
            categoryDescription: item.categoryDescription,
            isDeleted: false,
            tagCategoryId: item.tagCategoryId,
            tagCategoryName: item.tagCategoryName,
            tags: [
              {
                tagId: tag.tagId,
                tagName: tag.tagName,
                description: tag.description,
                isDeleted: tag.isDeleted,
                tagCategoryId: tag.tagCategoryId,
                // subTags: [...tag.subTags]
              },
            ],
          };
          processedTags.push(newObject);
        }
      });
    }
  });

  return (
    <>
      <ProcessMentions
        text={text}
        tagData={processedTags ?? []}
        certData={employeeCertificate ?? []}
        learningPathData={employeeLearningPaths ?? []}
        clickable={clickable}
      />
    </>
  );
};

export default Mention;
