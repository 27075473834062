import { createContext } from 'react';

class Squad {
  squadId: string;
  squadName: string;
  shortName: string;
  isDeleted: boolean;
}

interface SquadsContextState {
  squad: Squad,
  squads: Squad[],
  setSquad: React.Dispatch<React.SetStateAction<Squad>>,
  setSquads: React.Dispatch<React.SetStateAction<Squad[]>>
}

const initialState: SquadsContextState = {
  squad: new Squad(),
  squads: [],
  setSquad: () => { },
  setSquads: () => { }
}

export const SquadsContext = createContext(initialState);