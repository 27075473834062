// TopLevelProviders.tsx
import { InteractionType } from '@azure/msal-browser';
import { useMsalAuthentication } from '@azure/msal-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';

import RequestInterceptor from './components/authentication/RequestInterceptor';
import { FeatureToggleProvider } from './components/featureToggle/FeatureToggle';
import { SnackbarProvider } from './components/shared/SnackbarContext';
import { LoggedInUserProvider } from './utilities/LoggedInUserContext';
import { SquadLeaderContext } from './utilities/SquadLeaderContext';
import { SquadsContext } from './utilities/SquadsContext';
import { TaskProvider } from './utilities/TaskContext';
import { UserContext } from './utilities/UserContext';
import { entelectBlue, entelectRed, entelectSuccess, entelectWarning } from './utilities/customColors';

const defaultSquadLeader = {
  squadLeaderId: '',
  squadId: '',
  userId: '',
  isDeleted: false,
  isAdmin: false,
  isSuperUser: false,
  categoryId: 0,
  user: { userId: '', emailAddress: '', isActive: false, isTenantManager: false, fullName: '' },
};

const TopLevelProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // ... all the state management code and theme creation code from the App component

  const defaultSquad = { squadId: '', squadName: '', shortName: '', isDeleted: false };
  const storedSquad = localStorage.getItem('selected_squad');

  const [user, setUser] = React.useState({ userId: '', emailAddress: '', isActive: false, isTenantManager: false, fullName: '' });
  const [squad, setSquad] = React.useState(storedSquad ? JSON.parse(storedSquad) : defaultSquad);
  const [squads, setSquads] = React.useState([]);
  useMsalAuthentication(InteractionType.Redirect);

  const newTheme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: entelectBlue, contrastText: '#fff' },
      success: { main: entelectSuccess, contrastText: '#fff' },
      error: { main: entelectRed },
      warning: { main: entelectWarning },
      secondary: {
        main: '#6DAA23',
        contrastText: '#fff',
      },
      info: {
        main: '#BEE3F8',
      },
    },
    shape: { borderRadius: 5 },
    typography: {
      fontFamily: ['Roboto', 'sans-serif'].join(','),
      allVariants: { color: entelectBlue },
      h3: {
        color: '#000',
        fontSize: '21px',
        fontWeight: 500,
        lineHeight: 'normal',
      },
      h4: { color: '#4A5568', fontWeight: 500 },
      h5: { color: '#4A5568', fontWeight: 500 },
      h6: { color: '#4A5568', fontWeight: 500 },
      caption: { color: '#646464' },
    },
  });

  const [activeSquadLeader, setActiveSquadLeader] = React.useState(defaultSquadLeader);
  const [squadLeader, setSquadLeader] = React.useState([defaultSquadLeader]);

  return (
    <ThemeProvider theme={newTheme}>
      <UserContext.Provider value={{ user, setUser }}>
        <SquadsContext.Provider value={{ squad, setSquad, squads, setSquads }}>
          <SnackbarProvider>
            <LoggedInUserProvider>
              <FeatureToggleProvider>
                <TaskProvider>
                  <SquadLeaderContext.Provider value={{ squadLeader, setSquadLeader, activeSquadLeader, setActiveSquadLeader }}>
                    <RequestInterceptor>{children}</RequestInterceptor>
                  </SquadLeaderContext.Provider>
                </TaskProvider>
              </FeatureToggleProvider>
            </LoggedInUserProvider>
          </SnackbarProvider>
        </SquadsContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  );
};

export default TopLevelProviders;
