import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import React from 'react';

import CopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import { blue500 } from 'src/utilities/customColors';
interface EmployeeContactDetailsProps {
  emailAddress: string;
  telephoneNumber?: string;
}

const IconButtonWithCopy = ({ value }: { value: string }) => {
  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
  };
  return (
    <IconButton aria-label={`copy-${value}`} sx={{ color: blue500 }} onClick={() => handleCopy(value)}>
      <CopyIcon />
    </IconButton>
  );
};
const EmployeeContactDetails = (props: EmployeeContactDetailsProps) => {
  const { emailAddress, telephoneNumber } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'block',
        minHeight: 24,
        [theme.breakpoints.down('sm')]: {
          margin: 0,
        },
        padding: 0,
        '& a': { textDecoration: 'none', color: blue500 },
      }}>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography variant="body1" display={'flex'} component="div" alignItems="center" justifyContent={'center'}>
          <MailOutlineIcon sx={{ p: '8px', pl: 0 }} />
          <a href={`mailto:${emailAddress}`}>
            <b>{emailAddress}</b>
          </a>
        </Typography>
        <IconButtonWithCopy value={emailAddress} />
      </Grid>

      <Grid style={{ display: 'flex', justifyContent: 'space-between', margin: 0 }}>
        <Typography variant="body1" display={'flex'} component="div" alignItems="center" justifyContent={'center'}>
          <CallIcon sx={{ p: '8px', pl: 0 }} />
          {telephoneNumber ? (
            <a href={`tel:${telephoneNumber}`}>
              <b>{telephoneNumber}</b>
            </a>
          ) : (
            <b>—</b>
          )}
        </Typography>
        {telephoneNumber && <IconButtonWithCopy value={telephoneNumber} />}
      </Grid>
    </Box>
  );
};

export default EmployeeContactDetails;
