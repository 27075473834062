import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { SortDirection, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';

interface ColumnHeaderProps {
  title: string;
  tooltipText?: string;
  columnKey: string;
  disableSort?: boolean;
  onClick?: (columnKey: string) => void;
  sortDirection?: (columnKey: string) => SortDirection;
  style?: CSSProperties;
}

const ColumnHeader: React.FC<ColumnHeaderProps & Partial<Omit<StackProps, 'onClick'>>> = ({
  title,
  tooltipText,
  columnKey,
  disableSort = false,
  onClick = () => {},
  sortDirection = () => false,
  style,
  ...props
}) => {
  const sortDirectionIndicator = () => {
    const sortByDirection = sortDirection(columnKey);

    if (!sortByDirection) {
      return null;
    } else if (sortByDirection === 'asc') {
      return <ArrowUpward fontSize="small" />;
    } else {
      return <ArrowDownward fontSize="small" />;
    }
  };

  const handleClick = () => {
    if (!disableSort) {
      onClick(columnKey);
    }
  };

  return (
    <Tooltip title={tooltipText ?? ''} placement="top-start">
      <Stack direction="row" alignItems="center"  onClick={handleClick} style={style} {...props} sx={{ cursor: disableSort ? 'default' : 'pointer', ...props.sx }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color:'#4A5568' }}>
          {title}
        </Typography>
        {sortDirectionIndicator()}
      </Stack>
    </Tooltip>
  );
};

export default ColumnHeader;
