import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import SnackBarComponent from 'src/components/shared/SnackBarComponent';
import { FeedbackService } from 'src/services/FeedbackService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { entelectBlue } from 'src/utilities/customColors';

interface FeedbackExportData {
  capturer: string;
  employeeName: string;
  sentiment: string;
  feedbackDescription: string;
  createdDate: Date;
  groupName: string;
  categoryName: string;
}

const FeedbackExportModal = () => {
  const feedbackService = new FeedbackService();
  const [exportFeedback, setExportFeedback] = useState<FeedbackExportData[] | any>([]);
  const [startDateValue, setStartDateValue] = useState<Date | null>(null);
  const [endDateValue, setEndDateValue] = useState<Date | null>(null);
  const squadId = React.useContext(SquadsContext).squad.squadId;
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openNoFeedback, setOpenNoFeedback] = useState(false);
  const [enableDownload, setEnableDownload] = useState(false);
  const [exportClicked, setExportClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [entityCount, setEntityCount] = useState<number | undefined>(undefined);

  const handleStartDateChange = (date: Date | null) => {
    setStartDateValue(date);
    setEnableDownload(false);
  };

  const handleEndDateChange = (date: Date) => {
    setEndDateValue(date);
    setEnableDownload(false);
  };

  const handleExportClick = (startDate: Date, endDate: Date) => {
    setExportClicked(true);
    setIsLoading(true);
    feedbackService
      .getFeedbackExportData(squadId, startDate.toDateString(), endDate.toDateString())
      .then((entities) => {
        setExportFeedback(entities);
      })
      .catch((error) => {
        console.error('Error fetching feedback export data:', error);
        setExportFeedback([]);
        setIsLoading(false);
      });
  };

  const handleDatesConfirmed = () => {
    if (startDateValue !== null && endDateValue !== null) {
      handleExportClick(startDateValue, endDateValue);
    }
  };

  useEffect(() => {
    if (startDateValue !== null && endDateValue !== null) handleDatesConfirmed();
  }, [startDateValue, endDateValue]);

  useEffect(() => {
    if (exportFeedback !== undefined && exportFeedback.length > 0) {
      setEnableDownload(true);
      setEntityCount(exportFeedback.length);
      setIsLoading(false);
    } else {
      if (exportClicked) {
        setEnableDownload(false);
        setOpenNoFeedback(true);
        setIsLoading(false);

        setTimeout(() => {
          setOpenSuccess(false);
        }, 3000);
      }
    }
  }, [exportFeedback]);

  const runDownload = () => {
    if (exportFeedback.length > 0) {
      setOpenSuccess(true);

      setTimeout(() => {
        setOpenSuccess(false);
      }, 3000);

      setEnableDownload(false);

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Feedback Data');

      worksheet.addRow(['Capturer', 'Employee Name', 'Sentiment', 'Feedback Description', 'Captured Date', 'Group Name', 'Category Name']);

      exportFeedback.forEach((feedback: FeedbackExportData) => {
        worksheet.addRow([
          feedback.capturer,
          feedback.employeeName,
          feedback.sentiment,
          feedback.feedbackDescription,
          feedback.createdDate,
          feedback.groupName,
          feedback.categoryName,
        ]);
      });

      worksheet.columns.forEach((column) => {
        if (column && column.eachCell) {
          column.eachCell({ includeEmpty: true }, (cell) => {
            column.width = Math.max(column.width || 0, cell.value ? String(cell.value).length * 1.1 : 10);
          });
        }
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'FeedbackData.xlsx');
      });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Typography sx={{ color: entelectBlue, pb: '20px', fontWeight: 'bold' }}>Feedback Export Dates: </Typography>

      <div style={{ display: 'flex' }}>
        <DatePicker
          label="Start Date: "
          value={startDateValue}
          views={['month', 'year', 'day']}
          onChange={handleStartDateChange}
          renderInput={(props) => <TextField {...props} />}
        />
        <div style={{ paddingLeft: '20px' }}>
          <DatePicker
            label="End Date: "
            value={endDateValue}
            views={['month', 'year', 'day']}
            onChange={handleEndDateChange}
            renderInput={(props) => <TextField {...props} />}
          />
        </div>
      </div>
      <div style={{ padding: '20px', color: entelectBlue }}>
        <Button color="success" variant="outlined" onClick={runDownload} disabled={!enableDownload}>
          {isLoading ? <CircularProgress size={24} /> : 'Download Export'}
        </Button>
        {!isLoading && enableDownload && entityCount && <Typography sx={{ pt: '20px' }}>Found {entityCount} feedback entries. </Typography>}
      </div>
      <SnackBarComponent
        message="Successful Feedback Export."
        severity="success"
        openSnackbarState={openSuccess}
        setOpenSnackbarState={setOpenSuccess}
      />
      <SnackBarComponent
        message="No Feedback For Selected Dates."
        severity="info"
        openSnackbarState={openNoFeedback}
        setOpenSnackbarState={setOpenNoFeedback}
      />
    </LocalizationProvider>
  );
};

export default FeedbackExportModal;
