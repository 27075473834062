import { Configuration } from '@azure/msal-browser';
import { env } from './env';
export const msalConfig: Configuration = {
  auth: {
    clientId: env.REACT_APP_AZURE_CLIENT_ID ?? '',
    authority: 'https://login.microsoftonline.com/e3183c89-78ae-43e0-b8a3-3eb012e5d9ea',
    redirectUri: '/',
    clientCapabilities: ['CP1'],
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints and scopes for the web API you would like to use.
export const apiConfig = {
  scopes: [`api://${env.REACT_APP_AZURE_CLIENT_ID}/access_as_user`],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com',
};
