import { MentionData, defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import { SubMentionComponentProps } from '@draft-js-plugins/mention/lib/Mention';
import { Chip, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { EmployeeLearningPathDTO } from 'src/Models/LearninpathModels';
import { TagCategory } from 'src/Models/TagModels';
import { CertificationsDetails, EmployeeCertificateDTO } from 'src/services/CertificationService';
import { makeStyles } from 'tss-react/mui';

export const CERTIFICATION_TAG_COLOUR = 'rgba(22, 182, 18, 1)';
export const LEARNING_PATH_TAG_TYPE = 'learnigPathTag';
export const DEFAULT_TAG_TYPE = 'tag';
export const CERTIFICATION_TAG_TYPE = 'certification';

export const useStyles = makeStyles()((theme) => ({
  editor: {
    fontSize: '16px',
    color: 'gray',
    minHeight: '80px',
    maxHeight: '200px',
    maxWidth: '100%',
    wordBreak: 'break-word',
    boxSizing: 'border-box',
    border: '1px solid #ddd',
    cursor: 'text',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'inset 0px 1px 4px -2px #ABABAB',
    background: '#fefefe',
    overflowY: 'auto',
    '&:global(.public-DraftEditor-content)': {
      minHeight: '140px',
    },
  },
}));

export const MentionComponent: ComponentType<SubMentionComponentProps> = (mentionProps) => {
  const backgroundColor = mentionProps.mention.type === 'certification' ? CERTIFICATION_TAG_COLOUR : mentionProps.mention.color;

  return (
    <Chip
      size="small"
      sx={{
        height: '100%',
        lineHeight: '2',
        fontSize: '13px',
        paddingY: '4px',
        backgroundColor: backgroundColor,
        color: 'white',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
      }}
      label={
        <Typography sx={{ whiteSpace: 'normal', color: 'white', m: '4px', fontFamily: 'Roboto, sans-serif', fontSize: '13px' }}>
          {mentionProps.children}
        </Typography>
      }
    />
  );
};

const useSuggestions = (
  certifications: CertificationsDetails[] | undefined,
  employeeCertificates: EmployeeCertificateDTO[] | undefined,
  tags: TagCategory[] | null,
  employeeLearningPaths: EmployeeLearningPathDTO[] | undefined,
) => {
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);
  const [mentions, setMentions] = useState<MentionData[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isEmpty(certifications) && isEmpty(tags) && isEmpty(employeeLearningPaths)) return;

    const mapItems = (items: any, mapFunc: any) => (items ? items.map(mapFunc) : []);

    const mappedCertifications = mapItems(certifications, (cert: any) => ({
      id: cert.certificateId,
      name: `${cert.examName}${cert.examName ? ' - ' : ''}${cert.certificateName}`,
      avatar: cert.icon,
      type: CERTIFICATION_TAG_TYPE,
      color: CERTIFICATION_TAG_COLOUR,
    }));

    const mappedLearningPaths = mapItems(employeeLearningPaths, (path: any) => ({
      id: path.id,
      name: `${path.name} - ${path.phases
        ?.filter((phase: any) => phase.isActive)
        .map((phase: any) => phase.name)
        .join(', ')}`,
      type: LEARNING_PATH_TAG_TYPE,
      color: CERTIFICATION_TAG_COLOUR,
    }));

    const mappedTags = mapItems(tags, (tag: any) =>
      tag.tags.map((linkTag: any) => ({
        id: linkTag.tagId,
        name: linkTag.tagName,
        avatar: 'https://dread0devstorage.blob.core.windows.net/dread0devblob/Icons/skillsIcon.png',
        type: DEFAULT_TAG_TYPE,
        color: tag.categoryColor,
      })),
    ).flat();

    let sortedSuggestions = mappedCertifications;

    if (employeeCertificates) {
      sortedSuggestions = mappedCertifications.sort((a: any, b: any) => {
        const isAInProgress = employeeCertificates.some((cert) => cert.certificateId === a.id && cert.status === 'In Progress');
        const isBInProgress = employeeCertificates.some((cert) => cert.certificateId === b.id && cert.status === 'In Progress');
        return isAInProgress === isBInProgress ? 0 : isAInProgress ? -1 : 1;
      });
    }

    const allSuggestions = [...sortedSuggestions, ...mappedTags, ...mappedLearningPaths];

    setMentions(allSuggestions);
    setSuggestions(allSuggestions.slice(0, 5));
  }, [certifications, employeeCertificates, tags, employeeLearningPaths]);

  const onOpenChange = useCallback((_open: boolean) => setOpen(_open), []);

  const onSearchChange = useCallback(
    ({ trigger, value }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentions, trigger));
    },
    [mentions],
  );

  return { mentions, suggestions, open, onOpenChange, onSearchChange };
};

export default useSuggestions;
