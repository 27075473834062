import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { env } from './env';

const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: env.REACT_APP_APPLICATION_INSIGHTS,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});
ai.loadAppInsights();

const enhanceWithAITracking = <Props>(Component: React.ComponentType<Props>) => withAITracking(reactPlugin, Component);
export default enhanceWithAITracking;

export const appInsights: Microsoft.ApplicationInsights.IAppInsights = ai.appInsights;
