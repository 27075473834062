import { Autocomplete, Grid, TextField } from '@mui/material';
import React from 'react';
import { Tag } from 'src/Models/TagModels';

interface TagChartControlsProps {
  tags: Tag[];
  onTagChange: (selectedTag: Tag | null) => void;
}

const TagChartControls: React.FC<TagChartControlsProps> = ({ tags, onTagChange }) => {
  return (
    <Grid container id="tag-chart-controls" direction={{ xs: 'row' }} spacing={1}>
      <Grid item xs={6} lg={12}>
        <Autocomplete
          id="selected-tags"
          options={tags}
          getOptionLabel={(tag: Tag) => tag.tagName}
          multiple={false}
          filterSelectedOptions
          onChange={(event, value) => onTagChange(value)}
          renderInput={(params) => <TextField {...params} size="small" label="Apply other tags" InputLabelProps={{ shrink: true }} />}
        />
      </Grid>
    </Grid>
  );
};

export default TagChartControls;
