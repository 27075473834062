import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';

interface Props {
  name: string;
}

/**
 * @name NameInitials
 * @description Initials are displayed for the name of the user
 * @param {string} name - Name of the user
 * @returns {JSX.Element} - Initials of the name as a chip component
 */

const NameInitials = ({ name }: Props) => {
  const [initials, setInitials] = useState<string>('');
  const [label, setLabel] = useState('');
  useEffect(() => {
    const getInitials = (name: string) => {
      const regex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
      const matches = name.matchAll(regex);
      const nameInitials = [...matches].map((match) => match[1].toUpperCase()).join('');
      setInitials(nameInitials);
      setLabel(nameInitials);
    };

    getInitials(name);
  }, [name]);

  return (
    <div className="col-md-6">
      <Chip
        onMouseOver={() => {
          setLabel(name);
        }}
        onMouseLeave={() => {
          setLabel(initials);
        }}
        label={label}
        sx={{ p: 0 }}
        role="button"
        tabIndex={0}
      />
    </div>
  );
};

export default NameInitials;
