import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

import FlagIcon from '@mui/icons-material/Flag';
import VisibilityIcon from '@mui/icons-material/VisibilityOff';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { UnflaggedIcon } from 'src/components/shared/UnflaggedIcon';

interface FeedbackCategoryDisplayProps {
  name: string;
  hexColor: string;
  backgroundColour: string;
  isFlagged?: boolean;
  isHiddenFromOverview?: boolean;
  hasReason?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  statusPaper: {
    height: '33px',
    minWidth: '120px',
  },
}));

const FeedbackCategoryDisplay: React.FC<FeedbackCategoryDisplayProps> = ({
  name,
  hexColor,
  backgroundColour,
  isFlagged = false,
  isHiddenFromOverview = false,
  hasReason = false,
}) => {
  const { classes } = useStyles();

  return (
    <Paper
      variant="outlined"
      square
      className={classes.statusPaper}
      sx={{ borderLeft: 6, borderLeftColor: hexColor, backgroundColor: backgroundColour }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ height: 1, pl: 1, pr: 1, color: hexColor }}>
        <Typography noWrap>{name}</Typography>
        {hasReason && (
          <Box sx={{ pl: '4px' }}>
            <UnflaggedIcon aria-label="unflagged-icon" />
          </Box>
        )}
        {!hasReason && isFlagged && <FlagIcon aria-label="flag-icon" fontSize="small" />}
        {!hasReason && isHiddenFromOverview && <VisibilityIcon aria-label="visibility-icon" fontSize="small" />}
      </Stack>
    </Paper>
  );
};

export default FeedbackCategoryDisplay;
