import { Avatar, Skeleton } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  employeeCard: {
    width: 'auto',
    borderRadius: '0!important',
    '&:hover': {
      background: '#13397010',
      cursor: 'pointer',
    },
    '& .MuiCardHeader-title': {
      fontSize: '18px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      color: '#000000',
      marginBottom: '0',
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '14px',
      fontFamily: 'Roboto',
      marginTop: '0px',
    },
  },
}));

const EntityLoading = () => {
  const { classes } = useStyles();

  return (
    <Card
      data-testid="entity-loading"
      variant="outlined"
      className={classes.employeeCard}
      sx={{
        backgroundColor: '#FFFFFF',
        borderLeft: 'none',
      }}>
      <CardHeader
        avatar={<Avatar />}
        title={
          <Skeleton>
            <span>place holder</span>
          </Skeleton>
        }
        subheader={
          <Skeleton>
            <span>place holder</span>
          </Skeleton>
        }
      />
    </Card>
  );
};

export default EntityLoading;
