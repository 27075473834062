import { SeverityLevel } from '@microsoft/applicationinsights-web';
import React from 'react';
import { appInsights } from 'src/appInsights';
import ErrorPage from 'src/components/shared/ErrorPage';
import { useSnackbar } from 'src/components/shared/SnackbarContext';
import { useErrorBoundary } from 'use-error-boundary';

import { GenericErrorMessage } from './GenericMessages';

interface ErrorBoundaryProps {
  id: string;
  children: React.ReactNode;
  showNavBack?: Boolean;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ id, children, showNavBack }) => {
  const { openSnackbar } = useSnackbar();

  const onDidCatch = (error: any, errorInfo: any) => {
    try {
      appInsights.trackException(new Error(`UI Error caught in ErrorBoundary: ${id}`), undefined, undefined, undefined, SeverityLevel.Error);
      appInsights.trackException(error, undefined, {}, undefined, SeverityLevel.Error);
      openSnackbar('error', GenericErrorMessage);
    } catch (e) {
      console.error('Error logging to appInsights:', e);
    }
  };

  const { ErrorBoundary, didCatch, reset } = useErrorBoundary({ onDidCatch: onDidCatch });

  const onResolved = () => {
    reset();
  };

  return didCatch ? <ErrorPage onResolved={onResolved} showNavBack={showNavBack} /> : <ErrorBoundary>{children}</ErrorBoundary>;
};

export default ErrorBoundary;
