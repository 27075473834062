import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import FullDateWithDivider from 'src/components/FullDateWithDivider';
import FeedbackCardLoader from 'src/components/feedbackComponent/feedback/history/FeedbackCardLoader';
import FeedbackCard from 'src/components/manageCertificates/manageCertifications/CertificateHistoryModal/FeedbackCard';
import Duplicate from 'src/components/shared/Duplicate';
import { CategoryService } from 'src/services/CategoryService';
//import { CategoryService } from 'src/services/CategoryService';
import { CertificationService } from 'src/services/CertificationService';
import { FeedbackService } from 'src/services/FeedbackService';
import { Feedback } from 'src/services/FeedbackType';
import { SquadsContext } from 'src/utilities/SquadsContext';

interface CertificationCompanyFeedbackModalProps {
  employeeId: number;
  companyName: string;
  onClose?: () => void;
}

const certService = new CertificationService();

const CertificationCompanyFeedbackModal = ({ employeeId, companyName, onClose }: CertificationCompanyFeedbackModalProps) => {
  const squadID = useContext(SquadsContext).squad.squadId;
  const feedbackService = new FeedbackService();

  const [categoryList, setCategoryList] = useState([]);
  const [feedback, setFeedback] = useState<Feedback[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const categoryService = new CategoryService()

  const getCategories = async () => {
    const data = await categoryService.getAllCategoriesForSquad(squadID)
    setCategoryList(data)
  }

  useEffect(() => {
    const fetchData = async () => {
      getCategories()
      setIsLoading(true);
      const data = await certService.getCertificatesByEmployeeId(employeeId);
      const entries: Feedback[] = [];
      for (const employeeCertificate of data) {
        if (employeeCertificate.certificateCompany?.companyName === companyName) {
          const feedbackData = await feedbackService.getCertificationFeedback(employeeCertificate.certificateName, employeeCertificate.employeeId);
          for (const entry of feedbackData) {
            entries.push(entry);
          }
        }
      }
      setFeedback(feedback.concat(entries));
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Dialog open fullWidth maxWidth={'md'} onClose={onClose}>
      <DialogTitle sx={{ backgroundColor: '#E0E0E0', display: 'flex', justifyContent: 'center', color: 'black' }}>
        <Box>{companyName.toUpperCase()} CERTIFICATIONS</Box>
      </DialogTitle>
      <DialogContent>
        <div style={{ paddingBottom: '20px' }}></div>
        <Stack>
          {isLoading && (
            <Duplicate count={3}>
              <ListItem sx={{ pb: '40px' }}>
                <FeedbackCardLoader />
              </ListItem>
            </Duplicate>
          )}
          {feedback?.map((feedbackItem) => (
            <>
              <FullDateWithDivider date={feedbackItem.createdDate} />
              <ListItem key={feedbackItem.feedbackId} sx={{ pb: '40px' }}>
                {!isLoading && (
                  <FeedbackCard categoryList={categoryList} displayEmployeeDetail={false} feedbackItem={feedbackItem} employeeId={employeeId} />
                )}
              </ListItem>
            </>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: '#E0E0E0', pr: '40px' }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CertificationCompanyFeedbackModal;
