import { InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { useAccount, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { appInsights } from 'src/appInsights';
import { SquadLeaderService } from 'src/services/SquadLeaderService';
import { SquadService } from 'src/services/SquadService';
import UnauthorizedView from 'src/views/UnauthorizedView';
import useSWR from 'swr';

import { apiConfig, loginRequest } from '../../authConfig';
import { LoggedInUserContext } from '../../utilities/LoggedInUserContext';
import { SquadLeaderContext } from '../../utilities/SquadLeaderContext';
import { SquadsContext } from '../../utilities/SquadsContext';
import { SplashScreen } from '../shared/SplashScreen';

interface RequestInterceptorProps {
  children?: React.ReactNode;
}

const squadLeaderService = new SquadLeaderService();
const squadService = new SquadService();

let ACCESS_TOKEN = '';
const MAX_RETRIES = 3;

const parseJwt = (token: string) => {
  const decode = JSON.parse(atob(token.split('.')[1]));
  return decode;
};

const isTokenExpired = (token: any) => token.exp * 1000 < new Date().getTime();

const LoadingComponent: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { loggedInUser } = React.useContext(LoggedInUserContext);
  const { setSquadLeader, setActiveSquadLeader } = React.useContext(SquadLeaderContext);
  const { squad, squads, setSquad, setSquads } = React.useContext(SquadsContext);

  const { data: squadLeaders, isLoading: leadersLoading } = useSWR('squadLeaders', async () => squadLeaderService.getAll(), {
    revalidateOnFocus: false,
  });
  const { data: squadsData, isLoading: squadsloading } = useSWR(
    loggedInUser ? 'squads' : undefined,
    async () => squadService.getSquadsForCurrentUser(),
    {
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (isEmpty(loggedInUser) || !squadLeaders || !squadsData || !squad) return;

    const matchingSquadLeader = squadLeaders.filter((leader: any) => leader.user.emailAddress.toLowerCase() === loggedInUser.toLowerCase());

    setSquadLeader(matchingSquadLeader);

    const noSquadsAssigned = !squad.squadId || squads.length === 0;

    const currentSquad = noSquadsAssigned ? squadsData[0] : squad;

    setSquads(noSquadsAssigned ? squadsData : squads);
    setSquad(currentSquad);
    setActiveSquadLeader(matchingSquadLeader.find((leader: any) => leader.squadId === currentSquad.squadId));
  }, [loggedInUser, squadLeaders, squadsData]);

  if (leadersLoading || squadsloading || isEmpty(loggedInUser) || +squad.squadId === 0) return <SplashScreen />;
  if (!squadsloading && squadsData?.length === 0) return <UnauthorizedView />;

  return <>{children}</>;
};

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  useMsalAuthentication(InteractionType.Redirect);
  const { setLoggedInUser, setLoggedInUserFullName } = React.useContext(LoggedInUserContext);
  const navigate = useNavigate();

  if (!account) return null;

  // Axios configuration
  axios.defaults.baseURL = 'api/';

  const silentUrls = [
    { url: 'User', method: 'patch' },
    { url: 'Squads/squad/getSquadsByUserEmail', method: 'get' },
  ];

  // Axios request interceptor
  axios.interceptors.request.use(async (config) => {
    //api codegen fix
    if (config?.url?.startsWith('/api/')) {
      config.url = config.url.replace('/api/', '');
    }

    if (!silentUrls.some((urlObj) => urlObj.url === config.url && urlObj.method === config.method)) {
      // Add any additional logic here if needed
    }

    if (!account) throw Error('No active account! Verify a user has been signed in.');

    if (ACCESS_TOKEN === '' || isTokenExpired(parseJwt(ACCESS_TOKEN))) {
      try {
        const response = await instance.acquireTokenSilent({
          ...apiConfig,
          account,
          forceRefresh: true,
        });

        const bearerToken = `Bearer ${response.accessToken}`;
        setLoggedInUser(parseJwt(bearerToken).preferred_username);
        setLoggedInUserFullName(parseJwt(bearerToken).name);

        if (config?.headers) config.headers.Authorization = bearerToken;
        ACCESS_TOKEN = bearerToken;
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          appInsights.trackEvent('Silent token acquisition failed, redirecting to login', undefined, undefined);
          await instance.loginRedirect(loginRequest);
        } else {
          appInsights.trackException(e, undefined, undefined, undefined, SeverityLevel.Error);
        }
      }
    } else {
      if (config?.headers) config.headers.Authorization = ACCESS_TOKEN;
    }

    return config;
  });

  // Axios response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error?.response?.status;
      const resBaseURL = error?.response?.config?.baseURL;
      const config = error.config;

      //log an error response before a retry
      appInsights.trackException(error, undefined, undefined, undefined, SeverityLevel.Error);

      if (!config._retry) {
        config._retry = true;
        config._retryCount = 0;
      }

      // retry transient errors
      if (config._retryCount < MAX_RETRIES && (status >= 500 || !status)) {
        config._retryCount += 1;
        appInsights.trackEvent(`Retrying request... (${config._retryCount}/${MAX_RETRIES})`, undefined, undefined);
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(axios(config));
          }, 1000);
        });
      }

      if (resBaseURL === 'api/' && status === 401) {
        localStorage.removeItem('Authorization');
        navigate('/unauthorized');
      }

      return Promise.reject(error);
    },
  );

  return <LoadingComponent>{children}</LoadingComponent>;
};

export default RequestInterceptor;
