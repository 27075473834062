import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useIsMounted from 'src/hooks/useIsMounted';
import { SquadLeaderService } from 'src/services/SquadLeaderService';
import { SquadService } from 'src/services/SquadService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import EditUserModal from '../user/EditUserModal';
import AddSquadLeaderModal from './AddSquadLeaderModal';
import AddSquadModal from './AddSquadModal';
import EditSquadModal from './EditSquadModal';
import SquadLeaderInitials from './SquadLeaderInitials';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#476B97',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F6F6F6',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const squadService = new SquadService();
const squadLeaderService = new SquadLeaderService();

const ManageSquads = () => {
  const { setSquads } = useContext(SquadsContext);

  const [currentSquads, setCurrentSquads] = useState([]);
  const [open, setOpen] = useState(false);
  const [isTenantManager, setIsTenantManager] = useState(false);
  const [openAddSquadLeaderModal, setOpenAddSquadLeaderModal] = useState(false);
  const [openEditSquadModal, setOpenEditSquadModal] = useState(false);
  const [selectedLeader, setSelectedLeader] = useState<any>(null);
  const [selectedSquad, setSelectedSquad] = useState<any>(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [squadName, setSquadName] = useState('');
  const [shortName, setShortName] = useState('');
  const [squadLeaders, setSquadLeaders] = useState<any[]>([]);
  const handleClose = () => setOpen(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [squadLeaderId, setSquadLeaderId] = useState('');
  const isMounted = useIsMounted();
  const [openEditUser, setOpenEditUser] = useState(false);
  const handleCloseEditUser = () => setOpenEditUser(false);
  const handleOpenEditUser = () => setOpenEditUser(true);
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [permissions, setPermissions] = useState(0);
  const [openDeleteSquadDialog, setOpenDeleteSquadDialog] = useState(false);
  const handleCloseDeleteSquadDialog = () => setOpenDeleteSquadDialog(false);

  const loadSquads: any = useCallback(async () => {
    const response = await squadService.getAll();
    isMounted() && setCurrentSquads(response);
  }, []);

  useEffect(() => {
    loadSquads();
  }, [loadSquads]);

  const handleOpen = () => {
    resetValues();
    setOpen(true);
  };

  const handleArchiveSquad = async () => {
    const squad = { ...selectedSquad };

    squad.isDeleted = true;

    const response = await squadService.editSquad(squad);

    if (response && isMounted()) {
      loadSquads();
      setSelectedSquad(null);
      setOpenDeleteSquadDialog(false);
      updateSquads();
    }
  };

  const updateSquads = async () => {
    const response = await squadService.getAll();
    setSquads(response);
  };

  const handleArchiveSquadModal = async (squad: any) => {
    setSelectedSquad(squad);
    setOpenDeleteSquadDialog(true);
  };

  const filterSquadLeadersList = (squadLeader: any) => {
    setSquadLeaders((): any[] => {
      return (squadLeaders as any[]).filter((x) => x.emailAddress != squadLeader.emailAddress);
    });
  };

  const getPermissions = (squadLeader: any): number => {
    if (squadLeader.user.isTenantManager) return 3;
    if (squadLeader.isSuperUser) return 2;
    if (squadLeader.isAdmin) return 1;
    return 0;
  };

  const loadSquadLeaders: any = useCallback(async (squad: any) => {
    setSquadLeaders([]);
    const response: any[] = await squadLeaderService.getSquadLeadersBySquadId(squad.squadId);
    const leaders: any[] = [];

    response.forEach((squadLeader: any) => {
      const leader = {
        userId: squadLeader.user.userId,
        fullName: squadLeader.user.fullName,
        emailAddress: squadLeader.user.emailAddress,
        permissions: getPermissions(squadLeader),
        squadLeaderId: squadLeader.squadLeaderId,
        isTenantManager: squadLeader.user.isTenantManager
      };

      leaders.push(leader);
    });

    isMounted() && setSquadLeaders(leaders);
  }, []);

  const handleEditSquad = (squad: any) => {
    loadSquadLeaders(squad);
    setSelectedSquad(squad);
    setOpenEditSquadModal(true);
    setSquadName(squad.squadName);
    setShortName(squad.shortName);
    setIsDeleted(squad.isDeleted);
  };

  const handleIsDeletedChange = () => {
    setIsDeleted(!isDeleted);
  };

  const handleRemoveSquadLeader = async (squadLeader: any) => {
    if (selectedSquad) {
      const response = await squadService.removeLeader(squadLeader);

      if (response) {
        filterSquadLeadersList(squadLeader);
        loadSquads();
      }
    } else {
      filterSquadLeadersList(squadLeader);
    }
  };

  const onAddSquadLeaderHandler = async (e: any) => {
    e.preventDefault();

    try {
      if (selectedSquad) {
        const response = editMode
          ? await squadService.editSquadLeader(squadLeaderId, selectedSquad.squadId, 0, isAdmin, isSuperUser)
          : await squadService.addLeader(selectedSquad.squadId, 0, isAdmin, isSuperUser);

        if (!isMounted()) return;

        if (response) {
          loadSquads();
          setIsAdmin(false);
          setIsSuperUser(false);
          setSquadLeaderId('');
          setOpenAddSquadLeaderModal(false);
        }
      }
    } catch (error) { }
  };

  const onCancelAddSquadLeaderModal = async () => {
    setOpenAddSquadLeaderModal(false);
    clearValues();
  };

  const onCancelEditSquadModal = async () => {
    setOpenEditSquadModal(false);
  };

  const onEditSquadHandler = async (e: any) => {
    e.preventDefault();

    try {
      if (selectedSquad) {
        selectedSquad.squadName = squadName;
        selectedSquad.shortName = shortName;
        selectedSquad.isDeleted = isDeleted;
        const response = await squadService.editSquad(selectedSquad);

        if (!isMounted()) return;

        if (response) {
          loadSquads();
          setSquadName('');
          setShortName('');
          setIsDeleted(false);
          setOpenEditSquadModal(false);
        }
      }
    } catch (error) {
      loadSquads();
      setSquadName('');
      setShortName('');
    }
  };

  const onEditSquadLeaderHandler = async (e: any) => {
    e.preventDefault();

    try {
      if (selectedLeader) {
        const response = await squadLeaderService.editSquadLeader(selectedLeader, fullName, emailAddress, isActive, permissions, isTenantManager);

        if (!isMounted()) return;

        if (response) {
          loadSquads();
          loadSquadLeaders(selectedSquad);
          setOpenEditUser(false);
          setSelectedLeader(null);
          setFullName('');
          setEmailAddress('');
          setIsActive(true);
          setIsTenantManager(false);
          setPermissions(0);
        }
      }
    } catch (error) { }
  };

  const onSubmitSquadHandler = async (e: any) => {
    e.preventDefault();

    try {
      const payload = { squadName, shortName, squadLeaders, isTenantManager };
      const response = await squadService.create(payload);

      if (!isMounted()) return;

      if (response) {
        updateSquads();
        loadSquads();
        setOpen(false);
        setSquadName('');
        setShortName('');
        setSquadLeaders([]);
      }
    } catch (error) {
      setSquadName('');
      setShortName('');
    }
  };

  const clearValues = (): void => {
    setIsAdmin(false);
    setIsSuperUser(false);
    setEditMode(false);
    setSelectedLeader(null);
  };

  const resetValues = () => {
    setSquadName('');
    setShortName('');
    setIsDeleted(false);
    setSquadLeaders([]);
    setSelectedSquad(null);
    setIsTenantManager(false);
  };

  const handleEditSquadLeaderPermissions = (squadLeader: any) => {
    setOpenEditUser(true);
    setSelectedLeader(squadLeader);
    setFullName(squadLeader.fullName);
    setEmailAddress(squadLeader.emailAddress);
    setIsActive(squadLeader.isActive ?? true);
    setIsTenantManager(squadLeader.isTenantManager ?? false);
    setPermissions(squadLeader.permissions);
  };

  return (
    <Container>
      <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ mb: 3 }} justifyContent="space-between" alignItems={'center'} spacing={2}>
        <Typography variant="h6">Manage Tenants</Typography>
        <Button onClick={handleOpen} variant="contained" color="secondary" startIcon={<AddBoxOutlinedIcon />}>
          Add Tenant
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Tenant Name</StyledTableCell>
              <StyledTableCell align="center">Tenant Short Name</StyledTableCell>
              <StyledTableCell align="center">Tenant Leaders</StyledTableCell>
              <StyledTableCell align="center">Active</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentSquads.map((squad: any) => (
              <StyledTableRow key={squad.squadId}>
                <StyledTableCell align="center" sx={{ p: 0 }}>
                  {squad.squadName}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ p: 0 }}>
                  {squad.shortName}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ p: 0 }}>
                  <SquadLeaderInitials squadLeaders={squad.squadLeaders} />
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ p: 0 }}>
                  {squad.isDeleted ? <>No</> : <>Yes</>}
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ p: 0 }}>
                  <IconButton
                    onClick={() => {
                      handleEditSquad(squad);
                    }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleArchiveSquadModal(squad);
                    }}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddSquadModal
        open={open}
        squadName={squadName}
        shortName={shortName}
        squadLeaders={squadLeaders}
        setOpen={setOpen}
        resetValues={resetValues}
        handleClose={handleClose}
        setSquadName={setSquadName}
        setShortName={setShortName}
        setSquadLeaders={setSquadLeaders}
        onSubmitSquadHandler={onSubmitSquadHandler}
        handleRemoveSquadLeader={handleRemoveSquadLeader}
      />
      {selectedSquad && (
        <>
          <AddSquadLeaderModal
            editMode={editMode}
            squad={selectedSquad}
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            isSuperUser={isSuperUser}
            setIsSuperUser={setIsSuperUser}
            onAddSquadLeaderHandler={onAddSquadLeaderHandler}
            openAddSquadLeaderModal={openAddSquadLeaderModal}
            setOpenAddSquadLeaderModal={setOpenAddSquadLeaderModal}
            onCancelAddSquadLeaderModal={onCancelAddSquadLeaderModal}
          />
          <EditSquadModal
            squad={selectedSquad}
            isDeleted={isDeleted}
            squadName={squadName}
            shortName={shortName}
            loadSquads={loadSquads}
            squadLeaders={squadLeaders}
            setSquadName={setSquadName}
            setShortName={setShortName}
            setSquadLeaders={setSquadLeaders}
            loadSquadLeaders={loadSquadLeaders}
            onEditSquadHandler={onEditSquadHandler}
            openEditSquadModal={openEditSquadModal}
            handleIsDeletedChange={handleIsDeletedChange}
            setOpenEditSquadModal={setOpenEditSquadModal}
            onCancelEditSquadModal={onCancelEditSquadModal}
            handleRemoveSquadLeader={handleRemoveSquadLeader}
            handleEditSquadLeaderPermissions={handleEditSquadLeaderPermissions}
          />
          {openEditUser && (
            <EditUserModal
              openEditUser={openEditUser}
              setOpenEditUser={setOpenEditUser}
              handleOpenEditUser={handleOpenEditUser}
              handleCloseEditUser={handleCloseEditUser}
              onEditSquadLeaderHandler={onEditSquadLeaderHandler}
              fullName={fullName}
              isActive={isActive}
              isTenantManager={isTenantManager}
              permissions={permissions}
              emailAddress={emailAddress}
              setFullName={setFullName}
              setIsActive={setIsActive}
              setIsTenant={setIsTenantManager}
              setPermissions={setPermissions}
              setEmailAddress={setEmailAddress}
            />
          )}
        </>
      )}
      {selectedSquad && openDeleteSquadDialog && (
        <>
          <Dialog
            open={openDeleteSquadDialog}
            onClose={handleCloseDeleteSquadDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'Archive Squad?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Do you want to archive this squad now?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteSquadDialog}>Cancel</Button>
              <Button onClick={handleArchiveSquad} autoFocus>
                Archive Squad
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default ManageSquads;
