import { Avatar } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { isEqual } from 'lodash';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProfilePicture } from 'src/hooks/useGetProfilePicture';

interface ProfilePictureProps {
  detailsExpanded?: boolean;
  alt: string;
  src?: string;
  employee?: {
    fullName: string;
    jobTitle: {
      title: string;
    };
    emailAddress: string;
    employeeId: number;
    telephoneNumber?: string;
    isDeleted: boolean;
  };
  employeeId:number,
  telephoneNumber?: string;
  emailAddress: string;
  className?: string;
  sx?: SxProps<Theme>;
  variant?: 'square' | 'rounded' | 'circular';
  showEyeBadge?: boolean;
}

const RelatedEmployeesProfile: React.FC<ProfilePictureProps> = ({
  detailsExpanded = false,
  alt,
  src = '',
  employee,
  employeeId,
  emailAddress,
  className,
  sx,
  variant,
  showEyeBadge,
}) => {
  const navigate = useNavigate();
  const { profilePictureImgSrc } = useGetProfilePicture(emailAddress, 120);

  const navigateToEmployeeProfile = () => {
    if (showEyeBadge) {
      navigate(`/employee-profile/${employeeId}`)
    }
  }

  return (
    <>
        <Avatar
          data-testid="profile-picture"
          key={emailAddress}
          variant={variant}
          alt={alt}
          src={profilePictureImgSrc === '' ? src : profilePictureImgSrc}
          className={className}
          sx={{
            ...sx,
            cursor: showEyeBadge ? 'pointer' : '',
          }}
          onClick={navigateToEmployeeProfile}
        />
    </>
  );
};

export default memo(RelatedEmployeesProfile, (prevProps, nextProps) => isEqual(prevProps, nextProps));
