import React from 'react';

const NoSquadGroupsImage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="490" height="450" viewBox="0 0 490 450" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect opacity="0.0550363" x="246" y="206" width="216" height="216" rx="20" fill="#002850" />
      <rect opacity="0.126814" x="219" y="179" width="270" height="270" rx="20" stroke="#002850" strokeWidth="2" strokeDasharray="8 10" />
      <rect opacity="0.04" y="27" width="215" height="216" rx="20" fill="#002850" />
      <rect opacity="0.6" x="104" y="1" width="139" height="140" rx="20" stroke="#DADAFF" />
      <path
        d="M366.553 100H136.128C125.523 100 116.926 108.597 116.926 119.202V349.628C116.926 360.233 125.523 368.83 136.128 368.83H366.553C377.158 368.83 385.756 360.233 385.756 349.628V119.202C385.756 108.597 377.158 100 366.553 100Z"
        fill="#BDC9DA"
      />
      <g filter="url(#filter0_d_5083_22765)">
        <path
          d="M335.414 121H76.5862C71.2919 121 67 125.285 67 130.571V178.429C67 183.715 71.2919 188 76.5862 188H335.414C340.708 188 345 183.715 345 178.429V130.571C345 125.285 340.708 121 335.414 121Z"
          fill="white"
        />
        <path
          d="M196.281 136H146.719C143.56 136 141 138.462 141 141.5C141 144.538 143.56 147 146.719 147H196.281C199.44 147 202 144.538 202 141.5C202 138.462 199.44 136 196.281 136Z"
          fill="#BDC9DA"
        />
        <path
          d="M231.24 161H146.76C143.579 161 141 163.462 141 166.5C141 169.538 143.579 172 146.76 172H231.24C234.421 172 237 169.538 237 166.5C237 163.462 234.421 161 231.24 161Z"
          fill="#E5EAEF"
        />
        <path
          d="M104 172C113.941 172 122 163.941 122 154C122 144.059 113.941 136 104 136C94.0589 136 86 144.059 86 154C86 163.941 94.0589 172 104 172Z"
          fill="#6DAA23"
        />
      </g>
      <g filter="url(#filter1_d_5083_22765)">
        <path
          d="M418.414 204H159.586C154.292 204 150 208.285 150 213.571V261.429C150 266.715 154.292 271 159.586 271H418.414C423.708 271 428 266.715 428 261.429V213.571C428 208.285 423.708 204 418.414 204Z"
          fill="white"
        />
        <path
          d="M279.281 219H229.719C226.56 219 224 221.462 224 224.5C224 227.538 226.56 230 229.719 230H279.281C282.44 230 285 227.538 285 224.5C285 221.462 282.44 219 279.281 219Z"
          fill="#BDC9DA"
        />
        <path
          d="M314.24 244H229.76C226.579 244 224 246.462 224 249.5C224 252.538 226.579 255 229.76 255H314.24C317.421 255 320 252.538 320 249.5C320 246.462 317.421 244 314.24 244Z"
          fill="#E5EAEF"
        />
        <path
          d="M187 255C196.941 255 205 246.941 205 237C205 227.059 196.941 219 187 219C177.059 219 169 227.059 169 237C169 246.941 177.059 255 187 255Z"
          fill="#6DAA23"
        />
      </g>
      <g filter="url(#filter2_d_5083_22765)">
        <path
          d="M335.414 287H76.5862C71.2919 287 67 291.285 67 296.571V344.429C67 349.715 71.2919 354 76.5862 354H335.414C340.708 354 345 349.715 345 344.429V296.571C345 291.285 340.708 287 335.414 287Z"
          fill="white"
        />
        <path
          d="M196.281 302H146.719C143.56 302 141 304.462 141 307.5C141 310.538 143.56 313 146.719 313H196.281C199.44 313 202 310.538 202 307.5C202 304.462 199.44 302 196.281 302Z"
          fill="#BDC9DA"
        />
        <path
          d="M231.24 327H146.76C143.579 327 141 329.462 141 332.5C141 335.538 143.579 338 146.76 338H231.24C234.421 338 237 335.538 237 332.5C237 329.462 234.421 327 231.24 327Z"
          fill="#E5EAEF"
        />
        <path
          d="M104 338C113.941 338 122 329.941 122 320C122 310.059 113.941 302 104 302C94.0589 302 86 310.059 86 320C86 329.941 94.0589 338 104 338Z"
          fill="#6DAA23"
        />
      </g>
      <defs>
        <filter id="filter0_d_5083_22765" x="63" y="121" width="286" height="75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5083_22765" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5083_22765" result="shape" />
        </filter>
        <filter id="filter1_d_5083_22765" x="146" y="204" width="286" height="75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5083_22765" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5083_22765" result="shape" />
        </filter>
        <filter id="filter2_d_5083_22765" x="63" y="287" width="286" height="75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5083_22765" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5083_22765" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default NoSquadGroupsImage;
