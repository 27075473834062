import { EmployeeLearningPhaseDTO, EmployeeLearningChecklistDTO } from "src/Models/LearninpathModels";
import { calculatePercentCompleted } from "src/utilities/NumberUtilities";

export const checkIsLearningPathPhaseOverdue = (selectedLearningPathPhase: EmployeeLearningPhaseDTO): boolean => {
    const endDate = selectedLearningPathPhase?.endDate;

    if ((endDate !== null || endDate !== undefined) && (typeof endDate === 'string' || typeof endDate === 'number')) {
        return new Date(endDate) < new Date();
    } else {
        return false;
    }
}

export const calculateLearningPathPhaseTasksCompleted = (selectedLearningPathPhase: EmployeeLearningPhaseDTO): Number => {
    if (selectedLearningPathPhase.checklists) {
        const tasks: EmployeeLearningChecklistDTO[] = selectedLearningPathPhase.checklists;
        const totalTasks: number = tasks.length;
        const completedTasks: number = tasks.filter((task) => task.isChecked === true).length;

        return calculatePercentCompleted(completedTasks, totalTasks);
    } else {
        return 0;
    }
}