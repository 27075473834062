import React, { createContext, useContext, useEffect, useState } from 'react';
import { CertificationFilterRequestModel, LearningPathFilterRequestModel } from 'src/Models/EmployeeModels';
import { EmployeeLearningPhaseBasicDTO } from 'src/Models/LearninpathModels';
import { TagGraphRequestModel } from 'src/Models/TagModels';
import { DashboardMetrics, EmployeeService } from 'src/services/EmployeeService';
import { IncommingSquadGroupGraphData, SquadGroupService } from 'src/services/SquadGroupService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import useSWR from 'swr';

interface FilterContextProps {
  metrics: DashboardMetrics | null;
  selectedFilter: string | null;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  selectedPhases: EmployeeLearningPhaseBasicDTO[] | null;
  setSelectedPhases: React.Dispatch<React.SetStateAction<EmployeeLearningPhaseBasicDTO[] | null>>;
  learningPathFilterRequest: LearningPathFilterRequestModel;
  setLearningPathFilterRequest: React.Dispatch<React.SetStateAction<LearningPathFilterRequestModel>>;
  certificationFilterRequest: CertificationFilterRequestModel;
  setCertificationFilterRequest: React.Dispatch<React.SetStateAction<CertificationFilterRequestModel>>;
  startDate: Date | null;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  endDate: Date | null;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  tagRequest: TagGraphRequestModel;
  setTagRequest: React.Dispatch<React.SetStateAction<TagGraphRequestModel>>;
  squadGroupGraphData: IncommingSquadGroupGraphData[];
  updateSquadData: () => void;
  setSquadChartLoading: React.Dispatch<React.SetStateAction<boolean>>;
  squadChartLoading: boolean;
  setShowAllEmployees: React.Dispatch<React.SetStateAction<boolean>>;
  showAllEmployees: boolean;
  toggleShowAllEmployees: () => boolean;
}

const FilterContext = createContext<FilterContextProps>({
  metrics: {
    quickFilterCounts: {
      flaggedCount: 0,
      negativeSentimentCount: 0,
      notOnAPathOrCertCount: 0,
      noFeedbackCount: 0,
    },
  },
  selectedFilter: '',
  setSelectedFilter: () => {},
  selectedPhases: [],
  setSelectedPhases: () => {},
  learningPathFilterRequest: {},
  setLearningPathFilterRequest: () => {},
  certificationFilterRequest: {},
  setCertificationFilterRequest: () => {},
  startDate: new Date(),
  setStartDate: () => {},
  endDate: new Date(),
  setEndDate: () => {},
  tagRequest: {},
  setTagRequest: () => {},
  updateSquadData: () => {},
  squadGroupGraphData: [],
  setSquadChartLoading: () => {},
  squadChartLoading: false,
  setShowAllEmployees: () => {},
  showAllEmployees: false,
  toggleShowAllEmployees: () => false,
});

const employeeService = new EmployeeService();

export const FilterProvider: React.FC = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [selectedPhases, setSelectedPhases] = useState<EmployeeLearningPhaseBasicDTO[] | null>(null);
  const [learningPathFilterRequest, setLearningPathFilterRequest] = useState<LearningPathFilterRequestModel>({});
  const [certificationFilterRequest, setCertificationFilterRequest] = useState<CertificationFilterRequestModel>({});
  const [tagRequest, setTagRequest] = useState<TagGraphRequestModel>({});
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [squadGroupGraphData, setSquadGroupGraphData] = useState<IncommingSquadGroupGraphData[]>([]);
  const [squadChartLoading, setSquadChartLoading] = useState(false);
  const [showAllEmployees, setShowAllEmployees] = useState(false);

  const { squad } = useContext(SquadsContext);
  const squadGroupService = new SquadGroupService();

  const getDashboardMetrics = async (squadId: string, startDate: Date | null, endDate: Date | null) => {
    return await employeeService.getDashboardMetrics(squadId, showAllEmployees, startDate, endDate);
  };

  const updateSquadData = async () => {
    setSquadChartLoading(true);
    const response = await squadGroupService.GetctiveSquadGroupGraphData(squad.squadId, showAllEmployees);
    const data = response;
    setSquadGroupGraphData(data);
    setSquadChartLoading(false);
  };

  const toggleShowAllEmployees = () => {
    setShowAllEmployees(!showAllEmployees);
    return showAllEmployees;
  };

  useEffect(() => {
    updateSquadData();
  }, [showAllEmployees]);

  const { data: metrics } = useSWR(['metrics', squad.squadId, startDate, endDate, showAllEmployees], () =>
    getDashboardMetrics(squad.squadId, startDate, endDate),
  );

  return (
    <FilterContext.Provider
      value={{
        toggleShowAllEmployees,
        squadChartLoading,
        setSquadChartLoading,
        squadGroupGraphData,
        updateSquadData,
        selectedFilter,
        setSelectedFilter,
        metrics: metrics ?? null,
        selectedPhases,
        setSelectedPhases,
        learningPathFilterRequest,
        setLearningPathFilterRequest,
        certificationFilterRequest,
        setCertificationFilterRequest,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        tagRequest,
        setTagRequest,
        setShowAllEmployees,
        showAllEmployees,
      }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
