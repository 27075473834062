import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Paper, Stack } from '@mui/material';
import React, { Suspense, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReducedEmployee } from 'src/Models/EmployeeModels';
import { SquadGroup } from 'src/services/SquadGroupService';
import DynamicTitleComponent from 'src/shared/DynamicTitleComponent';
import ErrorBoundary from 'src/shared/ErrorBoundary';
import { SquadsContext } from 'src/utilities/SquadsContext';

import SnackBarComponent from '../shared/SnackBarComponent';
import StyledTab from '../shared/StyledTab';
import StyledTabList from '../shared/StyledTabList';
import { useEntityContext } from './context/EntityContext';
import EmployeeCard from './entityList/EmployeeCard';
import EntityList from './entityList/EntityList';
import GroupCard from './entityList/GroupCard';
import Feedback from './feedback/Feedback';
import FeedbackTagLibrary from './feedback/history/components/FeedbackTagLibrary';

interface DesktopFeedbackPageProps {
  selectedTabIndex: string;
  showEntityList: boolean;
  onTabSelectionChange: (event: React.SyntheticEvent, newValue: string) => void;
  employees: ReducedEmployee[] | null;
  employeeStatus: string;
  selectedEmployee: ReducedEmployee | null;
  groups: SquadGroup[] | null;
  groupStatus: string;
  selectedGroup: any;
  setGroup: React.Dispatch<React.SetStateAction<SquadGroup | null>>;
  setEmployee: (employee: ReducedEmployee | null) => void;
  getEmployees: (refresh?: boolean) => void;
  employeeSearchQueryParam: string;
}

const DesktopFeedbackPage: React.FC<DesktopFeedbackPageProps> = ({
  selectedTabIndex,
  showEntityList,
  onTabSelectionChange,
  employees,
  employeeStatus,
  groups,
  groupStatus,
  selectedGroup,
  setGroup,
  setEmployee,
  getEmployees,
  employeeSearchQueryParam,
}) => {
  const { squad } = useContext(SquadsContext);
  const { getGroups, employee: selectedEmployee } = useEntityContext();

  const navigate = useNavigate();
  const params = useParams();
  const hasEmployeeIdParam = !!params['id'];
  const [openSnackbarState, setOpenSnackbarState] = useState(false);

  return (
    <>
      <DynamicTitleComponent />
      <Stack direction={'row'} sx={{ display: 'grid', gridTemplateColumns: '1.5fr 4fr 1.5fr' }}>
        <Paper sx={{ display: showEntityList ? 'block' : 'none' }}>
          <TabContext value={selectedTabIndex}>
            <StyledTabList onChange={onTabSelectionChange} indicatorColor="primary">
              <StyledTab label="Individuals" value="individuals" iconPosition="start" icon={<PersonIcon />} />
              <StyledTab label="Groups" value="groups" iconPosition="start" icon={<GroupIcon />} />
            </StyledTabList>
            <Box display={'flex'} alignItems={'center'} pr={'24px'}>
              <div id="rotate-action-button" />
            </Box>
            <TabPanel value={'individuals'} data-testid={'tab-0'} sx={{ p: 0 }}>
              <EntityList
                employeeSearchQueryParam={employeeSearchQueryParam}
                entities={employees ?? []}
                filterBy="fullName"
                isLoading={employeeStatus === 'pending'}
                renderEntity={(employee) => (
                  <ErrorBoundary id={`EmployeeCardDesktop-${employee.employeeId}`} showNavBack={false}>
                    <EmployeeCard employee={employee} isSelected={employee.employeeId === selectedEmployee?.employeeId} key={employee.employeeId} />
                  </ErrorBoundary>
                )}
                entityType="Employees"
                selectEntity={(entity: ReducedEmployee) => {
                  if (hasEmployeeIdParam) {
                    navigate(`/capture-feedback/individuals/${entity.employeeId}`);
                  }
                  setGroup(null);
                  setEmployee(entity);
                }}
                loadEntities={() => getEmployees(true)}
              />
            </TabPanel>
            <TabPanel value={'groups'} sx={{ p: 0 }}>
              <EntityList
                entities={groups ?? []}
                filterBy="groupName"
                isLoading={groupStatus === 'pending'}
                renderEntity={(group) => <GroupCard selectSquadGroup={() => setGroup(group)} squadGroup={group} selectedSquadGroup={selectedGroup} />}
                entityType="Groups"
                selectEntity={(entity) => {
                  setEmployee(null);
                  setGroup(entity);
                }}
                loadEntities={() => getGroups()}
              />
            </TabPanel>
          </TabContext>
        </Paper>
        <Suspense fallback={<div></div>}>{squad.squadId && <Feedback employee={selectedEmployee} group={selectedGroup} />}</Suspense>
        <Suspense fallback={<div></div>}>{selectedEmployee && <FeedbackTagLibrary inModal={false} />}</Suspense>
      </Stack>
      <SnackBarComponent
        message="Updated employee learnings"
        severity="success"
        openSnackbarState={openSnackbarState}
        setOpenSnackbarState={setOpenSnackbarState}
      />
    </>
  );
};

export default DesktopFeedbackPage;
