import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MultiCertIcon from '@mui/icons-material/MilitaryTech';
import Tooltip from '@mui/material/Tooltip';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { EmployeeCertificateDTO } from 'src/services/CertificationService';
import { REDUCED_ICON_LENGTH } from 'src/utilities/Constants';

interface CertificateIconProps {
  certifications: EmployeeCertificateDTO[];
}

function getUniqueIcons(icons: string[]): string | null {
  const uniqueIcons = new Set(icons);
  return uniqueIcons.size === 1 ? Array.from(uniqueIcons)[0] : null;
}

export const CertificateIcon = ({ certifications }: CertificateIconProps) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
  };

  const displayedCertifications = expanded ? certifications : certifications.slice(0, 3);

  const icons = certifications.map((certificate) => certificate.icon).filter((icon): icon is string => Boolean(icon));
  const uniqueIcon = getUniqueIcons(icons);

  return (
    <>
      {!isEmpty(icons) &&
        (uniqueIcon ? (
          <Tooltip title={certifications[0].certificateName}>
            <img key={certifications[0].certificateId} src={uniqueIcon} style={{ maxWidth: REDUCED_ICON_LENGTH, maxHeight: REDUCED_ICON_LENGTH }} />
          </Tooltip>
        ) : (
          <Tooltip
            title={
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                {displayedCertifications.map((certificate) => (
                  <div key={certificate.certificateId} style={{ textAlign: 'left' }}>
                    {certificate.certificateName}
                  </div>
                ))}
                {certifications.length > 3 && (
                  <div style={{ textAlign: 'left', cursor: 'pointer' }} onClick={toggleExpanded}>
                    {expanded ? (
                      <>
                        Read less <ExpandLessIcon style={{ verticalAlign: 'middle' }} />
                      </>
                    ) : (
                      <>
                        Read more <ExpandMoreIcon style={{ verticalAlign: 'middle' }} />
                      </>
                    )}
                  </div>
                )}
              </div>
            }>
            <MultiCertIcon sx={{ width: REDUCED_ICON_LENGTH }} />
          </Tooltip>
        ))}
    </>
  );
};
