import React, { createContext, useContext, useEffect, useState } from 'react';
import { AsyncStatus } from 'src/hooks/useAsync';
import { FeedbackGroup, FeedbackService } from 'src/services/FeedbackService';
import useSWR from 'swr';
interface FeedbackContextProps {
  displayedFeedbackItems: FeedbackGroup[];
  setDisplayedFeedbackItems: (feedback: FeedbackGroup[]) => void;
  feedbackItems: FeedbackGroup[];
  getFeedbackItems: (silent?: boolean) => Promise<void>;
  feedbackStatus: AsyncStatus;
  feedbackError: unknown;
  mutateFeedbackItems: (data: FeedbackGroup[]) => void;
  loadMoreItems: () => Promise<void>;
  isLoadingPage: boolean;
  isLoadingData: boolean;
  onCategoryUpdate: (category: number) => void;
  isInitialPageLoad: boolean;
  setIsInitialPageLoad: (isInitialPageLoad: boolean) => void;
  loadSpecificFeedbackItem: (feedbackId: string) => void;
  hasFlaggedFeedback: boolean;
  hasMoreFeedback: boolean;
}
const feedbackService = new FeedbackService();
const FeedbackContext = createContext<FeedbackContextProps | undefined>(undefined);

interface FeatureToggleProviderProps {
  children: React.ReactNode;
  employeeId?: number;
  groupId?: number;
  squadId?: number;
}

const getFeedback = async (employeeId?: number, groupId?: number, squadId?: number, pageNumber?: number, pageSize?: number, categoryId?: number, feedbackId?: string) => {
  if ((!employeeId && !groupId) || !squadId) {
    return [];
  }
  const response = feedbackId && employeeId
    ? (await feedbackService.getGroupByDateItem(employeeId, squadId, feedbackId)).data
    : employeeId
      ? (await feedbackService.getGroupByDatePage(employeeId, squadId, pageNumber, pageSize, categoryId)).data
      : groupId
        ? (await feedbackService.getGroupfeedbackGroupByDatePage(groupId, squadId, pageNumber, pageSize, categoryId)).data
        : [];

  return response;
};

export const FeedbackProvider: React.FC<FeatureToggleProviderProps> = ({ children, employeeId, groupId, squadId }) => {
  const pageSize = 20;
  const [categoryId, setCategoryId] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [hasFlaggedFeedback, setHasFlaggedFeedback] = React.useState(false);
  const [wasSpecificFeedbackItem, setWasSpecificFeedbackItem] = React.useState(false);

  const {
    data: feedbackItems,
    isLoading,
    error: feedbackError,
    mutate: mutateFeedbackItems,
  } = useSWR(
    `/api/feedback?${employeeId}/${categoryId}`,
    async () => getFeedback(employeeId, groupId, squadId, 0, pageSize, categoryId),
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    const checkForFlaggedFeedback = async () => {
      if (!employeeId || !squadId) {
        return;
      }
      const response = (await feedbackService.getGroupByDatePage(employeeId, squadId, 0, pageSize, -1)).data;
      if (response.length > 0) {
        setHasFlaggedFeedback(true);
      }
    }

    checkForFlaggedFeedback();
  }, []);

  const [displayedFeedbackItems, setDisplayedFeedbackItems] = useState<FeedbackGroup[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
  const [hasMoreFeedback, setHasMoreFeedback] = useState(true);

  const onCategoryUpdate = (category: number) => {
    setCategoryId(category)
    if (category !== categoryId || wasSpecificFeedbackItem) {
      setWasSpecificFeedbackItem(false);
      loadMoreItems(0, category);
    }
  }

  const loadSpecificFeedbackItem = async (feedbackId: string) => {
    const newFeedback = await getFeedback(employeeId, groupId, squadId, undefined, undefined, undefined, feedbackId) as FeedbackGroup[];
    mutateFeedbackItems(newFeedback, false);
    setIsLoadingData(false);
    setWasSpecificFeedbackItem(true);
  }

  function mergeFeedbackByDate(feedbackList: FeedbackGroup[]) {
    const mergedFeedback: any = {};

    feedbackList.forEach(dateFeedback => {
      const { createdDate, feedback } = dateFeedback;

      if (mergedFeedback[createdDate]) {
        mergedFeedback[createdDate].push(...feedback);
      } else {
        mergedFeedback[createdDate] = [...feedback];
      }
    });

    const mergedFeedbackList = Object.keys(mergedFeedback).map(createdDate => ({
      createdDate,
      feedback: mergedFeedback[createdDate]
    }));

    return mergedFeedbackList;
  }

  const loadMoreItems = async (page?: number, category?: number) => {
    let pageToUse = -1;
    let categoryToUse = 0;
    if (page !== 0) {
      pageToUse = pageNumber;
      setIsLoadingPage(true)
    } else {
      setIsLoadingData(true);
    }
    if (category !== 0 && category !== undefined) {
      categoryToUse = category;
    }

    const newFeedback = await getFeedback(employeeId, groupId, squadId, pageToUse + 1, pageSize, categoryToUse) as FeedbackGroup[];
    const flatFeedbackList = newFeedback.flatMap(group => group.feedback);
    if (flatFeedbackList.length < pageSize) {
      setHasMoreFeedback(false)
    }

    if (newFeedback.length === 0) {
      setIsLoadingData(false);
      setIsLoadingPage(false)
    } else {
      let updatedFeedbackItems = [];
      if (pageToUse + 1 === 0) {
        updatedFeedbackItems = newFeedback;
        setIsLoadingData(false);
        setIsLoadingPage(false)
      } else {
        updatedFeedbackItems = mergeFeedbackByDate([...feedbackItems, ...newFeedback]);
      }

      mutateFeedbackItems(updatedFeedbackItems, false);
      setPageNumber(pageToUse + 1);
      setIsLoadingData(false);
      setIsLoadingPage(false)
    }
  };

  return (
    <FeedbackContext.Provider
      value={{
        displayedFeedbackItems,
        setDisplayedFeedbackItems,
        feedbackItems,
        getFeedbackItems: () => mutateFeedbackItems(),
        feedbackStatus: isLoading ? 'pending' : 'success',
        feedbackError,
        mutateFeedbackItems,
        loadMoreItems,
        isLoadingPage,
        isLoadingData,
        onCategoryUpdate,
        isInitialPageLoad,
        setIsInitialPageLoad,
        loadSpecificFeedbackItem,
        hasFlaggedFeedback,
        hasMoreFeedback,
      }}>
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedbackContext = () => {
  const context = useContext(FeedbackContext);

  if (!context) {
    throw new Error('useFeedbackContext must be used within a FeedbackContextProvider');
  }

  return context;
};
