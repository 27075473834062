import TabList from "@mui/lab/TabList";
import { styled } from "@mui/material";
import { DEFAULT_TAB_HEIGHT } from "src/utilities/Constants";

const StyledTabList = styled(TabList)({
    height: DEFAULT_TAB_HEIGHT,
    minHeight: DEFAULT_TAB_HEIGHT,
    paddingTop: '10px',
    width: '100%'
});

export default StyledTabList;