import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { memo } from 'react';
import WordCloud from 'react-d3-cloud';
import { blue1000, entelectBlue, green300, Purple400 } from 'src/utilities/customColors';
import { makeStyles } from 'tss-react/mui';

export interface FeedbackWordCloudProps {
  wordCloud: {
    description: string;
  }[];
  selectedWord: string;
  setSelectedWord: (word: string) => void;
}

const FeedbackWordCloud = (props: FeedbackWordCloudProps) => {
  const useStyles = makeStyles()((theme) => ({
    cloud: {
      alignItems: 'center',
      justifyItems: 'center',
      maxHeight: '250px',
      width: '100%',
      height: '100%',
    },
  }));

  var wordSequence = 0;

  const fontSizeMapper = (word: any, max_value: number) => {
    if (word.text === props.selectedWord) {
      return Math.log2(max_value) * 8;
    } else {
      return Math.log2(word.value) * 7;
    }
  };

  const fontWeightMapper = (word: string) => {
    if (word === props.selectedWord) {
      return 'bolder';
    } else {
      return 'normal';
    }
  };

  const colourMapping = (word: string) => {
    if (word === props.selectedWord) {
      wordSequence++;
      return Purple400;
    } else if (wordSequence % 2 === 0) {
      wordSequence++;
      return green300;
    } else {
      wordSequence++;
      return blue1000;
    }
  };

  var max_value = 25;
  const finalData: { text: any; value: number }[] = [];
  props.wordCloud?.forEach((word: any) => {
    let reviewWords = word.description.split(' ');
    reviewWords?.forEach((singleWord: any) => {
      let wordExists = finalData.find((entry) => entry.text === singleWord);
      if (typeof wordExists == 'undefined') {
        if (singleWord.length > 0) {
          finalData.push({ text: singleWord, value: 25 });
        }
      } else {
        wordExists.value += 50;
        if (wordExists.value > max_value) {
          max_value = wordExists.value;
        }
      }
    });
  });

  const { classes } = useStyles();

  return (
    <>
      {props.wordCloud.length !== 0 && (
        <Accordion
          disableGutters
          elevation={2}
          sx={{
            border: `1px solid ${entelectBlue}`,
            '&:before': {
              display: 'none',
            },
            mb: '10px',
            backgroundColor: '#FFF',
            borderRadius: '8px',
          }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ height: '56px' }} aria-controls="panel1a-content" id="panel1a-header">
            <div style={{ fontWeight: 700, fontSize: '18px', color: entelectBlue }}>Word Filter</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.cloud}>
              <WordCloud
                data={finalData}
                rotate={0}
                fontSize={(word, index) => {
                  return fontSizeMapper(word, max_value);
                }}
                padding={2}
                fontWeight={(word, index) => {
                  return fontWeightMapper(word.text);
                }}
                width={840}
                height={300}
                fill={(word: any) => {
                  return colourMapping(word.text);
                }}
                random={() => 0.5}
                onWordClick={(event, d) => {
                  if (d.text === props.selectedWord) {
                    props.setSelectedWord('');
                  } else {
                    props.setSelectedWord(d.text);
                  }
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default memo(FeedbackWordCloud);
