import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import ChartHeading from 'src/components/dashboardShared/ChartHeading';

import { useClientManagementFilterContext } from '../context/ClientManagementFilterContext';
import StatusOverviewChart from './StatusOverviewChart';

const StatusBarChart: React.FC = () => {
  const { statusGraphData, updateStatusData, statusGraphLoading } = useClientManagementFilterContext();

  useEffect(() => {
    updateStatusData();
  }, []);

  return (
    <>
      <ChartHeading>{'Status overview'}</ChartHeading>
      {!statusGraphLoading ? (
        <Box display="flex" flexDirection="column" width="100%">
          <StatusOverviewChart statusData={statusGraphData} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default StatusBarChart;
