import { ArrowUpward } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import React, { forwardRef, Ref } from 'react';

interface BackToTopButtonProps {
  handleClick: () => void;
}

const BackToTopButton = forwardRef((props: BackToTopButtonProps, ref: Ref<HTMLDivElement>) => {
  const { handleClick } = props;

  return (
    <Box
      ref={ref}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      <Tooltip title={'Scroll to the top'}>
        <Button variant="contained" onClick={handleClick} endIcon={<ArrowUpward fontSize="small" />}>
          BACK TO TOP
        </Button>
      </Tooltip>
    </Box>
  );
});

export default BackToTopButton;
