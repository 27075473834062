import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ReducedEmployee } from 'src/Models/EmployeeModels';
import { Tag } from 'src/Models/TagModels';
import useIsMounted from 'src/hooks/useIsMounted';
import { CertificationService, EmployeeCertificateDTO } from 'src/services/CertificationService';
import { EmployeeSkill, SkillService } from 'src/services/SkillService';
import { TagService } from 'src/services/TagService';
import { SquadsContext } from 'src/utilities/SquadsContext';

import CertificationCompanyFeedbackModal from '../manageCertificates/components/CertificationCompanyFeedbackModal';
import { CERTIFICATION_TAG_COLOUR } from '../mentions/MentionsShared';
import TagBubble from '../shared/TagBubble';
import EmployeeTagModal from './EmployeeTagModal';
import { useEntityContext } from './context/EntityContext';

export interface FeedbackTagsProps {
  employee: ReducedEmployee | null;
  refreshData: boolean;
  showImported: boolean;
  clickable: boolean;
  kaminoTags?: EmployeeSkill[];
}

export const FeedbackTags = (props: FeedbackTagsProps) => {
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>();
  const [selectedTag, setSelectedTag] = useState<any | null>(null);
  const [flatTags, setFlatTags] = useState([]);
  const [employeeTags, setEmployeeTags] = useState<Tag[]>([]);
  const [certificates, setCertificates] = useState<EmployeeCertificateDTO[]>([]);
  const [skills, setEmployeeSkills] = useState<EmployeeSkill[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [skillTags, setSkillTags] = useState<string[]>();
  const isMounted = useIsMounted();
  const { tags: tagsData, isTagsLoading } = useEntityContext();

  const { squad } = useContext(SquadsContext);
  const tagService = new TagService();
  const skillService = new SkillService();

  const fetchTagsByEmployee = useCallback(async () => {
    if (props.employee?.employeeId) {
      const tagsResponse = await tagService.getTagsByEmployee(props.employee.employeeId);
      isMounted() && setEmployeeTags(tagsResponse);
    }
  }, []);

  const fetchCertificatesByEmployee = useCallback(async () => {
    if (props.employee?.employeeId) {
      const certificationService = new CertificationService();
      const certificatesResponse = await certificationService.getCertificatesByEmployeeId(props.employee.employeeId);
      isMounted() && setCertificates(certificatesResponse);
    }
  }, []);

  const fetchSkillsByEmployee = useCallback(async () => {
    if (props.employee?.employeeId) {
      const skillsReponse = await skillService.getSkillsByEmployee(props.employee.employeeId);
      isMounted() && setEmployeeSkills(skillsReponse);
    }
  }, []);

  let skillNames: any = [];

  useEffect(() => {
    fetchTagsByEmployee();
    fetchCertificatesByEmployee();
    if (props.showImported) {
      fetchSkillsByEmployee();
    }
  }, [props.refreshData, props.showImported, props?.employee?.employeeId]);

  useEffect(() => {
    if (employeeTags && tagsData && !isTagsLoading) {
      const employeeTagIds = employeeTags?.map((tag: Tag) => tag.tagId);
      const employeeTagCategoryIds = employeeTags?.map((tag: Tag) => tag.tagCategoryId);
      const tagsDataCopy = cloneDeep(tagsData || []);
      const employeeTagData = tagsDataCopy?.filter((tagCategory) => employeeTagCategoryIds?.includes(tagCategory.tagCategoryId || -1));
      skillNames = skills?.map((skill: any) => skill.skillName) || [];

      employeeTagData?.forEach((tagCategory) => {
        tagCategory.tags = tagCategory?.tags?.filter((tag) => employeeTagIds?.includes(tag.tagId || -1));
      });

      let flattenedTags: any = [];
      const seenTagIds = new Set();
      if (employeeTagData) {
        employeeTagData.forEach((tagCategory) => {
          tagCategory?.tags?.forEach((tag) => {
            if (!seenTagIds.has(tag.tagId)) {
              flattenedTags.push({
                tagId: tag.tagId,
                tagName: tag.tagName,
                tagCategoryName: tagCategory.tagCategoryName,
                tagColor: tagCategory.categoryColor,
                tagType: 'tag',
              });

              seenTagIds.add(tag.tagId);
            }
          });
        });
      }
      setFlatTags(flattenedTags);
    }
  }, [employeeTags, tagsData, skills]);

  useEffect(() => {
    if (props.kaminoTags) {
      const stringTags = props.kaminoTags.map((x) => x.skillName);
      setSkillTags(_.uniq(stringTags));
    }
  }, [props.kaminoTags]);

  return (flatTags && flatTags.length > 0) || (certificates && certificates?.length > 0) ? (
    <Box px={1.5}>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          border: 'none',
        }}>
        <AccordionSummary>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Typography variant="subtitle2" sx={{ textDecoration: 'underline', flexGrow: 1, textAlign: 'right' }}>
              {expanded ? 'Hide' : 'Show'} associated tags
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          <Stack direction="row" gap="4px" sx={{ flexWrap: 'wrap', overflowX: 'auto', marginBottom: '20px' }}>
            {flatTags.map((tag: any) => (
              <Tooltip key={tag.tagId} title={tag.tagCategoryName} placement="bottom">
                <TagBubble tag={tag as Tag} clickable={props.clickable} onClick={props.clickable ? () => setSelectedTag(tag) : undefined} />
              </Tooltip>
            ))}

            {skillTags && (
              <Stack direction="row" gap="4px" sx={{ flexWrap: 'wrap', overflowX: 'auto', marginBottom: '20px' }}>
                {skillTags.map((kaminoTag: any) => (
                  <Tooltip title={kaminoTag} placement="bottom">
                    <Chip
                      size="small"
                      label={kaminoTag}
                      clickable={false}
                      sx={{
                        color: 'white',
                        backgroundColor: 'grey',
                      }}
                    />
                  </Tooltip>
                ))}
              </Stack>
            )}
            {certificates && certificates.length > 0 && (
              <>
                {Array.from(new Set(certificates.map((certificate) => certificate.certificateCompany?.companyName))).map((companyName) => (
                  <Tooltip
                    key={companyName}
                    title={
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        {certificates
                          .filter((certificate) => certificate.certificateCompany?.companyName === companyName)
                          .map((certificate) => (
                            <div key={certificate.certificateId} style={{ textAlign: 'left' }}>
                              <div>{certificate.certificateName}</div>
                            </div>
                          ))}
                      </div>
                    }>
                    <Chip
                      size="small"
                      label={companyName}
                      sx={{
                        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
                        backgroundColor: CERTIFICATION_TAG_COLOUR,
                        color: 'white',
                        marginX: '1px',
                        marginBottom: '4px',
                      }}
                      clickable={props.clickable}
                      onClick={props.clickable ? () => setSelectedCompanyName(companyName) : undefined}
                    />
                  </Tooltip>
                ))}
                {selectedCompanyName && (
                  <CertificationCompanyFeedbackModal
                    employeeId={props.employee!.employeeId}
                    companyName={selectedCompanyName}
                    onClose={() => setSelectedCompanyName(undefined)}
                  />
                )}
              </>
            )}
            {skillNames.map((skill: string, index: number) => (
              <Chip
                key={`skill-${index}`}
                label={skill}
                size="small"
                sx={{
                  marginBottom: '4px',
                  backgroundColor: '#A9A9A9',
                  color: 'white',
                  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
                  marginX: '1px',
                }}
              />
            ))}
          </Stack>

          {selectedTag && (
            <EmployeeTagModal
              squadId={squad.squadId}
              tagId={selectedTag.tagId}
              employeeId={props.employee!.employeeId}
              open={true}
              onClose={() => setSelectedTag(null)}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  ) : null;
};
