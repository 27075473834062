import { Button, Grid, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { SquadService } from 'src/services/SquadService';
import AddUserModal from '../user/AddUserModal';
import SquadLeaderControl from './SquadLeaderControl';
import SquadLeadersTable from './SquadLeadersTable';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #476B97',
  boxShadow: 24,
  p: 2,
};

const squadService = new SquadService();

const MAX_CHARACTER_LIMIT: number = 4;

const EditSquadModal = (props: any) => {
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [permissions, setPermissions] = useState(0);
  const [openAddUser, setOpenAddUser] = useState(false);
  const handleCloseAddUser = () => setOpenAddUser(false);
  const handleOpenAddUser = () => setOpenAddUser(true);
  const [isTenantManager, setIsTenantManager] = useState(false);

  const addUserHandler = async (e: any) => {
    e.preventDefault();

    const response = await squadService.addUser(props.squad.squadId, fullName, emailAddress, isActive, permissions, isTenantManager);
    if (response) resetValues();
  };

  const resetValues = () => {
    setOpenAddUser(false);
    setFullName('');
    setEmailAddress('');
    setIsActive(true);
    setPermissions(0);
  };

  return (
    <Modal open={props.openEditSquadModal} onClose={props.onCancelEditSquadModal}>
      <Box sx={style} component="form" onSubmit={props.onEditSquadHandler}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Update Squad: {props.squad.squadName}
        </Typography>
        <Stack spacing={2}>
          <Grid container columnSpacing={2}>
            <Grid item xs={8}>
              <TextField
                style={{ width: `100%` }}
                required
                variant="outlined"
                label="Squad Name"
                id="squadName"
                value={props.squadName}
                onChange={(e) => props.setSquadName(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                variant="outlined"
                label="Squad Short Name"
                id="shortName"
                value={props.shortName}
                inputProps={{ maxLength: MAX_CHARACTER_LIMIT }}
                helperText={`${MAX_CHARACTER_LIMIT - props.shortName.length}`}
                onChange={(e) => props.setShortName(e.target.value)}
              />
            </Grid>
          </Grid>
          <SquadLeaderControl
            setOpenAddUser={setOpenAddUser}
            fullName={fullName}
            isActive={isActive}
            permissions={permissions}
            emailAddress={emailAddress}
            setFullName={setFullName}
            setIsActive={setIsActive}
            setPermissions={setPermissions}
            setEmailAddress={setEmailAddress}
            squadLeaders={props.squadLeaders}
            setSquadLeaders={props.setSquadLeaders}
            selectedSquad={props.squad}
            loadSquadLeaders={props.loadSquadLeaders}
            loadSquads={props.loadSquads}
          />
          <AddUserModal
            openAddUser={openAddUser}
            setOpenAddUser={setOpenAddUser}
            handleOpenAddUser={handleOpenAddUser}
            handleCloseAddUser={handleCloseAddUser}
            addUserHandler={addUserHandler}
            fullName={fullName}
            isActive={isActive}
            isTenantManager={isTenantManager}
            permissions={permissions}
            emailAddress={emailAddress}
            setFullName={setFullName}
            setIsActive={setIsActive}
            setIsTenantManager={setIsTenantManager}
            setPermissions={setPermissions}
            setEmailAddress={setEmailAddress}
          />
        </Stack>

        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={!props.isDeleted} onChange={props.handleIsDeletedChange} name="isDeleted" />}
              label="Is Active"
            />
          </FormGroup>
        </FormControl>
        {props.squadLeaders.length ? (
          <SquadLeadersTable
            squadLeaders={props.squadLeaders}
            handleRemoveSquadLeader={props.handleRemoveSquadLeader}
            handleEditSquadLeaderPermissions={props.handleEditSquadLeaderPermissions}
          />
        ) : null}

        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={props.onCancelEditSquadModal}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            Update Squad
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditSquadModal;
