import { BaseService } from "./BaseService";

export interface EmployeeSkill{
    employeeSkillsId : number,
    employeeId : number,
    skillName : string,
}

export class SkillService extends BaseService{
    constructor(){
        super('Skills');
    }

    async getAllEmployeeSkills(): Promise<EmployeeSkill[]>{
        return (await this.httpService.get(``)).data;
    }

    async getSkillsByEmployee(employeeId:number): Promise<EmployeeSkill[]>{
        return (await this.httpService.get(`/skills/${employeeId}`)).data;
    }
}