import { useEffect } from 'react';

import { appInsights } from '../appInsights';

const useGlobalErrorHandler = () => {
  window.onerror = function (message, source, lineno, colno, error) {
    if (appInsights && error) {
      appInsights.trackException(error);
    }

    // Return true to prevent the default browser error handling
    return true;
  };

  useEffect(() => {
    if (appInsights) {
      appInsights.trackEvent('Dread-0 UI loaded', { name: 'Dread-0 UI loaded' });
    }
  }, []);
};

export default useGlobalErrorHandler;
