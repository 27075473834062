import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import UnauthorizedView from 'src/views/UnauthorizedView';
import useIsMounted from '../../hooks/useIsMounted';
import { SquadLeaderContext } from '../../utilities/SquadLeaderContext';
import { SquadsContext } from '../../utilities/SquadsContext';

const RequireAuthorization = (props: any) => {
  const isMounted = useIsMounted();
  const [authorized, setAuthorized] = useState(false);
  const users: any = React.useContext(SquadLeaderContext).squadLeader;
  const { squad } = React.useContext(SquadsContext);

  const setAuthorizedIfMounted = useCallback((authorized: boolean) => {
    isMounted() && setAuthorized(authorized);
  }, [isMounted(), setAuthorized]);

  useEffect(() => {
    const selectedSquad = users.filter((x: any) => x.squadId === squad.squadId)[0];
    if (props.userAuthorization && selectedSquad) {
      switch (props.userAuthorization) {
        case 'user':
          selectedSquad.isSuperUser ? setAuthorizedIfMounted(true) : setAuthorizedIfMounted(false);
          break;
        case 'team':
          selectedSquad.isAdmin || selectedSquad.isSuperUser ? setAuthorizedIfMounted(true) : setAuthorizedIfMounted(false);
          break;
        case 'categories':
          selectedSquad.isSuperUser || selectedSquad.isAdmin ? setAuthorizedIfMounted(true) : setAuthorizedIfMounted(false);
          break;
        case 'overview':
          selectedSquad.isSuperUser || selectedSquad.isAdmin ? setAuthorizedIfMounted(true) : setAuthorizedIfMounted(false);
          break;
        case 'title':
          selectedSquad.isSuperUser ? setAuthorizedIfMounted(true) : setAuthorizedIfMounted(false);
          break;
        case 'squads':
          selectedSquad.user.isTenantManager ? setAuthorizedIfMounted(true) : setAuthorizedIfMounted(false);
          break;
        case 'employee-bulk-upload':
          selectedSquad.isSuperUser ? setAuthorizedIfMounted(true) : setAuthorizedIfMounted(false);
          break;
        default:
          setAuthorizedIfMounted(false);
      }
    }
  }, [props.userAuthorization, squad.squadId]);

  return authorized ? <>{props.children}</> : <UnauthorizedView />
}

export default RequireAuthorization;
