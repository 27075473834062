import { BaseService } from "./BaseService"

export class CategoryService extends BaseService {
  constructor() {
    super('Category')
  }

  async getAllCategoriesForSquad(id) {
    return (await this.httpService.get(`/${id}`)).data;
  }

  async createCategory(model) {
    return (await this.httpService.post('', model)).data
  }

  async updateCategory(model) {
    return (await this.httpService.patch('', model)).data
  }

  async getAllCategoriesForSquad(squadId) {
    return (await this.httpService.get(`/${squadId}`)).data
  }

}
