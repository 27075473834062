import { Tooltip } from '@mui/material';
import React from 'react';

interface HotkeysProps {
  text: string;
  imageSrc: string;
  children: React.ReactElement;
  tooltipPlacement?: 'left' | 'right' | 'top' | 'bottom';
}

const Hotkeys: React.FC<HotkeysProps> = ({ text, imageSrc, children, tooltipPlacement }) => {
  return (
    <Tooltip
      enterDelay={2000}
      title={
        <div>
          {text}
          <div>
            <img height={'48px'} src={imageSrc} alt={text} loading="lazy" />
          </div>
        </div>
      }
      arrow
      placement={tooltipPlacement}>
      {children}
    </Tooltip>
  );
};

export default Hotkeys;
