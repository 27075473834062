import { Badge, BadgeProps, badgeClasses } from "@mui/material";
import React from "react";

const CustomBadge: React.FC<BadgeProps> = ({
    children,
    invisible,
    badgeContent
}) => {
    return (
        <>
            {!invisible &&
                <Badge
                    badgeContent={badgeContent}
                    color="error"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        [`& .${badgeClasses.badge}`]: {
                            position: 'relative',
                            right: '0.75rem'
                        }
                    }}
                >
                    {children}
                </Badge>
            }
            {invisible && children}
        </>
    );
}

export default CustomBadge;