import React, { useEffect, useState, useCallback } from 'react';
import { Autocomplete, Divider, TextField } from '@mui/material';
import useIsMounted from 'src/hooks/useIsMounted';
import { UserService } from 'src/services/UserService';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { SquadService } from 'src/services/SquadService';

const userService = new UserService();
const squadService = new SquadService();

const SquadLeaderControl = (props: any) => {
  const isMounted = useIsMounted();
  const [users, setUsers] = useState([]);

  const loadUsers = useCallback(async () => {
    const response = await userService.getAll();

    if (!isMounted()) return;

    const addUser = { userId: 0, fullName: 'Add New User'};

    response.unshift(addUser)

    setUsers(response);
  }, []);

  const onFilteredListChange = async (value: any) => {
    if (!value) return;

    props.setOpenAddUser(value.userId === 0)

    if ((value.userId !== 0 && !props.selectedSquad) || (value.userId === 0 && props.selectedSquad)) {
      const squadLeader: any =
      {
        userId: value.userId,
        fullName: value.fullName,
        emailAddress: value.emailAddress,
        isActive: value.isActive,
        permissions: 0
      }
      const leaders: any[] = [...props.squadLeaders];
      const existing = leaders.find(x => x.emailAddress == value.emailAddress);

      if (existing || !squadLeader.emailAddress) return;
      
      leaders.push(squadLeader as never);

      props.setSquadLeaders(leaders)
    } else {
      const response = await squadService.addLeader(props.selectedSquad.squadId, value.userId, false, false)

      if (response) {
        props.loadSquadLeaders(props.selectedSquad)
        props.loadSquads()
      }
    }
  };

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={users}
        sx={{ width: 600 }}
        onChange={(e: any, value: any) => onFilteredListChange(value)}
        getOptionLabel={(option: any) => option.fullName}
        renderInput={(params) => <TextField {...params} label="Squad Leader" />}
        renderOption={(props, option: any) => (
          <>
            <span {...props}>
              {option.userId == 0 ? <PersonAddAlt1Icon sx={{mr: 2}} /> : null}
              {option.fullName}
            </span>
            {option.userId == 0 ? <Divider /> : null}
          </>
        )}
      />
    </>
  );
}

export default SquadLeaderControl;
