import FlagIcon from '@mui/icons-material/Flag';
import { useTabContext } from '@mui/lab/TabContext';
import { Checkbox, IconButton, TableRow, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { entelectBlue } from 'src/utilities/customColors';

import EmployeeSquadModal from './EmployeeSquadModal';
import { NarrowTableCell } from './NarrowTableCell';
import Sentiment from './Sentiment';

const BlackTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

interface Props {
  employee: DashboardEmployee;
  handleCheckBoxChecked?: (value: DashboardEmployee) => void;
  selectedEmployees?: DashboardEmployee[];
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const EmployeeOverviewTableRow: React.FC<Props> = ({ employee, handleCheckBoxChecked, selectedEmployees }: Props) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const tabContext = useTabContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <StyledTableRow key={employee.employeeId} sx={{ cursor: 'pointer', height: '50px' }}>
      <NarrowTableCell sx={{ width: '10px' }}>
        <Checkbox
          {...label}
          checked={selectedEmployees?.includes(employee)}
          onChange={() => handleCheckBoxChecked && handleCheckBoxChecked(employee)}
        />
      </NarrowTableCell>
      <NarrowTableCell sx={{ width: '10px' }}>
        {employee.sentiment && tabContext?.value !== 'client-management' ? (
          <Sentiment sentiment={employee.sentiment} />
        ) : (
          <>{employee.employeeBenchStatus}</>
        )}
      </NarrowTableCell>
      <NarrowTableCell sx={{ width: '50px' }}>
        {employee.followUp ? (
          <IconButton>
            <FlagIcon color="error" />
          </IconButton>
        ) : (
          ''
        )}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '250px' }}>
        <a
          href={`/capture-feedback/indivduals/${employee.employeeId ?? undefined}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}>
          <Link to={`/capture-feedback/${employee.employeeId}`}>{employee.fullName}</Link>
        </a>
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '250px' }}>
        {employee.jobTitle.title}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '150px' }}>
        {employee.groupNames && employee.groupNames?.length > 0 && (
          <>
            {employee.groupNames && employee.groupNames.length === 1 ? (
              <BlackTooltip
                title={
                  <div>
                    <i>Select to view group leader(s)</i>
                  </div>
                }>
                <Typography
                  fontWeight={400}
                  sx={{ color: entelectBlue, ':hover': { cursor: 'pointer', textDecoration: 'underline' }, fontSize: '12px' }}
                  onClick={openModal}>
                  {employee.groupNames.map((group) => (
                    <div key={group}>{group}</div>
                  ))}
                </Typography>
              </BlackTooltip>
            ) : (
              <BlackTooltip
                title={
                  <div>
                    Squads:
                    {employee && employee.groupNames.map((group) => <div key={group}>{group}</div>)}
                    <i>Select to view group leaders</i>
                  </div>
                }>
                <Typography
                  fontWeight={400}
                  sx={{ color: entelectBlue, ':hover': { cursor: 'pointer', textDecoration: 'underline' }, fontSize: '12px' }}
                  onClick={openModal}>
                  {employee.groupNames && employee.groupNames?.length} squads
                </Typography>
              </BlackTooltip>
            )}
          </>
        )}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '150px' }}>
        {employee.daysOnSquad.timeOnTFOString && employee.daysOnSquad.timeOnTFOString.length > 0 ? employee.daysOnSquad.timeOnTFOString : ''}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '150px' }}>
        {employee.latestFeedbackCategory}
      </NarrowTableCell>
      <NarrowTableCell align="left" sx={{ width: '150px' }}>
        {employee.status.statusName}
      </NarrowTableCell>
      <EmployeeSquadModal isOpen={isModalOpen} onClose={closeModal} employeeName={employee.fullName} employeeId={employee.employeeId} />
    </StyledTableRow>
  );
};

export default EmployeeOverviewTableRow;
