import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { BarElement, CategoryScale, Chart, ChartData, ChartOptions, Legend, LinearScale, Tooltip } from 'chart.js';
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { DashboardLearningPathDTO, DashboardLearningPhaseBasicDTO, EmployeeLearningPhaseBasicDTO } from 'src/Models/LearninpathModels';
import { LEARNING_PATH_COLORS } from 'src/utilities/learningPathColors';

import { useFilterContext } from '../context/FilterContext';

interface LearningPathOverviewBarChartProps {
  dashboardLearningPathData: DashboardLearningPathDTO[] | undefined;
}

const LearningPathChartOverview: React.FC<LearningPathOverviewBarChartProps> = (props: LearningPathOverviewBarChartProps) => {
  const [selectedLearningPathName, setSelectedLearningPathName] = useState<{} | null>();

  const { dashboardLearningPathData } = props;

  const { setSelectedPhases, selectedPhases, setLearningPathFilterRequest } = useFilterContext();

  const [chartData, setChartData] = useState<ChartData<'bar'>>({ labels: [], datasets: [] });

  const getChartData = (data: DashboardLearningPathDTO[] | undefined, phases?: EmployeeLearningPhaseBasicDTO[] | null): ChartData<'bar'> => {
    let labels: string[] = [];
    const statusCounts: { [key: string]: { [key: string]: number } } = {
      InProgress: {},
      OnPause: {},
      Abandoned: {},
      Completed: {},
    };

    if (data) {
      if (phases) {
        labels = [...new Set(phases.map((phase) => phase.name || 'unknown'))];
        phases.forEach((phase) => {
          const status = phase.status || 'unknown';
          const name = phase.name || 'unknown';

          if (!statusCounts[status]) {
            statusCounts[status] = {};
          }
          if (!statusCounts[status][name]) {
            statusCounts[status][name] = 0;
          }
          statusCounts[status][name]++;
        });
      } else {
        labels = [...new Set(data.map((item) => item.name))];
        data.forEach((item) => {
          const status = item.status || 'unknown';
          const name = item.name || 'unknown';

          if (!statusCounts[status]) {
            statusCounts[status] = {};
          }
          if (!statusCounts[status][name]) {
            statusCounts[status][name] = 0;
          }
          statusCounts[status][name]++;
        });
      }
    }

    return {
      labels,
      datasets: Object.keys(statusCounts).map((status) => ({
        label: status.charAt(0).toUpperCase() + status.slice(1),
        data: labels.map((label) => statusCounts[status][label] || 0),
        backgroundColor: LEARNING_PATH_COLORS[status as keyof typeof LEARNING_PATH_COLORS] || grey[500],
        borderWidth: 1,
      })),
    };
  };

  const handleClick = (event: any, elements: any) => {
    if (elements.length > 0) {
      const element = elements[0];
      const datasetIndex = element.datasetIndex;
      const index = element.index;
      const datasetLabel = chartData.datasets[datasetIndex].label;
      const label = chartData.labels ? chartData.labels[index] : null;

      if (selectedPhases) {
        const selectedPhase = selectedPhases.find((phase) => phase.name === label);
        setLearningPathFilterRequest({
          selectedPhaseStatus: datasetLabel || null,
          selectedPhaseName: selectedPhase?.name || null,
          selectedPathName: selectedLearningPathName,
        });
      } else {
        setLearningPathFilterRequest({ selectedPathStatus: datasetLabel || null, selectedPathName: label || null });
        setSelectedLearningPathName(label || null);
        if (dashboardLearningPathData) {
          const learningPhases = dashboardLearningPathData
            .filter((item) => item.name === label)
            .reduce<DashboardLearningPhaseBasicDTO[]>((acc, item) => acc.concat(item?.learningPhases || []), []);

          if (learningPhases) {
            setSelectedPhases(learningPhases);
            setChartData(getChartData(dashboardLearningPathData, learningPhases));
          } else {
            setSelectedPhases(null);
            setChartData(getChartData(dashboardLearningPathData));
          }
        }
      }
    }
  };

  useEffect(() => {
    if (dashboardLearningPathData && !selectedPhases) setChartData(getChartData(dashboardLearningPathData));
  }, [dashboardLearningPathData, selectedPhases]);

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    onClick: handleClick,
  };

  Chart.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

  return (
    <Box id="learning-path-overview-chart">
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default LearningPathChartOverview;
