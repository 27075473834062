import { AlertColor } from '@mui/material';
import React, { ReactNode, createContext, useState } from 'react';
import SnackBarComponent from './SnackBarComponent';

type SnackbarContextType = {
  openSnackbar: (severity: AlertColor, message: string) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  openSnackbar: () => {},
});

type SnackbarProviderProps = {
  children: ReactNode;
};

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [openSnackbarState, setOpenSnackbarState] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');

  const openSnackbar = (severity: AlertColor, message: string) => {
    setSeverity(severity);
    setMessage(message);
    setOpenSnackbarState(true);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <SnackBarComponent openSnackbarState={openSnackbarState} setOpenSnackbarState={setOpenSnackbarState} severity={severity} message={message} />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => React.useContext(SnackbarContext);
