import TabContext from '@mui/lab/TabContext';
import { Container, Paper, Box } from '@mui/material';
import React, { useState } from 'react';

import { ClientManagementDashboardProvider } from '../clientManagementDashboard/context/ClientManagementDashboardContext';
import { ClientManagementFilterContextProvider } from '../clientManagementDashboard/context/ClientManagementFilterContext';
import DashboardContent from '../dashboardShared/DashboardContent';
import DashboardTabs from '../dashboardShared/DashboardTabs';
import { EntityProvider } from '../feedbackComponent/context/EntityContext';
import VariantDrawer from '../shared/VariantDrawer';
import { DashboardProvider } from './context/DashboardContext';
import { FilterProvider } from './context/FilterContext';

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const [selectedTab, setSelectedTab] = useState<string>('active-employees');

  return (
    <>
      <VariantDrawer />
      <Box
        sx={{
          transform: 'scale(0.9)',
          transformOrigin: 'top left',
          width: '111.111%',
        }}>
        <Container maxWidth="xl">
          <Paper>
            <TabContext value={selectedTab}>
              <DashboardTabs setSelectedTab={setSelectedTab} />
              <DashboardContent />
            </TabContext>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

const DashboardWrapper = () => (
  <FilterProvider>
    <ClientManagementFilterContextProvider>
      <EntityProvider>
        <DashboardProvider>
          <ClientManagementDashboardProvider>
            <Dashboard />
          </ClientManagementDashboardProvider>
        </DashboardProvider>
      </EntityProvider>
    </ClientManagementFilterContextProvider>
  </FilterProvider>
);

export default DashboardWrapper;
