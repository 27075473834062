import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #476B97',
  boxShadow: 24,
  p: 2,
};

const EditUserModal = (props: any) => {
  const handleIsActiveChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setIsActive(event.target.checked);
  };

  const handleIsTenantManagerChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setIsTenant(event.target.checked);
  };

  const handlePermissionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setPermissions(event.target.value);
  };

  return (
    <Modal open={props.openEditUser} onClose={props.handleCloseEditUser}>
      <Box sx={style} component="form" onSubmit={props.onEditSquadLeaderHandler}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Edit User
        </Typography>
        <Stack spacing={2}>
          <TextField
            required
            variant="outlined"
            label="Full Name"
            id="fullName"
            value={props.fullName}
            onChange={(e) => props.setFullName(e.target.value)}
          />
          <TextField
            required
            variant="outlined"
            label="Email Address"
            id="emailAddress"
            value={props.emailAddress}
            onChange={(e) => props.setEmailAddress(e.target.value)}
          />
          <Stack spacing={0}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={props.isActive} onChange={handleIsActiveChanged} />} label="Active" />
            </FormGroup>
          <Stack spacing={0}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={props.isTenantManager} onChange={handleIsTenantManagerChanged} />} label="Tenant Manager" />
            </FormGroup>
          </Stack>
          </Stack>
          <Divider />
          <FormControl>
            <FormLabel id="permissions-group-label">Additional Permissions</FormLabel>
            <RadioGroup aria-labelledby="permissions-group-label" value={props.permissions} onChange={handlePermissionsChange}>
              <FormControlLabel value={0} control={<Radio />} label="User" />
              <Typography variant="caption" display="block" gutterBottom sx={{ mt: -1, ml: 3.8, mb: 1 }}>
                Can capture and view only their own feedback/action follow ups on employees.
              </Typography>
              <FormControlLabel value={1} control={<Radio />} label="Admin" />
              <Typography variant="caption" display="block" gutterBottom sx={{ mt: -1, ml: 3.8, mb: 1 }}>
                Can manage employees and capture feedback/action follow ups on employees.
              </Typography>
              <FormControlLabel value={2} control={<Radio />} label="Super User" />
              <Typography variant="caption" display="block" gutterBottom sx={{ mt: -1, ml: 3.8 }}>
                Can manage employees, categories, job titles, users and capture feedback/action follow ups on employees.
              </Typography>
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={props.handleCloseEditUser}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            Edit User
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditUserModal;
