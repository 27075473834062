import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  CertificationService,
  CertificationsDetails,
  CreateEmployeeCertificationDTO,
  EmployeeCertificateDTO,
  EmployeeCertificationUpdateDTO,
} from 'src/services/CertificationService';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

interface CertificationProps {
  handleSubmitted?: (addtionalComments: string) => void;
  certification: CertificationsDetails;
  employeeCertificate?: EmployeeCertificateDTO;
  employeeId: number;
  onClose?: () => void;
  feedbackId?: number;
}

const certificationService = new CertificationService();

const onSubmitNew = async (newEmployeeCertification: CreateEmployeeCertificationDTO) => {
  await certificationService.addEmployeeCertification(newEmployeeCertification);
};

const onSubmitUpdate = async (updatedEmployeeCertification: EmployeeCertificationUpdateDTO) => {
  await certificationService.updateEmployeeCertification(updatedEmployeeCertification);
};

enum Status {
  InProgress = 'In progress',
  Completed = 'Completed',
  Abandoned = 'Abandoned',
  Failed = 'Failed',
}

const statuses: Array<Status> = [Status.InProgress, Status.Completed, Status.Abandoned, Status.Failed];

const CreateCertificationModal: React.FC<CertificationProps> = ({ handleSubmitted, certification, employeeCertificate, employeeId, onClose, feedbackId }) => {
  const [selectedEmployeeCertificate, setSelectedEmployeeCertificate] = useState<EmployeeCertificateDTO | null>(employeeCertificate || null);
  const [preventSubmit, setPreventSubmit] = useState(false);
  const [reason, setReason] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<Status>((employeeCertificate?.status as Status) ?? Status.InProgress);
  const [examDate, setExamDate] = useState<Date>(employeeCertificate?.examDate ? new Date(employeeCertificate.examDate) : new Date());
  const [newExamDate, setNewExamDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date>(employeeCertificate?.startDate ? new Date(employeeCertificate.startDate) : new Date());
  const [startDateError, setStartDateError] = useState('');
  const [completedDate, setCompletedDate] = useState<Date | null>(employeeCertificate?.completedDate ? new Date(employeeCertificate.completedDate) : null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const maxStartDate = selectedStatus === Status.Completed && completedDate ? completedDate : new Date();
  const isAdd = !employeeCertificate;

  const handleSubmit = async () => {
    if (preventSubmit) return;

    if (selectedStatus === Status.Completed && completedDate && startDate > completedDate && startDate > new Date()) {
      setStartDateError("Start date cannot be newer than the current date.")
      return;
    }

    setStartDateError('');

    if (isAdd) {
      await onSubmitNew({
        certificateId: certification.certificateId,
        employeeId: employeeId,
        examDate: examDate ?? new Date(),
        completedDate: completedDate ? completedDate : null,
        startDate: startDate ?? new Date(),
        reason: reason,
        status: selectedStatus,
        isDeleted: false,
      });
    }

    if (selectedEmployeeCertificate) {
      if (selectedEmployeeCertificate)
        await onSubmitUpdate({
          ...selectedEmployeeCertificate,
          completedDate: selectedStatus === Status.Completed && completedDate ? completedDate : null,
          examDate: examDate ?? newExamDate,
          startDate: startDate,
          reason: reason,
          status: selectedStatus,
          feedbackId: feedbackId,
        });
    }

    if (!isAdd && !selectedEmployeeCertificate) {
      onClose?.();
    }

    const comment = [
      `\n`,
      `Start date: ${moment(startDate).format('YYYY/MM/DD')}`,
      `Status: ${selectedStatus}`,
      selectedStatus === Status.InProgress && examDate && `Exam date: ${moment(examDate).format('YYYY/MM/DD')}`,
      selectedStatus === Status.Failed && newExamDate && `Rewrite Exam date: ${moment(newExamDate).format('YYYY/MM/DD')}`,
      selectedStatus === Status.Abandoned && reason && `Reason : ${reason}`,
      selectedStatus === Status.Completed && `Completed date: ${moment(completedDate).format('YYYY/MM/DD')}`,
      `\n`,
    ]
      .filter(Boolean)
      .join('\n');

    handleSubmitted?.(comment);
    onClose?.();
  };

  useEffect(() => {
    if (!employeeCertificate) return;
    setSelectedEmployeeCertificate({
      employeeCertificateId: employeeCertificate.employeeCertificateId,
      certificateId: employeeCertificate.certificateId,
      employeeId: employeeCertificate.employeeId,
      examDate: employeeCertificate.examDate,
      completedDate: employeeCertificate.completedDate,
      startDate: employeeCertificate.startDate,
      reason: employeeCertificate.reason,
      status: employeeCertificate.status,
      certificateName: employeeCertificate.certificateName,
      isDeleted: false,
    });
  }, [employeeCertificate]);

  const onSetStartDate = (newDate: Date) => {
    setStartDate(newDate);
    
    if (selectedStatus === Status.InProgress && newDate > examDate) {
      setExamDate(newDate);
    }
  };

  const onSetExamDate = (newDate: Date) => {
    setExamDate(newDate);
  };
  const onSetNewExamDate = (newDate: Date) => {
    setNewExamDate(newDate);
  };

  const onSetCompletedDate = (newDate: Date) => {
    const offsetDate = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000));
    setCompletedDate(new Date(offsetDate));
    if (selectedStatus === Status.Completed) {
      setDisableSubmit(false);
    }
  };
  const handleFormKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSelectedStatusSelect = (status: Status) => {
    setSelectedStatus(status);
    if (status === Status.Completed && (!startDate || !completedDate)) {
      setDisableSubmit(true);
    } else if (status === Status.Abandoned && !reason) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }

  const onReasonChange = (e: string) => {
    setReason(e);
    if (e.length === 0) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      onKeyDown={handleFormKeyPress}>
      <Dialog open fullWidth onClose={onClose} >
        <DialogTitle>Employee Certification</DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={1}>
            <FormControl fullWidth>
              <InputLabel htmlFor="status-label">Status</InputLabel>
              <Select
                autoFocus
                notched
                label="Status"
                onFocus={() => setPreventSubmit(true)}
                onBlur={() => setPreventSubmit(false)}
                id={'status-label'}
                value={selectedStatus}
                onChange={(event) => {
                  handleSelectedStatusSelect(event.target.value as Status);
                }}>
                {statuses.map((status) => (
                  <MenuItem key={status} value={status}>
                    <Typography>{status}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedStatus === Status.InProgress && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start date *"
                  value={startDate}
                  onChange={onSetStartDate}
                  inputFormat='yyyy/MM/dd'
                  views={["year", "month", "day"]}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="Exam date *"
                  value={examDate}
                  minDate={startDate}
                  inputFormat='yyyy/MM/dd'
                  views={["year", "month", "day"]}
                  onChange={onSetExamDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}
            {selectedStatus === Status.Completed && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start date *"
                  value={startDate}
                  inputFormat='yyyy/MM/dd'
                  views={["year", "month", "day"]}
                  onChange={onSetStartDate}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={maxStartDate}
                />
                {startDateError && (
                  <FormHelperText error>{startDateError}</FormHelperText>
                )}
                <DatePicker
                  label="Exam completed date *"
                  minDate={startDate}
                  maxDate={new Date()}
                  value={completedDate}
                  inputFormat='yyyy/MM/dd'
                  views={["year", "month", "day"]}
                  onChange={onSetCompletedDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            )}
            {selectedStatus === Status.Abandoned &&
              <TextField
                inputRef={(input) => input && input.focus()}
                required
                label="Reason"
                variant="outlined"
                value={reason}
                onChange={(e: any) => onReasonChange(e.target.value)} />}
            {selectedStatus === Status.Failed && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="New exam date *"
                  disablePast
                  value={newExamDate}
                  inputFormat='yyyy/MM/dd'
                  views={["year", "month", "day"]}
                  onChange={onSetNewExamDate}
                  renderInput={(params) => <TextField {...params} />}
                />

              </LocalizationProvider>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
          <Button onClick={handleSubmit} disabled={disableSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default CreateCertificationModal;
