import { CircularProgress, Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useEffect, useState } from 'react';
import { EmployeeDetails } from 'src/Models/EmployeeModels';
import useIsMounted from 'src/hooks/useIsMounted';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';

interface EmployeeSquadModalProps {
  isOpen: boolean;
  onClose: () => void;
  employeeName: string;
  employeeId?: number;
  employeeDetails?: EmployeeDetails;
}

const EmployeeSquadModal: React.FC<EmployeeSquadModalProps> = ({
  isOpen,
  onClose,
  employeeName,
  employeeId,
  employeeDetails: employeeDetailProps,
}) => {
  const displayEmployeeName = employeeName.toUpperCase();
  const employeeService = new EmployeeService();
  const { squad } = useContext(SquadsContext);
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails>();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (employeeId && !employeeDetailProps && isOpen)
      employeeService.getSquadsByEmployeeId(employeeId, parseInt(squad.squadId)).then((data) => {
        isMounted() && setEmployeeDetails(data);
      });
  }, [isOpen]);

  useEffect(() => {
    if (!employeeId && employeeDetailProps && isOpen) {
      setEmployeeDetails(employeeDetailProps);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle bgcolor="#F6F6F6" color="#4A5568">
        {displayEmployeeName} - SQUAD ALLOCATION
      </DialogTitle>
      {employeeDetails ? (
        <DialogContent>
          {employeeDetails &&
            employeeDetails.currentEmployeeGroups &&
            employeeDetails.currentEmployeeGroups.map((employeeGroup, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                <Typography color="#000000" sx={{ fontSize: '18px', fontWeight: 500, marginRight: '10px' }}>
                  {index + 1}) {employeeGroup.groupName}
                </Typography>
                <Typography sx={{ fontSize: '10px', fontWeight: 300, marginRight: '10px' }}>
                  {employeeGroup.groupMembers.filter((gm) => !gm.isDeleted).length} active group members
                </Typography>
                <Typography color="#5E5E5E" sx={{ fontSize: '16px', fontWeight: 500, marginRight: '10px' }}>
                  Squad Leaders:
                </Typography>
                {employeeGroup.groupLeaders.map((groupLeader, glindex) => (
                  <div key={groupLeader.groupLeaderId} style={{ backgroundColor: glindex % 2 === 0 ? '#EDF2F7' : '#FFFFFF' }}>
                    <Typography color="#5E5E5E" sx={{ fontSize: '14px', fontWeight: 400, marginRight: '10px', font: 'Roboto', lineHeight: '30px' }}>
                      {groupLeader.squadLeader.user.fullName}
                    </Typography>
                  </div>
                ))}
                <Divider />
                <Divider />
              </div>
            ))}
        </DialogContent>
      ) : (
        <div style={{ alignContent: 'center' }}>
          <CircularProgress />
        </div>
      )}
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onClose} style={{ border: '1px solid' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeSquadModal;
