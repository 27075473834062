import { BaseService } from './BaseService';

export class FeedbackKeywordsService extends BaseService {
  constructor() {
    super('FeedbackKeywords');
  }

  async getByFeedbackId(id, squadId) {
    return (await this.httpService.get(`/Reporting/${id}/${squadId}`)).data;
  }
}
