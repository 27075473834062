import CloseIcon from '@mui/icons-material/Close';
import { Button, createTheme, FormHelperTextProps, IconButton, Modal, TextField, Theme, ThemeProvider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { entelectBlue } from 'src/utilities/customColors';
import { makeStyles } from 'tss-react/mui';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (feedback: string) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],

    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2),
    '&>button': {
      width: '128px',
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  helperText: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginLeft: theme.spacing(1),
  },
  header: {
    height: '50px',
    color: entelectBlue,
    backgroundColor: '#F6F6F6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: theme.spacing(0, 2),
  },
  body: {
    padding: theme.spacing(2),
  },
}));

const ReasonModal: React.FC<Props> = ({ open, onClose, onSave }) => {
  const { classes } = useStyles();
  const [feedback, setFeedback] = useState('');

  const handleSave = () => {
    // Handle saving the feedback

    onSave(feedback);
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  const helperTextProps: FormHelperTextProps = {
    className: classes.helperText,
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSave();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ThemeProvider theme={createTheme({ palette: { primary: { main: entelectBlue } } })}>
        <div className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <div className={classes.header}>
              <Typography variant="h6">Reasons for being unflagged</Typography>
              <IconButton onClick={handleCancel} color={'primary'}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.body}>
              <TextField
                id="feedback-textfield"
                label="Feedback"
                FormHelperTextProps={helperTextProps}
                placeholder="Please provide feedback for this update."
                multiline
                minRows={4}
                variant="outlined"
                fullWidth
                value={feedback}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  required: true,
                }}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button variant="outlined" className={classes.cancelButton} onClick={handleCancel}>
                Cancel
              </Button>
              <Button disabled={!feedback} variant="contained" color="primary" type="submit">
                Save
              </Button>
            </div>
          </form>
        </div>
      </ThemeProvider>
    </Modal>
  );
};

export default ReasonModal;
