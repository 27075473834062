import { Container } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReducedEmployee } from 'src/Models/EmployeeModels';
import { useAsync } from 'src/hooks/useAsync';
import useIsMobile from 'src/hooks/useIsMobile';
import { CategoryService } from 'src/services/CategoryService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { entelectBlue } from 'src/utilities/customColors';
import { makeStyles } from 'tss-react/mui';

import { EditorProvider } from '../Editor/EditorContext';
import DesktopFeedbackPage from './DesktopFeedbackPage';
import { TabVisibilityProvider, useTabVisibility } from './FeedTabContext';
import MobileFeedbackPage from './MobileFeedbackPage';
import { CategoryProvider, useCategoryContext } from './context/CategoryContext';
import { EntityProvider, useEntityContext } from './context/EntityContext';

const useStyles = makeStyles()((theme) => ({
  tabs: {
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: 500,
    marginRight: theme.spacing(1),
    color: '#5B5B5B',
    fontFamily: 'Roboto',
    padding: 0,
    margin: 0,
    '&.Mui-selected': {
      color: entelectBlue,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  customContainer: {
    height: '100%',
    paddingLeft: '50px',
    width: '95%',
    overflow: 'hidden',
  },
  customContainerMobile: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
  },
}));
const categoryService = new CategoryService();

const FeedbackPage = () => {
  const { tab, id } = useParams();
  const [searchParams] = useSearchParams();
  const { classes } = useStyles();
  const tabs = ['individuals', 'groups'];
  const selectedTabIndex = tab || 'individuals';
  const { squad } = useContext(SquadsContext);
  const {
    setEmployee,
    employee: selectedEmployee,
    group: selectedGroup,
    setGroup,
    getEmployees,
    employeeStatus,
    employees,
    groupStatus,
    groups,
    setFocus,
  } = useEntityContext();
  const { setCategoryList } = useCategoryContext();
  const selectedGroupId = searchParams.get('selectedSquad') ?? '';
  const selectedEntelectEmployee = searchParams.get('entelectId') ?? '';
  const employeeSearchQueryParam = searchParams.get('search') ?? '';
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { showTabContext, toggleTabVisibility } = useTabVisibility();

  const showEntityList = !(isMobile && (selectedEmployee || selectedGroup));
  const tabGridWidth = selectedEmployee ? '80vw' : '100vw';
  const feedbackGridWidth = showTabContext ? `calc(100vh - ${tabGridWidth})` : '100vw';
  const feedbackGridOpacity = showTabContext ? 0.6 : 1;

  useEffect(() => {
    if (tabs.includes(tab ?? '') === false) {
      const queryString = searchParams.toString();
      navigate(`/capture-feedback/individuals/${tab ?? ''}${isEmpty(queryString) ? '' : '?' + queryString}`);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(selectedEntelectEmployee)) return;
    const newEmployee = employees?.find((employee) => employee.employeeId === parseInt(selectedEntelectEmployee));
    newEmployee && setEmployee(newEmployee);
  }, [employees, selectedEntelectEmployee]);

  useEffect(() => {
    if (employees && selectedEmployee) {
      const newEmployee = employees?.find((employee) => employee.employeeId === parseInt(selectedEmployee.employeeId));
      if (newEmployee && selectedEmployee?.emailAddress !== newEmployee.emailAddress) setEmployee(newEmployee);
      navigate(`/capture-feedback/individuals/${newEmployee?.employeeId ?? ''}`);
    } else if (id) {
      const newEmployee = employees?.find((employee) => employee.employeeId === parseInt(id));
      setEmployee(newEmployee);
      navigate(`/capture-feedback/individuals/${id ?? ''}`);
    }
  }, [id, employees]);

  useEffect(() => {
    if (selectedGroupId && groups) {
      const newGroup = groups?.find((group) => group.groupId === parseInt(selectedGroupId));
      if (newGroup && selectedGroup?.groupId !== newGroup.groupId) setGroup(newGroup);
    }
  }, [selectedGroupId, groups]);

  const getCategoryList = async () => {
    return categoryService.getAllCategoriesForSquad(squad.squadId);
  };

  const { value: categoryList, execute: getCategories } = useAsync(getCategoryList, false, 'Failed to load category list. Please try again later.');

  const onTabSelectionChange = (event: React.SyntheticEvent, newValue: string) => {
    setEmployee(null);
    setGroup(null);
    navigate(`/capture-feedback/${newValue}`);
  };

  useEffect(() => {
    if (squad.squadId) {
      getEmployees();
      getCategories();
    }
  }, [squad.squadId]);

  useEffect(() => {
    if (selectedEmployee !== null) {
      setFocus(true);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    setCategoryList(categoryList);
  }, [categoryList]);

  const handleEntitySelection = (entity: React.SetStateAction<ReducedEmployee | null>) => {
    setGroup(null);
    setEmployee(entity);
    toggleTabVisibility(false);
  };

  return (
    <Container maxWidth={'xl'} className={isMobile ? classes.customContainerMobile : classes.customContainer}>
      {isMobile ? (
        <MobileFeedbackPage
          employeeSearchQueryParam={employeeSearchQueryParam}
          classes={classes}
          showTabContext={showTabContext}
          tabGridWidth={tabGridWidth}
          selectedTabIndex={selectedTabIndex}
          employees={employees as ReducedEmployee[]}
          employeeStatus={employeeStatus}
          selectedEmployee={selectedEmployee}
          groups={groups}
          groupStatus={groupStatus}
          selectedGroup={selectedGroup}
          handleEntitySelection={handleEntitySelection}
          getEmployees={getEmployees}
          onTabSelectionChange={onTabSelectionChange}
          showEntityList={showEntityList}
          setGroup={setGroup}
          setEmployee={setEmployee}
          feedbackGridOpacity={feedbackGridOpacity}
          feedbackGridWidth={feedbackGridWidth}
        />
      ) : (
        <DesktopFeedbackPage
          employeeSearchQueryParam={employeeSearchQueryParam}
          selectedTabIndex={selectedTabIndex}
          showEntityList={showEntityList}
          onTabSelectionChange={onTabSelectionChange}
          employees={employees as ReducedEmployee[]}
          employeeStatus={employeeStatus}
          selectedEmployee={selectedEmployee}
          groups={groups}
          groupStatus={groupStatus}
          selectedGroup={selectedGroup}
          setGroup={setGroup}
          setEmployee={setEmployee}
          getEmployees={getEmployees}
        />
      )}
    </Container>
  );
};

const FeedbackPageWrapper = () => (
  <EntityProvider>
    <CategoryProvider>
      <TabVisibilityProvider>
        <EditorProvider>
          <FeedbackPage />
        </EditorProvider>
      </TabVisibilityProvider>
    </CategoryProvider>
  </EntityProvider>
);

export default FeedbackPageWrapper;
