import { PluginEditorProps } from '@draft-js-plugins/editor';
import { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions';
import React, { useCallback, useEffect, useState } from 'react';
import { CertificationService, CertificationsDetails, EmployeeCertificateDTO } from 'src/services/CertificationService';
import { LearningPathFallBackService } from 'src/services/LearningPathFallBackService';
import useSwr from 'swr';

import { useEditor } from '../Editor/EditorContext';
import { useEntityContext } from '../feedbackComponent/context/EntityContext';
import CreateCertificationModal from '../manageCertificates/modals/CreateCertificationModal';
import useSuggestions from './MentionsShared';
import './mention-styles.css';

const certificationService = new CertificationService();
const learningPathService = new LearningPathFallBackService();

type MentionsProps = {
  feedbackId?: number;
  editorRef: any;
  MentionSuggestions: React.ComponentType<MentionSuggestionsPubProps>;
} & Partial<PluginEditorProps>;

export const AddFeedbackMentions: React.FC<MentionsProps> = ({ feedbackId, editorRef, MentionSuggestions }) => {
  const { employee, setFocus } = useEntityContext();
  const { appendTextToEditorContent } = useEditor();

  const employeeId = employee?.employeeId;

  const [employeeCertification, setEmployeeCertification] = useState<EmployeeCertificateDTO>();
  const [certification, setCertification] = useState<CertificationsDetails | null>(null);

  const fetchCertificates = async () => certificationService.getCertificatesByEmployeeId(employeeId!);
  const fetchLearningPaths = async () => await learningPathService.getEmployeeLearningPaths(employeeId);

  const { data: certifications, mutate } = useSwr('getAllCertifications', async () => await certificationService.getCertificationsDetails(), {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });
  const { data: employeeCertificates } = useSwr(employeeId ? ['mentions', employeeId] : undefined, fetchCertificates);

  const { data: employeeLearningPaths } = useSwr(employeeId ? ['getApiLearningPaths', employeeId] : undefined, fetchLearningPaths);

  const { tags } = useEntityContext();

  React.useEffect(() => {
    if (!certifications) mutate();
  }, [certifications, mutate]);

  const { mentions, suggestions, open, onOpenChange, onSearchChange } = useSuggestions(
    certifications,
    employeeCertificates,
    tags,
    employeeLearningPaths,
  );

  useEffect(() => {
    if (mentions?.length > 0) {
      editorRef.current?.focus();
    }
    setFocus(false);
  }, [editorRef]);

  const handleClose = useCallback(() => setCertification(null), []);

  return (
    <div>
      {mentions && (
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={(e) => {
            if (e.type === 'certification') {
              setCertification(certifications?.find((cert) => cert.certificateId === e.id) ?? null);
              setEmployeeCertification(employeeCertificates?.find((cert) => cert.certificateId === e.id));
            }
          }}
        />
      )}
      {certification && (
        <CreateCertificationModal
          employeeId={employeeId ?? 0}
          employeeCertificate={employeeCertification}
          onClose={handleClose}
          certification={certification}
          handleSubmitted={(e) => appendTextToEditorContent(e, false)}
          feedbackId={feedbackId}
        />
      )}
    </div>
  );
};
