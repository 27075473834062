import { Box, Chip, Grid, LinearProgress, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import useSWR from 'swr';

import RelatedEmployeesProfile from './RelatedEmployeeProfile';

interface RelatedEmployeeProps {
  employeeId: number;
}

export const RelatedEmployees: React.FC<RelatedEmployeeProps> = ({ employeeId }) => {
  const { squad } = useContext(SquadsContext);
  const employeeService = new EmployeeService();

  const { data: relatedEmployeesData, isLoading } = useSWR(employeeId ? ['relatedEmployees', employeeId] : undefined, async () =>
    employeeService.getRelatedEmployeesByEmployeeId(squad.squadId, employeeId),
  );

  return (
    <>
      {!isLoading && relatedEmployeesData && relatedEmployeesData.length > 0 && (
        <Typography variant="h6" sx={{ color: '#4A5568' }}>
          Related Employees
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {relatedEmployeesData && (
          <Grid container spacing={2}>
            {relatedEmployeesData.length > 0 &&
              relatedEmployeesData.slice(0, 12).map((relatedEmployee, index) => {
                if (!relatedEmployee) {
                  return null;
                }

                const uniqueChips = new Set(relatedEmployee.chips.map((tag: any) => tag.chipName));

                return (
                  <Grid item key={index} xs={12} md={4} lg={3}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}>
                      <RelatedEmployeesProfile
                        alt={relatedEmployee.fullName}
                        emailAddress={relatedEmployee.emailAddress}
                        employeeId={relatedEmployee.employeeId}
                        showEyeBadge={true}
                      />
                      <Typography sx={{ textAlign: 'center' }} fontSize={'12px'}>
                        {relatedEmployee.fullName}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}>
                        {[...uniqueChips].map((tag, tagIndex) => {
                          const foundChip = relatedEmployee.chips.find((chip: any) => chip.chipName === tag);
                          const chipColour = foundChip ? foundChip.chipColour : '#133970';

                          return (
                            <Chip
                              key={tagIndex}
                              label={tag}
                              size="small"
                              style={{
                                margin: '1px',
                                background: chipColour === '#000000' || chipColour === '#fff' ? '#133970' : chipColour,
                                color: '#FFF',
                                fontSize: '8px',
                                height: '15px',
                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
                              }}
                            />
                          );
                        })}
                      </div>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        )}
        {isLoading && (
          <>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
