import GroupIcon from '@mui/icons-material/Group';
import { Box, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { useTheme } from '@mui/system';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { EmployeeDetails } from 'src/Models/EmployeeModels';
import EmployeeSquadModal from 'src/components/dashboardShared/EmployeeSquadModal';
import { GroupOutlinedIcon } from 'src/components/shared/GroupOutlinedIcon';
import { entelectBlue } from 'src/utilities/customColors';

interface EmployeeTeamDetailsProps {
  employeeName: string;
  employeeDetails?: EmployeeDetails;
}

export const EmployeeTeamDetails = (props: EmployeeTeamDetailsProps) => {
  const theme = useTheme();
  const BlackTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
      },
    }),
  );

  const { employeeDetails, employeeName } = props;

  const employeeSquads = employeeDetails?.currentEmployeeSquads ?? [];
  const employeeGroups = employeeDetails?.currentEmployeeGroups ?? [];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'block',
        maxWidth: 360,
        minHeight: 24,
        [theme.breakpoints.down('sm')]: {
          margin: 0,
        },
        padding: 0,
        '& a': { textDecoration: 'none', color: 'black' },
      }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography display={'flex'} component="div" alignItems="center" justifyContent={'center'} color={'#5E5E5E'}>
          <GroupIcon sx={{ p: '8px', pl: 0 }} />
          {!isEmpty(employeeSquads) ? (
            employeeSquads.map((employeeSquad, index: number) => `${employeeSquad.squad.squadName} (${employeeSquad.squad.shortName})`).join(', ')
          ) : (
            <b>—</b>
          )}
        </Typography>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Typography display={'flex'} component="div" alignItems="center" justifyContent={'center'} color={'#5E5E5E'}>
          <GroupOutlinedIcon />
          <div style={{ marginLeft: '8px' }}>
            {!isEmpty(employeeGroups) ? (
              <BlackTooltip
                title={
                  <div>
                    <i>Select to view group leader(s)</i>
                  </div>
                }>
                <Typography sx={{ color: entelectBlue, ':hover': { cursor: 'pointer', textDecoration: 'underline' } }} onClick={openModal}>
                  {employeeGroups.map((employeeGroup, index: number) => (
                    <span key={index}>
                      {employeeGroup.groupName}
                      {index < employeeGroups.length - 1 && ', '}
                    </span>
                  ))}
                </Typography>
              </BlackTooltip>
            ) : (
              <b>—</b>
            )}
          </div>
        </Typography>
      </div>

      <EmployeeSquadModal isOpen={isModalOpen} onClose={closeModal} employeeName={employeeName} employeeDetails={employeeDetails} />
    </Box>
  );
};
