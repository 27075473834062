import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FlagIcon from '@mui/icons-material/Flag';
import { Avatar, Grid, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import * as React from 'react';
import { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { ReducedEmployee } from 'src/Models/EmployeeModels';
import ProfilePicture from 'src/components/employee/ProfilePicture';
import { CertificateIcon } from 'src/components/manageCertificates/components/CertificateIcon';
import { CertificationService } from 'src/services/CertificationService';
import { REDUCED_ICON_LENGTH } from 'src/utilities/Constants';
import useSwr from 'swr';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  employeeCard: {
    // Change this to reduce the sizes of all the card elements (besides the icons)
    fontSize: '14px',

    padding: '0.59em 1em',
    borderRadius: '0',

    '&:hover': {
      background: '#13397010',
      cursor: 'pointer',
    },
    '& .MuiCardHeader-avatar': {
      marginRight: '1em',
    },
    '& .MuiCardHeader-title': {
      fontSize: '1em',
      fontWeight: 400,
      color: '#000000DE',
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '0.875em',
      fontWeight: 400,
    },
  },
}));

interface EmployeeCardProps {
  employee: ReducedEmployee;
  isSelected: boolean;
}

const certificationService = new CertificationService();

const EmployeeCard = (props: EmployeeCardProps) => {
  const { employee, isSelected } = props;
  const { classes } = useStyles();
  const today = new Date();
  const availableDate = employee.availableDate ? new Date(employee.availableDate) : null;
  const shouldBlur = employee.isDeleted || (availableDate && availableDate > today);
  const showGreenArrow = availableDate && availableDate > today;

  const [background, setBackground] = useState('#FFFFFF');

  const fetchCertificates = async () => certificationService.getCertificatesByEmployeeId(employee.employeeId);
  const { data: certifications, mutate } = useSwr(employee.employeeId ? ['fetchCertificates', employee.employeeId] : undefined, fetchCertificates, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });

  React.useEffect(() => {
    if (!certifications) mutate();
  }, [certifications, mutate]);

  useEffect(() => {
    if (isSelected) {
      setBackground('#13397026');
    } else if (shouldBlur) {
      setBackground('#F6F6F6');
    } else {
      setBackground('#FFFFFF');
    }
  }, [employee, isSelected]);

  return (
    <Card
      key={employee?.employeeId}
      variant="outlined"
      data-testid="employee-card"
      className={classes.employeeCard}
      sx={{
        backgroundColor: { background },
        opacity: shouldBlur ? '0.7' : '1',
      }}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs>
          <CardHeader
            avatar={
              <LazyLoad debounce={300} height={40} offset={500} scrollContainer={'#entity-list'} placeholder={<Avatar alt={employee.fullName} />}>
                <ProfilePicture alt={employee.fullName} emailAddress={employee.emailAddress} sx={{ width: '32px', height: '32px' }} />
              </LazyLoad>
            }
            title={employee.fullName}
            sx={{ p: 0 }}
          />
        </Grid>

        <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
          {certifications && certifications.length > 0 && (
            <LazyLoad
              debounce={300}
              offset={500}
              scrollContainer={'#entity-list'}
              style={{ width: REDUCED_ICON_LENGTH, height: REDUCED_ICON_LENGTH }}>
              <CertificateIcon certifications={certifications} />
            </LazyLoad>
          )}

          {employee.followUp && <FlagIcon aria-label="flagged icon" sx={{ width: REDUCED_ICON_LENGTH, color: '#D80020' }} />}
        </Grid>
        {showGreenArrow && (
          <Grid sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title="Incoming Employee" placement="top">
              <ArrowForwardIcon fontSize="small" sx={{ opacity: '0.7' }} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default EmployeeCard;
