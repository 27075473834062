import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import React, { forwardRef } from 'react';

interface SnackBarComponentProps {
  openSnackbarState: boolean;
  setOpenSnackbarState: React.Dispatch<React.SetStateAction<boolean>>;
  severity: AlertProps['severity'];
  message: string;
}

const SnackBarComponent = ({ openSnackbarState, setOpenSnackbarState, severity, message }: SnackBarComponentProps) => {
  const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

  const handleCloseSnackbar = (event?: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarState && setOpenSnackbarState(false);
  };

  return (
    <Snackbar open={openSnackbarState} autoHideDuration={6000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%', color: '#FFF' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarComponent;
