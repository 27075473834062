import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

const ChartHeading: React.FC<TypographyProps> = ({ children, variant }) => {
  return (
    <Typography variant={variant ?? 'body1'} color={'#000000'}>
      {children}
    </Typography>
  );
};

export default ChartHeading;
