import React, { createContext, useContext, useMemo, useState } from 'react';
import { Category } from 'src/services/FeedbackType';

interface CategoryContextProps {
  categoryList: Category[];
  setCategoryList: React.Dispatch<React.SetStateAction<Category[]>>;
}

const CategoryContext = createContext<CategoryContextProps>({
  categoryList: [],
  setCategoryList: () => {},
});

export const CategoryProvider: React.FC = ({ children }) => {
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const value = useMemo(() => ({ categoryList, setCategoryList }), [categoryList]);
  return <CategoryContext.Provider value={value}>{children}</CategoryContext.Provider>;
};

export const useCategoryContext = () => {
  return useContext(CategoryContext);
};
