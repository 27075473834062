import { ArrowForward } from '@mui/icons-material';
import { Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { entelectBlue } from 'src/utilities/customColors';

const EmployeeFeedbackDetails = (props: any) => {
  const navigate = useNavigate();
  const routeToEmployee = () => {
    navigate(`/capture-feedback/${props.feedbackItem.employee.employeeId}`);
  };

  return (
    <div style={{ paddingLeft: '9px' }}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={1} paddingTop={'20px'}>
        <Typography>Employee:</Typography>
        <Chip sx={{ backgroundColor: '#476B9733', Opacity: '0.2' }} label={props.feedbackItem.employee.fullName}></Chip>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
          <Typography onClick={routeToEmployee} sx={{ color: entelectBlue, cursor: 'pointer' }}>
            View Employee
          </Typography>
          <ArrowForward onClick={routeToEmployee} sx={{ color: entelectBlue, cursor: 'pointer' }} />
        </Stack>
      </Stack>
    </div>
  );
};

export default EmployeeFeedbackDetails;
