import HttpService from './HttpService';

export class BaseService {
  httpService: HttpService;

  constructor(url: string) {
    this.httpService = new HttpService(url);
  }

  async getAll() {
    return (await this.httpService.get(``)).data;
  }

  async get(id: string) {
    return (await this.httpService.get(`/${id}`)).data;
  }

  async delete(id: string) {
    return (await this.httpService.delete(`/${id}`)).data;
  }

  async create(body: any) {
    return this.httpService.post(``, body);
  }

  async update(body: any) {
    return (await this.httpService.put(``, body)).data;
  }

  async edit(body: any) {
    return (await this.httpService.patch(``, body)).data;
  }
}
