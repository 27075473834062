import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import React from 'react';
import ProfilePicture from 'src/components/employee/ProfilePicture';
import { Category, Feedback } from 'src/services/FeedbackType';
import { getTime } from 'src/utilities/DateUtilities';
import { makeStyles } from 'tss-react/mui';
import EmployeeFeedbackDetails from './components/EmployeeFeedbackDetails';
import FeedbackCategoryDisplay from './components/FeedbackCategoryDisplay';
import FeedbackDescription from './components/FeedbackDescription';

const useStyles = makeStyles()(() => ({
  employeeAvatar: {
    position: 'relative',
    width: '52px',
    height: '52px',
    borderTop: '15px',
    borderleft: '15px',
    margin: '8px',
  },
}));

interface FeedbackCardProps {
  feedbackItem: Feedback;
  categoryList: Category[];
  displayEmployeeDetail: boolean;
  employeeId: number;
}

const HEX_COLOUR_BLUE = '#55AB68D9';

const FeedbackCard = ({ feedbackItem, displayEmployeeDetail }: FeedbackCardProps) => {
  const { classes } = useStyles();

  const theme = useTheme();
  const smallDevicesBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const time = getTime(new Date(feedbackItem.createdDate));

  return (
    <Box sx={{ width: '100%' }} key={feedbackItem.feedbackId}>
      <Card
        id={`feedbackId-${feedbackItem.feedbackId}`}
        elevation={0}
        sx={{ borderLeft: 20, borderLeftColor: feedbackItem.sentiment.hexColor, backgroundColor: '#FAFAFA' }}>
        <Stack justifyContent="space-between" sx={{ minHeight: '80px', ml: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            {!smallDevicesBreakpoint && (
              <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
                <ProfilePicture emailAddress={feedbackItem.createdByEmail} alt={feedbackItem.createdByEmail} className={classes.employeeAvatar} />
                <Stack direction="row" alignItems={'center'} ml={2} sx={{ color: 'black' }}>
                  <b>
                    {feedbackItem.createdBy}
                    {feedbackItem.squadGroup && <>{`(${feedbackItem.squadGroup.groupName})`}</>}
                  </b>
                  <Typography variant="body1" ml={1} fontWeight={600} fontSize={'11px'} color={'#4C5667'}>
                    {time}
                  </Typography>
                </Stack>
              </Stack>
            )}

            <Stack direction="row">
              {feedbackItem.feedbackFollowUpId ? <EventAvailableIcon sx={{ color: HEX_COLOUR_BLUE }} fontSize="large" /> : ''}
              <FeedbackCategoryDisplay
                name={feedbackItem.category.name}
                backgroundColour={feedbackItem.category.backgroundHexColor}
                hexColor={feedbackItem.category.hexColor}
                isFlagged={feedbackItem.followUp}
                hasReason={!!feedbackItem.unflaggedFeedback?.unflagReason}
                isHiddenFromOverview={feedbackItem.hideFromOverview}
              />
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
            <FeedbackDescription feedbackItem={feedbackItem} employeeId={feedbackItem.employeeId} />
          </Stack>
          <Stack alignItems="start" justifyContent={'space-between'} sx={{ p: 1, display: 'flex', width: 'inherit' }}>
            {displayEmployeeDetail && <EmployeeFeedbackDetails feedbackItem={feedbackItem} />}
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default FeedbackCard;
