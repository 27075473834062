import ClearIcon from '@mui/icons-material/Clear';
import { Button, Grid, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { EmployeeService } from 'src/services/EmployeeService';
import { getDateTime } from 'src/utilities/DateUtilities';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { useDashboardContext } from '../context/DashboardContext';
import { useFilterContext } from '../context/FilterContext';

const employeeService = new EmployeeService();

const GlobalDateFilter: React.FC = (props: any) => {
  const { setEmployees } = useDashboardContext();

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setLearningPathFilterRequest,
    setSelectedPhases,
    learningPathFilterRequest,
    setCertificationFilterRequest,
    certificationFilterRequest,
    setTagRequest,
    setSelectedFilter,
    selectedFilter,
    showAllEmployees,
  } = useFilterContext();

  const { squad } = React.useContext(SquadsContext);

  const getAllEmployees = async () => {
    const response = await employeeService.getActiveDashboardEmployees(+squad.squadId, showAllEmployees);
    setEmployees(response);
  };

  const handleDisabledButton = () => {
    return (
      isEmpty(learningPathFilterRequest) && isEmpty(certificationFilterRequest) && startDate === null && endDate === null && isEmpty(selectedFilter)
    );
  };

  const onClearFilters = async () => {
    setLearningPathFilterRequest({});
    setCertificationFilterRequest({});
    setTagRequest({});
    setStartDate(null);
    setEndDate(null);
    setSelectedPhases(null);
    await getAllEmployees();
    localStorage.removeItem('selectedFilter');
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    setSelectedFilter('');
  };

  useEffect(() => {
    onClearFilters();
  }, [showAllEmployees]);

  return (
    <Grid container direction="row" spacing={0.5}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid item xs={4}>
          <DatePicker
            label="Start date"
            InputProps={{ size: 'small' }}
            value={startDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              if (getDateTime(newValue) > props.endDate) {
                newValue = props.endDate;
              }
              setStartDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={4}>
          <DatePicker
            label="End date"
            InputProps={{ size: 'small' }}
            value={endDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              if (getDateTime(newValue) < getDateTime(startDate)) {
                newValue = startDate;
              }
              setEndDate(newValue);
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
      <Grid item xs={4}>
        <Button size="small" startIcon={<ClearIcon />} disabled={handleDisabledButton()} onClick={onClearFilters}>
          Clear
        </Button>
      </Grid>
    </Grid>
  );
};

export default GlobalDateFilter;
