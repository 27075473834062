import { Check } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'src/components/shared/SnackbarContext';
import { FeedbackGroupPayload, FeedbackService } from 'src/services/FeedbackService';
import { Category } from 'src/services/FeedbackType';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { DEFAULT_SENTIMENT_ID } from '../../../../utilities/Constants';
import { useEntityContext } from '../../context/EntityContext';
import { useFeedbackContext } from '../../context/FeedbackContext';
import SentimentSelector from './components/SentimentSelector';

interface FeedbackFormProps {
  categoryList: Category[];
  defaultCategoryId: number;
}

const feedbackService = new FeedbackService();
const GroupFeedbackForm = ({ categoryList, defaultCategoryId }: FeedbackFormProps) => {
  const { squad } = useContext(SquadsContext);
  const { group } = useEntityContext();
  const { getFeedbackItems } = useFeedbackContext();
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = React.useState(false);
  const { openSnackbar } = useSnackbar();

  const saveFormStateToSessionStorage = (state: FeedbackGroupPayload) => {
    sessionStorage.setItem(`groupGeedbackForm_${group?.groupId}`, JSON.stringify(state));
  };
  const loadFormStateFromSessionStorage = () => {
    const savedState = sessionStorage.getItem(`groupGeedbackForm_${group?.groupId}`);

    return savedState ? JSON.parse(savedState) : null;
  };
  const savedState = loadFormStateFromSessionStorage();
  const initialState: Partial<FeedbackGroupPayload> = {
    categoryId: (defaultCategoryId ?? 0) > 0 ? defaultCategoryId.toString() : undefined,
    squadId: parseInt(squad.squadId),
    groupId: group?.groupId ?? 0,
    sentimentId: DEFAULT_SENTIMENT_ID,
    description: '',
  };

  const methods = useForm<FeedbackGroupPayload>({
    defaultValues: {
      ...initialState,
      ...savedState,
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = methods;
  const formValues = watch();

  const onSubmit = async (data: FeedbackGroupPayload) => {
    const sentData = { ...data, categoryId: `${data.categoryId}` } as FeedbackGroupPayload;

    try {
      await feedbackService.createGroupFeedback(sentData);
      setIsSubmittedSuccessfully(true);
      getFeedbackItems(true);
      setTimeout(() => setIsSubmittedSuccessfully(false), 2000); // reset success message after 3 seconds
      reset(
        { ...initialState, categoryId: formValues.categoryId },
        { keepValues: false, keepDirty: false, keepDefaultValues: false, keepIsSubmitted: false },
      );

      const search = document.getElementById('entity-search');
      search?.focus();
    } catch (error) {
      console.error({ error });
      openSnackbar('error', 'Error submitting group feedback');
    }
  };

  useEffect(() => {
    saveFormStateToSessionStorage(formValues);
  }, [formValues]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="description"
            control={control}
            defaultValue=""
            rules={{
              required: 'Feedback notes is required.',
            }}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                onFocus={(e) => e.target.select()}
                placeholder="Feedback notes"
                multiline
                fullWidth
                minRows={2}
                sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, minHeight: '78px', whiteSpace: 'pre-wrap', pt: 1 }}
                error={!!errors.description}
              />
            )}
          />
          {errors.description && <FormHelperText error>{errors.description.message}</FormHelperText>}
          <Stack direction="row" spacing={2} sx={{ width: '100%', pt: 2 }}>
            <FormControl required sx={{ m: { xs: 0 }, width: { xs: '100%', md: '200px' } }} size="small">
              <InputLabel id="select-label" shrink sx={{ backgroundColor: '#FFF' }}>
                Feedback Category
              </InputLabel>
              <Controller
                name="categoryId"
                control={control}
                defaultValue={undefined}
                rules={{ required: 'Feedback category is required.', pattern: { value: /[1-9]\d*$/, message: 'Feedback category is required.' } }}
                render={({ field }) => {
                  return (
                    <Select
                      labelId="select-label"
                      id="feedback-select"
                      label={'Feedback Category'}
                      {...field}
                      error={!!errors.categoryId}
                      value={initialState.categoryId}>
                      {categoryList.map((category) => (
                        <MenuItem key={category.categoryId} value={category.categoryId}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
              {errors.categoryId && <FormHelperText error>{errors.categoryId.message}</FormHelperText>}
            </FormControl>
            <SentimentSelector />
            <LoadingButton
              loading={isSubmitting}
              sx={{ height: '36px', width: '96px' }}
              variant="contained"
              color={isSubmittedSuccessfully ? 'success' : 'secondary'}
              type="submit">
              {isSubmittedSuccessfully ? <Check /> : <span>Submit</span>}
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};

export default GroupFeedbackForm;
