// Variables declared here are injected into the window object by the pipeline
// this is done to allow the pipeline to inject the variables into the client app after the build
// https://github.com/codegowhere/react-inject-env

declare global {
  interface Window {
    env: any;
  }
}
type EnvType = {
  REACT_APP_AZURE_CLIENT_ID: string;
  REACT_APP_APPLICATION_INSIGHTS: string;
  REACT_DEPLOYMENT_ENVIRONMENT: string;
};
export const env: EnvType = { ...process.env, ...window.env };
