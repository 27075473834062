import { Box, Divider, LinearProgress, List, ListItem, ListItemText, ListSubheader, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { calculateTimeDifferenceInDaysOrWeeks, getFullDate } from 'src/utilities/DateUtilities';
import { calculatePercentCompleted } from 'src/utilities/NumberUtilities';
import { checkIsLearningPathPhaseOverdue } from './LearningPathUtilities';
import { useLearningPathContext } from './context/LearningPathContext';
import { EmployeeLearningPathDTO } from 'src/Models/LearninpathModels';

interface LearningPathOverviewChecklistProps {
    selectedLearningPath: EmployeeLearningPathDTO
}

const LearningPathOverviewChecklist: React.FC<LearningPathOverviewChecklistProps> = ({ selectedLearningPath }: LearningPathOverviewChecklistProps) => {
    const { selectedLearningPathPhase } = useLearningPathContext();

    const [timeLeft, setTimeLeft] = React.useState<string>()
    const tasks = selectedLearningPath?.phases?.find(phase => phase)?.checklists;
    const totalTasks: number = tasks ? tasks.length : 0;
    const completedTasks: number = tasks ? tasks.filter((phase) => phase.isChecked === true).length : 0;
    const isPhaseOverdue = selectedLearningPathPhase && checkIsLearningPathPhaseOverdue(selectedLearningPathPhase);


    const calculateDaysOverdue = (endDate: string, startDate: string) => {
        setTimeLeft(calculateTimeDifferenceInDaysOrWeeks(startDate, endDate))
    }

    useEffect(() => {
        if (selectedLearningPath && selectedLearningPath.startDate && selectedLearningPath.endDate) {
            calculateDaysOverdue(selectedLearningPath.endDate, selectedLearningPath.startDate)
        }
    }, [selectedLearningPath])

    const percentTasksCompleted: number = tasks ? calculatePercentCompleted(completedTasks, totalTasks) : 0;

    const phasesSortedByDate: EmployeeLearningPathDTO[] = selectedLearningPath?.phases?.sort((a, b) => {
        if (a.startDate !== undefined && b.startDate !== undefined) {
            return new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf();
        }
        return -1;
    }) ?? [];

    return (
        <Box
            sx={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
            }}>
            <Box
                id="learning-path-checklist-title"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    marginBottom: '7px',
                    alignSelf: 'stretch',
                }}>
                <Typography variant="subtitle2" color={isPhaseOverdue ? '#EF6C00' : 'default'} >
                    {timeLeft}
                </Typography>
                <Typography variant="caption" color={'#646464'}>
                    {selectedLearningPathPhase?.name} checklist ({completedTasks}/{totalTasks})
                </Typography>
                <LinearProgress variant="determinate" value={percentTasksCompleted} />
            </Box>

            {phasesSortedByDate.length &&
                <List
                    sx={{
                        flexGrow: 1,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        scrollbarWidth: 'thin',

                        borderRadius: '5px',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                    }}>
                    {selectedLearningPath?.phases?.map((phase) => {
                        return phase.checklists && (
                            <>

                                <ListSubheader disableSticky>
                                    <Divider>
                                        <Typography variant='overline' color={'#000000de'}>
                                            {phase.name + ' phase'}

                                        </Typography>
                                    </Divider>

                                </ListSubheader>


                                {phase.checklists.map((item) => {
                                    return (
                                        <ListItem key={item.id} sx={{ py: 1 }}>
                                            <ListItemText
                                                primary={item.name &&
                                                    <Typography
                                                        variant='body1'
                                                        fontSize='14px'
                                                        color='#000000DE'
                                                        sx={{ textDecoration: item.isChecked ? 'line-through' : 'none' }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                }
                                                secondary={item.isChecked ? getFullDate(new Date(item.checkedDate!).toLocaleString('en-ZA')) : ''}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </>
                        )
                    })}
                </List>
            }
        </Box>
    );
};

export default LearningPathOverviewChecklist;
