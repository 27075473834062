/* Blues */
export const entelectBlue = '#133970';
export const blue50 = '#E5EAEF';
export const blue100 = '#BDC9DA';
export const blue200 = '#93A6C0';
export const blue300 = '#6984A7';
export const blue400 = '#476B97';
export const blue500 = '#1F5388';
export const blue600 = '#164B7F';
export const blue700 = '#0A4274';
export const blue800 = '#033868';
export const blue900 = '#002850';
export const blue1000 = '#043870';

/* Reds */
export const entelectRed = '#EE0202';
export const entelectDanger = '#D65745';

export const red50 = '#D71920';

/* Greys */
export const grey50 = '#F6F6F6';
export const grey100 = '#EEEEEE';
export const grey200 = '#E6E6E6';
export const grey300 = '#E1E1E1';
export const grey400 = '#DFDFDF';
export const grey500 = '#DDDDDD';
export const grey600 = '#D2D2D2';
export const grey700 = '#5D5D5D';

/* Greens */
export const entelectGreen = '#8ABB4F';
export const entelectSuccess = '#55AB68';

export const green50 = '#EEF7F0';
export const green100 = '#00984A';
export const green200 = '#2E7D32';
export const green300 = '#99C555';

/* Oranges */
export const entelectWarning = '#DA823B';

export const orange50 = '#F7931D';

/* Purples */
export const Purple50 = '#D4D0E4';
export const Purple100 = '#8479B1';
export const Purple200 = '#644DB0';
export const Purple300 = '#5300EB';
export const Purple400 = '#504AA4';

/* Pinks */
export const Pink50 = '#F286B6';
export const Pink100 = '#B41D8D';
