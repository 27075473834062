import { AxiosResponse } from 'axios';
import { forEach, isUndefined } from 'lodash';

import { BaseService } from './BaseService';
import { Employee } from './FeedbackType';

export interface Company {
  companyId: number;
  companyName: string;
  isDeleted: boolean;
  icon: string;
}
export interface UpdateCompany {
  companyId: number;
  companyName: string;
  isDeleted: boolean;
  icon?: File;
  iconURL: string;
}
export interface CreateCompany {
  companyName: string;
  icon: File;
}
export interface UpdateCertificate {
  certificateId: number;
  companyId: number;
  certificateName: string;
  isDeleted?: boolean;
  examName: string;
}
export interface CertificationDashboard {
  company: Company;
  numberOfCertifications: number;
}

export interface CreateCertificate {
  companyId: number;
  certificateName: string;
  isDeleted?: boolean;
  examName: string;
}

export interface EmployeeCertificateDTO {
  employeeCertificateId: number;
  employeeId: number;
  certificateId: number;
  isDeleted: boolean;
  status?: string;
  startDate: string;
  completedDate?: string;
  reason?: string;
  examDate?: string;
  certificateName: string;
  examName?: string;
  icon?: string;
  certificateCompany?: Company;
}

export interface CertificationsDetails {
  companyId: number;
  certificateId: number;
  companyName: string;
  certificateName: string;
  examName: string;
  numberInProgress: number;
  numberCertified: number;
  icon: string;
}

export interface CertificationCompleted {
  employeeId: number;
  employeeName: string;
  completedDate: Date;
}

export interface CertificationInProgress {
  employeeId: number;
  employeeName: string;
  startDate: Date;
}

export interface EmployeeCertificationUpdateDTO {
  employeeCertificateId: number;
  status?: string;
  completedDate: Date | null;
  reason?: string;
  examDate?: Date;
  startDate: Date;
  feedbackId?: number;
}

export interface CreateEmployeeCertificationDTO {
  employeeId: number;
  certificateId: number;
  isDeleted: boolean;
  status: string;
  startDate: Date;
  completedDate: Date | null;
  reason?: string;
  examDate?: Date;
}

export interface CertificationDashboardPayload {
  company: Company;
  numberOfCertifications: number;
}

export interface DashboardCertificationCompanyDTO {
  companyName: string;
  companyId: number;
  numberOfCertificates: number;
  certifications: DashboardCerticationDTO[];
}

export interface DashboardCerticationDTO {
  certificateId: number;
  examName: string;
  status: string;
  startDate: Date;
}

export interface EmployeeMentionCertificationList {
  employee: Employee;
  certifications: EmployeeCertificateDTO[];
}

export class CertificationService extends BaseService {
  constructor() {
    super('Certification');
  }

  async getAll(): Promise<CertificationDashboard[]> {
    return (await this.httpService.get(`/companies/dashboard`)).data;
  }

  async getCertificationsDetails(): Promise<CertificationsDetails[]> {
    return (await this.httpService.get('/certifications/details')).data;
  }

  async getCompletedCertifications(certificateId: number): Promise<CertificationCompleted[]> {
    const response: CertificationCompleted[] = (await this.httpService.get(`/certification/employees/completed/${certificateId}`)).data;
    return response.map((item) => ({ ...item, completedDate: new Date(item.completedDate) }));
  }

  async getInProgressCertifications(certificateId: number): Promise<CertificationInProgress[]> {
    const response: CertificationInProgress[] = (await this.httpService.get(`/certification/employees/inprogress/${certificateId}`)).data;
    return response.map((item) => ({ ...item, startDate: new Date(item.startDate) }));
  }

  async getCompanyById(companyId: number): Promise<Company> {
    return (await this.httpService.get(`/companies/${companyId}`)).data;
  }

  async removeCertificate(id: string | number): Promise<AxiosResponse> {
    return await this.httpService.delete(`/certificate/delete/${id}`);
  }

  async removeCompany(id: string | number): Promise<AxiosResponse> {
    return await this.httpService.delete(`/company/delete/${id}`);
  }

  async getCompanies(): Promise<CertificationDashboardPayload[]> {
    return (await this.httpService.get(`/companies/dashboard`)).data;
  }

  async getDashboardCertificateCompanies(): Promise<CertificationDashboardPayload[]> {
    return (await this.httpService.get(`/dashboard`)).data;
  }

  async addCertification(model: CreateCertificate): Promise<any> {
    return (await this.httpService.post(`/certificates`, model)).data;
  }

  async addCertificationCompany(model: CreateCompany): Promise<any> {
    var data = new FormData();
    forEach(model, (value, key) => {
      data.append(key, value);
    });

    return (await this.httpService.post(`/companies`, data)).data;
  }

  async addEmployeeCertification(model: CreateEmployeeCertificationDTO): Promise<any> {
    return (await this.httpService.post(`/employee-certificates`, model)).data;
  }

  async updateCompany(model: UpdateCompany): Promise<any> {
    var data = new FormData();
    forEach(model, (value, key) => {
      if (key === 'icon') {
        data.append(key, value as File);
      } else {
        data.append(key, isUndefined(value) ? '' : value.toString());
      }
    });

    return (await this.httpService.patch('/company/update', data)).data;
  }

  async updateEmployeeCertification(model: EmployeeCertificationUpdateDTO): Promise<any> {
    return (await this.httpService.patch('/employee-certification/update', model)).data;
  }

  async updateCertificate(model: UpdateCertificate): Promise<any> {
    return (await this.httpService.patch('/certification/update', model)).data;
  }

  async getMentionsCertifications(squadId: number): Promise<EmployeeMentionCertificationList[]> {
    return (await this.httpService.get(`/employee-mention-certifications/${squadId}`)).data;
  }

  async getCertificateById(certificateId: number): Promise<CertificationsDetails> {
    return (await this.httpService.get(`/certificate/${certificateId}`)).data;
  }

  async getCertificatesByEmployeeId(employeeId: number): Promise<EmployeeCertificateDTO[]> {
    return (await this.httpService.get(`/certificates/${employeeId}`)).data;
  }

  async getCompanyByCertification(certificateId: Number): Promise<Company> {
    return (await this.httpService.get(`/company/${certificateId}`)).data;
  }

  async getCertificationCompanyDashboard(
    startDate: Date | null,
    endDate: Date | null,
    showAllEmployees: boolean,
  ): Promise<DashboardCertificationCompanyDTO[]> {
    const params = new URLSearchParams();

    if (startDate) {
      params.append('startDate', new Date(startDate).toISOString());
    }

    if (endDate) {
      params.append('endDate', new Date(endDate).toISOString());
    }
    const queryString = params.toString();

    const url = queryString ? `/dashboard/overview/${showAllEmployees}?${queryString}` : `/dashboard/overview/${showAllEmployees}`;
    return (await this.httpService.get(url)).data;
  }
}
