import { useEffect, useRef, useState } from 'react';
import { useEntityContext } from '../../../context/EntityContext';
import { useCategoryContext } from '../../../context/CategoryContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import useIsMobile from 'src/hooks/useIsMobile';
import { FeedbackGroup } from 'src/services/FeedbackService';
import LoaderPage from '../LoaderPage';
import React from 'react';
import { Box, Divider, Grow, ListItem, Stack } from '@mui/material';
import FilterComponent from './FilterComponent';
import { TransitionGroup } from 'react-transition-group';
import FeedbackCard from '../FeedbackCard';
import { useFeedbackContext } from '../../../context/FeedbackContext';

interface GroupFeedbackHistoryProps { }

const GroupFeedbackHistory = (props: GroupFeedbackHistoryProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { group } = useEntityContext();

  const {
    displayedFeedbackItems,
    setDisplayedFeedbackItems,
    feedbackItems,
    getFeedbackItems,
    feedbackStatus,
    loadMoreItems,
    isInitialPageLoad,
    setIsInitialPageLoad,
    onCategoryUpdate,
    isLoadingData,
  } = useFeedbackContext();
  const { categoryList } = useCategoryContext();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (group) getFeedbackItems();
  }, [group]);

  useEffect(() => {
    setDisplayedFeedbackItems(filterFeedbackByCategoryName(feedbackItems, selectedCategory));
  }, [selectedCategory, feedbackItems]);

  useEffect(() => {
    if (scrollContainerRef.current && !isEmpty(feedbackItems)) {
      const storedScrollPosition = sessionStorage.getItem(`scrollPosition-${group?.groupId}`);

      if (storedScrollPosition) {
        scrollContainerRef.current.scrollTop = Number(storedScrollPosition);
      }
    }
  }, [feedbackItems]);

  const scrollFeedbackId = searchParams.get('feedbackId');
  const navigate = useNavigate();

  useEffect(() => {
    if (feedbackItems?.length === 0 || !scrollFeedbackId) return;

    const scrollIntoView = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const element = document.getElementById(`feedbackId-${scrollFeedbackId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });

        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.delete('feedbackId');
        navigate(`?${updatedSearchParams.toString()}`, { replace: true });
      }
    };

    scrollIntoView();
  }, [feedbackItems, scrollFeedbackId, searchParams, navigate]);

  const handleScroll = () => {
    if (scrollContainerRef.current && group && !isEmpty(feedbackItems)) {
      sessionStorage.setItem(`scrollPosition-${group.groupId}`, String(scrollContainerRef.current.scrollTop));

      const container = scrollContainerRef.current;
      const scrollPosition = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      if (scrollPosition + clientHeight >= scrollHeight) {
        loadMoreItems();
      }
    }
  };

  const isMobile = useIsMobile();

  const filterFeedbackByCategoryName = (feedback: FeedbackGroup[], categoryId: number): FeedbackGroup[] => {
    switch (categoryId) {
      case -1:
        return feedback?.map((f) => ({ ...f, feedback: f.feedback.filter((item) => item.followUp) }));
      case 0:
        return feedback;
      default:
        return feedback?.map((f) => ({ ...f, feedback: f.feedback.filter((item) => item.category.categoryId === categoryId) }));
    }
  };

  useEffect(() => {
    setDisplayedFeedbackItems(filterFeedbackByCategoryName(feedbackItems, selectedCategory));
  }, [feedbackItems]);

  if (isInitialPageLoad && feedbackStatus === 'success') {
    setIsInitialPageLoad(false);
  }

  if (isInitialPageLoad) return <LoaderPage />;

  const onCategorySelect = (item: number) => {
    setSelectedCategory(item);
    onCategoryUpdate(item);
  };

  return (
    <>
      <Divider sx={{ mt: 2 }} />
      <FilterComponent
        selectedCategory={selectedCategory}
        feedbackItems={feedbackItems}
        handleSelect={onCategorySelect}
      />
      {isLoadingData ? (
        <LoaderPage hideFilterPart={true} />
      ) : (
        <Box ref={scrollContainerRef} sx={{ overflow: 'auto' }} onScroll={handleScroll} id="feedback-container">
          {displayedFeedbackItems?.map((feedbackItem, index) => {
            return (
              <Stack spacing={3} key={`${feedbackItem.createdDate}-${index}`} sx={{ marginY: { xs: '4px' } }}>
                {!isMobile && feedbackItem?.feedback?.length > 0}
                <TransitionGroup sx={{ width: '100%' }}>
                  {feedbackItem.feedback?.map((item) => (
                    <Grow key={item.feedbackId}>
                      <ListItem key={item.feedbackId} sx={{ padding: '2px', mt: '4px' }}>
                        <FeedbackCard feedbackItem={item} categoryList={categoryList} displayEmployeeDetail={false} />
                      </ListItem>
                    </Grow>
                  ))}
                </TransitionGroup>
              </Stack>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default GroupFeedbackHistory;
