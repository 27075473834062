import { BaseService } from "./BaseService"

export class SquadLeaderService extends BaseService {
  constructor() {
    super('SquadLeader')
  }

  async editSquadLeader(selectedLeader, fullName, emailAddress, isActive, permissions, isTenantManager) {
    const payload = { squadLeaderId: selectedLeader.squadLeaderId, fullName, emailAddress, isActive, permissions, isTenantManager }
    return (await this.httpService.put(``, payload)).data;
  }

  async updateSquadLeaderDefaultCategory(categoryId, squadId) {
    const payload = { categoryId, squadId }

    return (await this.httpService.patch(``, payload)).data;
  }

  async getSquadLeadersBySquadId(squadId) {
    return (await this.httpService.get(`/${squadId}`)).data;
  }
}