import Editor, { PluginEditorProps } from '@draft-js-plugins/editor';
import PluginEditor from '@draft-js-plugins/editor/lib/Editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import { ContentState, EditorState, Entity, Modifier, SelectionState } from 'draft-js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CertificationService, CertificationsDetails, EmployeeCertificateDTO } from 'src/services/CertificationService';
import { LearningPathFallBackService } from 'src/services/LearningPathFallBackService';
import useSwr from 'swr';

import { useEntityContext } from '../feedbackComponent/context/EntityContext';
import CreateCertificationModal from '../manageCertificates/modals/CreateCertificationModal';
import useSuggestions, { CERTIFICATION_TAG_COLOUR, DEFAULT_TAG_TYPE, MentionComponent, useStyles } from './MentionsShared';
import './mention-styles.css';

const certificationService = new CertificationService();

type MentionsProps = {
  feedbackId?: number;
  onEditorStateChange: (editorState: EditorState) => void;
  onChange: (e: string) => void;
  textToAdd: string;
  setResetEditorState: (reset: boolean) => void;
  resetEditorState: boolean;
} & Partial<PluginEditorProps>;

const findAllStartIndexes = (text: string, word: string) => {
  let startIndexes = [];
  for (let i = 0; (i = text.indexOf(word, i)) !== -1; i++) {
    startIndexes.push(i);
    i++;
  }
  return startIndexes;
};

export const EditFeedbackMentions: React.FC<MentionsProps> = ({ onEditorStateChange, textToAdd, feedbackId, ...props }) => {
  const ref = useRef<PluginEditor>(null);
  const { classes } = useStyles();

  const { employee, setFocus } = useEntityContext();
  const learningPathService = new LearningPathFallBackService();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [hasAddedEditingText, setHasAddedEditingText] = useState(false);

  const employeeId = employee?.employeeId;

  const [employeeCertification, setEmployeeCertification] = useState<EmployeeCertificateDTO>();
  const [certification, setCertification] = useState<CertificationsDetails | null>(null);

  const fetchCertificates = async () => certificationService.getCertificatesByEmployeeId(employee?.employeeId!);
  const fetchLearningPaths = async () => await learningPathService.getEmployeeLearningPaths(employee?.employeeId);

  const { data: certifications, mutate } = useSwr('getAllCertifications', async () => await certificationService.getCertificationsDetails(), {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });
  const { data: employeeCertificates } = useSwr(employeeId ? ['mentions', employeeId] : undefined, fetchCertificates);

  const { data: employeeLearningPaths } = useSwr(employeeId ? ['getApiLearningPaths', employeeId] : undefined, fetchLearningPaths);

  const { tags } = useEntityContext();

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: '#',
      mentionPrefix: '#',
      mentionComponent: MentionComponent,
      entityMutability: 'IMMUTABLE',
    });
    return { plugins: [mentionPlugin], MentionSuggestions: mentionPlugin.MentionSuggestions };
  }, []);

  React.useEffect(() => {
    if (!certifications) mutate();
  }, [certifications, mutate]);

  const { mentions, suggestions, open, onOpenChange, onSearchChange } = useSuggestions(
    certifications,
    employeeCertificates,
    tags,
    employeeLearningPaths,
  );

  const handleEditorChange = useCallback(
    (newEditorState: EditorState) => {
      setEditorState(newEditorState);
      props.onChange(newEditorState.getCurrentContent().getPlainText().trim());
    },
    [onEditorStateChange],
  );

  useEffect(() => {
    if (mentions?.length > 0) {
      ref.current?.focus();
    }
    setFocus(false);
  }, [ref]);

  const handleClose = useCallback(() => setCertification(null), []);

  useEffect(() => {
    if (!hasAddedEditingText && suggestions.length > 0) {
      const currentEditor = ref.current?.getEditorState();
      if (currentEditor && textToAdd) {
        const newContentState1 = ContentState.createFromText(textToAdd);
        let newEditorState = currentEditor;
        newEditorState = EditorState.createWithContent(newContentState1, newEditorState.getDecorator());
        const tagsToMatch = tags?.map((tag) => tag.tags).flatMap((tag) => tag);
        const certsToMatch = certifications?.map((cert) => ({
          tagName: cert.examName + ' - ' + cert.certificateName,
          tagId: cert.certificateId + 1000,
          tagColor: CERTIFICATION_TAG_COLOUR,
        }));

        const learningPathsToMatch = employeeLearningPaths?.map((learnigPath: any) => ({
          tagId: learnigPath.id,
          tagName: `${learnigPath.name} - ${learnigPath.phases?.filter((phase: any) => phase.isActive).map((phase: any) => phase.name)}`,
          tagColor: CERTIFICATION_TAG_COLOUR,
        }));

        [...(tagsToMatch ? tagsToMatch : []), ...(certsToMatch ? certsToMatch : []), ...(learningPathsToMatch ? learningPathsToMatch : [])]?.forEach(
          (tag: any) => {
            const startIndexes = findAllStartIndexes(textToAdd, tag.tagName);
            if (startIndexes.length === 0) return;
            startIndexes.forEach((startIndex) => {
              const currentContentState = newEditorState.getCurrentContent();
              const entityKey = Entity.create('#mention', 'IMMUTABLE', {
                mention: {
                  id: tag.tagId,
                  name: tag.tagName,
                  avatar: 'https://dread0devstorage.blob.core.windows.net/dread0devblob/Icons/skillsIcon.png',
                  type: DEFAULT_TAG_TYPE,
                  color: tag.tagColor,
                },
              });
              const newContentState = Modifier.replaceText(
                currentContentState,
                new SelectionState({
                  anchorKey: currentContentState.getFirstBlock().getKey(),
                  anchorOffset: startIndex - 1,
                  focusKey: currentContentState.getFirstBlock().getKey(),
                  focusOffset: startIndex + tag.tagName.length + 1,
                }),
                '#' + tag.tagName,
                undefined,
                entityKey,
              );
              const contentStateWithSpace = Modifier.insertText(newContentState, newContentState.getSelectionAfter(), ' ');
              newEditorState = EditorState.push(newEditorState, contentStateWithSpace, 'insert-characters');
            });
          },
        );
        setEditorState(newEditorState);
        setHasAddedEditingText(true);
      }
    }
  }, [textToAdd, suggestions]);

  const appendCertificationDetailsToEditor = useCallback((text: string) => {
    const currentEditor = ref.current?.getEditorState();
    if (currentEditor) {
      const currentContent = currentEditor.getCurrentContent();
      const blockMap = currentContent.getBlockMap();
      const key = blockMap.last().getKey();
      const length = blockMap.last().getLength();
      const selection = new SelectionState({
        anchorKey: key,
        anchorOffset: length,
        focusKey: key,
        focusOffset: length,
      });
      const textWithInsert = Modifier.insertText(currentContent, selection, text);
      const editorWithInsert = EditorState.push(currentEditor, textWithInsert, 'insert-characters');
      const newEditorState = EditorState.moveFocusToEnd(editorWithInsert);
      setEditorState(newEditorState);
    }
  }, []);

  const onEditorClick = (): void => {
    ref.current?.focus();
  };

  useEffect(() => {
    if (props.resetEditorState) {
      setEditorState(EditorState.createEmpty());
      props.setResetEditorState(false);
    }
  }, [props.resetEditorState]);

  return (
    <div key={'editor container'} className={classes.editor} onClick={onEditorClick}>
      <Editor
        ref={ref}
        editorKey={'editor'}
        editorState={editorState}
        onChange={handleEditorChange}
        plugins={plugins}
        placeholder="Feedback notes"
        preserveSelectionOnBlur={true}
      />
      {mentions && (
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={(e) => {
            if (e.type === 'certification') {
              setCertification(certifications?.find((cert) => cert.certificateId === e.id) ?? null);
              setEmployeeCertification(employeeCertificates?.find((cert) => cert.certificateId === e.id));
            }
          }}
        />
      )}
      {certification && (
        <CreateCertificationModal
          employeeId={employeeId ?? 0}
          employeeCertificate={employeeCertification}
          onClose={handleClose}
          certification={certification}
          handleSubmitted={(e) => appendCertificationDetailsToEditor(e)}
          feedbackId={feedbackId}
        />
      )}
    </div>
  );
};
