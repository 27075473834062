import { Check } from '@mui/icons-material';
import LoadingButtonMui, { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import React from 'react';
import Hotkeys from '../feedbackComponent/hotkeys/Hotkeys';
const ctrlEnterHotkey = require('../feedbackComponent/hotkeys/images/ctrlenter.png');

interface LoadingButtonProps extends MuiLoadingButtonProps {
  isSubmitting: boolean;
  isSubmittedSuccessfully: boolean;
  label: string;
}

const LoadingButton = ({ isSubmittedSuccessfully, isSubmitting, label, ...rest }: LoadingButtonProps) => {
  return (
    <Hotkeys text="Submit" imageSrc={ctrlEnterHotkey}>
      <LoadingButtonMui
        loading={isSubmitting}
        size='small'
        variant="contained"
        color={isSubmittedSuccessfully ? 'success' : 'secondary'}
        {...rest}>
        {isSubmittedSuccessfully ? <Check /> : <span>{label}</span>}
      </LoadingButtonMui>
    </Hotkeys>
  );
};

export default LoadingButton;
