import { Box } from '@mui/material';
import { grey, lightBlue, lightGreen, orange, red } from '@mui/material/colors';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Legend, Tooltip, ChartData, ChartOptions } from 'chart.js';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { DashboardCerticationDTO, DashboardCertificationCompanyDTO } from 'src/services/CertificationService';

import { useFilterContext } from '../context/FilterContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Legend, Tooltip);

interface CertificationOverviewBarChartProps {
  certificationData: DashboardCertificationCompanyDTO[] | undefined;
}

const CertificationOverviewChart: React.FC<CertificationOverviewBarChartProps> = (props: CertificationOverviewBarChartProps) => {
  const { certificationData } = props;

  const [chartData, setChartData] = useState<ChartData<'bar'>>({ labels: [], datasets: [] });

  const { certificationFilterRequest, setCertificationFilterRequest } = useFilterContext();

  const getInitialChartData = (data: DashboardCertificationCompanyDTO[] | undefined): ChartData<'bar'> => {
    if (!data) return { labels: [], datasets: [] };

    const labels = data.map((item) => item.companyName);
    const datasets = [
      {
        data: data.map((item) => {
          return item.numberOfCertificates;
        }),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderWidth: 1,
      },
    ];

    return { labels, datasets };
  };

  const getCompanyChartData = (certifications: DashboardCerticationDTO[]): ChartData<'bar'> => {
    const labels = [...new Set(certifications.map((cert) => cert.examName))];

    const statusCounts: { [key: string]: { [key: string]: number } } = {
      inprogress: {},
      completed: {},
      abandoned: {},
      failed: {},
    };

    certifications.forEach((cert) => {
      const status = cert.status.toLowerCase().replace(' ', '');
      const examName = cert.examName;

      if (!statusCounts[status][examName]) {
        statusCounts[status][examName] = 0;
      }
      statusCounts[status][examName]++;
    });

    return {
      labels,
      datasets: Object.keys(statusCounts).map((status) => ({
        label: status.charAt(0).toUpperCase() + status.slice(1),
        data: labels.map((label) => statusCounts[status][label] || 0),
        backgroundColor: getStatusColor(status),
        borderWidth: 1,
      })),
    };
  };

  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'inprogress':
        return lightBlue[500];
      case 'completed':
        return lightGreen[500];
      case 'abandoned':
        return orange[500];
      case 'failed':
        return red[500];
      default:
        return grey[500];
    }
  };

  const handleClick = (event: any, elements: any) => {
    if (elements.length > 0) {
      const element = elements[0];
      const index = element.index;
      const label = chartData.labels ? chartData.labels[index] : null;

      if (certificationData && label && !certificationFilterRequest.companyId) {
        const company = certificationData.find((item) => item.companyName === label);
        if (company) {
          setCertificationFilterRequest({ companyId: company.companyId });
          setChartData(getCompanyChartData(company.certifications));
        }
      } else if (certificationFilterRequest.companyId && label) {
        const company = certificationData?.find((item) => item.companyId === certificationFilterRequest.companyId);
        const certification = company?.certifications.find(
          (cert) =>
            cert.examName === label &&
            cert.status.replace(' ', '').toLowerCase() === chartData.datasets[element.datasetIndex].label?.replace(' ', '').toLowerCase(),
        );
        if (certification) {
          setCertificationFilterRequest({
            companyId: certificationFilterRequest.companyId,
            certificationId: certification.certificateId,
            certificationStatus: certification.status,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (certificationData && isEmpty(certificationFilterRequest)) setChartData(getInitialChartData(certificationData));
  }, [certificationData, certificationFilterRequest]);

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: !isEmpty(certificationFilterRequest),
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    onClick: handleClick,
  };

  return (
    <Box id="certification-overview-chart">
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default CertificationOverviewChart;
