import { BaseService } from "./BaseService"

export class SquadService extends BaseService {
  constructor() {
    super('Squads')
  }

  async addLeader(squadId, userId, isAdmin, isSuperUser) {
    const payload = { squadId, userId, isAdmin, isSuperUser }
    return await this.httpService.post(`/AddSquadLeader`, payload);
  }

  async addUser(squadId, fullName, emailAddress, isActive, permissions, isTenantManager) {
    const payload = { userId: 0, fullName, emailAddress, isActive, permissions, isTenantManager }
    return await this.httpService.post(`/AddUserAsSquadLeader/${squadId}`, payload);
  }
  async editSquadLeader(squadLeaderId, squadId, userId, isAdmin, isSuperUser) {
    const payload = { squadLeaderId, squadId, userId, isAdmin, isSuperUser }
    return await this.httpService.patch(`/UpdateSquadLeader`, payload);
  }

  async editSquad(squad) {
    return await this.httpService.patch(``, squad);
  }

  async getSquadsForCurrentUser() {
    return (await this.httpService.get(`/squad/getSquadsByUserEmail`)).data;
  }

  async removeLeader(squadLeader) {
    if (!squadLeader) return;
    return await this.httpService.patch(`/RemoveSquadLeader/${squadLeader.squadLeaderId}`, {});
  }
}