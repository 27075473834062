import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import * as React from 'react';

import * as customColorsClass from '../../utilities/customColors';

const Sentiment = (props: any) => {
  const renderContent = useCallback(() => {
    if (props.sentiment.sentimentId) {
      switch (props.sentiment.description.toLowerCase()) {
        case 'green':
          return (
            <SentimentSatisfiedAltIcon
              style={{ color: customColorsClass.entelectSuccess, fontSize: props.sentiment.fontSize ? props.sentiment.fontSize : '2rem' }}
            />
          );

        case 'amber':
          return (
            <SentimentNeutralIcon
              style={{ color: customColorsClass.entelectWarning, fontSize: props.sentiment.fontSize ? props.sentiment.fontSize : '2rem' }}
            />
          );

        case 'red':
          return (
            <SentimentVeryDissatisfiedIcon
              style={{ color: customColorsClass.entelectDanger, fontSize: props.sentiment.fontSize ? props.sentiment.fontSize : '2rem' }}
            />
          );

        default:
          return null;
      }
    }
    return null;
  }, [props.sentiment]);

  return <IconButton disabled={true}>{renderContent()}</IconButton>;
};

export default Sentiment;
