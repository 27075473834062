import { Box } from '@mui/material';
import { ArcElement, Chart, ChartOptions, Tooltip } from 'chart.js';
import React, { useContext, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { TagDashboardGraphModel } from 'src/Models/TagModels';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { useDashboardContext } from '../context/DashboardContext';
import { useFilterContext } from '../context/FilterContext';

interface TagChartProps {
  tagData: TagDashboardGraphModel[];
}

const employeeService = new EmployeeService();

const TagChart: React.FC<TagChartProps> = ({ tagData }) => {
  const getDistinctTags = (data: TagDashboardGraphModel[]) => {
    return data.reduce((acc: TagDashboardGraphModel[], current: TagDashboardGraphModel) => {
      const existingTag = acc.find((tag) => tag.tagName === current.tagName);
      if (!existingTag) {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  const { setEmployees, setIsLoading, getAllEmployees } = useDashboardContext();
  const { squadId } = useContext(SquadsContext).squad;
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | null>(null);
  const { showAllEmployees, startDate, endDate } = useFilterContext();

  const distinctTagData = getDistinctTags(tagData);

  Chart.register(ArcElement, Tooltip);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const amounts = distinctTagData.map((tag) => tag.amountTagged);
  const colors = distinctTagData.map((_, index) => (index === selectedBarIndex ? 'grey' : getRandomColor()));
  const names = distinctTagData.map((tag) => tag.tagName);

  const chartData = {
    labels: names,
    datasets: [
      {
        data: amounts,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        if (selectedBarIndex === index) {
          setSelectedBarIndex(null);
          getAllEmployees();
        } else {
          setSelectedBarIndex(index);
          const clickedTag = distinctTagData[index];
          getActiveEmployees(clickedTag.tagId);
        }
      }
    },
  };

  const getActiveEmployees = async (tagId: number) => {
    setIsLoading(true);
    const response = await employeeService.GetActiveEmployeesBySelectedTagId(
      +squadId,
      tagId,
      showAllEmployees,
      startDate ? startDate : null,
      endDate ? endDate : null,
    );
    setEmployees(response);
    setIsLoading(false);
  };

  return (
    <Box id="top-five-tags-chart" alignSelf="center" sx={{ width: '100%' }}>
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default TagChart;
