import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'src/components/shared/SnackbarContext';

export type AsyncStatus = 'idle' | 'pending' | 'success' | 'error' | 'mutating';
export const useAsync = <T, E = unknown>(asyncFunction: () => Promise<T>, immediate = true, errorNotificationMessage = '') => {
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const [value, setValue] = useState<unknown>();
  const [error, setError] = useState<unknown>();
  const { openSnackbar } = useSnackbar();
  const execute = useCallback(
    (silent: boolean = false) => {
      if (!silent) {
        setStatus('pending');
      }
      setError(undefined);
      return asyncFunction()
        .then((response: unknown) => {
          setValue(response as T);
          setStatus('success');
        })
        .catch((error: unknown) => {
          setError(error as E);
          setStatus('error');
          !isEmpty(errorNotificationMessage) && openSnackbar('error', errorNotificationMessage);
        });
    },
    [asyncFunction],
  );

  const mutate = useCallback(
    (newValue: T) => {
      const previousValue = value;
      setStatus('mutating');
      setValue(newValue);
      execute(true).catch(() => {
        // rollback to the previous value in case of error
        setValue(previousValue);
      });
    },
    [value, execute],
  );

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate, asyncFunction]);

  const memoizedStatus = useMemo(() => status, [status]);
  const memoizedValue = useMemo(() => value as T, [value]);
  const memoizedError = useMemo(() => error as E, [error]);

  return { execute, mutate, status: memoizedStatus, value: memoizedValue, error: memoizedError };
};
