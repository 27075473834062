import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DashboardEmployee } from 'src/Models/EmployeeModels';

import SearchBar from '../SearchBar';
import AssignToSquadModal from '../clientManagementDashboard/AssignToSquadModal';
import EmployeeOverviewTableRow from '../dashboardShared/EmployeeOverviewTableRow';
import { NarrowTableCell } from '../dashboardShared/NarrowTableCell';
import FeedbackExportModal from '../feedbackComponent/feedback/history/components/FeedbackExportModal';
import ColumnHeader from '../shared/ColumnHeader';
import EmployeeDetailFilter from '../shared/EmployeeDetailFilter';
import { useDashboardContext } from './context/DashboardContext';
import { useFilterContext } from './context/FilterContext';

const TableArea: React.FC = () => {
  const [employeesToAddToGroup, setEmployeesToAddToGroup] = useState<DashboardEmployee[]>([]);
  const [sortByDirection, setSortByDirection] = useState<SortDirection>('asc');
  const [sortByOption, setSortByOption] = useState({
    value: 'fullName',
    name: undefined,
  });
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [squadModalOpen, setSquadModalOpen] = useState(false);
  const [filterBarOpen, setFilterBarOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentItems, setCurrentItems] = useState<DashboardEmployee[]>();
  const navigate = useNavigate();
  const { showAllEmployees } = useFilterContext();

  const { employees, isLoading } = useDashboardContext();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleDetailFilterStatusChange = (status: boolean) => {
    setIsFilterApplied(status);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setCurrentItems(sortEmployees().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [sortByOption, sortByDirection, showAllEmployees, employees, page]);

  useEffect(() => {
    if (!isEmpty(searchTerm)) {
      const filteredItems = currentItems!.filter((item) => item.fullName.toLowerCase().includes(searchTerm.toLowerCase()));
      setCurrentItems(filteredItems);
    } else {
      setCurrentItems(sortEmployees().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }
  }, [searchTerm]);

  const getSortDirection = (key: string) => (key === sortByOption.value ? sortByDirection : false);

  const handleSortClicked = (option: string) => {
    if (option === sortByOption.value) {
      setSortByDirection((previousSortDirection) => {
        return previousSortDirection === 'asc' ? 'desc' : 'asc';
      });
    } else {
      setSortByOption((previousSortByOption) => {
        return { ...previousSortByOption, value: option };
      });
      setSortByDirection('asc');
    }
  };

  const sortEmployees: any = () => {
    let filtered: any = employees;
    if (sortByOption.value) {
      switch (sortByOption.value) {
        case 'status':
          filtered.sort((a: any, b: any) => {
            const statusComparison = sortByDirection === 'asc' ? 1 : -1;
            if (a.status.sortIndex !== b.status.sortIndex) {
              return (a.status.sortIndex > b.status.sortIndex ? 1 : -1) * statusComparison;
            } else {
              return a.fullName.localeCompare(b.fullName);
            }
          });
          break;
        case 'daysOnSquad':
          filtered.sort(function (a: any, b: any) {
            const daysComparison = sortByDirection === 'asc' ? 1 : -1;
            if (a.daysOnSquad.days !== b.daysOnSquad.days) {
              return (a.daysOnSquad.days - b.daysOnSquad.days) * daysComparison;
            } else {
              return a.fullName.localeCompare(b.fullName);
            }
          });
          break;
        case 'title':
          filtered.sort((a: any, b: any) => {
            const titleComparison = sortByDirection === 'asc' ? 1 : -1;
            if (a.jobTitle.title !== b.jobTitle.title) {
              return (a.jobTitle.title > b.jobTitle.title ? 1 : -1) * titleComparison;
            } else {
              return a.fullName.localeCompare(b.fullName);
            }
          });
          break;
        case 'latestFeedbackCategory':
          filtered.sort((a: any, b: any) => {
            const latestFeedbackCategoryComparison = sortByDirection === 'asc' ? 1 : -1;
            if (a.latestFeedbackCategory !== b.latestFeedbackCategory) {
              return (a.latestFeedbackCategory > b.latestFeedbackCategory ? 1 : -1) * latestFeedbackCategoryComparison;
            } else {
              return a.fullName.localeCompare(b.fullName);
            }
          });
          break;
        default:
          filtered.sort((a: any, b: any) => {
            const fullNameComparison = sortByDirection === 'asc' ? 1 : -1;
            return a.fullName.localeCompare(b.fullName) * fullNameComparison;
          });
          break;
      }
    }
    return filtered;
  };

  const handleAddEmployee = () => {
    navigate('/add-employee', { replace: true, state: { fromManage: false } });
  };

  const handleOpenFeedbackModal = () => {
    setFeedbackModalOpen(!feedbackModalOpen);
  };

  const handleAssignToSquad = () => {
    setSquadModalOpen(!squadModalOpen);
  };

  const handleOpenSquadModal = () => {
    setSquadModalOpen(!squadModalOpen);
  };

  const handleOpenFilterPanel = () => {
    setFilterBarOpen(!filterBarOpen);
  };

  const handleCheckBoxChecked = (employee: DashboardEmployee) => {
    setEmployeesToAddToGroup((prev = []) => (prev.includes(employee) ? prev.filter((id) => id !== employee) : [...prev, employee]));
  };

  const resetSelectedEmployees = () => {
    setEmployeesToAddToGroup([]);
  };

  return (
    <>
      <EmployeeDetailFilter
        drawerOpen={filterBarOpen}
        employeeList={employees}
        closeFilterComponent={handleOpenFilterPanel}
        onFilterChange={handleDetailFilterStatusChange}
        isIncommingTab={false}
      />
      <Grid container id="table-controls">
        <Grid item id="main-controls" lg={12} rowGap={1}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} lg={6}>
              <Grid container id="search-controls" gap="6px" alignItems="center">
                <Grid item xs={12} sm={'auto'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <SearchBar
                    sx={{ width: '300px' }}
                    id="entity-search"
                    placeholder={'Search employees...'}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    onFocus={(e) => e.target.select()}
                    fullWidth
                  />
                  <FilterAltIcon
                    fontSize="large"
                    sx={{
                      marginLeft: '40px',
                      color: isFilterApplied ? 'lightBlue' : 'gray',
                      cursor: 'pointer',
                    }}
                    onClick={handleOpenFilterPanel}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid container justifyContent="flex-end" alignItems="center" sx={{ padding: '6px' }}>
                <Grid item xs={12} lg={6}>
                  <Grid container justifyContent="flex-end" alignItems="center" sx={{ padding: '6px' }}>
                    <Button onClick={handleAssignToSquad} variant="outlined" startIcon="+" disabled={employeesToAddToGroup.length < 1}>
                      Assign To Squad
                    </Button>
                  </Grid>
                </Grid>
                <Button onClick={handleAddEmployee} variant="outlined" startIcon="+">
                  Add Employee
                </Button>
                <Grid item sx={{ display: 'flex', alignItems: 'center', marginRight: '20px', pl: '10px' }}>
                  <Grid container alignItems="center" component={Link} to={''} onClick={handleOpenFeedbackModal} style={{ textDecoration: 'none' }}>
                    <FileDownloadSharpIcon />
                    <span style={{ paddingLeft: '8px' }}>EXPORT FEEDBACK</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid id="employee-overview-table" container>
        <Grid item width={'100%'}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <NarrowTableCell></NarrowTableCell>
                  <NarrowTableCell></NarrowTableCell>
                  <NarrowTableCell></NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Name"
                      display={'flex'}
                      justifyContent={'flex-start'}
                      tooltipText="Order by Name"
                      sx={{ pl: 0 }}
                      columnKey="fullName"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Title"
                      display={'flex'}
                      justifyContent={'flex-start'}
                      tooltipText="Order by job title"
                      sx={{ pl: 0 }}
                      columnKey="title"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader title="Squad" columnKey="squad" />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="TFO time"
                      tooltipText="Order by days on the team"
                      columnKey="daysOnSquad"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Today's feedback"
                      tooltipText="Order by Today's feedback"
                      columnKey="latestFeedbackCategory"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                  <NarrowTableCell>
                    <ColumnHeader
                      title="Status"
                      tooltipText="Order by status"
                      columnKey="status"
                      onClick={handleSortClicked}
                      sortDirection={getSortDirection}
                    />
                  </NarrowTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading || !currentItems ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  currentItems.map((employee: any) => (
                    <EmployeeOverviewTableRow
                      key={employee.employeeId}
                      employee={employee}
                      handleCheckBoxChecked={handleCheckBoxChecked}
                      selectedEmployees={employeesToAddToGroup}
                    />
                  ))
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={employees.length}
              page={page}
              showFirstButton
              showLastButton
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <Modal
        open={feedbackModalOpen}
        onClose={handleOpenFeedbackModal}
        sx={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Paper sx={{ p: '40px' }}>
          <FeedbackExportModal />
        </Paper>
      </Modal>
      <AssignToSquadModal
        open={squadModalOpen}
        onClose={handleOpenSquadModal}
        selectedEmployees={employeesToAddToGroup}
        resetSelectedEmployees={resetSelectedEmployees}
        handleCheckBoxChecked={handleCheckBoxChecked}
      />
    </>
  );
};

export default TableArea;
