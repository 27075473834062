import { AlertProps } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useAsync } from 'src/hooks/useAsync';
import { EmployeeService } from 'src/services/EmployeeService';
import { Employee } from 'src/services/FeedbackType';
import { SquadGroupService, SquadLeader } from 'src/services/SquadGroupService';
import { SquadLeaderService } from 'src/services/SquadLeaderService';
import { GenericErrorMessage } from 'src/shared/GenericMessages';
import { SquadsContext } from 'src/utilities/SquadsContext';
import { SnackbarContext } from '../shared/SnackbarContext';

import ModalForm from './ModalForm';

interface EditProps {
  squadId: number;
  groupId: number;
  isActive: boolean;
  groupName: string;
  groupOwner: SquadLeader;
  groupLeaders: SquadLeader[];
  groupMembers: Employee[];
}

interface CreateProps {
  squadId: number;
  groupName: string;
  groupOwner: SquadLeader;
  groupLeaders: SquadLeader[];
  groupMembers: Employee[];
}
export const editSquadGroupService = async (payload: EditProps) => {
  const squadGroupService = new SquadGroupService();
  return squadGroupService.edit(payload);
};

export const createSquadGroupService = async (payload: CreateProps, snackbar: (severity: AlertProps['severity'], message: string) => void) => {
  const squadGroupService = new SquadGroupService();
  return squadGroupService
    .create(payload)
    .then(function () {
      snackbar('success', 'Group successfully created');
    })
    .catch(function () {
      snackbar('error', GenericErrorMessage);
    });
};

interface Props {
  openCreateGroupModal: boolean;
  handleCloseCreateGroupModal: () => void;
  selectedGroup?: any;
  handleSuccess: () => void;
}
const CreateSquadGroupModal: React.FC<Props> = (props) => {
  const { handleCloseCreateGroupModal, openCreateGroupModal, selectedGroup, handleSuccess } = props;
  const { squad } = useContext(SquadsContext);
  const editMode = selectedGroup !== null;

  const squadGroupService = new SquadGroupService();
  const squadLeaderService = new SquadLeaderService();
  const employeeService = new EmployeeService();

  const { value: groups, execute: groupExecute } = useAsync<any[]>(async () => squadGroupService.getSquadGroupsBySquadId(squad.squadId), false);
  const { openSnackbar } = useContext(SnackbarContext);

  const {
    value: squadLeaders,
    status: squadLeadersStatus,
    execute: squadLeadersExecute,
  } = useAsync<SquadLeader[]>(async () => await squadLeaderService.getSquadLeadersBySquadId(squad.squadId), false);
  const {
    value: employees,
    status: employeesStatus,
    execute: employeesExecute,
  } = useAsync<Employee[]>(async () => await employeeService.getActiveEmployeesForSquad(squad.squadId), false);

  useEffect(() => {
    squadLeadersExecute();
  }, []);

  useEffect(() => {
    groupExecute();
    employeesExecute();
  }, [openCreateGroupModal]);

  const onSubmit = (data: any) => {
    if (editMode) {
      const editGroup = {
        groupId: selectedGroup.groupId,
        groupName: data.groupName.trim(),
        isActive: selectedGroup.isActive,
        squadId: selectedGroup.squadId,
        groupOwner: data.groupOwner as SquadLeader,
        groupLeaders: data.groupLeaders as SquadLeader[],
        groupMembers: data.groupMembers as Employee[],
      };
      editSquadGroupService(editGroup)
        .then(() => {
          openSnackbar('success', 'Group successfully updated');
          handleCloseCreateGroupModal();
          handleSuccess();
        })
        .catch(function () {
          openSnackbar('error', GenericErrorMessage);
        });
    } else {
      const createGroup = {
        squadId: parseInt(squad.squadId),
        groupName: data.groupName.trim() as string,
        groupOwner: data.groupOwner as SquadLeader,
        groupLeaders: data.groupLeaders as SquadLeader[],
        groupMembers: data.groupMembers as Employee[],
      };
      createSquadGroupService(createGroup, openSnackbar)
        .then(() => {
          handleCloseCreateGroupModal();
          handleSuccess();
        })
        .catch(function () {
          openSnackbar('error', GenericErrorMessage);
        });
    }
  };
  const onCancel = () => {
    handleCloseCreateGroupModal();
  };
  const data = {
    employees: employees,
    employeesStatus: employeesStatus,
    squadLeaders: squadLeaders,
    squadLeadersStatus: squadLeadersStatus,
    groups: groups,
  };

  return openCreateGroupModal ? <ModalForm data={data} onCancel={onCancel} onSubmit={onSubmit} selectedGroup={selectedGroup} /> : <></>;
};

export default CreateSquadGroupModal;
