import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';
import React from 'react';
import { grey50 } from 'src/utilities/customColors';

interface ConfirmArchiveModalProps {
  title: string;
  message: string;
  handleConfirm: () => void;
}

export const ConfirmArchiveModal = (props: ConfirmArchiveModalProps) => {
  const handleCloseClick = () => {
    setOpen(false);
  };
  const handleConfimClick = () => {
    props.handleConfirm();
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <IconButton title={props.title} onClick={() => setOpen(true)}>
        <ArchiveIcon />
      </IconButton>
      <Modal
        onClick={(e) => e.stopPropagation()}
        open={open}
        onClose={handleCloseClick}
        sx={{ display: 'fixed', alignItems: 'center', justifyContent: 'center', overflowY: 'auto' }}>
        <Box sx={{ backgroundColor: 'white', minWidth: { xs: '200px', md: '200px' }, borderRadius: '8px' }}>
          <Grid
            id="title"
            style={{
              backgroundColor: grey50,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0 14px 0 20px',
              height: 50,
              width: '100%',
              borderRadius: '6px',
            }}>
            <Typography variant="h6" component="div" align="center" sx={{ color: '#4A5568' }}>
              {props.title}
            </Typography>
            <IconButton onClick={handleCloseClick} size={'small'}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>

          <Grid container sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid sx={{ marginX: '12px', marginY: '12px' }}>
              <Typography sx={{ fontSize: '15px', fontWeight: 500, py: '4px', px: '8px' }}>{props.message}</Typography>
            </Grid>
          </Grid>
          <Grid
            id="title"
            style={{
              backgroundColor: grey50,
              paddingLeft: '20px',
              paddingRight: '20px',
              height: 52,
              width: '100%',
              borderRadius: '12px',
            }}>
            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={2} justifyContent="flex-end" sx={{ mt: 2, pt: 1 }}>
              <Button variant="outlined" onClick={handleCloseClick}>
                CANCEL
              </Button>
              <Button variant="contained" color="error" type="submit" onClick={handleConfimClick}>
                ARCHIVE
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
