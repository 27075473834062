import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import useSWR from 'swr';

import { useFilterContext } from './FilterContext';

interface DashboardContextProps {
  employees: DashboardEmployee[];
  setEmployees: React.Dispatch<React.SetStateAction<DashboardEmployee[]>>;
  getAllEmployees: () => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  employeeCount: number;
  setEmployeeCount: React.Dispatch<React.SetStateAction<number>>;
}

const DashboardContext = createContext<DashboardContextProps>({
  employees: [],
  setEmployees: () => {},
  getAllEmployees: () => {},
  isLoading: false,
  setIsLoading: () => {},
  employeeCount: 0,
  setEmployeeCount: () => {},
});

const employeeService = new EmployeeService();

export const DashboardProvider: React.FC = ({ children }) => {
  const [employees, setEmployees] = useState<DashboardEmployee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employeeCount, setEmployeeCount] = useState<number>(employees.length ?? 0);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const { squad } = React.useContext(SquadsContext);
  const params = useParams();
  const { selectedFilter, showAllEmployees } = useFilterContext();

  useEffect(() => {
    const selectedFilter = localStorage.getItem('selectedFilter');
    setShouldFetch(!selectedFilter);
  }, [params, selectedFilter]);

  const { data } = useSWR(
    shouldFetch ? `api/employees/${squad.squadId}` : null,
    async () => await employeeService.getActiveDashboardEmployees(+squad.squadId, showAllEmployees),
  );

  const loadEmployees = () => {
    if (shouldFetch) {
      setIsLoading(true);
      if (data) {
        setEmployees(data);
        setEmployeeCount(data.length);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (data && shouldFetch) {
      loadEmployees();
    }
  }, [data, shouldFetch]);

  const getAllEmployees = () => {
    if (!localStorage.getItem('selectedFilter')) {
      loadEmployees();
    } else {
      setEmployees(employees);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        setEmployeeCount,
        employeeCount,
        employees,
        setEmployees,
        getAllEmployees,
        isLoading,
        setIsLoading,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
