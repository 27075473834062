import React, { createContext, useContext, useState } from 'react';
import { EmployeeLearningPathDTO, EmployeeLearningPhaseDTO, EmployeeLearningChecklistDTO } from 'src/Models/LearninpathModels';
import { useEditor } from 'src/components/Editor/EditorContext';

interface LearningPathContextProps {
  selectedLearningPath?: EmployeeLearningPathDTO;
  selectedLearningPathPhase?: EmployeeLearningPhaseDTO | null;
  selectedLearningPathName: string;
  setSelectedLearningPath: React.Dispatch<React.SetStateAction<EmployeeLearningPathDTO>>;
  setSelectedLearningPathPhase: React.Dispatch<React.SetStateAction<EmployeeLearningPhaseDTO | undefined>>;
  setSelectedLearningPathName: React.Dispatch<React.SetStateAction<string>>;
  checkListItems?: EmployeeLearningChecklistDTO[] | null;
  setCheckListItems: React.Dispatch<React.SetStateAction<EmployeeLearningChecklistDTO[] | null | undefined>>;
  setToggleChecklist: React.Dispatch<React.SetStateAction<boolean>>;
  toggleChecklist: boolean;
  clearLearningPathSelectionContext: () => void
}

const LearningPathContext = createContext<LearningPathContextProps>({
  clearLearningPathSelectionContext: () => { },
  selectedLearningPath: {},
  selectedLearningPathName: '',
  selectedLearningPathPhase: {},
  setSelectedLearningPath: () => { },
  setSelectedLearningPathName: () => { },
  setSelectedLearningPathPhase: () => { },
  checkListItems: [],
  setCheckListItems: () => { },
  setToggleChecklist: () => { },
  toggleChecklist: false,
});

interface LearningPathProviderProps {
  children: React.ReactNode;
}

export const LearningPathProvider: React.FC<LearningPathProviderProps> = ({ children }) => {
  const [selectedLearningPath, setSelectedLearningPath] = useState<EmployeeLearningPathDTO>();
  const [selectedLearningPathPhase, setSelectedLearningPathPhase] = useState<EmployeeLearningPhaseDTO | undefined>();
  const [selectedLearningPathName, setSelectedLearningPathName] = useState<string>('');
  const [checkListItems, setCheckListItems] = useState<EmployeeLearningChecklistDTO[] | null>([]);
  const [toggleChecklist, setToggleChecklist] = useState<boolean>(false);
  const { resetEditorState } = useEditor()

  const clearLearningPathSelectionContext = () => {
    resetEditorState()
    setSelectedLearningPath(undefined)
    setSelectedLearningPathPhase(undefined)
    setSelectedLearningPathName('')
    setCheckListItems(null)
    setToggleChecklist(false)
  }

  return (
    <LearningPathContext.Provider
      value={{
        clearLearningPathSelectionContext,
        selectedLearningPath,
        selectedLearningPathPhase,
        selectedLearningPathName,
        setSelectedLearningPath,
        setSelectedLearningPathName,
        setSelectedLearningPathPhase,
        checkListItems,
        setCheckListItems,
        setToggleChecklist,
        toggleChecklist,
      }}>
      {children}
    </LearningPathContext.Provider>
  );
};

export const useLearningPathContext = () => {
  return useContext(LearningPathContext);
};
