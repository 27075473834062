import { Card, Stack } from '@mui/material';
import React from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import { useToggle } from 'src/hooks/useToggle';
import { Category, Feedback, Sentiment } from 'src/services/FeedbackType';
import FeedbackCardHeader from './FeedbackCardHeader';
import EditFeedbackCard from './components/EditFeedbackCard';
import EmployeeFeedbackDetails from './components/EmployeeFeedbackDetails';
import FeedbackDescription from './components/FeedbackDescription';
import CardMenu from './components/Menu';

interface FeedbackCardProps {
  feedbackItem: Feedback;
  categoryList: Category[];
  sentimentList?: Sentiment[];
  displayEmployeeDetail: boolean;
  searchTerms?: string[];
  refreshData?: () => void | undefined;
  showMoreIcon?: boolean
}

const FeedbackCard = ({ feedbackItem, categoryList, displayEmployeeDetail, searchTerms, refreshData, showMoreIcon = true }: FeedbackCardProps) => {
  const [isEditable, toggleEditMode] = useToggle(false);
  const isMobile = useIsMobile();

  return (
    <Card
      id={`feedbackId-${feedbackItem.feedbackId}`}
      elevation={isEditable ? 0 : 1}
      sx={
        isEditable
          ? {}
          : {
            position: 'relative',
            width: '100%',
            ...(feedbackItem.sentimentId === 1 && {
              '&::before': {
                content: '""',
                position: 'absolute',
                left: 0,
                top: 0,
                width: '5.71px',
                height: '100%',
                backgroundColor: 'red',
                borderRadius: '4px 0px 0px 4px',
                opacity: 1,
              }
            })
          }}
    >
      <FeedbackCardHeader
        feedbackItem={feedbackItem}
        isEditable={isEditable}
        isMobile={isMobile}
      />

      <Stack direction={'row'} justifyContent={'space-between'} alignItems="flex-end">
        {isEditable ? (
          <EditFeedbackCard
            isMobile={isMobile}
            feedbackItem={feedbackItem}
            categoryList={categoryList}
            handleClose={() => toggleEditMode(false)}
            refreshData={refreshData || (() => { })}
          />
        ) : (
          <>
            <FeedbackDescription feedbackItem={feedbackItem} searchTerms={searchTerms} />
            {showMoreIcon && (<CardMenu
              refreshData={refreshData || (() => { })}
              handleEdit={() => {
                toggleEditMode(true);
              }}
              feedbackItem={feedbackItem}
            />)}
          </>
        )}
      </Stack>

      <Stack alignItems="start" justifyContent={'space-between'} sx={{ paddingTop: '4px', display: 'flex', width: 'inherit' }}>
        {displayEmployeeDetail && <EmployeeFeedbackDetails feedbackItem={feedbackItem} />}
      </Stack>
    </Card >
  );
};

export default FeedbackCard;
