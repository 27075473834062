import moment from 'moment';

export const getDateDiff = (date: moment.MomentInput) => {
  return moment(moment().toDate()).diff(moment(date), 'days');
};

export const getTime = (date: moment.MomentInput) => {
  return moment(date).format('HH:mm');
};

export const getDay = (date: moment.MomentInput) => {
  return moment(date).day();
};

export const getDate = (date: moment.MomentInput) => {
  return moment(date).format('D');
};

export const getYear = (date: moment.MomentInput) => {
  return moment(date).format('Y');
};

export const getMonth = (date: moment.MomentInput) => {
  return moment(date).format('M');
};

export const getDateTime = (date: moment.MomentInput, format: string = 'YYYY/MM/DD') => {
  return moment(date).format(format);
};

export const getFullDate = (date: moment.MomentInput, format: string = 'DD-MM-YY') => {
  return moment(date).format(format);
};

export const getFullDateTime = (date: moment.MomentInput, format: string = 'YYYY/MM/DD HH:mm:ss') => {
  return moment(date).format(format);
};

export const calculateDays = (startDateStr: string, endDateStr: string): number => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const differenceMs = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days
  const daysDifference = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return daysDifference;
};

export const calculateWeeks = (startDateStr: string, endDateStr: string): number => {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const differenceMs = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to weeks
  const weeksDifference = Math.ceil(differenceMs / (1000 * 60 * 60 * 24 * 7));

  return weeksDifference;
};

export const calculateTimeDifferenceInDaysOrWeeks = (startDateStr: string, endDateStr: string): string => {
  const daysDifference = calculateDays(startDateStr, endDateStr);

  if (daysDifference >= 7) {
    const weeksDifference = calculateWeeks(startDateStr, endDateStr);
    return `${weeksDifference} weeks to go`;
  } else if (daysDifference < 7 && daysDifference >= 0) {
    return `${daysDifference} days to go`;
  } else {
    return `${Math.abs(daysDifference)} days overdue`;
  }
};

export const calculateTimeDifferenceInDaysOrWeeksWithOutText = (startDateStr: string, endDateStr: string): number => {
  const daysDifference = calculateDays(startDateStr, endDateStr);
  if (daysDifference >= 7) {
    const weeksDifference = calculateWeeks(startDateStr, endDateStr);
    return weeksDifference;
  } else {
    return daysDifference;
  }
};

export const calculateTimeDifferenceInDaysOrWeeksWithText = (startDateStr: string, endDateStr: string): string => {
  const daysDifference = calculateDays(startDateStr, endDateStr);
  if (daysDifference >= 7) {
    const weeksDifference = calculateWeeks(startDateStr, endDateStr);
    return weeksDifference + ' weeks';
  } else {
    return daysDifference + ' days';
  }
};

export const getToday = (): string => {
  const today = new Date();
  const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const todayString = currentDate.toDateString();

  if (todayString === new Date().toDateString()) {
    return 'Today';
  } else {
    return todayString;
  }
};
