import React, { ReactNode } from 'react';

type DuplicateProps = {
  count: number;
  children: ReactNode;
};

const Duplicate: React.FC<DuplicateProps> = ({ count, children }) => {
  const duplicateChildren = () => {
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(<React.Fragment key={i}>{children}</React.Fragment>);
    }
    return result;
  };

  return <>{duplicateChildren()}</>;
};

export default Duplicate;
