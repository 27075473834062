import  React from 'react';
import { Button, Stack, Typography } from '@mui/material';

interface ConfirmArchiveProps {
  cancelArchiveClicked: React.MouseEventHandler<HTMLButtonElement> | undefined;
  confirmArchiveClicked: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const ConfirmArchive: React.FC<ConfirmArchiveProps> = ({
  cancelArchiveClicked,
  confirmArchiveClicked,
}) => {
  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='center'
      justifyContent='flex-end'
      sx={{ marginTop: '15px', marginBottom: '15px' }}
    >
      <Typography sx={{ color: 'red' }}>Are you sure?</Typography>
      <Button
        variant='outlined'
        sx={{ color: 'gray', borderColor: 'gray' }}
        onClick={cancelArchiveClicked}
      >
        NO
      </Button>
      <Button
        variant='contained'
        color='error'
        onClick={confirmArchiveClicked}
      >
        CONFIRM ARCHIVE
      </Button>
    </Stack>
  );
};

export default ConfirmArchive;
