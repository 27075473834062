import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

import ClientManagementQuickFilters from '../ClientManagementQuickFilters';
import IncommingEmployeeGlobalDateFilter from './IncommingEmployeeGlobalDateFilter';
import SquadBarChart from './SquadBarChart';
import StatusBarChart from './StatusBarChart';

const ClientManagementChartsArea: React.FC = () => {
  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <Accordion elevation={0} expanded={expanded} onChange={() => setExpanded(!expanded)} defaultExpanded>
      <AccordionSummary
        sx={{
          pointerEvents: 'none',
          [`&.${accordionSummaryClasses.root}`]: {
            minHeight: '12px',
            height: '12px',
            paddingX: 0,
          },
          [`& .${accordionSummaryClasses.content}`]: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
            marginY: 0,
          },
        }}>
        <Box sx={{ pointerEvents: 'auto' }} onClick={() => setExpanded(!expanded)}>
          <Typography variant="subtitle2" sx={{ textDecoration: 'underline' }}>
            {expanded ? 'Hide' : 'Show'} dashboard view
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ paddingX: 0 }}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={4}>
            <IncommingEmployeeGlobalDateFilter />
          </Grid>
          <Grid item container minHeight="312px" display="flex" flexDirection="row" spacing={8}>
            <Grid item xs={12} md={2}>
              <ClientManagementQuickFilters />
            </Grid>
            <Grid item container md={10} display="flex" flexDirection="row" spacing={8}>
              <Grid item xs={13} md={5.5}>
                <StatusBarChart />
              </Grid>
              <Grid item xs={13} md={5.5}>
                <SquadBarChart />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ClientManagementChartsArea;
