import { ExpandMore as ExpandIcon } from '@mui/icons-material';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TagCategory, Tag } from 'src/Models/TagModels';
import { useEditor } from 'src/components/Editor/EditorContext';
import { useEntityContext } from 'src/components/feedbackComponent/context/EntityContext';
import { useFeedbackTagLibraryContext } from 'src/components/feedbackComponent/context/FeedbackTagLibraryContext';
import StyledTab from 'src/components/shared/StyledTab';
import StyledTabList from 'src/components/shared/StyledTabList';
import TagBubble from 'src/components/shared/TagBubble';

import SearchBar from '../../../../SearchBar';

interface FeedbackTagLibraryProps {
  handleSubmitted?: (additionalComments: any) => void;
  inModal: boolean;
}

const FeedbackTagLibrary = (props: FeedbackTagLibraryProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTabIndex, setSelectedTab] = useState('tags');
  const { tags } = useEntityContext();
  const [categories, setCategories] = useState<TagCategory[]>(tags ?? []);
  const [entityList, setEntityList] = useState<TagCategory[]>(tags ?? []);
  const [expanded, setExpanded] = useState<string[]>([]);
  const { setTagName } = useFeedbackTagLibraryContext();
  const { appendTextToEditorContentWithMentions } = useEditor();

  const handleChange = (panel: string) => () => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter((expanded) => expanded !== panel));
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const handleSubmit = (tag: Tag, tagCategoryColor: string) => {
    if (props.inModal) {
      setTagName(tag?.tagName!);
    } else {
      appendTextToEditorContentWithMentions(tag?.tagName!, tag.tagId, 'Tag', tagCategoryColor);
    }
  };

  const onTabSelectionChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const handleSearch = (searchTerm: string, data: TagCategory[]) => {
    const temp: TagCategory[] = [];
    data.forEach((category) => {
      const matchingTags = category.tags.filter((tag) => tag.tagName.toLowerCase().includes(searchTerm.toLowerCase()));
      if (matchingTags.length > 0) {
        temp.push({
          tagCategoryId: category.tagCategoryId,
          tagCategoryName: category.tagCategoryName,
          categoryDescription: category.categoryDescription,
          categoryColor: category.categoryColor,
          tags: matchingTags,
        });
        handleChange(category.tagCategoryName);
      }
    });
    setEntityList(temp);
    return temp;
  };

  useEffect(() => {
    if (searchTerm.length < 2 || searchTerm === '') {
      setEntityList(categories);
    } else {
      handleSearch(searchTerm, categories);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (tags) {
      setCategories(tags);
      setEntityList(tags);
    }
  }, [tags]);

  useEffect(() => {
    const handleInputChange = (e: CustomEvent) => {
      const currentEditorState = e.detail?.value;
      if (!currentEditorState) return;

      const feedbackInput = currentEditorState.trimEnd();
      const words = feedbackInput.split(' ');
      const lastWord = words[words.length - 1];

      if (lastWord.startsWith('#') && lastWord.length > 1) {
        setSearchTerm(lastWord.slice(1));
      } else {
        setSearchTerm('');
      }
    };

    window.addEventListener('onFeedbackInputChange', handleInputChange);

    return () => {
      window.removeEventListener('onFeedbackInputChange', handleInputChange);
    };
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setEntityList(categories);
    } else {
      setEntityList(handleSearch(searchTerm, tags ?? []));
    }
  }, [searchTerm, categories, tags]);

  return (
    <Paper sx={{ height: 'calc(100vh - 100px)', display: 'flex', flexDirection: 'column' }}>
      <TabContext value={selectedTabIndex}>
        <StyledTabList onChange={onTabSelectionChange} indicatorColor="primary" variant="standard">
          <StyledTab label="Tags" value="tags" icon={<TagOutlinedIcon />} iconPosition="start" />
        </StyledTabList>
        <Box display={'flex'} justifyContent={'center'} sx={{ my: 2, px: 1.25 }}>
          <SearchBar placeholder="Search tags" setSearchTerm={setSearchTerm} searchTerm={searchTerm} autoFocus onFocus={(e) => e.target.select()} />
        </Box>

        <Box sx={{ maxHeight: '100%', overflowY: 'scroll', scrollbarWidth: 'thin' }}>
          <TabPanel value={'tags'} data-testid={'tab-0'} sx={{ p: 0 }}>
            {entityList?.map((entity) => (
              <Accordion
                expanded={expanded.includes(entity.tagCategoryName) === false}
                defaultExpanded
                onChange={handleChange(entity.tagCategoryName)}
                sx={{ backgroundColor: '##FFE', maxHeight: '100%' }}
                key={entity.tagCategoryId}>
                <AccordionSummary expandIcon={<ExpandIcon />} sx={{ backgroundColor: '##FFE' }}>
                  <Typography color={'#000000DE'}>{entity.tagCategoryName}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                  {entity.tags.map((tag) => (
                    <TagBubble tag={tag} onClick={() => handleSubmit(tag, entity.categoryColor)} key={tag.tagId} />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </TabPanel>
        </Box>
      </TabContext>
    </Paper>
  );
};

export default FeedbackTagLibrary;
