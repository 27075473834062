import { Box, Card, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import React from 'react';


const LoaderFeedbackCard = () => {
  const theme = useTheme();
  const smallDevicesBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ width: '100%' }}>
      <Card elevation={0} sx={{ borderLeft: 20, borderLeftColor: 'gray', backgroundColor: '#FAFAFA' }}>
        <Stack justifyContent="space-between" sx={{ minHeight: '120px', ml: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            {!smallDevicesBreakpoint && (
              <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
                <Stack direction="row" alignItems={'center'} ml={2} sx={{ color: 'black' }}>
                  <Skeleton>
                    <b>Placeholder text Placeholder text</b>
                  </Skeleton>
                </Stack>
              </Stack>
            )}

            <Stack direction="row"></Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
            <Typography variant="body1" sx={{ pt: 1, pl: 2, pr: 2, whiteSpace: 'pre-wrap' }}>
              <Skeleton>
                <span>Placeholder text Placeholder text</span>
              </Skeleton>
              <Skeleton>
                <span>Placeholder text Placeholder text</span>
              </Skeleton>
            </Typography>
          </Stack>

          <Stack alignItems="start" justifyContent={'space-between'} sx={{ p: 1, display: 'flex', width: 'inherit' }}></Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default LoaderFeedbackCard;
