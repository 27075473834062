import { Box, LinearProgress, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { EmployeeLearningChecklistDTO } from 'src/Models/LearninpathModels';
import { calculateTimeDifferenceInDaysOrWeeks, getToday } from 'src/utilities/DateUtilities';
import { calculatePercentCompleted } from 'src/utilities/NumberUtilities';

import { useEditor } from '../Editor/EditorContext';
import Checklist from '../shared/Checklist';
import { useLearningPathContext } from './context/LearningPathContext';

const LearningPathPhaseCheckList: React.FC = () => {
  const { selectedLearningPathPhase, checkListItems, setCheckListItems } = useLearningPathContext();
  const { appendTextToEditorContent, removeTextFromEditor } = useEditor();
  const timeLeft: string = calculateTimeDifferenceInDaysOrWeeks(`${new Date().toISOString()}`, `${selectedLearningPathPhase?.endDate}`);

  const totalItems: number = checkListItems ? checkListItems.length : 0;
  const completedItems: number = checkListItems ? checkListItems.filter((phase) => phase.isChecked === true).length : 0;
  const isSelectedLearningPathPhaseOverdue = selectedLearningPathPhase?.endDate && new Date(selectedLearningPathPhase.endDate) < new Date();
  const percentCompleted: number = checkListItems ? calculatePercentCompleted(completedItems, totalItems) : 0;
  const isSelectedLeanringPhasePaused = selectedLearningPathPhase?.status == 'Paused';

  useEffect(() => {
    if (selectedLearningPathPhase) setCheckListItems(selectedLearningPathPhase.checklists);
  }, [selectedLearningPathPhase, setCheckListItems]);

  const handleToggle = (itemId: number) => {
    const updatedItems = checkListItems?.map((item: EmployeeLearningChecklistDTO) =>
      item.id === itemId
        ? {
            ...item,
            isChecked: !item.isChecked,
            checkedDate: item?.checkedDate !== '0001-01-01T00:00:00' ? item.checkedDate : getToday(),
          }
        : item,
    );

    updatedItems?.map((checkListItem: EmployeeLearningChecklistDTO) => {
      if (checkListItem.isChecked && checkListItem.id === itemId) {
        appendTextToEditorContent(checkListItem.name!, true);
      } else if (checkListItem.checkedDate === getToday() && !checkListItem.isChecked && checkListItem.id === itemId) {
        removeTextFromEditor(checkListItem.name!);
      }
    });

    setCheckListItems(updatedItems);
  };

  return (
    <Box
      sx={{
        height: '220px',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        id="learning-path-checklist-title"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginBottom: '7px',
          alignSelf: 'stretch',
        }}>
        {!isSelectedLeanringPhasePaused ? (
          <Typography variant="subtitle2" color={isSelectedLearningPathPhaseOverdue ? '#EF6C00' : 'default'}>
            {timeLeft}
          </Typography>
        ) : (
          <Typography variant="subtitle2" color={isSelectedLearningPathPhaseOverdue ? '#EF6C00' : 'default'}>
            Phase paused
          </Typography>
        )}
        <Typography variant="caption" color={'#646464'}>
          {selectedLearningPathPhase?.name} checklist ({completedItems}/{totalItems})
        </Typography>
        <LinearProgress variant="determinate" value={percentCompleted} />
      </Box>

      {!isEmpty(checkListItems) && checkListItems != undefined && <Checklist variant="feedback" items={checkListItems} onChange={handleToggle} />}
    </Box>
  );
};

export default LearningPathPhaseCheckList;
