function mapJobTitleToCategory(employeeJobTitle: string): string {
  const jobTitleToCategory: Record<string, string> = {
    'Graduate Software Engineer': 'Software Engineer',
    'Junior Software Engineer': 'Software Engineer',
    'Intermediate Software Engineer': 'Software Engineer',
    'Senior Software Engineer': 'Software Engineer',
    'Graduate Business Analyst': 'Business Analyst',
    'Business Analyst': 'Business Analyst',
    'Intermediate Business Analyst': 'Business Analyst',
    'Senior Business Analyst': 'Business Analyst',
    'QA Engineer': 'Quality Assurance',
    'Intermediate QA Engineer': 'Quality Assurance',
    'Senior QA Engineer': 'Quality Assurance',
    'UX Engineer': 'UX Designer',
    'Intermediate UX Engineer': 'UX Designer',
    'Senior UX Engineer': 'UX Designer',
    'Data Scientist': 'Software Engineer',
    'Intermediate Data Scientist': 'Software Engineer',
    'Senior Data Scientist': 'Software Engineer',
    'Software Engineer': 'Software Engineer',
    'Senior Quality Assurance Engineer': 'Quality Assurance',
    'Senior Software Engineer - Project Lead': 'Software Engineer',
    'Intermediate Quality Assurance Engineer': 'Quality Assurance',
    'Graduate UX Engineer': 'UX Designer',
  };
  return jobTitleToCategory[employeeJobTitle] || 'ANY';
}

export default mapJobTitleToCategory;
