import FlagIcon from '@mui/icons-material/Flag';
import VisibilityIcon from '@mui/icons-material/VisibilityOff';
import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { UnflaggedIcon } from 'src/components/shared/UnflaggedIcon';

interface FeedbackCategoryDisplayProps {
  name: string;
  hexColor: string;
  backgroundColour: string;
  isFlagged?: boolean;
  isHiddenFromOverview?: boolean;
  hasReason?: boolean;
}

const FeedbackCategoryDisplay: React.FC<FeedbackCategoryDisplayProps> = ({
  name: categoryName,
  hexColor,
  backgroundColour,
  isFlagged = false,
  isHiddenFromOverview = false,
  hasReason = false,
}) => {

  return (
    <Box
      display='flex'
      justifyContent="space-between"
      alignItems="center"
      gap='4px'
    >
      {!hasReason && isFlagged && <FlagIcon aria-label="flag-icon" color='error' />}
      <Box
        id="feedback-category-display"
        sx={{
          height: '38px',
          minWidth: '120px',
          borderLeft: 6,
          borderLeftColor: hexColor,
          backgroundColor: backgroundColour,
        }}
      >
        <Stack direction="row" height='100%' justifyContent="space-between" alignItems="center" px='5px' sx={{ color: hexColor }}>
          <Typography variant='body2' fontWeight='600' noWrap>{categoryName}</Typography>
          {hasReason && (
            <Box sx={{ pl: '4px' }}>
              <UnflaggedIcon aria-label="unflagged-icon" />
            </Box>
          )}
          {!hasReason && isHiddenFromOverview && <VisibilityIcon aria-label="visibility-icon" fontSize="small" />}
        </Stack>
      </Box>
    </Box>
  );
};

export default FeedbackCategoryDisplay;
