import React, { createContext, useContext, useEffect, useState } from 'react';
import { DashboardEmployee } from 'src/Models/EmployeeModels';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';
import useSWR from 'swr';

interface ClientManagementDashboardContextProps {
  employees: DashboardEmployee[];
  setEmployees: React.Dispatch<React.SetStateAction<DashboardEmployee[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getAllIncommingEmployees: () => any;
  incommingEmployeeCount: number;
  setIncommingEmployeeCount: React.Dispatch<React.SetStateAction<number>>;
}

const ClientManagementDashboardContext = createContext<ClientManagementDashboardContextProps>({
  employees: [],
  setEmployees: () => {},
  isLoading: false,
  setIsLoading: () => {},
  getAllIncommingEmployees: () => {},
  incommingEmployeeCount: 0,
  setIncommingEmployeeCount: () => {},
});

export const ClientManagementDashboardProvider: React.FC = ({ children }) => {
  const [employees, setEmployees] = useState<DashboardEmployee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { squad } = useContext(SquadsContext);
  const employeeService = new EmployeeService();
  const [incommingEmployeeCount, setIncommingEmployeeCount] = useState<number>(employees.length);

  const getAllIncommingEmployees = () => {
    loadEmployees();
  };

  const { data } = useSWR(`api/incoming/employees/${squad.squadId}`, async () => await employeeService.GetAllIncommingEmployees(+squad.squadId));

  const loadEmployees = () => {
    setIsLoading(true);
    if (data) {
      setEmployees(data);
      setIncommingEmployeeCount(data.length ?? employees.length);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEmployees();
  }, [data]);

  return (
    <ClientManagementDashboardContext.Provider
      value={{
        setIncommingEmployeeCount,
        incommingEmployeeCount,
        employees,
        setEmployees,
        isLoading,
        setIsLoading,
        getAllIncommingEmployees,
      }}>
      {children}
    </ClientManagementDashboardContext.Provider>
  );
};

export const useClientManagementDashboardContext = () => {
  return useContext(ClientManagementDashboardContext);
};
