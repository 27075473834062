export enum SentimentEnum {
  red = 1,
  amber = 2,
  green = 3,
}
export type SentimentKey = keyof typeof SentimentEnum;

export const TFO_SQUAD_ID = 1;
export const DEFAULT_NON_TFO_STATUS_ID = 1;

export const DEFAULT_SENTIMENT: SentimentKey = 'green';
export const DEFAULT_SENTIMENT_ID: number = 3;
export const TFO_SHORT_NAME: string = 'TFO';
export const FEEDBACK_FORM_EDITOR_KEY = 'FeedbackFormEditorKey';
export const EDIT_FEEDBACK_FORM_KEY = 'EditFeedbackKey';

export const DEFAULT_TAB_HEIGHT: string = '42px';

export const REDUCED_ICON_LENGTH: string = '20px';

export enum LearningPhaseStatus {
  'InProgress' = 1,
  'Overdue' = 2,
  'Abandoned' = 3,
  'Skipped' = 4,
  'Paused' = 5,
  'Completed' = 6,
}

export type LearningPhaseStatusKey = keyof typeof LearningPhaseStatus;

export enum LearningPathStatus {
  'InProgress' = 1,
  'Abandoned' = 2,
  'Paused' = 3,
  'Completed' = 4,
}
