import { Autocomplete, Box, Button, Drawer, Grid, Paper, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DashboardEmployee } from 'src/Models/EmployeeModels';

import { useDashboardContext } from '../activeEmployeesDashboard/context/DashboardContext';
import { useClientManagementDashboardContext } from '../clientManagementDashboard/context/ClientManagementDashboardContext';

interface EmployeeFilterProps {
  employeeList: DashboardEmployee[];
  drawerOpen: boolean;
  closeFilterComponent: any;
  onFilterChange: (status: boolean) => void;
  isIncommingTab: boolean;
}

const EmployeeDetailFilter = ({ employeeList, drawerOpen, closeFilterComponent, onFilterChange, isIncommingTab }: EmployeeFilterProps) => {
  const availableStatuses = Array.from(new Set(employeeList.map((x) => x.latestStatus)));
  const availableJobtitles = Array.from(new Set(employeeList.map((x) => x.jobTitle.shortName)));
  const latestFeedback = Array.from(new Set(employeeList.map((x) => x.latestFeedbackCategory)));
  const availableSquads = Array.from(new Set(employeeList.flatMap((x) => x.groupNames)));

  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [jobTitleFilter, setJobtitleFilter] = useState<string[]>([]);
  const [feedbackFilter, setFeedbackFilter] = useState<string[]>([]);
  const [filteredList, setFilteredList] = useState<DashboardEmployee[]>([]);
  const [resultCount, setResultCount] = useState<number>();
  const [squadgroupFilter, setSquadGroupFilter] = useState<string[]>([]);
  const { setEmployees, getAllEmployees } = useDashboardContext();
  const { setEmployees: setIncommingEmployees, getAllIncommingEmployees } = useClientManagementDashboardContext();

  const handleClearAll = () => {
    onFilterChange(false);
    setStatusFilter([]);
    setSquadGroupFilter([]);
    setResultCount(undefined);
    setJobtitleFilter([]);
    setFilteredList([]);
    setFeedbackFilter([]);
    getAllEmployees();
    closeFilterComponent();
    if (isIncommingTab) getAllIncommingEmployees();
  };

  const handleAddStatus = (event: any, newValue: string[]) => {
    setStatusFilter(newValue);
  };

  const handleAddJobTitle = (event: any, newValue: string[]) => {
    setJobtitleFilter(newValue);
  };

  const handleAddFeedbackCategory = (event: any, newValue: string[]) => {
    setFeedbackFilter(newValue);
  };

  const handleAddGroups = (event: any, newValue: string[]) => {
    setSquadGroupFilter(newValue);
  };

  const updateResultounter = () => {
    const newFilterCount = employeeList.filter((employee) => {
      const statusMatch = statusFilter.length === 0 || statusFilter.includes(employee.latestStatus);
      const jobTitleMatch = jobTitleFilter.length === 0 || jobTitleFilter.includes(employee.jobTitle.shortName);
      const feedbackMatch = feedbackFilter.length === 0 || feedbackFilter.includes(employee.latestFeedbackCategory);
      const groupMatch = squadgroupFilter.length === 0 || employee.groupNames.some((group) => squadgroupFilter.includes(group));

      return statusMatch && jobTitleMatch && feedbackMatch && groupMatch;
    });
    setResultCount(newFilterCount.length);
  };

  const handleApplyFilter = () => {
    const filteredEmployees = employeeList.filter((employee) => {
      const statusMatch = statusFilter.length === 0 || statusFilter.includes(employee.latestStatus);
      const jobTitleMatch = jobTitleFilter.length === 0 || jobTitleFilter.includes(employee.jobTitle.shortName);
      const feedbackMatch = feedbackFilter.length === 0 || feedbackFilter.includes(employee.latestFeedbackCategory);
      const groupMatch = squadgroupFilter.length === 0 || employee.groupNames.some((group) => squadgroupFilter.includes(group));

      const isAnyFilterApplied =
        statusFilter.length > 0 || jobTitleFilter.length > 0 || feedbackFilter.length > 0 || (squadgroupFilter.length > 0 && filteredList.length > 0);
      onFilterChange(isAnyFilterApplied);

      return statusMatch && jobTitleMatch && feedbackMatch && groupMatch;
    });

    setFilteredList(filteredEmployees);
  };

  useEffect(() => {
    updateResultounter();
  }, [feedbackFilter, jobTitleFilter, statusFilter, squadgroupFilter]);

  useEffect(() => {
    if (filteredList.length > 0) {
      if (!isIncommingTab) {
        setEmployees(filteredList);
      } else {
        setIncommingEmployees(filteredList);
      }
    }
  }, [filteredList]);

  return (
    <Drawer open={drawerOpen} anchor="right" onClose={closeFilterComponent}>
      <Paper sx={{ display: 'flex', flexDirection: 'column', padding: 2, width: '300px', height: '100%' }}>
        <Grid sx={{ pt: '80px' }}>
          <Typography sx={{ color: 'black', fontSize: '16px', mb: 2 }}>Filter employee list</Typography>

          <Box sx={{ mb: 2 }}>
            <Autocomplete
              multiple
              options={availableStatuses}
              value={statusFilter}
              onChange={handleAddStatus}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Status" placeholder="Select Status" />}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Autocomplete
              multiple
              options={availableJobtitles}
              value={jobTitleFilter}
              onChange={handleAddJobTitle}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Job title" placeholder="Select job title" />}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Autocomplete
              multiple
              options={latestFeedback}
              value={feedbackFilter}
              onChange={handleAddFeedbackCategory}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Feedback category" placeholder="Select feedback category" />}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Autocomplete
              multiple
              options={availableSquads}
              value={squadgroupFilter}
              onChange={handleAddGroups}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => <TextField {...params} label="Squad groups" placeholder="Select squad" />}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            {' '}
            <Typography color="black">Number of results: {resultCount}</Typography>
          </Box>
          <Grid container sx={{ justifyContent: 'center', p: '8px', display: 'flex', flexDirection: 'row' }}>
            <Button color="success" onClick={handleApplyFilter} sx={{ mr: '6px' }} variant="outlined">
              Apply to list
            </Button>
            <Button onClick={handleClearAll} variant="outlined">
              Clear all
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Drawer>
  );
};

export default EmployeeDetailFilter;
