import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ErrorPageProps {
  onResolved: () => void;
  showNavBack?: Boolean;
}
const ErrorPage = ({ onResolved, showNavBack = true }: ErrorPageProps) => {
  const navigate = useNavigate();

  const goBack = () => {
    onResolved();
    navigate(-1);
  };
  return (
    <div style={{ textAlign: 'center', marginTop: '50px', width: '100%' }}>
      <h3>Oops! Something went wrong.</h3>
      <p>Please try refreshing the page or contact support if the problem persists.</p>
      {showNavBack && (
        <Button variant="contained" color="secondary" onClick={() => goBack()}>
          Back
        </Button>
      )}
    </div>
  );
};

export default ErrorPage;
