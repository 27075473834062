import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import FeedbackCardLoader from 'src/components/feedbackComponent/feedback/history/FeedbackCardLoader';
import Duplicate from 'src/components/shared/Duplicate';
import { CertificationService, CertificationsDetails, EmployeeCertificateDTO } from 'src/services/CertificationService';
import { FeedbackService } from 'src/services/FeedbackService';
import { Feedback } from 'src/services/FeedbackType';
import { SquadsContext } from 'src/utilities/SquadsContext';

import FullDateWithDivider from '../../../FullDateWithDivider';
import { StudyTimeline } from '../../components/StudyTimeline';
import FeedbackCard from './FeedbackCard';
import { CategoryService } from 'src/services/CategoryService';

interface CertTagProps {
  employeeCertificate: EmployeeCertificateDTO;
  onClose?: () => void;
}

const certService = new CertificationService();

const CertificateTagHistoryModal = ({ employeeCertificate, onClose }: CertTagProps) => {
  const squadID = useContext(SquadsContext).squad.squadId;
  const feedbackService = new FeedbackService();

  const [categoryList, setCategoryList] = useState([]);
  const [certificate, setCertificate] = useState<CertificationsDetails | undefined>();
  const [feedback, setFeedback] = useState<Feedback[]>();
  const [isCompletedCert, setCompletedCert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const categoryService = new CategoryService()

  const loadCategories = async () => {
    const data = await categoryService.getAllCategoriesForSquad(squadID)
    setCategoryList(data)
  }

  useEffect(() => {
    loadCategories();
    const fetchData = async () => {
      setIsLoading(true);
      const data = await certService.getCertificateById(employeeCertificate.certificateId);
      setCertificate(data);
      const feedbackData = await feedbackService.getCertificationFeedback(employeeCertificate.certificateName, employeeCertificate.employeeId);
      setFeedback(feedbackData);
      setIsLoading(false);
      if (employeeCertificate.completedDate !== null) setCompletedCert(true);
    };
    fetchData();
  }, []);

  return (
    <Dialog open fullWidth maxWidth={'md'} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: isCompletedCert ? '#4CAF50' : '#E0E0E0',
          display: 'flex',
          justifyContent: 'center',
          color: isCompletedCert ? 'white' : 'black',
        }}>
        <Box>STUDY TIMELINE</Box>
      </DialogTitle>
      <DialogContent>
        <div style={{ paddingBottom: '20px' }}></div>
        <StudyTimeline
          certificateName={certificate?.certificateName ?? ''}
          startDate={employeeCertificate.startDate}
          completedDate={employeeCertificate.completedDate}
        />
        <Stack>
          {isLoading && (
            <Duplicate count={3}>
              <ListItem sx={{ pb: '40px' }}>
                <FeedbackCardLoader />
              </ListItem>
            </Duplicate>
          )}
          {feedback?.map((feedbackItem) => (
            <React.Fragment key={feedbackItem.feedbackId}>
              <FullDateWithDivider date={feedbackItem.createdDate} />
              <ListItem key={feedbackItem.feedbackId} sx={{ pb: '40px' }}>
                {!isLoading && (
                  <FeedbackCard
                    categoryList={categoryList}
                    displayEmployeeDetail={false}
                    feedbackItem={feedbackItem}
                    employeeId={employeeCertificate.employeeId}
                  />
                )}
              </ListItem>
            </React.Fragment>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: '#E0E0E0', pr: '40px' }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CertificateTagHistoryModal;
