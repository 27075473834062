import { createContext } from 'react';
import { User } from './UserContext';

export class SquadLeader {
  squadLeaderId: string;
  squadId: string;
  userId: string;
  isDeleted: boolean;
  isAdmin: boolean;
  isSuperUser: boolean;
  user?: User;
  categoryId?: number;
}

interface SquadLeaderContextState {
  squadLeader: Array<SquadLeader>;
  activeSquadLeader: SquadLeader;
  setSquadLeader: React.Dispatch<React.SetStateAction<SquadLeader[]>>;
  setActiveSquadLeader: React.Dispatch<React.SetStateAction<SquadLeader>>;
}

const initialState: SquadLeaderContextState = {
  squadLeader: new Array<SquadLeader>(),
  activeSquadLeader: new SquadLeader(),
  setSquadLeader: () => [],
  setActiveSquadLeader: () => {},
};

export const SquadLeaderContext = createContext(initialState);
