export interface ActiveFeatureToggles {
  groupAllocation: boolean;
  certificates: boolean;
  skills: boolean;
  wordCloud: boolean;
  tagging: boolean;
  statusTracking: boolean;
}

type Flags = { [K in keyof ActiveFeatureToggles]: boolean } | undefined | {};
export interface FeatureToggleConfig {
  environment?: {
    Development: Flags;
    QA: Flags;
    Production: Flags;
  };
  users?: {
    [key: string]: Flags;
  };
  tenants?: { [key: string]: Flags };
}

export const featureTogglesConfig: FeatureToggleConfig = {
  environment: {
    Development: {
      // Add feature toggles here
      groupAllocation: true,
      certificates: true,
      skills: true,
      wordCloud: false,
      tagging: true,
      statusTracking: false,
    },
    QA: {
      groupAllocation: true,
      certificates: true,
      skills: true,
      wordCloud: false,
      tagging: true,
      statusTracking: false,
    },
    Production: {
      wordCloud: false,
      tagging: false,
      statusTracking: false,
    },
  },
  users: {

  },
  tenants: {
    BTC: {},
  },
};
