import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays } from 'date-fns';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FeedbackPayload } from 'src/services/FeedbackService';
import { SquadLeaderContext } from 'src/utilities/SquadLeaderContext';
import { SquadsContext } from 'src/utilities/SquadsContext';

import { getDisplayName } from '../../../../utilities/StringUtilities';

const FeedbackFollowUpForm = (props: any) => {
  const [modalOpened, setModalOpened] = React.useState(false);

  const { register, setValue, watch, getValues } = useFormContext<FeedbackPayload>();
  const { squad } = React.useContext(SquadsContext);
  const { activeSquadLeader, squadLeader } = React.useContext(SquadLeaderContext);


  const feedbackEntered = watch('description');
  const watchFollowUpNote = watch('feedbackFollowUp.followUpNote', feedbackEntered);
  const watchFollowUp = watch('feedbackFollowUp');

  const handleOpenModal = React.useCallback(() => {
    setModalOpened(true);
  }, []);

  const handleAddModal = React.useCallback(() => {
    setModalOpened(false);
  }, []);

  const handleCloseModal = React.useCallback(() => {
    setValue('feedbackFollowUp', undefined);
    setModalOpened(false);
  }, [setValue]);

  React.useEffect(() => {
    modalOpened &&
      setValue('feedbackFollowUp', {
        squadId: parseInt(squad.squadId),
        dueDate: addDays(new Date(), 1).toISOString(),
        followUpNote: getValues('description'),
        isCompleted: false,
        taskName: '',
        userId: parseInt(activeSquadLeader?.userId ?? '0'),
      });
  }, [modalOpened]);

  return (
    <>
      <Button
        variant="text"
        size='medium'
        color="primary"
        startIcon={!isEmpty(getValues('feedbackFollowUp')) ? <EventAvailableIcon color="success" /> : <CalendarTodayIcon color="primary" />}
        onClick={handleOpenModal}>
        FOLLOW UP
      </Button>
      {modalOpened && (
        <Modal open={modalOpened} onClose={handleCloseModal}>
          <Box sx={{ width: '400px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              spacing={2}
              sx={{ width: { xs: 300, md: 400 }, background: '#FFF', pr: 0, pt: 3, pl: 5, pb: 3, borderRadius: '5px' }}>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" paddingBottom={'15px'}>
                    Add a Follow Up
                  </Typography>
                  <TextField
                    required
                    autoFocus
                    variant="outlined"
                    label="Task Name"
                    helperText="To-do list title, keep it short"
                    id="taskName"
                    {...register('feedbackFollowUp.taskName', { required: true })}
                    sx={{ width: '85%' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl required size="small" sx={{ width: '85%' }}>
                    <InputLabel>Assigned To</InputLabel>
                    <Select label="Assign To:" {...register('feedbackFollowUp.userId', { required: true })} defaultValue={activeSquadLeader?.userId}>
                      {squadLeader.map((leader: any) => (
                        <MenuItem key={leader.squadLeaderId} value={leader.userId}>
                          {leader.user.fullName === '' ? getDisplayName(leader.user.emailAddress) : leader.user.fullName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>To-do Person</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required size="small" sx={{ width: '100%' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Choose a date"
                        {...register('feedbackFollowUp.dueDate')}
                        inputFormat="dd/MM/yyyy"
                        value={watch('feedbackFollowUp.dueDate')}
                        onChange={(date) => setValue('feedbackFollowUp.dueDate', date ? new Date(date).toISOString() : '')}
                        renderInput={(params) => <TextField {...params} sx={{ width: '85%' }} />}
                      />
                    </LocalizationProvider>
                    <FormHelperText>DD/MM/YYYY</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="column" alignItems="flex-end" sx={{ width: '85%' }}>
                    <Typography
                      align="center"
                      justifyContent="left"
                      variant="subtitle1"
                      sx={{ ':hover': { cursor: 'pointer' }, color: 'blue' }}
                      onClick={() => setValue('feedbackFollowUp.followUpNote', '')}>
                      clear
                    </Typography>
                    <TextField
                      required
                      variant="outlined"
                      label="Add Notes"
                      helperText="Information for the meeting"
                      id="followUpNote"
                      value={watchFollowUpNote}
                      {...register('feedbackFollowUp.followUpNote', { required: true })}
                      onChange={(e) => setValue('feedbackFollowUp.followUpNote', e.target.value)}
                      sx={{ width: '100%' }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={8.5}>
                  <Stack direction="row" alignItems="center" justifyContent="right" spacing={2}>
                    <Button variant="outlined" onClick={handleCloseModal}>
                      CANCEL
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleAddModal}
                      disabled={Object.values(watchFollowUp ?? {}).some((value) => value === '')}>
                      ADD
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default FeedbackFollowUpForm;
