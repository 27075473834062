import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import SquadLeaderControl from './SquadLeaderControl';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #476B97',
  boxShadow: 24,
  p: 2,
};

const AddSquadLeaderModal = (props: any) => {
  return (
    <Modal open={props.openAddSquadLeaderModal} onClose={props.handleCloseAddSquadLeaderModal}>
      <Box sx={style} component="form" onSubmit={props.onAddSquadLeaderHandler}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.editMode ? `Update Squad Leader from ${props.squad.squadName}` : `Add Squad Leader to ${props.squad.squadName}`}
        </Typography>
        <SquadLeaderControl
          editMode={props.editMode}
          userId={props.userId}
          isAdmin={props.isAdmin}
          setIsAdmin={props.setIsAdmin}
          isSuperUser={props.isSuperUser}
          setIsSuperUser={props.setIsSuperUser}
          handleSquadLeaderChanged={props.handleSquadLeaderChanged}
        />
        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={props.onCancelAddSquadLeaderModal}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            {props.editMode ? 'Update Squad Leader' : 'Add Squad Leader'}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddSquadLeaderModal;
