import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useEntityContext } from '../../context/EntityContext';

const GroupFeedbackHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { group, setGroup } = useEntityContext();

  const onBackClicked = () => {
    setGroup(null);
  };

  if (!group) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="center" sx={{ backgroundColor: '#EEE' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: '#476B97', color: '#ffffff', px: 1, width: '100%', borderRadius: '4px 4px 0px 0px', flexGrow: 1, height: '84px' }}>
        <Stack direction="row" spacing={2} alignItems="center">
          {isMobile && (
            <>
              <IconButton onClick={onBackClicked}>
                <ArrowBackIosIcon sx={{ color: '#FFF' }} />
              </IconButton>
            </>
          )}
          <Stack>
            <Typography variant="h6" color={'inherit'} fontWeight="500">
              {group.groupName}
            </Typography>
            <Typography variant="body2" color={'inherit'}>
              Group Owner: {group.groupOwner.squadLeader.user.fullName}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GroupFeedbackHeader;
