import { Button, Grid, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import AddUserModal from '../user/AddUserModal';
import EditUserModal from '../user/EditUserModal';
import SquadLeaderControl from './SquadLeaderControl';
import SquadLeadersTable from './SquadLeadersTable';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #476B97',
  boxShadow: 24,
  p: 2,
};

const MAX_CHARACTER_LIMIT: number = 4;

const AddSquadModal = (props: any) => {
  const [fullName, setFullName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [permissions, setPermissions] = useState(0);
  const [openAddUser, setOpenAddUser] = useState(false); 
  const [isTenantManager, setIsTenantManager] = useState(false);
  const handleCloseAddUser = () => setOpenAddUser(false);
  const handleOpenAddUser = () => setOpenAddUser(true);
  const [openEditUser, setOpenEditUser] = useState(false);
  const handleCloseEditUser = () => setOpenEditUser(false);
  const handleOpenEditUser = () => setOpenEditUser(true);
  const [selectedLeader, setSelectedLeader] = useState<any>(null);

  const addUserHandler = async (e: any) => {
    e.preventDefault();

    const squadLeader: any = { userId: 0, fullName, emailAddress, isActive, permissions, isTenantManager };
    const leaders: any[] = [...props.squadLeaders];
    const existing = leaders.find((x) => x.emailAddress === emailAddress);

    if (existing) {
      return;
    }

    leaders.push(squadLeader as never);

    props.setSquadLeaders(leaders);
    resetValues();
  };

  const onEditSquadLeaderHandler = async (e: any) => {
    e.preventDefault();

    const squadLeader: any = { userId: selectedLeader?.userId ?? 0, fullName, emailAddress, isActive, permissions };
    let leaders: any[] = [...props.squadLeaders];

    leaders = leaders.filter((x) => x.emailAddress != selectedLeader.emailAddress);

    const existing = leaders.find((x) => x.emailAddress == emailAddress);

    if (existing) {
      return;
    }

    leaders.push(squadLeader as never);
    props.setSquadLeaders(leaders);
    resetValues();
  };

  const handleEditSquadLeaderPermissions = (squadLeader: any) => {
    setOpenEditUser(true);
    setSelectedLeader(squadLeader);
    setFullName(squadLeader.fullName);
    setEmailAddress(squadLeader.emailAddress);
    setIsActive(squadLeader.isActive ?? true);
    setPermissions(squadLeader.permissions);
    setIsTenantManager(squadLeader.user.isTenantManager);
  };

  const resetValues = () => {
    setOpenAddUser(false);
    setOpenEditUser(false);
    setFullName('');
    setEmailAddress('');
    setIsActive(true);
    setPermissions(0);
  };

  return (
    <>
      <Modal open={props.open} onClose={props.handleClose}>
        <Box sx={style} component="form" onSubmit={props.onSubmitSquadHandler}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Add Tenant
          </Typography>
          <Stack spacing={2}>
            <Grid container columnSpacing={2}>
              <Grid item xs={8}>
                <TextField
                  style={{ width: `100%` }}
                  required
                  variant="outlined"
                  label="Squad Name"
                  id="squadName"
                  value={props.squadName}
                  onChange={(e) => props.setSquadName(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  variant="outlined"
                  label="Squad Short Name"
                  id="shortName"
                  value={props.shortName}
                  inputProps={{ maxLength: MAX_CHARACTER_LIMIT }}
                  helperText={`${MAX_CHARACTER_LIMIT - props.shortName.length}`}
                  onChange={(e) => props.setShortName(e.target.value)}
                />
              </Grid>
            </Grid>
            <SquadLeaderControl
              setOpenAddUser={setOpenAddUser}
              fullName={fullName}
              isActive={isActive}
              permissions={permissions}
              emailAddress={emailAddress}
              setFullName={setFullName}
              setIsActive={setIsActive}
              setPermissions={setPermissions}
              setEmailAddress={setEmailAddress}
              squadLeaders={props.squadLeaders}
              setSquadLeaders={props.setSquadLeaders}
            />
            {props.squadLeaders.length ? (
              <SquadLeadersTable
                squadLeaders={props.squadLeaders}
                handleRemoveSquadLeader={props.handleRemoveSquadLeader}
                handleEditSquadLeaderPermissions={handleEditSquadLeaderPermissions}
              />
            ) : null}
          </Stack>
          <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button variant="outlined" onClick={props.handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" type="submit">
              Add Tenant
            </Button>
          </Stack>
        </Box>
      </Modal>
      <AddUserModal
        openAddUser={openAddUser}
        setOpenAddUser={setOpenAddUser}
        handleOpenAddUser={handleOpenAddUser}
        handleCloseAddUser={handleCloseAddUser}
        addUserHandler={addUserHandler}
        fullName={fullName}
        isActive={isActive}
        permissions={permissions}
        emailAddress={emailAddress}
        isTenantManager={isTenantManager}
        setFullName={setFullName}
        setIsActive={setIsActive}
        setPermissions={setPermissions}
        setIsTenantManager={setIsTenantManager}
        setEmailAddress={setEmailAddress}
      />
      <EditUserModal
        openEditUser={openEditUser}
        setOpenEditUser={setOpenEditUser}
        handleOpenEditUser={handleOpenEditUser}
        handleCloseEditUser={handleCloseEditUser}
        onEditSquadLeaderHandler={onEditSquadLeaderHandler}
        fullName={fullName}
        isActive={isActive}
        permissions={permissions}
        emailAddress={emailAddress}
        isTenantManager={isTenantManager}
        setFullName={setFullName}
        setIsActive={setIsActive}
        setIsTenantManager={setIsTenantManager}
        setPermissions={setPermissions}
        setEmailAddress={setEmailAddress}
      />
    </>
  );
};

export default AddSquadModal;
