import { BaseService } from './BaseService';

export class KuduJobService extends BaseService {
  constructor() {
    super('KuduJob');
  }

  async triggerBulkImportJob() {
    return await this.httpService.post('', {});
  }
}
