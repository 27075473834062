import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { EmployeeService } from 'src/services/EmployeeService';
import { SquadsContext } from 'src/utilities/SquadsContext';

import ChartHeading from '../dashboardShared/ChartHeading';
import QuickFilterCard from '../dashboardShared/QuickFilterCard';
import { useClientManagementDashboardContext } from './context/ClientManagementDashboardContext';
import { useClientManagementFilterContext } from './context/ClientManagementFilterContext';

const employeeService = new EmployeeService();

const ClientManagementQuickFilters: React.FC = () => {
  const { selectedFilter, setSelectedFilter, metrics, startDate, endDate, setStartDate, setEndDate } = useClientManagementFilterContext();
  const squadId = useContext(SquadsContext).squad.squadId;
  const { setEmployees, getAllIncommingEmployees, setIsLoading } = useClientManagementDashboardContext();

  const countIncomming = metrics?.countIncomming;
  const countInterviewing = metrics?.countInterview;
  const countConfirmed = metrics?.countConfirmed;
  const countNotConfirmed = metrics?.countNotConfirmed;
  const countLeftTFO = metrics?.countLeftTFO;

  const handleSelectFilter = (e: React.MouseEvent<HTMLElement>, newFilter: string) => {
    if (newFilter !== selectedFilter) {
      setSelectedFilter(newFilter);
    } else {
      setSelectedFilter('');
    }
  };

  useEffect(() => {
    if (selectedFilter) {
      setEmployeesResult(selectedFilter);
    } else {
      getAllIncommingEmployees();
      setStartDate(null);
      setEndDate(null);
    }
  }, [selectedFilter]);

  const setEmployeesResult = async (filterBy: string) => {
    setIsLoading(true);
    const filteredEmployees = await employeeService.GetIncommingDashboardEmployeeQuickFilter(squadId, filterBy);
    setEmployees(filteredEmployees);

    // apply global date filter when dates are selected.
    if (startDate && endDate)
      setEmployees(
        filteredEmployees.filter((x) => {
          const availableDate = new Date(x.availableDate);
          return availableDate >= new Date(startDate) && availableDate <= new Date(endDate);
        }),
      );

    setIsLoading(false);
  };

  return (
    <>
      <ChartHeading>Quick filters</ChartHeading>
      <Grid container direction={{ xs: 'column', md: 'row', lg: 'column' }} spacing={{ xs: 1, md: 1, lg: 1 }}>
        <Grid item md>
          <QuickFilterCard
            title="Incoming TFO"
            id="countIncomming"
            value="countIncomming"
            count={countIncomming}
            icon={<HourglassEmptyIcon color="primary" />}
            isSelected={selectedFilter === 'countIncomming'}
            onSelect={handleSelectFilter}
          />
        </Grid>
        <Grid item md>
          <QuickFilterCard
            title="Ready for interview"
            id="countInterview"
            value="countInterview"
            count={countInterviewing}
            icon={<EventAvailableIcon color="success" />}
            isSelected={selectedFilter === 'countInterview'}
            onSelect={handleSelectFilter}
          />
        </Grid>
        <Grid item md>
          <QuickFilterCard
            title="Confirmed by Client"
            id="countConfirmed"
            value="countConfirmed"
            count={countConfirmed}
            icon={<HowToRegIcon color="success" />}
            isSelected={selectedFilter === 'countConfirmed'}
            onSelect={handleSelectFilter}
          />
        </Grid>
        <Grid item md>
          <QuickFilterCard
            title="Unconfirmed"
            id="countNotConfirmed"
            value="countNotConfirmed"
            count={countNotConfirmed}
            icon={<MoreTimeIcon color="warning" />}
            isSelected={selectedFilter === 'countNotConfirmed'}
            onSelect={handleSelectFilter}
          />
        </Grid>
        <Grid item md>
          <QuickFilterCard
            title="TFO departures"
            id="countLeftTFO"
            value="countLeftTFO"
            count={countLeftTFO}
            icon={<ExitToAppIcon color="error" />}
            isSelected={selectedFilter === 'countLeftTFO'}
            onSelect={handleSelectFilter}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default ClientManagementQuickFilters;
